import React from "react";

const MobileProgressBar = ({ status }) => {
  return (
    <div className={`order-progress-bar-mobile in-progress ${status}`}>
      <div className="progress-meter-mobile"></div>
    </div>
  );
};

export default MobileProgressBar;
