import React from "react";
import UselessPhoto from "../../utils/img/OBJECTS.svg";

function SelectCampaignModal(props) {
  const {
    setShowCampaignModal,
    campaignDiscount,
    setSelectedCampaign,
    selectedCampaign,
  } = props;
  const handleSelectCampaign = () => {
    setShowCampaignModal(false);
  };
  return (
    <>
      <div className="order-box mb-4">
        {" "}
        <div className="gift-div">
          <p
            style={{
              fontWeight: "bold",
              height: "30px",
              paddingTop: "5px",
            }}
          >
            Kampanya Seçiniz
          </p>
          {/* <img src={ArrorRight} /> */}
        </div>
        {campaignDiscount && (
          <div>
            {" "}
            <div
              style={{
                padding: "10px 10px",
                width: "100%",
              }}
            >
              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {campaignDiscount &&
                    campaignDiscount.map((el) => {
                      return (
                        <div key={el._id} className="campaign-label">
                          <div
                            className="main-radio-button"
                            style={{
                              position: "relative",
                            }}
                          >
                            <img src={UselessPhoto} />
                            <p className="discount-title info">İNDİRİM</p>
                            <p className="discount-title price">
                              {el.discount}TL
                            </p>
                          </div>
                          <label className="campaign-label" htmlFor={el._id}>
                            {el.title}
                          </label>
                          <input
                            style={{
                              marginBottom: "13px",
                            }}
                            className="radio-button-commerce"
                            checked={selectedCampaign?._id === el._id}
                            type="radio"
                            value={el._id}
                            id={el._id}
                            name="invoice"
                            onChange={(e) => {
                              if (selectedCampaign?._id === el._id) {
                                setSelectedCampaign(null);
                                console.log("onchange", el);
                              } else {
                                console.log("el", el);
                                setSelectedCampaign(el);
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SelectCampaignModal;
