import React, { useEffect, useState } from "react";
import { getUserById } from "../../library/network/requests/webusers";
import logo from "../../utils/img/icons/delete.svg";
import AddCreditCard from "./AddCreditCard";
import { deleteCreditCards } from "../../library/network/requests/creditcards";
import SuccessInfo from "./SuccessInfo";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import Loader from "./Loader";
import DeleteModal from "./DeleteModal";
import { Helmet } from "react-helmet-async";
function CreditCards() {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = storageHelper.getStoreWithDecryption("userId");
  const [cardId, setCardId] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [cards, setCards] = useState([]);
  const handleDeleteIconClick = (id) => {
    setCardId(id);
    setShowModal(true);
  };

  const getCards = () => {
    setIsLoading(true);
    getUserById(userId).then((res) => setCards(res?.data.creditCards));
    setIsLoading(false);
  };
  useEffect(() => {
    getCards();
  }, []);

  const handleDelete = async () => {
    const res = await deleteCreditCards(cardId);
    setShowModal(false);
    getCards();
  };
  if (isLoading) return <Loader />;
  return (
    <>
      <Helmet>
        <title>{`Kredi Kartlarım - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>{" "}
      <div className="form-container ">
        <h3 className="mb-4">Kredi Kartlarım</h3>{" "}
        <div className="register-content">
          {cards &&
            cards.map((el) => {
              return (
                <form key={el._id} className="register-form   ">
                  <div className="form-area    container-address">
                    <h5 className="my-3">{el.cardTitle}</h5>
                    <input
                      style={{ width: "100%" }}
                      readOnly
                      defaultValue={el.number}
                      type="text"
                    />{" "}
                    <button
                      onClick={() => handleDeleteIconClick(el._id)}
                      type="button"
                      className="delete-button"
                    >
                      {" "}
                      <img src={logo} />
                    </button>
                  </div>
                </form>
              );
            })}
        </div>
      </div>
      <div>
        <AddCreditCard
          index={0}
          setMessage={setMessage}
          setSuccess={setSuccess}
          getCards={getCards}
        />
      </div>
      {success && <SuccessInfo message={message} />}
      <DeleteModal
        showModal={showModal}
        id={cardId}
        handleDelete={handleDelete}
        setShowModal={setShowModal}
      />
    </>
  );
}

export default CreditCards;
