import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import email from "../../utils/img/mail-svg.svg";

import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import baseService from "../../library/network/services/BaseService";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Bu alan boş bırakılamaz")
    .required("Bu alan boş bırakılamaz"),
});

function ForgotPassword() {
  const [error, setError] = useState("");
  const webUserService = async (values) =>
    baseService.post("webUsers/forgotPassword", values);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{`Şifremi Unuttum - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      <div className="register-content forgot-password">
        <h1>Şifremi Unuttum</h1>
        <small>
          Lütfen eposta adresinizi aşağıya girin. Size bir doğrulama kodu
          göndereceğiz.
        </small>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={LoginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              values.email = values.email.trim().toLowerCase();
              const res = await webUserService(values);
              if (res.data.status === 200) {
                navigate(
                  "/auth/sifre-dogrulama",
                  { state: { email: values.email } },
                  { replace: true }
                );
                setError(res.data.message);
              } else {
                setError("Bir daha deneyiniz");
              }
            } catch (error) {
              console.log(error);
              setError("Bir daha deneyiniz");
            }
          }}
        >
          {({ values }) => (
            <Form className="register-form my-4">
              <div
                style={{
                  position: "relative",
                }}
                className="form-area"
              >
                <label className="web" htmlFor="email">
                  E-posta adresin<span>*</span>
                </label>
                <img src={email} alt="person" className="input-img  mobile" />{" "}
                <Field
                  className="input-mobile"
                  type="text"
                  name="email"
                  value={values.email.trim().toLowerCase()}
                  placeholder="E-posta adresin"
                />
                <ErrorMessage
                  name="data"
                  component="div"
                  className="error-auth"
                />
              </div>
              {error && <div className="my-3 error-auth">{error}</div>}
              <div
                className="web"
                style={{
                  gap: "30px",
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  onClick={() => {
                    handleButtonClicks(
                      "/auth/login",
                      "Giriş Yap",
                      "Şifremi Unuttum"
                    );
                  }}
                  to="/auth/login"
                >
                  <span className="button-new button-ghost">Geri Dön</span>
                </Link>
                <button
                  style={{ fontSize: "14px" }}
                  type="submit"
                  className="button-new button-primary"
                >
                  Kod Gönder
                </button>
              </div>{" "}
              <div
                className="mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <button
                  type="submit"
                  className=" button  button-primary mobile-button-auth"
                >
                  Kod Gönder
                </button>
              </div>
              <Link
                onClick={() => {
                  handleButtonClicks(
                    "/auth/login",
                    "Giriş Yap",
                    "Şifremi Unuttum"
                  );
                }}
                to="/auth/login"
              >
                <span
                  style={{ color: "#676767" }}
                  className=" mobile d-flex justify-content-center align-items-center "
                >
                  Geri Dön
                </span>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default ForgotPassword;
