import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileIcon from "../../utils/img/user-circle.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../../contexts/AccountContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import icon from "../../utils/img/Logo Icon White.svg";
import logo from "../../utils/img/Logo Text Vertical.svg";
import cartIcon from "../../utils/img/icons/ShoppingCartSimple.svg";
import { getAllServiceCurrentCategories } from "../../library/network/requests/serviceCategory";
import Loader from "./Loader";
import { getAllBrands } from "../../library/network/requests/brands";
import baseService from "../../library/network/services/BaseService";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import slugify from "slugify";
import { CartFavoritesContext } from "../../contexts/CartFavoritesContext";
import CartIconButton from "./CartIconButton";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);
  const { logout } = useContext(AccountContext);
  const userEmail = useSelector((state) => state.account?.user);
  const isLoggedIn = useSelector((state) => state.account?.isLoggedIn);
  const [profileOpen, setProfileOpen] = useState(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { cart } = useContext(CartFavoritesContext);
  const location = useLocation();
  const cityService = async () =>
    baseService.get("cities/64672a1631d68191785adcde");
  const [city, setCity] = useState("");
  const getServices = async () => {
    const res = await getAllServiceCurrentCategories();
    setServices(res.data);
  };
  const getCity = async () => {
    setIsLoading(true);
    try {
      const res = await cityService();
      setCity(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleMenuHover = () => {
    setIsOpen(true);
  };

  const handleMenuLeave = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const getBrands = async () => {
    const res = await getAllBrands();
    setBrands(res.data);
  };
  const setLoadingFalse = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  useEffect(() => {
    setIsOpen(false);
    setProfileOpen(false);
  }, [location.pathname]);

  const userId = storageHelper.getStoreWithDecryption("userId");
  const handleMainMenuClick = (menuName, menuUrl) => {
    const website = "https://onlinelostra.com/";
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "mainmenuClicks",
      menuName: menuName,
      menuUrl: website + menuUrl,
    });
  };

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      setLoadingFalse();
    }
    getServices();

    getCity();
    getBrands();
  }, [userEmail, userId]);

  return (
    <>
      <div className="load">
        <span className="card"></span>
        <span className="user"></span>
        <span className="logout"></span>
        <span className="location"></span>
        <span className="receipt"></span>
      </div>

      <header>
        <div
          style={{
            padding: "0 10px",
          }}
          className="container"
        >
          {" "}
          <div className="header-main">
            {" "}
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {" "}
              <CartIconButton mobile={true} />
              <div className="mobile mobile-profile dropdown">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    onClick={() => {
                      if (profileOpen === null) {
                        setProfileOpen(true);
                      } else {
                        setProfileOpen((currValue) => !currValue);
                      }
                    }}
                    className="user-menu has-drop"
                  >
                    <img src={ProfileIcon} alt="profil" />
                  </div>
                </div>

                <div
                  onMouseLeave={() => {
                    setProfileOpen(false);
                  }}
                  style={
                    !profileOpen
                      ? {
                          display: "none",
                        }
                      : { display: "block" }
                  }
                  className="mobile-dropdown-box"
                >
                  {isLoggedIn && userId ? (
                    <div className="inner">
                      <ul>
                        <li
                          onClick={() => {
                            handleMainMenuClick("Profilim", "hesap");
                          }}
                        >
                          <Link className="icon-user" to="/hesap">
                            Profilim
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            handleMainMenuClick(
                              "Siparişlerim",
                              "hesap/siparis"
                            );
                          }}
                        >
                          <Link className="icon-receipt" to="/hesap/siparis">
                            Siparişlerim
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            handleMainMenuClick("Siparişlerim", "hesap/adres");
                          }}
                        >
                          <Link className="icon-location" to="/hesap/adres">
                            Adreslerim
                          </Link>
                        </li>

                        <li
                          onClick={() => {
                            logout();
                            navigate("/");
                            handleMainMenuClick("Çıkış Yap", "");
                          }}
                        >
                          <Link className="icon-power-circle">Çıkış Yap</Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <>
                      <div className="inner">
                        <ul>
                          <li
                            onClick={() => {
                              handleMainMenuClick(
                                "Giriş Yap",
                                "auth/giris-yap"
                              );
                            }}
                          >
                            {" "}
                            <Link to={"/auth/giris-yap"}>Giriş Yap</Link>
                          </li>
                        </ul>
                      </div>{" "}
                    </>
                  )}
                </div>
              </div>{" "}
            </div>
            <div
              onClick={() => {
                handleMainMenuClick("Anasayfa", "");
              }}
            >
              <a
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  alignItems: "center",
                  gap: "5px",
                }}
                href="/"
              >
                <img
                  className="main-logo"
                  style={{ width: "70px" }}
                  src={icon}
                  alt="Online Lostra Logo"
                />
                <img src={logo} alt="Online Lostra Logo" />
              </a>
            </div>
            <div
              onMouseEnter={() => {
                handleMenuHover();
              }}
              onClick={() => setIsOpen((currValue) => !currValue)}
              className="mobile-menu"
            >
              {" "}
              <nav
                onClick={(e) => e.stopPropagation()}
                className="main-nav mobile-nav"
                id="main-nav"
                style={
                  isOpen
                    ? {
                        visibility: "visible",
                        opacity: "1",
                      }
                    : { visibility: "hidden", opacity: "0" }
                }
              >
                <ul>
                  <li className="dropdown">
                    <Link className="has-drop">Hizmetlerimiz</Link>
                    <div className="dropdown-box ">
                      <div className="inner">
                        <ul>
                          {services &&
                            services.map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    handleMainMenuClick(
                                      `${item?.name} Hizmetlerimiz`,
                                      `${slugify(item?.name, {
                                        lower: true,
                                        remove: /[*+~.()'"!:@?]/g,
                                      })}`
                                    );
                                  }}
                                  key={item.id}
                                  id={item.id}
                                >
                                  <Link
                                    to={`/${slugify(item?.name, {
                                      lower: true,
                                      remove: /[*+~.()'"!:@?]/g,
                                    })}`}
                                  >
                                    {item?.name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown">
                    <Link to="#" className="has-drop">
                      Fiyatlar
                    </Link>
                    <div className="dropdown-box mega ">
                      <div className="row inner">
                        {services &&
                          services.map((item) => {
                            const filteredBrands = brands?.filter(
                              (brand) =>
                                brand?.serviceCategoryId?.name === item?.name
                            );

                            const lastThreeBrands = filteredBrands?.slice(0, 3);

                            return (
                              <Fragment key={item.id}>
                                <div className="col-lg-6">
                                  <h3
                                    onClick={() => setActiveMenu(item.name)}
                                    className="has-drop-inner"
                                  >
                                    {item.name} Fiyatları
                                  </h3>
                                  <ul
                                    className={
                                      activeMenu === item.name
                                        ? "mega-list active-price-ul"
                                        : "mega-list"
                                    }
                                  >
                                    {lastThreeBrands.map((brand) => (
                                      <li
                                        onClick={() => {
                                          handleMainMenuClick(
                                            `${
                                              item?.name ===
                                              brand?.serviceCategoryId?.name
                                                ? brand?.title
                                                : null
                                            } Fiyatları`,
                                            `${slugify(brand.title, {
                                              lower: true,
                                              remove: /[*+~.()'"!:@?]/g,
                                            })}-temizleme-fiyati`
                                          );
                                        }}
                                        key={brand?._id}
                                      >
                                        <Link
                                          to={`/${slugify(brand.title, {
                                            lower: true,
                                            remove: /[*+~.()'"!:@?]/g,
                                          })}-temizleme-fiyati`}
                                        >
                                          {item?.name ===
                                          brand?.serviceCategoryId?.name
                                            ? brand?.title
                                            : null}
                                        </Link>
                                      </li>
                                    ))}

                                    <li
                                      onClick={() => {
                                        handleMainMenuClick(
                                          `${item?.name} Fiyatları`,
                                          `${slugify(item?.name, {
                                            lower: true,
                                            remove: /[*+~.()'"!:@?]/g,
                                          })}-fiyatlari`
                                        );
                                      }}
                                      className="all"
                                    >
                                      <Link
                                        to={`/${slugify(item?.name, {
                                          lower: true,
                                          remove: /[*+~.()'"!:@?]/g,
                                        })}-fiyatlari`}
                                      >
                                        {item.name} Modelleri
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </Fragment>
                            );
                          })}

                        <div
                          onClick={() => {
                            handleMainMenuClick(`Fiyatlarımız`, `fiyatlarimiz`);
                          }}
                          className="col-lg-6"
                        >
                          <Link
                            to="/fiyatlarimiz"
                            className="button button-primary"
                          >
                            Tüm Fiyatları Gör
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      handleMainMenuClick(`Kampanyalar`, `kampanyalar`);
                    }}
                  >
                    <Link to="/kampanyalar">Kampanyalar</Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMainMenuClick(`Bloglar`, `bloglar`);
                    }}
                  >
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li
                    onClick={() => {
                      handleMainMenuClick(`Mağaza`, `magaza`);
                    }}
                  >
                    <Link to="/magaza">Mağaza</Link>
                  </li>
                  <li className="dropdown">
                    <Link className="has-drop">Hizmet Bölgeleri</Link>
                    <div className="dropdown-box ">
                      <div className="inner">
                        <ul>
                          {services &&
                            services.map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    handleMainMenuClick(
                                      `${item?.name} Hizmet Bölgeleri`,
                                      `${slugify(item?.name, {
                                        lower: true,
                                        remove: /[*+~.()'"!:@?]/g,
                                      })}-lokasyonlari`
                                    );
                                  }}
                                  key={item.id}
                                  id={item.id}
                                >
                                  <Link
                                    to={`/${slugify(item?.name, {
                                      lower: true,
                                      remove: /[*+~.()'"!:@?]/g,
                                    })}-lokasyonlari`}
                                  >
                                    {item?.name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      handleMainMenuClick(`SSS`, `sikca-sorulan-sorular`);
                    }}
                  >
                    <Link to="/sikca-sorulan-sorular">SSS</Link>
                  </li>
                </ul>
              </nav>
            </div>
            {isLoading || (!userEmail && !isLoggedIn && userId) ? (
              // ||            (!userEmail && isLogged && userId && isLoggedIn)
              <Loader />
            ) : (
              <>
                {" "}
                <nav
                  className="main-nav web"
                  onMouseLeave={handleMenuLeave}
                  id="main-nav"
                >
                  <ul>
                    <li className="dropdown">
                      <Link className="has-drop">Hizmetlerimiz</Link>
                      <div className="dropdown-box">
                        <div className="inner">
                          <ul>
                            {services &&
                              services.map((item) => (
                                <li
                                  onClick={() => {
                                    handleMainMenuClick(
                                      `${item?.name} Hizmetlerimiz`,
                                      `${slugify(item?.name, {
                                        lower: true,
                                        remove: /[*+~.()'"!:@?]/g,
                                      })}`
                                    );
                                  }}
                                  key={item.id}
                                  id={item.id}
                                >
                                  <Link
                                    to={`/${slugify(item?.name, {
                                      lower: true,
                                      remove: /[*+~.()'"!:@?]/g,
                                    })}`}
                                  >
                                    {item?.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        handleMainMenuClick(`Mağaza`, `magaza`);
                      }}
                    >
                      <Link to="/magaza">Mağaza</Link>
                    </li>
                    <li className="dropdown">
                      <Link to="#" className="has-drop">
                        Fiyatlar
                      </Link>
                      <div className="dropdown-box mega">
                        <div className="row inner">
                          {services &&
                            services.map((item) => {
                              const filteredBrands = brands?.filter(
                                (brand) =>
                                  brand?.serviceCategoryId?.name === item?.name
                              );

                              const lastThreeBrands = filteredBrands?.slice(
                                0,
                                3
                              );

                              return (
                                <Fragment key={item.id}>
                                  <div className="col-lg-6">
                                    <h3
                                      onClick={() => setActiveMenu(item.name)}
                                      className="has-drop-inner"
                                    >
                                      {item.name} Fiyatları
                                    </h3>
                                    <ul
                                      className={
                                        activeMenu === item.name
                                          ? "mega-list active-price-ul"
                                          : "mega-list"
                                      }
                                    >
                                      {lastThreeBrands.map((brand) => (
                                        <li
                                          onClick={() => {
                                            handleMainMenuClick(
                                              `${
                                                item?.name ===
                                                brand?.serviceCategoryId?.name
                                                  ? brand?.title
                                                  : null
                                              } Fiyatları`,
                                              `${slugify(brand.title, {
                                                lower: true,
                                                remove: /[*+~.()'"!:@?]/g,
                                              })}-temizleme-fiyati`
                                            );
                                          }}
                                          key={brand?._id}
                                        >
                                          <Link
                                            to={`/${slugify(brand.title, {
                                              lower: true,
                                              remove: /[*+~.()'"!:@?]/g,
                                            })}-temizleme-fiyati`}
                                          >
                                            {item?.name ===
                                            brand?.serviceCategoryId?.name
                                              ? brand?.title
                                              : null}
                                          </Link>
                                        </li>
                                      ))}

                                      <li
                                        onClick={() => {
                                          handleMainMenuClick(
                                            `${item?.name} Fiyatları`,
                                            `${slugify(item?.name, {
                                              lower: true,
                                              remove: /[*+~.()'"!:@?]/g,
                                            })}-fiyatlari`
                                          );
                                        }}
                                        className="all"
                                      >
                                        <Link
                                          to={`/${slugify(item?.name, {
                                            lower: true,
                                            remove: /[*+~.()'"!:@?]/g,
                                          })}-fiyatlari`}
                                        >
                                          {item.name} Modelleri
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </Fragment>
                              );
                            })}

                          <div
                            onClick={() => {
                              handleMainMenuClick(
                                `Fiyatlarımız`,
                                `fiyatlarimiz`
                              );
                            }}
                            className="col-lg-6"
                          >
                            <Link
                              to="/fiyatlarimiz"
                              className="button button-primary"
                            >
                              Tüm Fiyatları Gör
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="dropdown">
                      <Link className="has-drop">Hizmet Bölgeleri</Link>
                      <div className="dropdown-box">
                        <div className="inner">
                          <ul>
                            {services &&
                              services.map((item) => (
                                <li
                                  onClick={() => {
                                    handleMainMenuClick(
                                      `${item?.name} Hizmet Bölgeleri`,
                                      `${slugify(item?.name, {
                                        lower: true,
                                        remove: /[*+~.()'"!:@?]/g,
                                      })}-lokasyonlari`
                                    );
                                  }}
                                  key={item.id}
                                  id={item.id}
                                >
                                  <Link
                                    to={`/${slugify(item?.name, {
                                      lower: true,
                                      remove: /[*+~.()'"!:@?]/g,
                                    })}-lokasyonlari`}
                                  >
                                    {item?.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="dropdown">
                      <Link className="has-drop">Diger</Link>
                      <div className="dropdown-box">
                        <div className="inner">
                          <ul>
                            <li
                              onClick={() => {
                                handleMainMenuClick(
                                  `Kampanyalar`,
                                  `kampanyalar`
                                );
                              }}
                            >
                              <Link to="/kampanyalar">Kampanyalar</Link>
                            </li>
                            <li
                              onClick={() => {
                                handleMainMenuClick(`Bloglar`, `bloglar`);
                              }}
                            >
                              <Link to="/blog">Blog</Link>
                            </li>
                            <li
                              onClick={() => {
                                handleMainMenuClick(`Mağaza`, `magaza`);
                              }}
                            >
                              <Link to="/magaza">Mağaza</Link>
                            </li>
                            <li
                              onClick={() => {
                                handleMainMenuClick(
                                  `SSS`,
                                  `sikca-sorulan-sorular`
                                );
                              }}
                            >
                              <Link to="/sikca-sorulan-sorular">SSS</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
                <div
                  style={{
                    margin: "0 10px",
                  }}
                  id="main-actions"
                >
                  {userId && isLoggedIn ? (
                    <>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="dropdown">
                          <div className=" web user-menu has-drop">
                            <h6>Hesabım</h6>
                            <span>{userEmail}</span>
                          </div>
                          <div className="dropdown-box">
                            <div className="inner">
                              <ul>
                                <li
                                  onClick={() => {
                                    handleMainMenuClick("Profilim", "hesap");
                                  }}
                                >
                                  <Link className="icon-user" to="/hesap">
                                    Profilim
                                  </Link>
                                </li>
                                <li
                                  onClick={() => {
                                    handleMainMenuClick(
                                      "Siparişlerim",
                                      "hesap/siparis"
                                    );
                                  }}
                                >
                                  <Link
                                    className="icon-receipt"
                                    to="/hesap/siparis"
                                  >
                                    Siparişlerim
                                  </Link>
                                </li>
                                <li
                                  onClick={() => {
                                    handleMainMenuClick(
                                      "Siparişlerim",
                                      "hesap/adres"
                                    );
                                  }}
                                >
                                  <Link
                                    className="icon-location"
                                    to="/hesap/adres"
                                  >
                                    Adreslerim
                                  </Link>
                                </li>
                                <li
                                  onClick={() => {
                                    logout();
                                    // navigate("/");
                                    handleMainMenuClick("Anasayfa", "");
                                  }}
                                >
                                  <Link className="icon-power-circle">
                                    Çıkış Yap
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* <div className="dropdown">
                          <div className="has-drop">hi</div>
                          <div className="dropdown-box">
                            <div className="inner">
                              <ul>
                                <li>hi</li>
                              </ul>
                            </div>
                          </div>
                        </div>{" "} */}
                        <CartIconButton mobile={false} />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                        // width: "190px",
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Link to={"/auth/giris-yap"}>
                        <p
                          onClick={() => {
                            handleMainMenuClick("Giriş Yap", "auth/giris-yap");
                          }}
                          to="#"
                          className="button button-menu button-primary login-button"
                        >
                          Giriş Yap
                        </p>
                      </Link>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {" "}
                        <CartIconButton mobile={false} />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </header>
      <div id="menu-overlay"></div>
    </>
  );
}

export default Header;
