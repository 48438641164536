import React from "react";

function Process() {
  return (
    <>
      <section id="process" className="light-bg">
        <div className="container">
          <div className="title">
            <h4>Süreç</h4>
            <h2
              style={{
                marginBottom: "80px",
              }}
            >
              Lostra hizmetimiz hangi aşamalardan oluşuyor?
            </h2>
          </div>
          <div className="process">
            <div className="process-box">
              <div className="number">
                <span>
                  <span>1</span>
                </span>
              </div>
              <div className="content">
                <h5>Siparişini Oluştur</h5>
                <p>
                  Yaptırmak istediğin hizmetini ve müsait tarihini seç ve
                  hızlıca siparişini oluştur.
                </p>
              </div>
            </div>
            <div className="process-box">
              <div className="number">
                <span>
                  <span>2</span>
                </span>
              </div>
              <div className="content">
                <h5>Jet Kuryeye Teslim Et</h5>
                <p>
                  Ürünleri kuryemize teslim edin. Ardından fiyat bilgisini
                  gönderelim.
                </p>
              </div>
            </div>
            <div className="process-box">
              <div className="number">
                <span>
                  <span>3</span>
                </span>
              </div>
              <div className="content">
                <h5>Siparişini Teslim Al</h5>
                <p>
                  Ürünlerini belirtilen gün içerisinde adresine teslim edelim.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Process;
