import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";
import slugify from "slugify";

function PricesBox({ prices }) {
  return (
    <>
      {prices.map((item) => (
        <Fragment key={item.id ?? item._id}>
          <div className="col-lg-6">
            <ul>
              <Link
                onClick={() => {
                  handleButtonClicks(
                    `/${slugify(item?.title, {
                      lower: true,
                      remove: /[*+~.()'"!:@?]/g,
                    })}-temizleme-fiyati`,
                    item?.title,
                    "Fiyatlarımız"
                  );
                }}
                to={`/${slugify(item?.title, {
                  lower: true,
                  remove: /[*+~.()'"!:@?]/g,
                })}-temizleme-fiyati`}
              >
                <li>
                  <p style={{ color: "#000" }}>{item.title}</p>
                  <p style={{ color: "#4d66ff", fontWeight: "bold" }}>
                    {item.price}TL
                  </p>
                </li>
              </Link>
            </ul>
          </div>
        </Fragment>
      ))}
    </>
  );
}

export default PricesBox;
