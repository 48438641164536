import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AccountProvider from "./contexts/AccountContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import AppLayout from "./layouts/app-layouts/index";
import AuthLayout from "./layouts/auth-layouts/Auth-layout";
import ErrorBoundary from "./components/app-layouts/ErrorBoundary";
import { useSelector } from "react-redux";
import { storageHelper } from "./utils/helpers/StorageHelper";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./components/pages/NotFound";
import Home from "./components/pages/Home/Home";
import { CartFavoritesProvider } from "./contexts/CartFavoritesContext";

function App() {
  const userId = storageHelper.getStoreWithDecryption("userId");

  const isLoggedIn = useSelector((state) => state.account?.isLoggedIn);
  return (
    <>
      <HelmetProvider>
        <CartFavoritesProvider>
          <AccountProvider>
            <LoadingProvider>
              <ErrorBoundary>
                <Suspense fallback={<Home />}>
                  <Routes>
                    <Route path={`/*`} element={<AppLayout />} />
                    {userId || isLoggedIn ? (
                      <Route path={`/*`} element={<AppLayout />} />
                    ) : (
                      <Route path={`/auth/*`} element={<AuthLayout />} />
                    )}
                    <Route path="/404-not-found" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </LoadingProvider>
          </AccountProvider>
        </CartFavoritesProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
