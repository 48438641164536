import React, { useEffect, useState } from "react";
import PricesDrawer from "../../app-layouts/PricesDrawer";
import Comments from "../../app-layouts/Comments";
import SssPanel from "../../app-layouts/SssPanel";
import Process from "../../app-layouts/Process";
import Privileges from "../../app-layouts/Privileges";
import { Link } from "react-router-dom";
import Loader from "../../app-layouts/Loader";
import { getAllBrands } from "../../../library/network/requests/brands";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";

function AllPrices() {
  const [isClicked, setIsClicked] = useState("");
  const [lostraPrices, setLostraPrices] = useState([]);
  const [kuruTemizlemePrices, setKuruTemizlemePrices] = useState([]);
  const [cantaTemizlemePrices, setCantaTemizlemePrices] = useState([]);
  const [camasirYikamaPrices, setCamasirYikamaPrices] = useState([]);
  const [utulemePrices, setUtulemePrices] = useState([]);

  const handleClick = (item) => {
    if (isClicked === item) {
      setIsClicked("");
    } else {
      setIsClicked(item);
    }
  };

  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    getPrices();
  }, []);
  const getPrices = async () => {
    setLoading(true);

    const res = await getAllBrands();
    const data = res.data;
    const lostraPrices = data?.filter(
      // (item) => item.serviceCategoryId.name === "Ayakkabı Temizleme"
      (item) => item.serviceCategoryId?.name.includes("Ayakkabı")
    );
    const sortedLostraPrices = sortDatas(lostraPrices);
    setLostraPrices(sortedLostraPrices);
    const cantaTemizlemePrices = data?.filter((item) =>
      item.serviceCategoryId?.name.includes("Çanta")
    );
    const sortedCantaTemizlemePrices = sortDatas(cantaTemizlemePrices);
    setCantaTemizlemePrices(sortedCantaTemizlemePrices);

    // const kuruTemizlemePrices = data?.filter(
    //   // (item) => item.serviceCategoryId.name === "Kuru Temizleme"
    //   (item) => item.serviceCategoryId?.name.includes("Kuru")
    // );
    // setKuruTemizlemePrices(kuruTemizlemePrices);

    // const camasirYikamaPrices = data?.filter((item) =>
    //   item.serviceCategoryId?.name.includes("Çamaşır")
    // );
    // setCamasirYikamaPrices(camasirYikamaPrices);

    // const utulemePrices = data?.filter((item) =>
    //   item.serviceCategoryId?.name.includes("Ütüleme")
    // );
    // setUtulemePrices(utulemePrices);

    setLoading(false);
  };
  const sortDatas = (unsortedData) => {
    const data = unsortedData.sort((a, b) => {
      const priceA = Number(a.price);
      const priceB = Number(b.price);

      if (isNaN(priceA) && isNaN(priceB)) {
        return 0;
      } else if (isNaN(priceA)) {
        return -1;
      } else if (isNaN(priceB)) {
        return 1;
      } else {
        return priceA - priceB;
      }
    });
    const tamir = data.filter((item) => item?.type === "tamir");
    const bakim = data.filter((item) => item?.type === "bakim");
    return [...tamir, ...bakim];
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{`Fiyat Listesi - Online Lostra`}</title>
        <meta
          name="description"
          content="Ayakkabı temizleme hizmeti fiyatları hakkında bilgi almak ve Online Lostra'da siparişinizi oluşturun ve kusursuz hizmetimizden yararlanın!"
        />
      </Helmet>

      <section className="page-section bg-element-2">
        {" "}
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Fiyat Listesi", url: "/fiyatlar" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>fiyatlarımız</h4>
            <h1>Fiyat Listeleri</h1>
          </div>
          <div className="faq">
            <PricesDrawer
              prices={lostraPrices}
              handleClick={handleClick}
              isClicked={isClicked}
              title={"Ayakkabı Temizleme Fiyat Listesi 👟"}
            />
            {/* <PricesDrawer
              prices={kuruTemizlemePrices}
              handleClick={handleClick}
              isClicked={isClicked}
              title={"Kuru Temizleme Fiyat Listesi 👗"}
            /> */}
            <PricesDrawer
              prices={cantaTemizlemePrices}
              handleClick={handleClick}
              isClicked={isClicked}
              title={"Çanta Temizleme Fiyat Listesi 👜"}
            />
            {/* <PricesDrawer
              prices={camasirYikamaPrices}
              handleClick={handleClick}
              isClicked={isClicked}
              title={"Çamaşır Yıkama Fiyat Listesi 👕"}
            /> */}
            {/* <PricesDrawer
              prices={utulemePrices}
              handleClick={handleClick}
              isClicked={isClicked}
              title={"Ütüleme Fiyat Listesi 👔"}
            /> */}
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-center mb-5">
        <div className="title">
          <Link
            onClick={() => {
              handleButtonClicks(
                "siparis-olustur",
                "Sipariş Oluştur",
                "Fiyatlarimiz"
              );
            }}
            to="/siparis-olustur"
          >
            <h2 className="button button-primary">Sipariş Oluştur</h2>
          </Link>
        </div>
      </div>

      <Privileges />
      <SssPanel pageType="general" category="general" />
    </>
  );
}

export default AllPrices;
