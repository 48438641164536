import React, { useEffect, useState } from "react";
import cancel from "../../utils/img/icons/cancel-gray.svg";

import NewAddress from "./NewAddress";
import baseService from "../../library/network/services/BaseService";
import {
  getUserAddress,
  getUserById,
} from "../../library/network/requests/webusers";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import AddCreditCard from "./AddCreditCard";
import Loader from "./Loader";

function OrderConfirmPreview({
  setPromoCodeData,
  offers,
  title,
  totalPrice,
  campaignDiscount,
  promoObj,
  setPromoObj,
  setNote,
  discount,
  discountType,
  promoValue,
  setPromoValue,
  handlePromoCode,
  error,
  message,
  setMessage,
  setPromo,
  change,
  setChange,
  setSuccess,
  getOffersById,
}) {
  const [adressResponse, setAddressResponse] = useState([]);
  const [newEntry, setNewEntry] = useState("");
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [mahalle, setMahalle] = useState([]);
  const userId = storageHelper.getStoreWithDecryption("userId");
  const [address, setAddress] = useState([]);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const deletePromoService = async (id, data) =>
    baseService.post("promocodes/delete/" + id, { promoCode: data });
  const [isClicked, setIsClicked] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const token = storageHelper.getStoreWithDecryption("token");
  const getCards = () => {
    setIsLoading(true);
    getUserById(userId).then((res) => setCards(res?.data.creditCards));
    setIsLoading(false);
  };

  const cityService = async () => baseService.get("cities");

  const getCities = async () => {
    const res = await cityService();
    setCityData(res.data);
  };
  const minPrice = 399;

  const getDistricts = async (il) => {
    const filteredData = cityData.filter((item) => item.city === il);
    setDistrictData(filteredData);
  };

  const getNeighborhood = (il) => {
    const filteredData = districtData[0].district.filter(
      (item) => item.name === il
    );
    setMahalle(filteredData);
  };

  const getAddress = async () => {
    try {
      if (userId) {
        setIsLoading(true);
        const res = await getUserAddress(userId);
        setAddress(res?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setAddress([]);
    }
  };

  useEffect(() => {
    getAddress();
    getCities();
    getCards();
  }, [userId]);

  const handleNewAddressChange = (address) => {
    setSelectedAddress(address._id);
  };
  const handleDeletePromo = async () => {
    try {
      const res = await deletePromoService(
        offers[0]?.orderId._id,
        promoObj?._id
      );
      if (res.status === 200) {
        setMessage("Promosyon kodu silindi.");
        setPromo("");
        setPromoObj(null);
        setPromoValue("");
        setPromoCodeData(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function formatDate(dateStr) {
    if (dateStr) {
      const parts = dateStr.split("/");
      if (parts.length === 3) {
        const day = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1;
        const year = parseInt(parts[2]);
        const date = new Date(year, month, day);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return date.toLocaleDateString("tr-TR", options);
      }
    }
    return null;
  }

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="col-xxl-5 col-xl-4 col-lg-5">
        <div className="load">
          <div className="plus-div active"> </div>
          <div className="gift-div active"> </div>
        </div>{" "}
        <div className="order-box">
          <h3 className="mb-4">
            {change === "order"
              ? "Sipariş Özeti"
              : change === "card"
              ? "Kartı Değiştir"
              : "Adresi Değiştir"}
          </h3>
          <div className="arrow-div"> </div>

          {change === "order" && (
            <>
              <table>
                <tbody>
                  <tr>
                    <td>Alım tarihi:</td>
                    {offers[0]?.orderId?.pickUpDate ? (
                      <td>
                        {formatDate(offers[0]?.orderId?.pickUpDate)} /{" "}
                        <span>{offers[0]?.orderId?.pickupTimeRange}</span>
                      </td>
                    ) : (
                      <td>Kargo ile gönderim.</td>
                    )}
                  </tr>
                  <tr>
                    <td>Teslimat tarihi: </td>
                    <td>5-15 iş günü içerisinde tamamlanacaktır.</td>
                  </tr>
                </tbody>
              </table>
              <div className="order-field">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Teslim Adresi:</h5>
                  <span
                    onClick={() => setChange("address")}
                    style={{
                      color: "rgb(62, 87, 254)",
                      marginBottom: "0.5rem",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Adresi Değiştir
                  </span>
                </div>
                <p>{offers[0]?.orderId?.address[0]?.address}</p>
              </div>
              <div className="order-field">
                <h5>Notunuz:</h5>
                <textarea
                  onChange={(e) => setNote(e.target.value)}
                  name="note"
                  id="note"
                  maxLength="500"
                  cols="30"
                  rows="6"
                  placeholder="Bizlere iletmek istediğiniz notu bu alana yazabilirsiniz."
                />
              </div>
              <div className="order-field d-flex flex-column justify-content-between">
                <div className="mb-4">
                  {" "}
                  {campaignDiscount && (
                    <div className="promo-info">
                      <p className="text-success">{campaignDiscount?.title}</p>
                    </div>
                  )}{" "}
                </div>
                <div className="order-field d-flex  justify-content-between">
                  <h5>Toplam Tutar:</h5>
                  <h5>{totalPrice < 0 ? 0 : totalPrice.toFixed(2)}₺</h5>
                </div>
                {discount ? (
                  <div className="order-field d-flex  justify-content-between align-items-center">
                    <h5>{title} :</h5>
                    <h6
                      style={{
                        color: "#46f86d",
                      }}
                    >
                      {discountType === "percentage" ? (
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          {" "}
                          <div
                            onClick={handleDeletePromo}
                            style={{
                              position: "absolute",
                              right: "-10px",
                              cursor: "pointer",
                              top: "-20px",
                            }}
                          >
                            {" "}
                            <img src={cancel} />
                          </div>
                          <span>
                            -{discount.toFixed(2)}% ({" "}
                            {((totalPrice * discount) / 100).toFixed(2)}₺ )
                          </span>
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={handleDeletePromo}
                              style={{
                                position: "absolute",
                                right: "-10px",
                                cursor: "pointer",
                                top: "-20px",
                              }}
                            >
                              {" "}
                              <img src={cancel} />{" "}
                            </div>
                            <span>-{discount.toFixed(2)}₺</span>
                          </div>
                        </>
                      )}
                    </h6>
                  </div>
                ) : (
                  <div className=" my-1">
                    <div
                      className="input-group"
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        style={{
                          borderRadius: "15px",
                        }}
                        type="text"
                        onChange={(e) => setPromoValue(e.target.value)}
                        id="promoCode"
                        className="promo-input"
                        value={promoValue}
                        placeholder="Promosyon kodu"
                      />
                      <button
                        onClick={handlePromoCode}
                        type="button"
                        id="promoCode"
                        disabled={promoValue.length < 1}
                        // disabled
                        className="promo-button"
                      >
                        Uygula
                      </button>
                    </div>
                    <div>
                      <h6 className="text-danger my-4">{error}</h6>
                    </div>
                    {message && (
                      <div className="promo-info">
                        <p className="text-success">{message}</p>
                        <div
                          className="img-div"
                          onClick={() => {
                            setMessage("");
                            setPromo("");
                            setPromoObj(null);
                          }}
                        >
                          <img src={cancel} alt="" className="cancel" />
                        </div>
                      </div>
                    )}{" "}
                  </div>
                )}
              </div>

              {discount ? (
                <div className=" d-flex flex-column justify-content-between">
                  <h5>Ödenecek Tutar :</h5>
                  <h2 className="price-sum">
                    {discountType === "percentage" ? (
                      <>
                        {campaignDiscount ? (
                          <span>
                            {" "}
                            {totalPrice - (totalPrice * discount) / 100 <= 0
                              ? 399
                              : (
                                  totalPrice -
                                  (totalPrice * discount) / 100 -
                                  campaignDiscount.discount
                                )?.toFixed(2)}{" "}
                            ₺
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {totalPrice - (totalPrice * discount) / 100 <=
                            minPrice
                              ? minPrice
                              : (
                                  totalPrice -
                                  (totalPrice * discount) / 100
                                ).toFixed(2)}{" "}
                            ₺
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {campaignDiscount ? (
                          <span>
                            {totalPrice - discount?.toFixed(2) <= minPrice
                              ? minPrice
                              : (
                                  totalPrice -
                                  discount -
                                  campaignDiscount.discount
                                )?.toFixed(2)}{" "}
                            ₺
                          </span>
                        ) : (
                          <span>
                            {totalPrice - discount?.toFixed(2) <= minPrice
                              ? minPrice
                              : (totalPrice - discount)?.toFixed(2)}{" "}
                            ₺
                          </span>
                        )}
                      </>
                    )}
                  </h2>
                </div>
              ) : (
                <>
                  {promoObj?.discountType ? (
                    <>
                      {" "}
                      <div className="d-flex flex-column justify-content-between">
                        <h5>Ödenecek Tutar :</h5>
                        {/* <h2 className="price-sum">{totalPrice.toFixed(2)}₺</h2> */}
                        {promoObj?.discountType === "percentage" ? (
                          <>
                            {campaignDiscount ? (
                              <h2 className="price-sum">
                                {totalPrice -
                                  (totalPrice * promoObj?.discount) / 100 <=
                                minPrice
                                  ? minPrice
                                  : (
                                      totalPrice -
                                      (totalPrice * promoObj?.discount) / 100 -
                                      campaignDiscount.discount
                                    )?.toFixed(2)}
                                ₺
                              </h2>
                            ) : (
                              <h2 className="price-sum">
                                {totalPrice -
                                  (totalPrice * promoObj?.discount) / 100 <=
                                minPrice
                                  ? minPrice
                                  : (
                                      totalPrice -
                                      (totalPrice * promoObj?.discount) / 100
                                    )?.toFixed(2)}
                                ₺
                              </h2>
                            )}
                          </>
                        ) : (
                          <>
                            {campaignDiscount ? (
                              <h2 className="price-sum">
                                {totalPrice - promoObj?.discount <= minPrice
                                  ? minPrice
                                  : (
                                      totalPrice -
                                      promoObj.discount -
                                      campaignDiscount.discount
                                    )?.toFixed(2)}
                                ₺
                              </h2>
                            ) : (
                              <h2 className="price-sum">
                                {totalPrice - promoObj?.discount <= minPrice
                                  ? minPrice
                                  : (totalPrice - promoObj.discount)?.toFixed(
                                      2
                                    )}
                                ₺
                              </h2>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="d-flex flex-column justify-content-between">
                        <h5>Ödenecek Tutar :</h5>
                        {campaignDiscount ? (
                          <h2 className="price-sum">
                            {totalPrice - campaignDiscount.discount <= minPrice
                              ? minPrice
                              : (
                                  totalPrice - campaignDiscount.discount
                                )?.toFixed(2)}
                            ₺
                          </h2>
                        ) : (
                          <h2 className="price-sum">
                            {totalPrice <= minPrice
                              ? minPrice
                              : totalPrice.toFixed(2)}
                            ₺
                          </h2>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
              <button type="submit" className="button button-primary w-100">
                Siparişi Tamamla
              </button>
            </>
          )}
          {change === "address" && (
            <NewAddress
              index={1}
              setAddressResponse={setAddressResponse}
              handleNewAddressChange={handleNewAddressChange}
              setSuccess={setSuccess}
              setMessage={setMessage}
              setIsClicked={setIsClicked}
              getAddress={getAddress}
              setNewEntry={setNewEntry}
              setCityData={setCityData}
              cityData={cityData}
              getCities={getCities}
              getDistricts={getDistricts}
              getNeighborhood={getNeighborhood}
              mahalle={mahalle}
              setMahalle={setMahalle}
              districtData={districtData}
              setDistrictData={setDistrictData}
              selectedNeighborhood={selectedNeighborhood}
              setSelectedNeighborhood={setSelectedNeighborhood}
              change={change}
              setChange={setChange}
              address={address}
              selectedAdd={offers[0]?.orderId?.address[0]?._id}
              getOffersById={getOffersById}
            />
          )}
          {change === "card" && (
            <AddCreditCard
              index={0}
              setMessage={setMessage}
              setSuccess={setSuccess}
              getCards={getCards}
              change={change}
              setChange={setChange}
              cards={cards}
              selectedCard={offers[0]?.orderId?.cardID?._id}
              setIsClicked={setIsClicked}
              getOffersById={getOffersById}
            />
          )}
          <p className="min-total">Minimum sipariş tutarı 399₺'dir.</p>
        </div>
      </div>
      {/* {success && <SuccessInfo message={message} />} */}
    </>
  );
}

export default OrderConfirmPreview;
