import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import {
  addUserAddress,
  updateWebuserAddress,
} from "../../library/network/requests/webusers";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import { userAddressUpdate } from "../../library/network/requests/address";
import { useParams } from "react-router-dom";
import baseService from "../../library/network/services/BaseService";

const AddressSchema = Yup.object().shape({
  title: Yup.string().required("Adres başlığı zorunludur."),
  address: Yup.string().required("Adres zorunludur."),
  city: Yup.string()
    .notOneOf(["il"], "Lütfen bir il seçin.")
    .required("İl zorunludur."),
  district: Yup.string()
    .notOneOf(["ilce"], "Lütfen bir ilçe seçin.")
    .required("İlçe zorunludur."),
});

function NewAddress({
  title,
  setAddressResponse,
  ecommerce,
  index,
  handleNewAddressChange,
  updateValue,
  getAddress,
  setIsClicked,
  setMessage,
  mahalle,
  setMahalle,
  cityData,
  getDistricts,
  getNeighborhood,
  selectedNeighborhood,
  setSelectedNeighborhood,
  setSuccess,
  setDistrictData,
  districtData,
  change,
  setChange,
  address,
  selectedAdd,
  getOffersById,
}) {
  const params = useParams();

  // if (index === 1) {
  // }
  const addEcommerceService = async (userId, values) =>
    baseService.post("webusers/ecommerceaddress" + "/" + userId, values);
  const userId = storageHelper.getStoreWithDecryption("userId");
  useEffect(() => {
    mahalle.sort((a, b) => {
      return a.district.localeCompare(b.district);
    });
    cityData.sort((a, b) => {
      return a.city.localeCompare(b.city);
    });
  }, []);

  const handleChange = async (item) => {
    try {
      const res = await userAddressUpdate(params.id, { address: item });
      getAddress();
      if (res.status === 200) {
        setMessage("Adresiniz başarıyla değişmiştir.");
        setSuccess(true);
        getOffersById();
        setIsClicked && setIsClicked((prevstate) => !prevstate);
        setTimeout(() => {
          setSuccess(false);
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div style={{ width: "100%" }} className="form-container">
        {change && (
          <>
            <h3 className="contact-form-title">Kayıtlı Adreslerim</h3>
            <div className="divider">
              <span></span>
            </div>
            <div className="register-content">
              {React.Children.toArray(
                address.map((item) => (
                  <>
                    <label
                      style={{
                        padding: "10px 15px",
                        background: "#f1f1f1",
                        cursor: "pointer",
                        border:
                          selectedAdd === item._id
                            ? "2px solid var(--primary-blue)"
                            : "none",
                      }}
                      className="d-flex form-area terms align-items-center justify-content-between order-box"
                    >
                      <div>
                        <h5 style={{ marginBottom: "5px" }}>{item.title}</h5>
                        <small style={{ fontWeight: "400", fontSize: "14px" }}>
                          {item.address}
                          <br />
                          {item.district},{item.city}
                        </small>
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center main-radio-button">
                        <input
                          type="radio"
                          className="m-0"
                          name="address"
                          value={item.title}
                          checked={selectedAdd === item._id}
                          onChange={() => handleChange(item)}
                        />
                        <small style={{ fontWeight: "400", fontSize: "14px" }}>
                          Seç
                        </small>
                      </div>
                    </label>
                  </>
                ))
              )}
            </div>
          </>
        )}

        <h3 className="contact-form-title">
          {title ? title : "Yeni Adres Ekle"}
        </h3>
        <div className="register-content">
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: updateValue?.title || "",
              address: updateValue?.address || "",
              city: updateValue?.city || "",
              district: updateValue?.district || "",
              neighborhood: updateValue?.neighborhood?.neighborhood || {},
            }}
            validationSchema={AddressSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                if (updateValue?._id !== undefined) {
                  const model = {
                    title: values.title,
                    address: values.address,
                    city: values.city,
                    district: values.district,
                    neighborhood: selectedNeighborhood,
                  };
                  const res = await updateWebuserAddress(
                    updateValue._id,
                    userId,
                    model
                  );

                  setSelectedNeighborhood("");
                  setDistrictData([]);
                  setMahalle([]);
                  getAddress();
                  resetForm();
                } else {
                  const model = {
                    title: values.title,
                    address: values.address,
                    city: values.city,
                    district: values.district,
                    neighborhood: selectedNeighborhood,
                  };
                  let res;

                  res = await addUserAddress(userId, model);

                  getAddress();
                  if (res.status === 200) {
                    setMessage("Yeni Adresiniz başarıyla eklenmiştir.");
                    setSuccess(true);

                    setIsClicked && setIsClicked((prevstate) => !prevstate);
                    setTimeout(() => {
                      setSuccess(false);
                    }, 1500);
                  }

                  if (index === 1) {
                    setAddressResponse(
                      res.data.addresses[res.data.addresses.length - 1]
                    );
                    handleNewAddressChange(
                      res.data.addresses[res.data.addresses.length - 1]
                    );
                  }
                  setSelectedNeighborhood("");
                  setDistrictData([]);
                  resetForm();
                  setMahalle([]);
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="register-form">
                <div className="form-area">
                  <label htmlFor="title">Adres Başlığı</label>
                  <Field type="text" name="title" placeholder="Adres Başlığı" />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div className="form-area">
                  <label htmlFor="address">Adres </label>
                  <Field type="text" name="address" placeholder="Adres" />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div className="form-area">
                  <label htmlFor="city" style={{ display: "block" }}>
                    İl
                  </label>
                  <select
                    onChange={(e) => {
                      setFieldValue("city", e.target.value);
                      getDistricts(e.target.value);
                    }}
                    name="city"
                    value={values.city}
                    style={{ display: "block" }}
                  >
                    <option style={{ display: "none" }} value="">
                      İl seçiniz
                    </option>
                    {cityData &&
                      cityData.map((city) => {
                        return (
                          <option
                            label={city.city}
                            key={city._id}
                            value={city.city}
                          >
                            {city.city}
                          </option>
                        );
                      })}
                  </select>
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className="form-area"
                >
                  <div style={{ flex: "1" }}>
                    {" "}
                    <label htmlFor="district" style={{ display: "block" }}>
                      İlçe
                    </label>
                    <select
                      onChange={(e) => {
                        setFieldValue("district", e.target.value);
                        getNeighborhood(e.target.value);
                      }}
                      name="district"
                      value={values.district}
                      disabled={districtData?.length > 0 ? false : true}
                      style={{ display: "block" }}
                    >
                      <option style={{ display: "none" }} value="">
                        İlçe seçiniz
                      </option>
                      {districtData &&
                        districtData[0]?.district?.map((item, i) => {
                          return (
                            <option label={item.name} key={i} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    <ErrorMessage
                      name="district"
                      component="div"
                      className="error-auth"
                    />
                  </div>
                  {values.city === "İstanbul" && (
                    <div style={{ flex: "1", marginLeft: "10px" }}>
                      {" "}
                      <label
                        htmlFor="neighborhood"
                        style={{ display: "block" }}
                      >
                        Mahalle
                      </label>
                      <select
                        // onChange={(e) => {
                        //   setFieldValue("neighborhood", e.target.value);
                        //   getDistricts(e.target.value);
                        // }}

                        disabled={mahalle?.length > 0 ? false : true}
                        onChange={(e) => {
                          const selectedObject = mahalle[0]?.neighborhood.find(
                            (item) => item.neighborhood === e.target.value
                          );
                          setSelectedNeighborhood(selectedObject);
                          setFieldValue("neighborhood", selectedNeighborhood);
                        }}
                        name="neighborhood"
                        // value={values.neighborhood}
                        value={selectedNeighborhood?.neighborhood}
                        style={{ display: "block" }}
                      >
                        <option style={{ display: "none" }} value="">
                          Mahalle seçiniz
                        </option>
                        {mahalle &&
                          mahalle[0]?.neighborhood?.map((item) => {
                            return (
                              <option
                                label={item.neighborhood}
                                key={item._id}
                                value={item.neighborhood}
                              >
                                {item.neighborhood}
                              </option>
                            );
                          })}
                      </select>
                      <ErrorMessage
                        name="neighborhood"
                        component="div"
                        className="error-auth"
                      />
                    </div>
                  )}
                </div>

                {change ? (
                  <>
                    <div className="register-button-area">
                      {title ? null : (
                        <button
                          type="submit"
                          disabled={
                            isSubmitting ||
                            !values.title ||
                            !values.address ||
                            !values.city ||
                            !values.district
                          }
                          className="button button-primary w-100"
                        >
                          Kaydet
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => setChange("order")}
                        className="button button-ghost w-100"
                      >
                        Sipariş Ekranına dön
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="form-area contact-button-container">
                    <button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !values.title ||
                        !values.address ||
                        !values.city ||
                        !values.district
                      }
                      className="button button-primary w-100"
                    >
                      Kaydet
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default NewAddress;
