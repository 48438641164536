import axios from "axios";
import { storageHelper } from "../../../utils/helpers/StorageHelper";

// const API_BASE_URL = "http://localhost:8000/api/";
const API_BASE_URL = "https://lionfish-app-mwoqx.ondigitalocean.app/api/";

const baseService = {};

baseService.get = async function (endpoint) {
  try {
    const token = storageHelper.getStoreWithDecryption("token") || "";
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = API_BASE_URL + endpoint;
    const response = await axios.get(url, { headers });

    return response;
  } catch (error) {
    if (error.response) {
      console.log("baseService.js/baseService.get", endpoint, error);
      return error.response;
    }
    return { status: 500 };
  }
};

baseService.post = async function (endpoint, data) {
  try {
    const token = storageHelper.getStoreWithDecryption("token") || "";
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = API_BASE_URL + endpoint;
    const response = await axios.post(url, data, { headers });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log("baseService.js/baseService.post", endpoint, error);
    return { status: 500 };
  }
};

baseService.delete = async function (endpoint) {
  try {
    const token = storageHelper.getStoreWithDecryption("token") || "";
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = API_BASE_URL + endpoint;
    const response = await axios.delete(url, { headers });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log("baseService.js/baseService.delete", endpoint, error);
    return { status: 500 };
  }
};

export default baseService;
