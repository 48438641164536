import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrow from "../../../utils/img/carousel/toleftbutton.svg";
import rightArrow from "../../../utils/img/carousel/torightbutton.svg";
import { useParams } from "react-router-dom";
import Loader from "../../app-layouts/Loader";

function SliderProduct({ imageUrls }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    if (Object.keys(imageUrls).length > 0) {
      setIsLoaded(true);
    }
  }, [imageUrls, slug]);

  const thumbnailSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Object.keys(imageUrls).length,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <img
        src={leftArrow}
        alt="Previous"
        style={{ width: "50px", height: "50px !important" }}
      />
    ),
    nextArrow: (
      <img
        src={rightArrow}
        alt="Next"
        style={{ width: "50px", height: "50px !important" }}
      />
    ),
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const thumbnailsRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div className="product-main-slider-container">
      <Slider ref={sliderRef} {...settings}>
        {Object.keys(imageUrls)?.map((key, index) => (
          <div key={index}>
            <img
              className="product-main-slider-image"
              src={imageUrls[key]}
              alt={`Slide ${index}`}
              // style={{ width: "100%" }}
            />
          </div>
        ))}
      </Slider>

      <div
        className="ecommerce-slider"
        style={{ marginTop: "20px", marginBottom: "100px" }}
      >
        <Slider ref={thumbnailsRef} {...thumbnailSettings}>
          {Object.keys(imageUrls)?.map((key, index) => (
            <div key={index} style={{ padding: "0 5px", cursor: "pointer" }}>
              <img
                src={imageUrls[key]}
                alt={`Thumbnail ${index}`}
                style={{
                  maxWidth: "75px",
                  borderRadius: "12px",
                  border: currentSlide === index ? "2px solid #4d66ff" : "none",
                }}
                onClick={() => handleThumbnailClick(index)}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default SliderProduct;
