import React from "react";
import Sally from "../../../utils/img/failed-saly.svg";
import basket from "../../../utils/img/bag-remove.svg";
import { Link } from "react-router-dom";

function FailedPayment() {
  return (
    <>
      <h1>
        <div className="confirmation-page ">
          <div
            className="order-information-text"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <img style={{ width: "60px", height: "60px" }} src={basket} />
            <h2 style={{ margin: "0" }}>Ödemeniz Başarısız Oldu</h2>
            <p
              className="order-information-title"
              style={{
                lineHeight: "33px",
                fontWeight: "500",
                maxWidth: "600px",
                fontSize: "16px",
              }}
            >
              Maalesef bu sipariş için ödeme başarısız oldu. Girdiğiniz ad, son
              kullanma tarihi veya CVV kodu bilgilerinin doğru olduğundan emin
              olun. Siparişinizin devam edebilmesi için bilgileri tekrar
              girmenizi rica ederiz.
            </p>
            <Link to="/hesap/siparis">
              <span className="button button-primary">
                Sipariş Ekranına Dön
              </span>
            </Link>
          </div>
          <img
            style={{
              objectFit: "contain",
              maxWidth: "600px",
              minWidth: "200px",
              width: "100%",
            }}
            src={Sally}
          />
        </div>
      </h1>
    </>
  );
}

export default FailedPayment;
