import React, { Fragment } from "react";
import ayakkabi from "../../../utils/img/icons/lostra.svg";
import kurutemizleme from "../../../utils/img/icons/kurutemizleme.svg";
import camasiryikama from "../../../utils/img/icons/camasiryikama.svg";
import utulemehizmeti from "../../../utils/img/icons/utulemehizmeti.svg";
import cantatemizleme from "../../../utils/img/icons/cantatemizleme.svg";
import Arrow from "../../../utils/img/icons/long-arrow-right.png";

import cancel from "../../../utils/img/icons/cancel.svg";
import ok from "../../../utils/img/icons/ok.svg";
import { Link } from "react-router-dom";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";
function PreviewOrdersCard({ finishedOrders }) {
  const icons = {
    ayakkabi: ayakkabi,
    kurutemizleme: kurutemizleme,
    camasiryikama: camasiryikama,
    utulemehizmeti: utulemehizmeti,
    cantatemizleme: cantatemizleme,
  };
  function calculatePrice(order) {
    const delivery = order.confirmOrder?.reduce(
      (acc, val) => acc + (val?.deliveryPrice ?? 0),
      0
    );
    const totalOfferPrice = order.confirmOrder?.reduce((acc, order) => {
      order?.services?.forEach((service) => {
        acc += service?.price ?? 0;
      });
      return acc;
    }, 0);
    const totalPrice = totalOfferPrice + delivery;
    return <>{totalPrice}</>;
  }
  return (
    <>
      <div className="web active-orders content-container">
        {" "}
        {/* <div className=" active-orders content-container"> */}
        {finishedOrders?.length > 0 ? (
          finishedOrders.map((order) => {
            return (
              <div key={order._id} className="order-boxes">
                <div className="my-2"> </div>
                <div
                  style={{ height: "110px", flexWrap: "wrap" }}
                  className=" btn-holder status-container"
                >
                  <div>
                    <p
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      {" "}
                      <span className="light-text">Sipariş No: </span>#
                      {order?.orderNumber}
                    </p>
                    <div className="d-flex ">
                      {order.categories?.map((category) => {
                        return (
                          <div
                            key={category._id}
                            className="mx-1 icon-container"
                          >
                            {" "}
                            <img
                              src={icons[category.icon]}
                              alt={category.icon}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      height: "50px",
                    }}
                  >
                    <p>
                      <span className="light-text">Sipariş Tarihi : </span>
                      <span style={{ marginRight: "14px" }}>
                        {new Date(order.OrderDate)
                          .toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .replace(/\//g, ".")}
                      </span>
                    </p>
                    {order.status.some((s) => s.statusName === "done") ? (
                      <div className="d-flex align-items-center">
                        <div className=" icon-container order-done">
                          {" "}
                          <img src={ok} />
                        </div>
                        <p
                          style={{
                            margin: "0",
                            padding: "0",
                            marginLeft: "10px",
                          }}
                          // className="pt-2 mx-3"
                        >
                          Sipariş Tamamlandı
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center  ">
                        <div className="icon-container order-cancel">
                          {" "}
                          <img src={cancel} />
                        </div>
                        <p
                          style={{
                            margin: "0",
                            padding: "0",
                            marginLeft: "10px",
                          }}
                        >
                          Sipariş İptal Edildi
                        </p>
                      </div>
                    )}
                  </div>
                  {order?.status.some((s) => s.statusName === "done") ? (
                    <div>
                      <p>
                        {" "}
                        <span className="light-text">Teslim Tarihi</span> :{" "}
                        {order?.status.map((item) => {
                          return (
                            <Fragment key={item._id}>
                              {item.statusName === "done" &&
                                new Date(item.date)
                                  .toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .replace(/\//g, ".")}
                            </Fragment>
                          );
                        })}
                      </p>
                      <p className=" total-sum d-flex align-items-center ">
                        {" "}
                        Ücret : {calculatePrice(order)} TL
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {" "}
                        <span
                          // style={{ marginRight: "40px" }}
                          className="light-text"
                        >
                          Teslim Tarihi :
                        </span>{" "}
                        <span style={{ marginRight: "66px" }}></span>
                      </p>
                      <p className="  total-sum d-flex align-items-center">
                        {" "}
                        Ücret : 0 TL
                      </p>
                    </div>
                  )}{" "}
                  <Link
                    onClick={() => {
                      handleButtonClicks(
                        `/siparis/detaylari/${order?._id}`,
                        "Sipariş Detayları",
                        "Siparişlerim"
                      );
                    }}
                    to={`/siparis/detaylari/${order?._id}`}
                  >
                    <span className="awaiting-confirm details-link cursor">
                      Sipariş detayı için tıklayınız.
                    </span>
                  </Link>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-order">
            <h5> Henüz tamamlanmış siparişiniz bulunmamaktadır.</h5>
          </div>
        )}
      </div>

      <div className="mobile active-orders content-container">
        {" "}
        {finishedOrders?.length > 0 ? (
          finishedOrders.map((order) => {
            return (
              <div key={order?._id} className="order-boxes-mobile">
                <div>
                  <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                    Sipariş No:{" "}
                    {order?.orderNumber ? order.orderNumber : "123456789"}
                  </span>{" "}
                  <div className="d-flex my-1 ">
                    {order?.categories.map((category) => {
                      return (
                        <div
                          key={category?._id}
                          className="mx-1 icon-container"
                        >
                          {" "}
                          <img src={icons[category.icon]} alt={category.icon} />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <p className="sub-text order-date-title">Sipariş Tarihi:</p>
                    <p className="sub-text font-500">
                      {new Date(order.OrderDate)
                        .toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                        .replace(/\//g, ".")}{" "}
                      {"  "} <br />
                      {new Date(order.OrderDate).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </p>
                  </div>
                  {order?.status[order.status.length - 1].statusName !==
                    "confirming" && (
                    <Link to={`/siparis/detaylari/${order?._id}`}>
                      <div
                        onClick={() => {}}
                        className="awaiting-confirm-mobile details awaiting-confirm-mobile-text cursor"
                      >
                        Sipariş detayı için tıklayınız.
                        <div className="confirming-arrow">
                          <img
                            style={{
                              width: "13px",
                              height: "10px",
                            }}
                            src={Arrow}
                          />
                        </div>
                      </div>
                    </Link>
                  )}
                  {order?.status[order.status.length - 1].statusName ===
                    "confirming" &&
                    order?.isConfirmed && (
                      <Link to={`/siparis/detaylari/${order?._id}`}>
                        <div className="awaiting-confirm-mobile details awaiting-confirm-mobile-text cursor">
                          Sipariş detayı için tıklayınız.
                          <div className="confirming-arrow">
                            <img
                              style={{
                                width: "13px",
                                height: "10px",
                              }}
                              src={Arrow}
                            />
                          </div>
                        </div>
                      </Link>
                    )}
                </div>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {order?.status.some((s) => s.statusName === "done") ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                          className="light-text"
                        >
                          Teslim Tarihi
                        </span>{" "}
                        :{" "}
                        {order?.status.map((item) => {
                          return (
                            <Fragment key={item._id}>
                              {item.statusName === "done" &&
                                new Date(item.date)
                                  .toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "numeric",
                                    hour12: false,
                                  })
                                  .replace(/\//g, ".")}
                            </Fragment>
                          );
                        })}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                        }}
                        className=" total-sum d-flex align-items-center "
                      >
                        {" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          Ücret
                        </span>{" "}
                        : {calculatePrice(order)} TL
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                          // style={{ marginRight: "40px" }}
                          className="light-text"
                        >
                          Teslim Tarihi :
                        </span>{" "}
                        <span style={{ marginRight: "66px" }}></span>
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                        className="  total-sum d-flex align-items-center"
                      >
                        {" "}
                        Ücret <span className="font-500"> : 0 TL</span>
                      </p>
                    </div>
                  )}{" "}
                  {order.status.some((s) => s.statusName === "done") ? (
                    <div className="d-flex align-items-center">
                      <div className=" icon-container-mobile order-done-mobile">
                        {" "}
                        <img src={ok} />
                      </div>
                      <p
                        style={{
                          margin: "0",
                          padding: "0",
                          fontSize: "12px",
                          fontWeight: "600",
                          marginLeft: "10px",
                        }}
                        // className="pt-2 mx-3"
                      >
                        Sipariş Tamamlandı
                      </p>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center  ">
                      <div className="icon-container-mobile order-cancel-mobile">
                        {" "}
                        <img src={cancel} />
                      </div>
                      <p
                        style={{
                          margin: "0",
                          padding: "0",
                          fontWeight: "600",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        Sipariş İptal Edildi
                      </p>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-order">
            <h5> Henüz tamamlanmış siparişiniz bulunmamaktadır.</h5>
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewOrdersCard;
