import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import baseService from "../../library/network/services/BaseService";
import slugify from "slugify";

function UpperFooter() {
  const blogService = async () => baseService.get("blogs");
  const [blogPosts, setBlogPosts] = useState([]);

  const getBlogs = async () => {
    const res = await blogService();

    const data = res.data.slice(-5);

    setBlogPosts(data);
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const convertToUrlFormat = (title) => {
    return slugify(title, {
      lower: true,
      remove: /[*+~.()'"!:@?]/g,
    });
  };

  return (
    <>
      <div className="upper-footer">
        <h3>Ayakkabılarınız İlk Günkü Gibi</h3>

        <div className="row container upper-footer-inner ">
          <div className="col-lg-6 upper-footer-border">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Popüler Ayakkabı Temizleme ve Bakım Hizmetlerimiz
            </p>
            <div className="row ">
              <div className="col-lg-6">
                <ul className="footer-menu upper-footer-inner">
                  <li>
                    <Link to="/nike-spor-ayakkabi-temizleme-fiyati">
                      Nike Spor Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/adidas-stan-smith-temizleme-fiyati">
                      Adidas Stan Smith Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/alexander-mcqueen-ayakkabi-temizleme-fiyati">
                      Alexander McQueen Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/nike-air-force-temizleme-fiyati">
                      Nike Air Force Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/vans-spor-ayakkabi-temizleme-fiyati">
                      Vans Spor Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="footer-menu upper-footer-inner">
                  <li>
                    <Link to="/skechers-spor-ayakkabi-temizleme-fiyati">
                      Skechers Spor Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/cizme-temizlik-and-bakim-temizleme-fiyati">
                      Bot Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/adidas-superstar-ayakkabi-temizleme-fiyati">
                      Adidas Superstar Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/camper-ayakkabi-temizleme-fiyati">
                      Camper Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                  <li>
                    <Link to="/new-balance-ayakkabi-temizleme-fiyati">
                      New Balance Spor Ayakkabı Temizleme ve Bakım Fiyatları
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pd-20">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Popüler Çanta Temizleme ve Bakım Hizmetlerimiz
            </p>
            <div className="row ">
              <div className="col-lg-6">
                <ul className="footer-menu upper-footer-inner">
                  <li>
                    <Link to="/standart-suet-canta-bakim-temizleme-fiyati">
                      Standart Süet Çanta Bakım Temizleme Fiyatı
                    </Link>
                  </li>
                  <li>
                    <Link to="/standart-cuzdan-bakim-temizleme-fiyati">
                      Standart Cüzdan Bakım Temizleme Fiyatı
                    </Link>
                  </li>
                  <li>
                    <Link to="/standart-deri-canta-bakim-temizleme-fiyati">
                      Marka Bez Çanta Bakım Temizleme Fiyatı
                    </Link>
                  </li>
                  <li>
                    <Link to="/standart-bez-canta-bakim-temizleme-fiyati">
                      Standart Bez Çanta Bakım Temizleme Fiyatı
                    </Link>
                  </li>
                  <li>
                    <Link to="/standart-deri-canta-bakim-temizleme-fiyati">
                      Standart Deri Çanta Bakım Temizleme Fiyatı
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="footer-menu upper-footer-inner">
                  {blogPosts.map((post, i) => (
                    <li
                      style={{
                        fontSize: "12px",
                      }}
                      key={i}
                    >
                      <Link to={`/blog/${convertToUrlFormat(post.title)}`}>
                        {post.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpperFooter;
