export function formatDate(dateStr) {
  if (dateStr) {
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      const day = Number(parts[0]);
      const month = Number(parts[1]) - 1;
      const year = Number(parts[2]);
      const date = new Date(year, month, day);
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return date.toLocaleDateString("tr-TR", options);
    } else {
      const date = new Date(dateStr);
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };

      return date.toLocaleDateString("tr-TR", options);
    }
  }
  return null;
}
