import React from "react";
import ProgressBar from "../../../components/app-layouts/ProgressBar";
import ayakkabi from "../../../utils/img/icons/lostra.svg";
import kurutemizleme from "../../../utils/img/icons/kurutemizleme.svg";
import camasiryikama from "../../../utils/img/icons/camasiryikama.svg";
import utulemehizmeti from "../../../utils/img/icons/utulemehizmeti.svg";
import cantatemizleme from "../../../utils/img/icons/cantatemizleme.svg";
import { Link } from "react-router-dom";
import MobileProgressBar from "../../../components/app-layouts/MobileProgressBar";
import Arrow from "../../../utils/img/icons/long-arrow-right.png";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";

function ActiveOrdersCard({ activeOrders }) {
  const icons = {
    ayakkabi: ayakkabi,
    kurutemizleme: kurutemizleme,
    camasiryikama: camasiryikama,
    utulemehizmeti: utulemehizmeti,
    cantatemizleme: cantatemizleme,
  };
  const possibleStatuses = [
    "pickedup",
    "pending",
    "ongoing",
    "delivering",
    "done",
  ];
  const possibleStatusesMobile = [
    "active",
    "pickedup",
    "pending",
    "ongoing",
    "delivering",
    "done",
  ];
  function getStatusTranslation(statusName) {
    switch (statusName) {
      case "active":
        return "Sipariş oluşturuldu";
      case "pickedup":
        return "Teslim alındı";
      case "pending":
        return "İnceleniyor";
      case "ongoing":
        return "Temizleniyor";
      case "delivering":
        return "Yolda";
      case "confirming":
        return "İnceleniyor";
      case "done":
        return "Teslim edildi";
      default:
        return statusName;
    }
  }
  function formatDate(dateStr) {
    if (dateStr) {
      const parts = dateStr.split("/");
      if (parts.length === 3) {
        const day = Number(parts[0]);
        const month = Number(parts[1]) - 1;
        const year = Number(parts[2]);
        const date = new Date(year, month, day);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return date.toLocaleDateString("tr-TR", options);
      } else {
        const date = new Date(dateStr);
        const options = {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        };

        return date.toLocaleDateString("tr-TR", options);
      }
    }
    return null;
  }
  return (
    <>
      {" "}
      <div className="web active-orders content-container">
        {activeOrders?.length > 0 ? (
          activeOrders.map((order) => {
            return (
              <div key={order?._id} className="order-boxes">
                <div className="my-2">
                  {" "}
                  {order.status && (
                    <ProgressBar
                      status={
                        order?.status[order?.status?.length - 1].statusName
                      }
                    />
                  )}
                </div>
                <div className=" btn-holder status-container">
                  <div>
                    <p className="status-name">Sipariş Oluşturuldu</p>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          marginRight: "2px",
                          fontSize: "12px",
                        }}
                        className="sub-text"
                      ></span>
                      <span className="sub-text font-weight-bold">
                        {formatDate(order.OrderDate)} {"  "} <br />
                        {new Date(order.OrderDate).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "numeric",
                          hour12: false,
                        })}
                      </span>
                    </div>
                    <p className="sub-text">
                      Sipariş No:{" "}
                      <span class="font-weight-bold">
                        {order?.orderNumber ? order.orderNumber : "123456789"}
                      </span>{" "}
                    </p>{" "}
                    <div className="d-flex my-1 ">
                      {order?.categories.map((category) => {
                        return (
                          <div
                            key={category?._id}
                            className="mx-1 icon-container"
                          >
                            {" "}
                            <img
                              src={icons[category.icon]}
                              alt={category.icon}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {possibleStatuses.map((status) => {
                    const matchingStatus = order.status.find(
                      (item) => item.statusName === status
                    );
                    const translatedStatus = getStatusTranslation(status);
                    return (
                      <React.Fragment key={`${status}+${order}`}>
                        <div key={`${status}+${order}`}>
                          <p className="status-name">{translatedStatus}</p>
                          {matchingStatus && (
                            <span
                              style={{ textAlign: "center" }}
                              className="sub-text"
                            >
                              {"  "} {formatDate(matchingStatus.date)} {"  "}
                              <br />
                              {new Date(matchingStatus.date).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "numeric",
                                  hour12: false,
                                }
                              )}
                              {order?.status[order.status.length - 1]
                                .statusName === "confirming" && (
                                <>
                                  {" "}
                                  {!order.isConfirmed && (
                                    <Link
                                      onClick={() => {
                                        handleButtonClicks(
                                          `siparis/onay/${order?._id}`,
                                          "Onayınız bekleniyor.",
                                          "Siparişlerim"
                                        );
                                      }}
                                      to={`/siparis/onay/${order?._id}`}
                                    >
                                      <span className="awaiting-confirm cursor">
                                        Onayınız bekleniyor. Lütfen detaylar
                                        için tıklayınız.
                                      </span>
                                    </Link>
                                  )}
                                </>
                              )}
                            </span>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                  {order?.status[order.status.length - 1].statusName !==
                    "confirming" && (
                    <Link
                      onClick={() => {
                        handleButtonClicks(
                          `siparis/detaylari/${order?._id}`,
                          "Sipariş detayı için tıklayınız.",
                          "Siparişlerim"
                        );
                      }}
                      to={`/siparis/detaylari/${order?._id}`}
                    >
                      <span className="awaiting-confirm details cursor">
                        Sipariş detayı için tıklayınız.
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-order">
            <h5>
              {" "}
              Henüz siparişiniz bulunmamaktadır. Sipariş oluşturmak için{" "}
              <Link
                onClick={() => {
                  handleButtonClicks(
                    "siparis-olustur",
                    "Sipariş oluşturmak için tıklayınız.",
                    "Siparişlerim"
                  );
                }}
                to={"/siparis-olustur"}
              >
                tıklayın.
              </Link>
            </h5>
          </div>
        )}
      </div>{" "}
      <div className="mobile active-orders content-container">
        {activeOrders?.length > 0 ? (
          activeOrders.map((order) => {
            return (
              <div key={order?._id} className="order-boxes-mobile">
                <div>
                  <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                    Sipariş No:{" "}
                    {order?.orderNumber ? order.orderNumber : "123456789"}
                  </span>{" "}
                  <div className="d-flex my-1 ">
                    {order?.categories.map((category) => {
                      return (
                        <div
                          key={category?._id}
                          className="mx-1 icon-container"
                        >
                          {" "}
                          <img src={icons[category.icon]} alt={category.icon} />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <p className="sub-text order-date-title">Sipariş Tarihi:</p>
                    <p className="sub-text font-500">
                      {formatDate(order.OrderDate)} {"  "} <br />
                      {new Date(order.OrderDate).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </p>
                  </div>
                  {order?.status[order.status.length - 1].statusName !==
                    "confirming" && (
                    <Link
                      onClick={() => {
                        handleButtonClicks(
                          `siparis/detaylari/${order?._id}`,
                          "Sipariş detayı için tıklayınız.",
                          "Siparişlerim"
                        );
                      }}
                      to={`/siparis/detaylari/${order?._id}`}
                    >
                      <div className="awaiting-confirm-mobile details awaiting-confirm-mobile-text cursor">
                        Sipariş detayı için tıklayınız.
                        <div className="confirming-arrow">
                          <img
                            alt="arrow"
                            style={{
                              width: "13px",
                              height: "10px",
                            }}
                            src={Arrow}
                          />
                        </div>
                      </div>
                    </Link>
                  )}
                  {order?.status[order.status.length - 1].statusName ===
                    "confirming" &&
                    order?.isConfirmed && (
                      <Link
                        onClick={() => {
                          handleButtonClicks(
                            `siparis/detaylari/${order?._id}`,
                            "Sipariş detayı için tıklayınız.",
                            "Siparişlerim"
                          );
                        }}
                        to={`/siparis/detaylari/${order?._id}`}
                      >
                        <div className="awaiting-confirm-mobile details awaiting-confirm-mobile-text cursor">
                          Sipariş detayı için tıklayınız.
                          <div className="confirming-arrow">
                            <img
                              alt="arrow"
                              style={{
                                width: "13px",
                                height: "10px",
                              }}
                              src={Arrow}
                            />
                          </div>
                        </div>
                      </Link>
                    )}
                  {order?.status[order.status.length - 1].statusName ===
                    "confirming" && (
                    <>
                      {!order.isConfirmed && (
                        <Link
                          onClick={() => {
                            handleButtonClicks(
                              `siparis/onay/${order?._id}`,
                              "Onayınız bekleniyor.",
                              "Siparişlerim"
                            );
                          }}
                          to={`/siparis/onay/${order?._id}`}
                        >
                          <div className="awaiting-confirm-mobile awaiting-confirm-mobile-text">
                            Onayınız bekleniyor.
                            <div className="confirming-arrow">
                              <img
                                alt="arrow"
                                style={{
                                  width: "13px",
                                  height: "10px",
                                }}
                                src={Arrow}
                              />
                            </div>
                          </div>
                        </Link>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {possibleStatusesMobile.map((status) => {
                      const matchingStatus = order.status.find(
                        (item) => item.statusName === status
                      );
                      const translatedStatus = getStatusTranslation(status);
                      return (
                        <React.Fragment key={`${status}+${order}`}>
                          <div key={`${status}+${order}`}>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                              className="status-name"
                            >
                              {order.status[order.status.length - 1]
                                .statusName === status ? (
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#46f86d",
                                  }}
                                >
                                  {translatedStatus}
                                </span>
                              ) : (
                                translatedStatus
                              )}

                              {/* {translatedStatus} */}
                            </p>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <MobileProgressBar
                    status={order?.status[order?.status?.length - 1].statusName}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-order">
            <h5>
              {" "}
              Henüz siparişiniz bulunmamaktadır. Sipariş oluşturmak için{" "}
              <Link
                onClick={() => {
                  handleButtonClicks(
                    "siparis-olustur",
                    "Sipariş oluşturmak için tıklayınız.",
                    "Siparişlerim"
                  );
                }}
                to={"/siparis-olustur"}
              >
                tıklayın.
              </Link>
            </h5>
          </div>
        )}
      </div>
    </>
  );
}

export default ActiveOrdersCard;
