import React, { useContext, useEffect, useState } from "react";
import Sally from "../../../utils/img/failed-saly.svg";
import basket from "../../../utils/img/icons/shopping basket.svg";
import { Link, useLocation } from "react-router-dom";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";

function ThankYouEcommerce() {
  const { clearCart } = useContext(CartFavoritesContext);
  const url = useLocation();
  const [orderNumber, setOrderNumber] = useState(null);

  const userId = storageHelper.getStoreWithDecryption("userId");
  useEffect(() => {
    clearCart();
    const orderNumberMatch = url.pathname.match(/\/magaza\/(\d+)\/thank-you/);
    const orderNumber = orderNumberMatch ? orderNumberMatch[1] : null;
    setOrderNumber(orderNumber);
    if (storageHelper.getStoreWithDecryption("purchaseData")) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(
        storageHelper.getStoreWithDecryption("purchaseData")
      );
    }
  }, []);
  return (
    <>
      <div className="confirmation-page">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img style={{ width: "40px", height: "40px" }} src={basket} />
          <h2 style={{ margin: "0" }}>Siparişin Onaylandı</h2>
          <p
            className="order-information-title"
            style={{
              lineHeight: "33px",
              maxWidth: "600px",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Siparişinizin alınnıştır. En kısa sürede size ulaşacaktır.
            {userId ? (
              <>
                <br />
                Siparişinin detaylarını{" "}
                <span className="link">
                  <Link
                    style={{ fontWeight: "550", textDecoration: "underline" }}
                    to={"/hesap/siparis"}
                  >
                    {" "}
                    “Siparişlerim”
                  </Link>
                </span>{" "}
                sayfasından görüntüleyebilirsin.
              </>
            ) : (
              <>
                <br />
                Sipariş numaranız: <strong>#{orderNumber}</strong>
                <br />
                Siparişinin detaylarını email adresine gönderdik.{" "}
              </>
            )}
          </p>
        </div>
        <img
          style={{
            objectFit: "contain",
            maxWidth: "600px",
            width: "100%",
          }}
          src={Sally}
        />
      </div>
    </>
  );
}

export default ThankYouEcommerce;
