import React from "react";
import { Helmet } from "react-helmet-async";

function Kvkk() {
  return (
    <>
      <Helmet>
        <title>{`Aydınlatma Metni - Online Lostra`}</title>
        <meta name="description" content="noindex" />
      </Helmet>
      <section className="page-section">
        <div className="container">
          <div className="title">
            {/* <h4>KVKK Aydınlatma Metni</h4> */}
            <h1>KİŞİSEL VERİ İŞLEME AYDINLATMA METNİ</h1>
          </div>
          <div className="policy" style={{ textAlign: "left", color: "black" }}>
            <ol>
              <li>VERİ SORUMLUSU</li>{" "}
              <p>
                DU TECH İNTERNET HİZMETLERİ VE LOJİSTİK ANONİM ŞİRKETİ (“ONLİNE
                LOSTRA”) olarak, siz müşterilerimize ait kişisel verilerin, 6698
                sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”), ikincil
                düzenlemeleri ve Kişisel Verileri Koruma Kurulu Kararlarına
                uygun olarak işlenmesi ve korunması için azami hassasiyeti
                göstermekteyiz. Bu aydınlatma metni hizmetlerimiz ve ONLİNE
                LOSTRA tarafından yönetilen onlinelostra.com internet sitesi
                (aracılığıyla, kişisel verilerinizi veri sorumlusu sıfatıyla
                nasıl işlediğimize dair sizleri bilgilendirmek amacıyla
                hazırlanmıştır. Kişisel verilerinizin korunmasına yönelik
                aldığımız tedbirler ve kişisel verileri işlerken uyum
                sağladığımız kurallar hakkında detaylı bilgi için ayrıca Kişisel
                Verilerin İşlenmesi ve Korunması Politikamıza göz atmanızı
                öneririz.
              </p>
              <li>KİŞİSEL VERİLERİN İŞLENMESİNE YÖNELİK İLKELER</li>
            </ol>
            <ul>
              <li>
                <strong>Kişisel Verilerin İşlenmesinde Genel İlkeler</strong> :
                Firma/Şirket KVKK’ nın 4. maddesi doğrultusunda işbu politika
                kapsamında kalan kişisel verilerin aşağıdaki ilkelere uygun
                olarak işleyeceğini kabul etmektedir:
              </li>
              <li>
                <strong>Hukuka Ve Dürüstlük Kurallarına Uygunluk </strong>:
                Firma/Şirket, veri sorumlusu sıfatı ile basiretli bir tacir
                olarak Anayasa ve KVKK başta olmak üzere yürürlüğe girecek olan
                tüm mevzuat hükümlerine uygun olarak ve Türk Medeni Kanunu’nun
                2. maddesi ile öngörülen dürüstlük kuralına uygun bir biçimde
                kişisel veri işleme faaliyetlerini yürüteceğini kabul
                etmektedir.
              </li>
              <li>
                <strong> Doğruluk ve Güncellik</strong> : Kişisel verilerin
                işlenmesi faaliyetlerinde tekniğin elverdiği ölçüde kişisel
                verilerin doğruluk ve güncelliğinin sağlanması için gerekli tüm
                tedbirleri almaktadır. İlgili kişinin veri sorumlusu sıfatıyla
                Firma’ya bildireceği talepler Firma’nın bizzat gerekli göreceği
                durumda hatalı veya güncel olmayan kişisel verilerin
                düzeltilmesi ve doğruluğunun denetlenmesi için Firma tarafından
                kurulan idari ve teknik mekanizmalar işletilecektir.{" "}
              </li>
              <li>
                <strong> Belirli, açık ve meşru amaçlar için işlemek</strong> :
                Firma tarafından kişisel veriler, ilgili mevzuat hükümlerinin
                gereklilikleri ile sunulan veya sunulacak olan hizmetlerle
                sınırlı olarak hukuka uygun biçimde işlenmekte olup, kişisel
                verilerin işlenme amacı verilerin işlenmeye başlanmasından önce
                açık ve kesin olarak belirlenmektedir.
              </li>
              <li>
                <strong>Amaçla bağlantılı, sınırlı ve ölçülü işlemek </strong>:
                Firma tarafından kişisel veriler işlenme amaçlarıyla bağlantılı
                ve sınırlı olarak ve bu amacın gerçekleşmesi için gerekli ölçüde
                işlenmektedir. Bu kapsamda verilerin işlenme amacı ile ilgili
                olmayan ve ihtiyaç duyulmayan kişisel verilerin işlenmesinden
                kaçınılması temel esastır.{" "}
              </li>
              <li>
                <strong>
                  {" "}
                  Mevzuat hükümleriyle öngörülen veya işlenme amacının
                  gerektirdiği süre ile sınırlı olarak işlemek
                </strong>{" "}
                : Kişisel veriler ilgili mevzuat hükümleriyle öngörülen süreler
                doğrultusunda ve/veya verilerin işlenme amacının gerektirdiği
                süre boyunca muhafaza edilmektedir. Mevzuat hükümleri ile
                öngörülen sürenin sonunda veya işlenme amacının gerektirdiği
                süre sonunda kişisel veriler Firma tarafından silinmekte, yok
                edilmekte veya anonim hale getirilmektedir. Verilerin gerekli
                sürenin sonunda muhafaza edilmesinin önlenmesi için gerekli
                idari ve teknik tedbirler alınacaktır.
              </li>
            </ul>
            <ol start={3}>
              <li>TOPLANAN KİŞİSEL VERİLERİNİZ</li>
              <p>
                Kişisel verileriniz birçok farklı yöntemle toplanmakta olup,
                toplanan kişisel verilerinizi sıralamamız gerekirse;{" "}
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Kimlik Bilgileri:</td>
                    <td>Ad, soyad,doğum tarihi, TC kimlik numarası</td>
                  </tr>
                  <tr>
                    <td>İletişim Bilgileri:</td>
                    <td>
                      Online Lostra hesabınıza kayıtlı GSM numarası, e-posta
                      adresi
                    </td>
                  </tr>
                  <tr>
                    <td>Ödeme Bilgileri: </td>
                    <td>Ödemelere ve ödeme yöntemlerine ilişkin bilgiler</td>
                  </tr>
                  <tr>
                    <td>Müşteri İşlem Bilgileri: </td>
                    <td>
                      Alışveriş/sipariş geçmişi, sipariş bilgisi, sipariş
                      sayısı, İnternet Sitesi kullanım bilgileri, tercih,
                      beğeni, ilgi alanları ve kullanım alışkanlıkları, fatura
                      bilgileri, talep/şikâyet bilgisi, yorum, puan ve
                      değerlendirme bilgileri
                    </td>
                  </tr>
                  <tr>
                    <td>Teslimat Adres Bilgileri : </td>
                    <td>
                      Sizin tarafınızdan manuel olarak eklenen teslimat
                      adresiniz
                    </td>
                  </tr>
                  <tr>
                    <td>İşlem Güvenliği Bilgileri</td>
                    <td>
                      Cihaz türü, modeli, işletim sistemi ve sürümü gibi
                      kullandığınız cihaza ilişkin veriler, IP adresi, kullanıcı
                      işlem kayıtları, giriş yapma yöntemi (Online Lostra
                      üyeliği ya da Google kayıt aracılığıyla)
                    </td>
                  </tr>
                  <tr>
                    <td>İdari Veriler:</td>
                    <td>
                      İlgili kişiye ait teslimat ve minimum sisteme girmiş
                      olduğu operasyonel bilgiler
                    </td>
                  </tr>
                </tbody>
              </table>
              <li className="my-3">
                KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI, TOPLANMA YÖNTEMİ VE
                HUKUKİ SEBEPLERİ
              </li>
              <p>
                İnternet Sitesi üzerinden, çağrı merkezi aracılığıyla telefon
                üzerinden ve bizimle e-posta aracılığıyla iletişim kurmanız
                halinde e-posta üzerinden tamamen otomatik ve kısmen otomatik
                yöntemler ile elde ettiğimiz kişisel verilerinizi, KVKK’nın 5.
                maddesinde belirtilen hukuki sebeplere dayalı olarak işliyoruz.
                Kişisel verilerinizi işleme amaçlarımız ve dayandığımız hukuki
                sebeplere aşağıda yer verdik:
              </p>
              <table className="kvkk-table">
                <thead>
                  <tr>
                    <th>Süreçte İşlenen Kişisel Verileriniz</th>
                    <th>Kişisel Veri İşleme Süreci</th>
                    <th>Dayanılan Hukuki Sebepler</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                      Ödeme Bilgileri, Müşteri İşlem Bilgileri,İşlem Güvenliği
                      Bilgileri,{" "}
                    </td>
                    <td>
                      Hukuki talep ve başvurularınızın incelenmesi,
                      değerlendirilmesi ve sonuçlandırılması; olası hukuki
                      uyuşmazlıkların giderilmesi ve bu amaçla yetkili kişi,
                      kurum ve kuruluşlarla bilgi paylaşımında bulunulması.
                    </td>
                    <td rowspan="3">
                      Kişisel veri işlemenin, kanunlarda açıkça öngörülmesi
                      (KVKK md. 5/2-a)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, İletişim Bilgileri, Ödeme Bilgileri,
                      Müşteri İşlem Bilgileri
                    </td>
                    <td>
                      İnternet Sitesi üzerinden verdiğiniz siparişlere ilişkin
                      sözleşme ve satış süreçlerinin yürütülmesi; ödemelere
                      ilişkin finans ve muhasebe işlerinin yürütülmesi,
                      siparişiniz sonucunda fatura düzenlenmesi.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                      Ödeme Bilgileri, Müşteri İşlem Bilgileri, İşlem Güvenliği
                      Bilgileri,{" "}
                    </td>
                    <td>
                      Faaliyetlerimizin mevzuata uygun olarak yürütülmesi ve
                      hukuki yükümlülüklerimiz kapsamında saklanması zorunlu
                      olan verilerin muhafazası.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                      İşlem Güvenliği Bilgileri
                    </td>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                      İşlem Güvenliği BilgileriKimlik Bilgileri, Hesap
                      Bilgileri, İletişim Bilgileri, İşlem Güvenliği Bilgileri
                    </td>
                    <td rowspan="3">
                      Kişisel veri işlemenin, Online Lostra ile aranızdaki
                      sözleşmenin kurulması veya ifası için gerekli olması (KVKK
                      md. 5/2-c)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Ödeme Bilgileri, Müşteri İşlem
                      Bilgileri, Teslimat Adres Bilgileri
                    </td>
                    <td>
                      Siparişlerinizin oluşturulması ve ödeme süreçlerinin
                      yönetilmesi, siparişin onaylanması, sipariş verdiğiniz
                      ürünlerin adresinize ulaştırılması için ödemenin alınması
                      ve ürününüzün teslim edilmesi.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, İletişim Bilgileri, Müşteri İşlem
                      Bilgileri,Teslimat Adres Bilgileri
                    </td>
                    <td>
                      Üyeliğiniz, siparişleriniz ve İnternet Sitesi
                      kullanımınıza dair gerekli konularda sizinle iletişime
                      geçilmesi;{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Müşteri İşlem Bilgileri, Ödeme Bilgileri
                    </td>
                    <td>
                      Ödemelere ilişkin finans ve muhasebe işlerinin
                      yürütülmesi, siparişiniz sonucunda fatura düzenlenmesi.
                    </td>
                    <td rowspan="2">
                      Kişisel verilerin hukuki yükümlülüklerimizi yerine
                      getirebilmek için işlenmesi (KVKK md. 5/2-ç)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                      Müşteri İşlem Bilgileri, Ödeme Bilgileri, İşlem Güvenliği
                      Bilgileri
                    </td>
                    <td>
                      Faaliyetlerimizin mevzuata uygun olarak yürütülmesi ve
                      hukuki yükümlülüklerimiz kapsamında saklanması zorunlu
                      olan verilerin muhafazası.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, Müşteri İşlem Bilgileri
                    </td>
                    <td>
                      Siparişinize ilişkin talep/şikayetlerinizin alınması ve
                      sonuçlandırılması.
                    </td>
                    <td>
                      Kişisel verilerin bir hakkın tesisi, kullanılması veya
                      korunması için işlenmesi (KVKK md. 5/2-e)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, İletişim Bilgileri, Müşteri İşlem
                      Bilgileri
                    </td>
                    <td>
                      Müşteri ilişkilerinin yönetilmesi, müşteri memnuniyeti
                      ölçümleri kapsamında anket ve benzeri beğeni/talep/şikâyet
                      tespit yöntemleri vasıtasıyla sizinle iletişime geçilmesi.
                    </td>
                    <td rowspan="5">
                      Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu
                      olması (KVKK md. 5/2-f)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, İletişim Bilgileri, Müşteri İşlem
                      Bilgileri
                    </td>
                    <td>
                      Ürün/hizmetlerimizin iyileştirilmesine yönelik yazılı veya
                      sözlü talepleriniz, şikayetleriniz ve geri
                      bildirimlerinizin incelenmesi, değerlendirilmesi ve
                      sonuçlandırılması.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Hesap Bilgileri, İletişim Bilgileri, Müşteri İşlem
                      Bilgileri, Ödeme Bilgileri, Teslimat Adres Bilgileri,
                      İşlem Güvenliği Bilgileri
                    </td>
                    <td>
                      Ürün ve hizmetlerimizin geliştirilmesi için analizler
                      yapılması; iş faaliyetlerimizin incelenmesi ve
                      denetlenmesi, ürün ve hizmetlerimize dair raporlama
                      çalışmalarının yürütülmesi.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                      Müşteri İşlem Bilgileri, Teslimat Adres Bilgileri, İşlem
                      Güvenliği Bilgileri
                    </td>
                    <td>
                      Müşterilerimize sunduğumuz hizmetlerimiz ile kampanya ve
                      indirimlerin kötüye kullanılmasının ve dolandırıcılığın
                      tespit edilmesi ve önlenmesi.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Hesap Bilgileri, İletişim Bilgileri, Ödeme Bilgileri,
                      Müşteri İşlem Bilgileri
                    </td>
                    <td>
                      Daha iyi bir kullanıcı deneyimi sağlamak amacıyla geçmiş
                      siparişlerinize ve kullanım alışkanlıklarınıza uygun
                      ürün/hizmet seçeneklerinin sunulması.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      İletişim Bilgileri, Müşteri İşlem Bilgileri, Teslimat
                      Adres Bilgileri
                    </td>
                    <td>
                      Tercih ve beğenilerinize, ilgi alanlarınıza, kullanım
                      alışkanlıklarınıza özel fırsat seçenekleri ile
                      kampanyaların sunulması ve bu çerçevede tanıtım
                      faaliyetlerinin yürütülmesi.
                    </td>
                    <td>
                      Kişisel verilerinizin işlenmesine açık rıza vermiş olmanız
                      (KVKK md. 5/1)
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="my-4">İşlemekteyiz. </p>
            </ol>
            <p>5. KİŞİSEL VERİLERİNİZ AKTARILMASI</p>
            <p>
              Kişisel verileriniz KVKK’nın 8. ve 9. maddelerine uygun olarak,
              amaçla bağlantılı, sınırlı ve ölçülü şekilde aşağıdaki hallerde
              üçüncü kişilere aktarılır:
            </p>
            <table className="kvkk-table">
              <thead>
                <tr>
                  <th>Aktarılan Kişisel Verileriniz</th>
                  <th>Aktarımın Amacı</th>
                  <th>Alıcı Grubu</th>
                  <th>Dayanılan Hukuki Sebepler</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Kimlik Bilgileri, İletişim Bilgileri,Teslimat Adres
                    Bilgileri
                  </td>
                  <td>
                    Teslimat hizmetlerini yerine getirmek, kişisel veri toplama
                    süreçlerinde ürün ve hizmet desteği almak gibi amaçlarla.
                  </td>
                  <td>
                    Online Lostra Dağıtıcıları, Kuryeleri teslimat, kargo,
                    ulaşım vb.)
                  </td>
                  <td>
                    Kişisel veri işlemenin, Online Lostra ile aranızdaki
                    sözleşmenin kurulması veya ifası için gerekli olması (KVKK
                    md. 5/2-c)
                  </td>
                </tr>
                <tr>
                  <td>
                    Kimlik Bilgileri, Hesap Bilgileri, İletişim Bilgileri,
                    Müşteri İşlem Bilgileri, Ödeme Bilgileri, Teslimat Adres
                    Bilgileri,İşlem Güvenliği Bilgileri,{" "}
                  </td>
                  <td>
                    Online Lostra’nın sunduğu ürün ve hizmetlerle ilgili olarak
                    altyapı ve bilişim hizmeti sağlayanlar dahil
                    tedarikçilerimizden destek alınması, finans ve muhasebe
                    işlerinin yürütülmesi, iş ortaklarımız ve tedarikçilerimizle
                    ilişkilerin yönetilmesi.
                  </td>
                  <td>
                    Yurt İçi İş Ortakları & Tedarikçiler (veri tabanı,
                    danışmanlık, vb. hizmeti veren firmaları ile)
                  </td>
                  <td>
                    Kişisel veri işlemenin, Online Lostra ile aranızdaki
                    sözleşmenin kurulması veya ifası için gerekli olması (KVKK
                    md. 5/2-c) Kişisel veri işlemenin meşru menfaatlerimiz için
                    zorunlu olması (KVKK md. 5/2-f)
                  </td>
                </tr>
                <tr>
                  <td>
                    Kişisel veri işlemenin, kanunlarda açıkça öngörülmesi (KVKK
                    md. 5/2-a) Kişisel verilerin hukuki yükümlülüklerimizi
                    yerine getirebilmek için işlenmesi (KVKK md. 5/2-ç)
                  </td>
                  <td>Yetkili Kişi, Kurum ya da Kuruluşlar</td>
                  <td>
                    Hukuki yükümlülüklerimizin yerine getirilmesi, bu kapsamda
                    yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi,
                    hukuki süreçlerin yürütülmesi ve faaliyetlerimizin mevzuata
                    uygun olarak yürütülmesi.
                  </td>
                  <td>
                    Kişisel veri işlemenin, kanunlarda açıkça öngörülmesi (KVKK
                    md. 5/2-a) Kişisel verilerin hukuki yükümlülüklerimizi
                    yerine getirebilmek için işlenmesi (KVKK md. 5/2-ç)
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <ol type="a">
              <li>
                Bilişim teknolojileri ya da uzmanlık gerektiren danışmanlık vb.
                hizmetlerini almak, kişisel veri toplama süreçlerinde ürün ve
                hizmet desteği almak gibi amaçlarla yurtiçinde yer alan iş
                ortakları ve hizmet sağlayıcılarıyla (veri tabanı, danışmanlık,
                vb. hizmeti veren firmaları ile) veya gerekmesi halinde Kanun’un
                9.maddesinde yer alan yurtdışına aktarım şartları yerine
                getirilerek yurtdışında yer alan iş ortakları ve hizmet
                sağlayıcıları ile,{" "}
              </li>
              <li>
                Yetkili ve görevli kamu kurum ve kuruluşları ile adli makamlara
                karşı olan bilgi, belge verme ve ilgili sair yükümlülüklerimizi
                yerine getirmek ve dava ve cevap hakları gibi yasal haklarımızı
                kullanabilmek amacıyla bizden istenen bilgileri anılan bu kurum,
                kuruluş ve makamlara,
              </li>
              <li>
                Ücret ödemesi gerektiren bir süreçte, ödemenizi kredi kartı ile
                yapmanız halinde kredi kartı bilgilerinizi Şirket tarafından
                kaydedilmeksizin ilgili banka, elektronik ödeme kuruluşu vb.
                hizmeti sağlayan üçüncü kişilere,{" "}
              </li>
              <li>
                Teslimat hizmetlerini yerine getirmek, kişisel veri toplama
                süreçlerinde ürün ve hizmet desteği almak gibi amaçlarla
                yurtiçinde yer alan iş ortakları ve hizmet sağlayıcılarıyla
                (teslimat, kargo, ulaşım vb. hizmetleri) veya gerekmesi halinde
                Kanun’un 9.maddesinde yer alan yurtdışına aktarım şartları
                yerine getirilerek yurtdışında yer alan iş ortakları ve hizmet
                sağlayıcıları ile
              </li>
            </ol>
            <p>paylaşmaktayız</p> */}
            <p>6. HAKLARINIZ</p>
            <p>
              Kanun’un 11. maddesi gereğince; bu Politikanın “İletişim”
              bölümünde yer alan yöntemlerle Şirket’e başvurarak:
            </p>
            <ol type="a">
              <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme, </li>
              <li>
                Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li>
                Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurtiçinde veya yurtdışında Kişisel Verilerinizin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel Verilerinizin eksik veya yanlış işlenmiş olması halinde
                bunların düzeltilmesini isteme,
              </li>
              <li>
                {" "}
                KVKK mevzuatında öngörülen şartlar çerçevesinde Kişisel
                Verilerinizin silinmesini veya yok edilmesini isteme
              </li>
              <li>
                Kişisel Verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
                isteme,
              </li>
              <li>
                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
                çıkmasına itiraz etme,
              </li>
              <li>
                {" "}
                Kişisel Verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğramanız halinde bu zararın giderilmesini talep etme
              </li>
            </ol>
            <p>haklarına sahipsiniz.</p>
            <p>7. İLETİŞİM</p>
            <p>
              Başvurularınızı Veri Sorumlusu olarak Şirket’in söz konusu başvuru
              prosedürü için tahsis edilmiş olan e-posta adresine
              <a href="mailto:info@onlinelostra.com">
                {" "}
                info@onlinelostra.com
              </a>{" "}
              Sistem üzerindeki kayıtlı e-postanızdan, Şirket’in
              [__________________] Kep adresine veya Şirket’in aşağıda
              belirtilen adresine yazılı adresine yazılı olarak iletebilirsiniz
              (İlgili talebin yasalar gereği belirli bir prosedürde yapılması
              gereken hallerde söz konusu prosedüre uyulması gerektiğini
              hatırlatmak isteriz.)
            </p>
            <p>
              Adres: Çırçır Mahallesi Dönen Sokak No:8-10B Eyüpsultan, İstanbul,
            </p>
            <p>Telefon:+90 850 533 1070</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Kvkk;
