import React from "react";
import cancel from "../../../utils/img/icons/cancel.svg";

function ImageSlider({
  offer,
  offerIndex,
  setIsModalOpen,
  setSelectedImageIndex,
  isModalOpen,
  setIndex,
  index,
  imageUrls,
  selectedImageIndex,
}) {
  function goToPreviousImage(off, e) {
    e.stopPropagation();
    const previousIndex =
      (index - 1 + offer.mainImages.length) % offer.mainImages.length;
    setSelectedImageIndex(offer.mainImages[previousIndex]);
    setIndex(previousIndex);
  }

  function goToNextImage(e) {
    e.stopPropagation();
    const nextIndex = (index + 1) % offer.mainImages.length;
    setSelectedImageIndex(offer.mainImages[nextIndex]);
    setIndex(nextIndex);
  }
  return (
    <div
      style={{
        opacity: isModalOpen ? 1 : 0,
        backgroundColor: isModalOpen ? "rgba(0,0,0,0.3)" : "transparent",
        zIndex: isModalOpen ? 1000 : -1,
      }}
      className="mainImagesOrderOverlay"
      onClick={() => setIsModalOpen(false)}
    >
      <div className="slideshowContainer">
        <img
          // className="servicecloseimg"
          style={{
            width: "25px",
            backgroundColor: "grey",
            padding: "5px",
            borderRadius: "30%",
            position: "absolute",
            top: "5px",
            zIndex: "1001",
            cursor: "pointer",
            right: "5px",
          }}
          onClick={() => setIsModalOpen(false)}
          src={cancel}
          alt="cancel"
        />
        <img
          className="mainImagesOrderSlider"
          style={{
            objectFit: "contain",
            maxWidth: "500px",
            // maxHeight: "110%",
            transform: isModalOpen
              ? "scale(1) translate(0,0)"
              : "scale(0.8) translate(0,100px)",
            transition: "transform 0.5s ease-in-out",
          }}
          src={imageUrls[selectedImageIndex]}
          alt={`Offer ${offerIndex + 1} Image ${selectedImageIndex + 1}`}
          onClick={(e) => e.stopPropagation()}
        />
        <div className="slideshowNavigation">
          <div
            className="slideshowArrow"
            onClick={(e) => {
              goToPreviousImage(offer, e);
            }}
          >
            &lt;
          </div>
          <div className="slideshowArrow" onClick={goToNextImage}>
            &gt;
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSlider;
