import React, { useContext, useEffect, useState } from "react";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";
import cancel from "../../../utils/img/icons/delete-product.svg";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";

import SideNavMenu from "./SideNavMenu";
import { useNavigate } from "react-router-dom";

function Checkout() {
  const [imageUrls, setImageUrls] = useState({});
  const navigate = useNavigate();
  const [counters, setCounters] = useState({});
  const { cart, updateCartQuantity, removeFromCart, deliveryPrices } =
    useContext(CartFavoritesContext);

  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getTotalPrice = () => {
    return cart
      .reduce((total, item) => {
        const uniqueKey = `${item.productId._id}-${item.size}-${item.color}`;
        return (
          deliveryPrices + total + item.productId.price * counters[uniqueKey]
        );
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    Promise.all(cart.map((item) => getImageUrl(item?.productId?.mainImage)));

    const initialCounters = cart.reduce((acc, item) => {
      const uniqueKey = `${item.productId._id}-${item.size}-${item.color}`;
      acc[uniqueKey] = item.quantity;
      return acc;
    }, {});
    setCounters(initialCounters);
  }, [cart]);

  const handleMultiplier = (uniqueKey, type) => {
    setCounters((prevCounters) => {
      const newCounters = { ...prevCounters };
      if (type === "decrement" && newCounters[uniqueKey] > 1) {
        newCounters[uniqueKey] -= 1;
      } else if (type === "increment") {
        newCounters[uniqueKey] += 1;
      }
      const [productId, size, color] = uniqueKey.split("-");
      updateCartQuantity(productId, size, color, newCounters[uniqueKey]);
      return newCounters;
    });
  };

  return (
    <section className="page-section">
      <div className="container">
        <div className="main-checkout-container ">
          <div
            style={{
              flex: 1,
            }}
            className="favorites "
          >
            <div className="favorites-header">
              <p>Sepetim</p>
            </div>
            <table className="favorites-table">
              <thead>
                <tr>
                  <th>Ürün</th>
                  <th>Fiyat</th>
                  <th>Adet</th>
                  <th>Toplam</th>
                </tr>
              </thead>
              <tbody>
                {cart &&
                  cart.map((item) => {
                    const uniqueKey = `${item.productId._id}-${item.size}-${item.color}`;
                    return (
                      <tr key={uniqueKey}>
                        <td
                          style={{
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <img
                            className="remove-cart-icon"
                            src={cancel}
                            alt="cancel"
                            onClick={() =>
                              removeFromCart(
                                item.productId._id,
                                item.size,
                                item.color
                              )
                            }
                          />
                          <div className="cart-product" key={item?._id}>
                            <img
                              src={imageUrls[item?.productId?.mainImage]}
                              alt={item?.productId?.mainImage}
                            />
                            <p className="fw-normal web">
                              {item?.productId?.name}
                            </p>{" "}
                            <p className="fw-normal mobile">
                              {item?.name.length > 10
                                ? item?.name.substring(0, 10) + "..."
                                : item?.name}
                            </p>
                          </div>
                        </td>
                        <td className="table-price">
                          {item.productId?.price.toFixed(2)}₺
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "16px",
                            }}
                          >
                            <div className="product-counter-container checkout">
                              <span
                                onClick={() =>
                                  handleMultiplier(uniqueKey, "decrement")
                                }
                                style={{ fontSize: "24px" }}
                              >
                                -
                              </span>
                              <span> {counters[uniqueKey]}</span>
                              <span
                                onClick={() =>
                                  handleMultiplier(uniqueKey, "increment")
                                }
                                style={{ fontSize: "24px" }}
                              >
                                +
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="checkout-total-table-price">
                            ₺
                            {(
                              item.productId?.price.toFixed(2) *
                              counters[uniqueKey]
                            ).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <SideNavMenu
            getTotalPrice={getTotalPrice}
            route="odeme"
            onSubmit={() => {
              navigate("/magaza/checkout/odeme");
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Checkout;
