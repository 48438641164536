import React, { useContext, useEffect } from "react";
import Sally from "../../../utils/img/failed-saly.svg";
import basket from "../../../utils/img/icons/shopping basket.svg";
import { Link } from "react-router-dom";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";

function ThankYou() {
  const { clearCart } = useContext(CartFavoritesContext);
  useEffect(() => {
    clearCart();

    if (storageHelper.getStoreWithDecryption("purchaseData")) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(
        storageHelper.getStoreWithDecryption("purchaseData")
      );
    }
  }, []);
  return (
    <>
      <div className="confirmation-page">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img style={{ width: "40px", height: "40px" }} src={basket} />
          <h2 style={{ margin: "0" }}>Siparişin Onaylandı</h2>
          <p
            className="order-information-title"
            style={{
              lineHeight: "33px",
              maxWidth: "600px",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Siparişinizin işlemleri başarı ile onaylanmıştır. En kısa sürede
            temizlenip adresinize teslim edilecektir.
            <br />
            Siparişinin detaylarını{" "}
            <span className="link">
              <Link
                style={{ fontWeight: "550", textDecoration: "underline" }}
                to={"/hesap/siparis"}
              >
                {" "}
                “Siparişlerim”
              </Link>
            </span>{" "}
            sayfasından görüntüleyebilirsin.
          </p>
        </div>
        <img
          style={{
            objectFit: "contain",
            maxWidth: "600px",
            width: "100%",
          }}
          src={Sally}
        />
      </div>
    </>
  );
}

export default ThankYou;
