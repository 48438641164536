import React from "react";

function SuccessInfo({ message }) {
  return (
    <div
      style={{
        zIndex: 9999,
      }}
      className=" success-alert alert alert-success"
      role="alert"
    >
      {message}
    </div>
  );
}

export default SuccessInfo;
