import React from "react";
import info from "../../../utils/img/icons/information.svg";

function Checkboxes({
  offer,
  setOfferId,
  setConfirmationModal,
  formik,
  setIsModalOpen,
  handleCheckboxChange,
  setSelectedImageIndex,
}) {
  const tamirServices = offer?.service?.filter((serv) => serv.type === "tamir");
  const bakimServices = offer?.service?.filter((serv) => serv.type === "bakim");

  return (
    <>
      {bakimServices?.length > 0 && (
        <>
          <h5>Bakım ve Temizlik</h5>
          {bakimServices.map((serv, index) => (
            <div
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
              key={index}
              className=" my-3"
            >
              <input
                id={`${offer?._id}-${serv?._id}-${index}`}
                type="checkbox"
                name={serv.mandatory ? "mandatory" : "selectedOffers"}
                value={`${offer?._id}-${serv?._id}-${index}`}
                checked={
                  serv.mandatory ||
                  formik.values.selectedOffers.includes(
                    `${offer?._id}-${serv?._id}-${index}`
                  )
                }
                onChange={(event) => {
                  if (serv.mandatory) {
                    setConfirmationModal(true);
                    setOfferId(offer);
                  } else {
                    formik.handleChange(event);
                    handleCheckboxChange(event, serv.price, serv);
                  }
                }}
              />
              <div>
                <label
                  htmlFor={`${offer?._id}-${serv?._id}-${index}`}
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "10px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {serv?.name} <span>{serv?.price.toFixed(2)}₺</span>
                </label>
                <small style={{ maxWidth: "600px" }} className="smallInfo">
                  ({serv?.description})
                  <span className="information-img">
                    {" "}
                    <img
                      onClick={() => {
                        setSelectedImageIndex(serv.images[0]);
                        setIsModalOpen(true);
                      }}
                      src={info}
                    />
                  </span>
                </small>
              </div>
            </div>
          ))}
        </>
      )}
      {tamirServices?.length > 0 && (
        <>
          <h5>Tamir İşlemleri</h5>
          {tamirServices.map((serv, index) => (
            <div
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
              key={index}
              className=" my-3"
            >
              <input
                id={`${offer?._id}-${serv?._id}-${index}`}
                type="checkbox"
                name={serv.mandatory ? "mandatory" : "selectedOffers"}
                value={`${offer?._id}-${serv?._id}-${index}`}
                checked={
                  serv.mandatory ||
                  formik.values.selectedOffers.includes(
                    `${offer?._id}-${serv?._id}-${index}`
                  )
                }
                onChange={(event) => {
                  if (serv.mandatory) {
                    setConfirmationModal(true);
                    setOfferId(offer);
                  } else {
                    formik.handleChange(event);
                    handleCheckboxChange(event, serv.price, serv);
                  }
                }}
              />
              <div>
                {" "}
                <label
                  htmlFor={`${offer?._id}-${serv?._id}-${index}`}
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "10px",
                    fontWeight: "500",
                  }}
                >
                  {serv?.name} <span>{serv?.price.toFixed(2)}₺</span>
                </label>
                <small style={{ maxWidth: "600px" }} className="smallInfo">
                  ({serv?.description})
                  <span className="information-img">
                    {" "}
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImageIndex(serv.images[0]);
                        setIsModalOpen(true);
                      }}
                      src={info}
                    />
                  </span>
                </small>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default Checkboxes;
