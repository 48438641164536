import React from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function DeliveryPolicy() {
  return (
    <>
      <Helmet>
        <title>{`Teslimat Koşulları - Online Lostra`}</title>
        <meta name="description" content="noindex" />
      </Helmet>

      <section className="page-section">
        {" "}
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Teslimat Koşulları", url: "/teslimat-kosullari" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Online Lostra</h4>
            <h1>İptal ve İade Koşulları </h1>
          </div>
          <div className="policy" style={{ textAlign: "left", color: "black" }}>
            <strong>MADDE 1 - MALIN TESLİMİ VE TESLİM ŞEKLİ</strong>
            <p>
              Sözleşme MÜŞTERİ tarafından onaylanmakla yürürlüğe girmiş olup
              MÜŞTERİ’nin ONLİNE LOSTRA’ dan satın almış olduğu
              Mallar/Hizmetler, MÜŞTERİ’nin sipariş formunda ve işbu belirtmiş
              olduğu adreste bulunan kişi/kişilere teslim edilecektir.
            </p>

            <strong>MADDE 2 - TESLİMAT MASRAFLARI VE İFASI</strong>
            <p>
              Mal/Hizmet’in teslimat masrafları ONLİNE LOSTRA’ya aittir. ONLİNE
              LOSTRA uygulamasında teslimat ücretinin kendisince karşılanacağını
              beyan etmişse, teslimat masrafları ONLİNE LOSTRA’ye ait olacaktır.
              Malın teslimatı; ONLİNE LOSTRA’nin stokunun müsait olması ve
              ödemenin gerçekleşmesinden sonra zaman taahhüttü olmaksızın
              yapılır. ONLİNE LOSTRA’nin Mal/Hizmet’i, MÜŞTERİ tarafından
              Mal/Hizmet’in sipariş edilmesinden itibaren ONLİNE LOSTRA’nin
              teslimatı gerçekleştirebildiği en kısa süre içinde teslim eder ve
              bu süre içinde yazılı bildirimle iki günlük süre uzatım hakkını
              saklı tutar. Herhangi bir nedenle MÜŞTERİ tarafından Mal/Hizmet
              bedeli ödenmez veya yapılan ödeme banka kayıtlarında iptal
              edilirse, ONLİNE LOSTRA’nin Mal/Hizmet’in teslimi yükümlülüğünden
              kurtulmuş kabul edileceği gibi yukarıda Madde 6.2'de belirtildiği
              üzere satın alınan hizmetin ifası tamamlanmamışsa müşteriye ait
              ürünler ödeme alınana kadar ONLİNE LOSTRA tarafından muhafaza
              edilecektir.
            </p>

            <strong>MADDE 3 - AYIP VE EKSİK BİLDİRİMİ</strong>
            <p>
              <strong>3.1. </strong> Teslim edilen her Ürün ve Hizmet, MÜŞTERİ
              tarafından dikkatle kontrol edilmelidir. Ürün’ün ayıplı veya eksik
              olması durumunda Ürün’ün MÜŞTERİ’ye ulaşmasından itibaren 30
              (otuz) gün içinde bu durumun ONLİNE LOSTRA’ya bildirmesi
              gerekmektedir.
            </p>
            <p>
              <strong> 3.2. </strong> Bu durumun bu süre içinde ONLİNE LOSTRA’ya
              bildirilmemesi ve/veya Ürün/Hizmet’deki ayıbın/eksikliğin ONLİNE
              LOSTRA’ dan kaynaklanmaması halinde ONLİNE LOSTRA tarafından
              herhangi bir sorumluluk kabul edilmez. MÜŞTERİ’nin, sözleşmenin
              kurulduğu tarihte ayıptan haberdar olduğu veya haberdar olmasının
              kendisinden beklendiği hâllerde, sözleşmeye aykırılık söz konusu
              olmaz. Bunların dışındaki ayıplara karşı tüketicinin seçimlik
              hakları saklıdır.
            </p>

            <strong>MADDE 4 - TESLİMAT</strong>
            <p>
              <strong> 4.1.</strong> Ürünler sınırlı sayıda üretilir. Stokların
              tükenmesi halinde, MÜŞTERİ bilgilendirilecek ve sipariş
              ulaştırılamayacaktır. Siparişiniz stokların tükenmesi yüzünden
              size ulaştırılamaz ise, MÜŞTERİ’ye aşağıdaki iki seçenekten birini
              tercih edebilir:
            </p>
            <p>
              • Cayma (iade) hakkınızı kullanabilirsiniz;[İG1] • veya Ürün
              değişimi yapabilirsiniz.
            </p>
            <p>
              <strong> 4.2.</strong> ONLİNE LOSTRA yalnızca İstanbul il
              sınırları içinde kendi belirlediği ve uygulamasında açıkça
              belirttiği bölgelerde teslimat yapmaktadır. Ürünler siparişinizi
              verirken bildirdiğiniz adrese teslim edilecektir. Teslimat
              adresinde bulunmamanız veya hatalı adres bildirmeniz durumunda
              ONLİNE LOSTRA hiçbir sorumluluk kabul etmez. Bu nedenle,
              MÜŞTERİ’nin Ürünü/Hizmeti geç teslim almasından kaynaklanan her
              türlü zarar ile Ürün’ün kargo firmasında beklemiş olması ve/veya
              kargonun teslim edilememesi nedeniyle ONLİNE LOSTRA’ya iade
              edilmesinden dolayı oluşan giderler de MÜŞTERİ’ye aittir.
            </p>
            <p>
              <strong>4.3. </strong>Sözleşme konusu Ürün’ün/Hizmetin teslimatı
              için satış bedelinin ödenmiş olması şarttır. Herhangi bir nedenle
              satış bedeli ödenmez veya banka kayıtlarında iptal edilir ise,
              ONLİNE LOSTRA Ürün’ün/Hizmetin teslimi yükümlülüğünden kurtulmuş
              kabul edilir.
            </p>
            <p>
              <strong>4.4. </strong> Sipariş edilen her bir Ürün/Hizmet, sipariş
              tarihinden itibaren yasal 30 (otuz) günlük süreyi aşmamak koşulu
              ile MÜŞTERİ’nin yerleşim yerinin uzaklığına bağlı olarak MÜŞTERİ
              veya gösterdiği adresteki kişi/kuruluşa en kısa zamanda teslim
              edilir.
            </p>
            <p>
              <strong> 4.5. </strong> Sipariş edilen Ürün, MÜŞTERİ’den başka bir
              kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun
              teslimatı kabul etmemesinden ONLİNE LOSTRA sorumlu tutulamaz.
            </p>

            <strong>MADDE 5 - SORUMLULUK</strong>
            <p>
              ONLİNE LOSTRA sözleşmede yer alan hizmetlerin kendisinden
              kaynaklanmayan nedenlerle MÜŞTERİ'ye uygulanmaması veya eksik
              uygulanması veya mücbir sebep durumlarında hiçbir sorumluluk kabul
              etmez.
            </p>

            <strong>MADDE 6 - MÜCBİR SEBEP</strong>
            <p>
              Taraflar’ın işbu sözleşmedeki yükümlülüklerini yerine getirmesini
              engelleyebilecek nitelikte bir mücbir sebebin veya nakliyeyi
              engelleyen hava muhalefetlerinin, ulaşım kesintisinin, yangın,
              deprem, sel baskını gibi olağanüstü olayların varlığı halinde
              sözleşme konusu Ürün süresi içerisinde teslim edilmez ise MÜŞTERİ
              işbu sözleşmenin 11. maddesinde belirtilen cayma hakkı veya
              teslimat süresinin engelleyici durumunun ortadan kalkmasına kadar
              ertelenmesi hakkından birini kullanabilir.
            </p>
            <p>
              <strong>ONLİNE LOSTRA </strong>
              bir <strong> DU TECH İNTERNET HİZMETLERİ A.Ş. </strong>
              kuruluşudur.{" "}
            </p>
            <p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <strong>Adresi: </strong>
                    </td>
                    <td>
                      {" "}
                      Çırçır Mahallesi Dönen Sokak No:8-10B Eyüpsultan,
                      İstanbul,
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <strong>Telefon:</strong>
                    </td>
                    <td>0 (850) 533 10 70 </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email:</strong>{" "}
                    </td>
                    <td>info@onlinelostra.com</td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Mersis No:</strong>{" "}
                    </td>
                    <td> 0313-1289-0720-0001</td>
                  </tr>
                </tbody>
              </table>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default DeliveryPolicy;
