import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../app-layouts/Loader";
import PricesBox from "../../app-layouts/PricesBox";
import { getBrandByCategoryId } from "../../../library/network/requests/brands";
import { getAllServiceCurrentCategories } from "../../../library/network/requests/serviceCategory";
import slugify from "slugify";

function Prices({ title }) {
  const params = useParams();
  const { slug } = params;
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);

  const getServices = async () => {
    if (title?.length > 1) {
      setLoading(true);
      const res = await getAllServiceCurrentCategories();
      const selectedService = res?.data?.find((item) => item?.name === title);
      getPrices(selectedService.id);
      setLoading(false);
    } else {
      setLoading(true);
      const res = await getAllServiceCurrentCategories();
      const selectedService = res?.data?.find(
        (item) =>
          slugify(item?.name, {
            lower: true,
            remove: /[*+~.()'"!:@?]/g,
          }) === slug
      );
      getPrices(selectedService.id);
      setLoading(false);
    }
  };

  useEffect(() => {
    getServices();
  }, [title]);
  const getPrices = async (id) => {
    setLoading(true);
    try {
      const res = await getBrandByCategoryId(id);

      const data = res.data.sort((a, b) => {
        const priceA = Number(a.price);
        const priceB = Number(b.price);

        if (isNaN(priceA) && isNaN(priceB)) {
          return 0;
        } else if (isNaN(priceA)) {
          return -1;
        } else if (isNaN(priceB)) {
          return 1;
        } else {
          return priceA - priceB;
        }
      });
      const tamir = data.filter((item) => item?.type === "tamir");
      const bakim = data.filter((item) => item?.type === "bakim");

      setPrices([...tamir, ...bakim]);
    } catch (error) {
      // Handle errors
      console.error("Error fetching or processing data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="page-section bg-element-2">
          <div className="container">
            <div className="title">
              <h4>{`Fiyat Listesi`.toLocaleUpperCase("tr-TR")}</h4>
              <h2>
                {prices[0]?.serviceCategoryId?.name}
                <br /> Fiyat Listesi
              </h2>
            </div>
            <div className="price-list-box">
              <div className="container">
                <div className="row">
                  {prices && <PricesBox brandId={slug} prices={prices} />}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Prices;
