import React from "react";
import AppStore from "../../utils/img/app-store-button.png";
import GooglePlay from "../../utils/img/google-play-button.png";
function MobileApp() {
  return (
    <>
      <section id="app" className="mobileAppInfo">
        <div className="container h-100">
          <div className="app-content">
            <div className="inner">
              <h3>Online Lostra Cebinde</h3>
              <h5>Hemen İndir!</h5>
              <div className="app-buttons">
                <a href="#" target="_blank">
                  <img src={AppStore} alt="Onine Lostra App Store İndir" />
                </a>
                <a href="#" target="_blank">
                  <img src={GooglePlay} alt="Onine Lostra Google Play İndir" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MobileApp;
