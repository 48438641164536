import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import cancel from "../../utils/img/icons/cancel-gray.svg";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable } from "firebase/storage";
import InputMask from "react-input-mask";
import Icon from "../../utils/img/icons/contact.png";
import ReCAPTCHA from "react-google-recaptcha";
import { addContact } from "../../library/network/requests/contact";
import uploadIcon from "../../utils/img/icons/upload.svg";
import SuccessInfo from "./SuccessInfo";
import baseService from "../../library/network/services/BaseService";
import {
  dijizinGetService,
  dijizinLoginService,
} from "../../library/network/requests/dijizn";

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "İsim en az 2 karekter olmalı.")
    .max(50, "İsim en falza 30 karekter olmalı.")
    .required("İsim gerekli"),
  lastname: Yup.string()
    .min(2, "Soy isim en az 2 karekter olmalı.")
    .max(50, "Soy isim en falza 30 karekter olmalı.")
    .required("Soy isim gerekli"),
  message: Yup.string()
    .min(5, "Mesaj en az 5 karekter olmalı")
    .max(500, "Mesaj en falza 500 karekter olmalı")
    .required("Mesaj gerekli"),
  number: Yup.number(),
  email: Yup.string()
    .email("Geçerli bir e-posta giriniz")
    .required("Email gereklidir"),
});

const ContactForm = () => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [error, setError] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const recaptchaRef = React.useRef();

  const [success, setSuccess] = useState(false);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const sanitizedFiles = files.map((file) => sanitizeFileName(file));
    setSelectedFile((prevFiles) => [...prevFiles, ...sanitizedFiles]);
  };
  const sanitizeFileName = (file) => {
    if (file) {
      const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9._-]/g, "_");
      const sanitizedFile = new File([file], sanitizedFileName, {
        type: file.type,
      });
      return sanitizedFile;
    }
    return null;
  };

  const PHONE_REGEX = /^05(\d){2} ?\d{3} ?\d{4}$/;
  return (
    <div className="contact-form-container container">
      <div className=" bg-image d-none d-md-block logo-container">
        <img
          style={{
            objectFit: "contain",
          }}
          src={Icon}
          alt="logo"
        />
      </div>
      <div className="register-content container">
        <h1 className="contact-form-title">İletişim formu</h1>
        <Formik
          initialValues={{
            email: "",
            phone: "",
            message: "",
            name: "",
            lastname: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              let mainImages = [];
              if (selectedFile.length > 0) {
                selectedFile.forEach((file) => {
                  mainImages.push(file.name);
                });
                selectedFile.forEach((file) => {
                  if (file) {
                    const storageRef = ref(storage, "images/" + file.name);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    uploadTask.on("state_changed", null, null, () => {
                      console.log("File uploaded successfully!");
                    });
                  }
                });
              }
              values.email = values.email.trim().toLowerCase();
              const res = await addContact({ ...values, mainImages });
              if (res.status === 201) {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                  event: "information_form",
                  formType: "İletişim Formu",
                  formStep: "1",
                  label: "basarılı",
                });
                setSuccess(true);
                setTimeout(() => {
                  setSuccess(false);
                }, 1500);
                setSubmitting(false);
                resetForm();
                setSelectedFile([]);
              }
            } catch (error) {
              setError(" Testi bir daha yapınız");
              console.log(error);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="register-form">
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: "1" }}>
                  <Field type="text" name="name" placeholder="Adın" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div style={{ flex: "1", marginLeft: "10px" }}>
                  <Field type="text" name="lastname" placeholder="Soyadın" />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              <div className="form-area">
                <InputMask
                  type="tel"
                  name="phone"
                  placeholder="Telefon numaran"
                  mask="0599 999 9999"
                  maskChar="_"
                  regex={PHONE_REGEX}
                  onChange={(e) => {
                    const formattedValue = e.target.value.replace(/\s+/g, "");
                    setFieldValue("phone", formattedValue);
                  }}
                />

                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-auth"
                />
              </div>

              <div className="form-area">
                <Field
                  type="email"
                  name="email"
                  value={values.email.trim().toLowerCase()}
                  placeholder="E-posta adresini gir"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-auth"
                />
              </div>

              <div className="form-area">
                <Field
                  component="textarea"
                  id="message"
                  placeholder="Mesajınızı girin"
                  name="message"
                  rows="3"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error-auth"
                />
                {error && <h2 className="error-auth">{error}</h2>}
              </div>
              <div className="form-group">
                <div className="custom-file">
                  <Field
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      handleImageChange(event);
                    }}
                    className="custom-file-input"
                  />
                  <img
                    style={{ width: "30px", cursor: "pointer" }}
                    src={uploadIcon}
                    alt="upload"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="file"
                    style={{
                      cursor: "pointer",
                      margin: "0 10px",
                      fontWeight: "400",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Fotoğraf Yükle
                  </label>
                </div>

                <ErrorMessage
                  name="file"
                  component="div"
                  className="text-danger"
                />
              </div>
              {selectedFile &&
                selectedFile.map((file, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          marginBottom: "5px",
                        }}
                      >
                        {file.name}
                      </p>
                      <img
                        onClick={() => {
                          setSelectedFile(
                            selectedFile.filter(
                              (item) => item.name !== file.name
                            )
                          );
                        }}
                        style={{ width: "8px", cursor: "pointer" }}
                        src={cancel}
                        alt="cancel"
                      />
                    </div>
                  </div>
                ))}

              <div className="form-area contact-button-container">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(token) => {
                    setCaptchaToken(token);

                    setError("");
                  }}
                />

                <button
                  type="submit"
                  className="button button-contact button-primary"
                >
                  {isSubmitting ? "Gönderiliyor" : "Gönder"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {/* </div> */}
      </div>
      {success && <SuccessInfo message={"Mesajınız iletilmiştir"} />}
    </div>
  );
};

export default ContactForm;
