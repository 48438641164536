import React, { useEffect, useState } from "react";
import { getUserOrder } from "../../../library/network/requests/orders";

import Loader from "../../app-layouts/Loader";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import { Helmet } from "react-helmet-async";
import baseService from "../../../library/network/services/BaseService";
import { storage } from "../../../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import ActiveOrdersCard from "./ActiveOrdersCard";
import PreviewOrdersCard from "./PreviewOrdersCard";
import EcommerceOrdersCard from "./EcommerceOrdersCard";

function Orders() {
  const [activeOrders, setActiveOrders] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [ecommerceOrders, setEcommerceOrders] = useState([]);
  const [finishedOrders, setFinishedOrders] = useState([]);
  const userId = storageHelper.getStoreWithDecryption("userId");
  const [activeStatus, setActiveStatus] = useState("ongoing");
  const [loading, setLoading] = useState(false);
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const ecommerceOrderService = async (id) =>
    baseService.get("ecommerceorders/webUser/" + id);
  useEffect(() => {
    getOrders();
    getEcommerceOrders();
  }, []);
  const getEcommerceOrders = async () => {
    setLoading(true);
    const res = await ecommerceOrderService(userId);
    setEcommerceOrders(res?.data);
    if (res?.data) {
      res?.data.forEach((order) => {
        order.products.forEach((product) => {
          getImageUrl(product.productId.mainImage);
        });
      });
    }
  };
  function formatDate(dateStr) {
    if (dateStr) {
      const parts = dateStr.split("/");
      if (parts.length === 3) {
        const day = Number(parts[0]);
        const month = Number(parts[1]) - 1;
        const year = Number(parts[2]);
        const date = new Date(year, month, day);
        const options = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        return date.toLocaleDateString("tr-TR", options);
      } else {
        const date = new Date(dateStr);
        const options = {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        };

        return date.toLocaleDateString("tr-TR", options);
      }
    }
    return null;
  }
  const getOrders = async () => {
    setLoading(true);
    const res = await getUserOrder(userId);
    if (res?.data?.length === 0) {
      setLoading(false);
      setActiveOrders("");
      setFinishedOrders("");
      return;
    }
    const filtered = res?.data?.sort(
      (a, b) => new Date(b.OrderDate) - new Date(a.OrderDate)
    );
    const doneOrders = filtered?.filter(
      (order) =>
        order.status?.some((status) => status.statusName === "done") ||
        order.status?.some((status) => status.statusName === "cancelled")
    );

    const activeData = filtered?.filter(
      (order) =>
        !order.status?.some((status) => status.statusName === "cancelled") &&
        !order.status?.some((status) => status.statusName === "done")
    );
    const paidActiveOrders = activeData?.filter(
      (order) => order.isDeliveryPaid === true
    );

    setActiveOrders(paidActiveOrders);
    setFinishedOrders(doneOrders);
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{`Siparişlerim - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      <h3 className="mb-4">Siparişlerim</h3>
      <div className=" order-titles">
        <div onClick={() => setActiveStatus("ongoing")} className="menu-item">
          <h5 className={activeStatus === "ongoing" ? "active" : ""}>
            Aktif Siparişlerim
          </h5>
        </div>
        <div onClick={() => setActiveStatus("done")} className="menu-item">
          {" "}
          <h5 className={activeStatus === "done" ? "active " : ""}>
            Geçmiş Siparişlerim
          </h5>
        </div>{" "}
        <div onClick={() => setActiveStatus("other")} className="menu-item">
          <h5 className={activeStatus === "other" ? "active" : ""}>
            Mağaza Siparişlerim
          </h5>
        </div>
      </div>
      <hr style={{ marginTop: "-14px" }} />
      {/* <div className="ecommerce-order-list">
        {ecommerceOrders &&
          ecommerceOrders.map((order) => (
            <div
              className="ecommerce-order-card"
              key={order.eCommerceOrderNumber}
            >
              {" "}
              <div className="ecommerce-order-products">
                {order.products.map((product) => (
                  <div
                    className="ecommerce-product-info"
                    key={product.productId._id}
                  >
                    <img
                      src={imageUrls[product.productId.mainImage]}
                      alt={product.productId.name}
                      className="ecommerce-product-image"
                    />
                    <p>{product.productId.name}</p>
                  </div>
                ))}
              </div>
              <div className="ecommerce-order-info">
                <p>
                  <strong>Sipariş numarası:</strong>{" "}
                  {order.eCommerceOrderNumber}
                </p>
                <p>
                  <strong>Sipariş tarihi:</strong> {formatDate(order.OrderDate)}
                </p>
                <p>
                  <strong>Toplam ücret:</strong> {order.totalPrice.toFixed(2)}₺
                </p>
                <p>
                  <strong>Sipariş durumu:</strong>{" "}
                  {order.status[order.status.length - 1].statusName}
                </p>
              </div>
            </div>
          ))}
      </div> */}
      {activeStatus === "other" && (
        <EcommerceOrdersCard
          ecommerceOrders={ecommerceOrders}
          imageUrls={imageUrls}
          formatDate={formatDate}
        />
      )}
      {activeStatus === "ongoing" && (
        <ActiveOrdersCard activeOrders={activeOrders} />
      )}
      {activeStatus === "done" && (
        <PreviewOrdersCard finishedOrders={finishedOrders} />
      )}
    </>
  );
}

export default Orders;
