import React from "react";
import cancel from "../../../utils/img/icons/cancel-gray.svg";

function AcikRiza({ setOnayMetni }) {
  return (
    <div className="modal-overlay">
      <div className="terms-container position-relative">
        <h3
          style={{
            textAlign: "center",
          }}
        >
          KVK KANUNU KAPSAMINDA AÇIK RIZA BEYANI
        </h3>
        <p>
          {" "}
          DU TECH İNTERNET HİZMETLERİ A.Ş. (‘‘<strong>ONLİNE LOSTRA</strong>’’)
          tarafından, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVK
          Kanunu”) ilgili hükümlerine uygun olarak bilginize sunulan Kişisel
          Verilerin Korunması Kanunu Kapsamında Genel Aydınlatma Metni
          çerçevesinde,
        </p>{" "}
        <p>
          {" "}
          Kişisel verilerinin veri sorumlusu sıfatıyla{" "}
          <strong>ONLİNE LOSTRA</strong> veya gerekli güvenlik tedbirlerini
          aldırmak suretiyle yetkilendirdiği veri işleyenler tarafından;{" "}
          <strong>ONLİNE LOSTRA</strong>’nın müşterilerine sunmuş olduğu ürün ve
          hizmetlerini en iyi koşullar altında sağlayabilmesi, ürün veya
          hizmetlerin güvenilir ve kesintisiz bir şekilde temin edilmesi,
          müşteri memnuniyetinin en üst seviyeye çıkarılması, ödemelerin
          yapılması, mezkûr hizmetlere ilişkin çeşitli işlemlerin yerine
          getirilmesi, operasyonların yürütülmesi ve geliştirilmesi, mezkûr ürün
          ve hizmetlerin veya farklı ürün ve hizmetlerin tanıtım, pazarlama,
          reklam ve kampanya faaliyetlerinin yapılması, müşterilerin
          fırsatlardan, kampanyalardan ve sair hizmetlerden haberdar edilmesi ve
          müşterilerle akdedilen sözleşmelerin gereklerinin yerine getirilmesi
          amaçlarıyla doğrudan veya dolaylı olarak ilgili olan kimlik
          bilgilerinin, adres bilgilerinin, iletişim bilgilerinin ve sair
          kişisel verilerin; başta mevzuatta öngörülen veya işlendikleri amaç
          için gerekli olan süre kadar muhafaza edilme ilkesi olmak üzere 6698
          Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVK Kanunu”) 4.
          maddesinde ifade edilen genel ilkelere uygun şekilde işlenebileceğini;
          elde edilebileceğini, kaydedilebileceğini, işlenme amacıyla uygun süre
          zarfında fiziksel veya elektronik ortamda güvenli bir şekilde
          depolanabileceğini, muhafaza edilebileceğini, değiştirilebileceğini,
          yeniden düzenlenebileceğini, mevzuata uygun biçimde açıklanabileceğini
          ve aktarılabileceğini, devralınabileceğini, sınıflandırılabileceğini,
          işlenebileceğini ya da verilerin kullanılmasının engellenebileceğini;
          yukarıda belirtilen hususlarla ilgili olarak{" "}
          <strong>ONLİNE LOSTRA</strong> tarafından bilgilendirildiğimi ve KVK
          Kanunu çerçevesinde açık rızam bulunduğunu kabul ve beyan ederim. İşbu
          kişisel verilerimin, yukarıda belirtilen amaçlarla bağlı kalmak
          kaydıyla, <strong>ONLİNE LOSTRA</strong> tarafından;{" "}
          <strong>ONLİNE LOSTRA</strong> çalışanlarına, görevlilerine, grup
          şirketlerine (Şirket ve/veya iş ortaklarına, hissedarlarına), kanunen
          yetkili kamu kurum ve kuruluşlarına, faaliyetlerini yürütebilmek
          amacıyla, hukuki zorunluluklar ve yasal sınırlamalar çerçevesinde
          bağımsız denetim şirketlerine, anket şirketlerine, tarafıma verilecek
          hizmetlerin ve/veya faaliyetlerin yürütülmesi için{" "}
          <strong>ONLİNE LOSTRA</strong>’nın hizmet aldığı veya birlikte
          çalıştığı iş ortaklarına ve hizmet sağlayıcılarına aktarılabileceğini
          ve bu hususta açık rızam olduğunu kabul ve beyan ederim.{" "}
        </p>
        <p>
          Bununla birlikte, KVK Kanunu’nun 11.maddesi ve ilgili mevzuat
          uyarınca; <strong>ONLİNE LOSTRA</strong>’ya başvurarak kendimle
          ilgili; kişisel veri işlenip işlenmediğini öğrenme, kişisel verilerim
          işlenmişse buna ilişkin bilgi talep etme, kişisel verilerimin işlenme
          amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
          yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
          kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması
          hâlinde bunların düzeltilmesini isteme, işbu verilerin işlenmesini
          gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerimin
          silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme
          taleplerinin kişisel verilerin aktarıldığı üçüncü kişilere
          bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler
          vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir sonucun
          ortaya çıkmasına itiraz etme, kişisel verilerimin kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini
          talep etme haklarımın olduğunu ve bu hakları kullanmak için kimliğimi
          tespit edici gerekli bilgiler ile kullanmayı talep ettiğim hakkıma
          yönelik açıklamaları da içeren talebimi www.sistemkozmetik.com
          adresindeki formu doldurarak yahut noter kanalıyla veya KVK Kanunu’nda
          belirtilen diğer yöntemler ile iletme hakkına sahip olduğumu kabul
          ediyorum.
        </p>
        <p>
          {" "}
          Ayrıca,
          <strong>
            <strong>ONLİNE LOSTRA</strong>
          </strong>{" "}
          ile paylaşmış olduğum kişisel verilerin doğru ve güncel olduğunu; işbu
          bilgilerde değişiklik olması halinde değişiklikleri
          <strong>
            <strong>ONLİNE LOSTRA</strong>
          </strong>
          ’ya bildireceğimi kabul ve beyan ederim.
        </p>
        <p>
          KVK Kanunu’nda tanımlanan özel nitelikli kişisel verilerim de dahil
          olmak üzere ilgili kişisel verilerimin işlenmesine, ilgili süreç
          kapsamında işlenme amacı ile sınırlı olmak üzere kullanılmasına ve
          paylaşılmasına, gereken süre zarfında saklanmasına açık rızam olduğunu
          ve bu hususta tarafıma gerekli aydınlatmanın yapıldığını; işbu metni
          okuduğumu ve anladığımı kabul ediyorum.
        </p>
        <p>
          Onaylamanız halinde sistem üzerinde kayıtlarınız açılacak olup, zaman
          damgalı olarak kayıt yapılacaktır. İlgili kişisel verilerinizin
          herhangi bir şekilde sizin tarafınızdan iletilmeden işlenmesi mümkün
          değildir.
        </p>
        <button
          onClick={() => setOnayMetni(false)}
          className="btn cancel-button"
        >
          Kapat
        </button>
        <div
          style={{
            top: "25px",
            right: "25px",
            cursor: "pointer",
            position: "absolute",
          }}
        >
          <img
            src={cancel}
            alt="cancel"
            className="cancel-icon"
            onClick={() => setOnayMetni(false)}
          />
        </div>
      </div>
    </div>
  );
}

export default AcikRiza;
