import React from "react";
import PricesBox from "./PricesBox";

function PricesDrawer({ title, prices, isClicked, handleClick }) {
  return (
    <div
      key={prices[0]?.serviceCategoryId?._id}
      onClick={() => handleClick(prices[0]?.serviceCategoryId?.name)}
      className={
        isClicked === prices[0]?.serviceCategoryId?.name
          ? "faq-box price-box active "
          : "faq-box price-box"
      }
    >
      <h3>{title}</h3>
      <div className="price-list-box-allPrices">
        <div className="container">
          <div className="row">
            <PricesBox prices={prices} />
          </div>
        </div>
      </div>
      <span className="activate"></span>
    </div>
  );
}

export default PricesDrawer;
