import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import balaban from "../../utils/img/carousel/balaban 1.svg";
import image2 from "../../utils/img/carousel/image 2359.svg";
import image3 from "../../utils/img/carousel/image 2360.svg";
import image4 from "../../utils/img/carousel/image 2361-2.svg";
import image5 from "../../utils/img/carousel/image 2362.svg";
import image6 from "../../utils/img/carousel/image 2363.svg";
import image7 from "../../utils/img/carousel/image 2364.svg";
import image8 from "../../utils/img/carousel/image 2365.svg";
import image10 from "../../utils/img/carousel/image 2361.svg";
import image9 from "../../utils/img/carousel/Rectangle 22410.svg";

function Carousel() {
  const carouselRef = React.useRef(null);
  const handlePreviousSlide = () => {
    carouselRef.current.slickPrev();
  };
  const handleNextSlide = () => {
    carouselRef.current.slickNext();
  };

  const images = [
    image2,
    image9,
    image3,
    image10,
    image5,

    image6,
    image4,
    balaban,
    image7,
    image8,
  ];
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoPlay: true,

    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1390,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 896,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 674,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <div style={{ color: "#fff", flex: 1 }}>
        <p>
          Çalışanlarına <br className="web" /> avantaj sağladığımız firmalar
        </p>
      </div>
      <div
        style={{
          flex: 2,
          overflow: "hidden",
        }}
      >
        <Slider ref={carouselRef} {...settings}>
          {images.map((image, index) => (
            <div
              style={{
                width: "130px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
              key={index}
            >
              <img
                className="carousel-image"
                src={image}
                alt={`carousel ${index}`}
              />
            </div>
          ))}
        </Slider>
        <div className="my-2 carousel-navigation">
          <div className="arrow prev" onClick={handlePreviousSlide}></div>
          <div className="arrow next" onClick={handleNextSlide}></div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
