import React, { useContext, useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
import cancel from "../../utils/img/icons/delete-product.svg";
import Arrow from "../../utils/img/icons/arrow-to-right.svg";

import { CartFavoritesContext } from "../../contexts/CartFavoritesContext";
import { useNavigate } from "react-router-dom";

function CartMenu({ mobile, setIsHovered }) {
  const { cart, removeFromCart, deliveryPrices } =
    useContext(CartFavoritesContext);
  const [imageUrls, setImageUrls] = useState({});
  const navigate = useNavigate();

  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };

  useEffect(() => {
    cart.forEach((product) => {
      getImageUrl(product?.productId?.mainImage, "products");
      product?.productId?.images?.forEach((image) => {
        getImageUrl(image, "products");
      });
    });
  }, [cart]);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`cart-icon-container-inner`}
    >
      <p className="cart-title">
        Sepetim <span>({cart.length} Ürün)</span>
      </p>{" "}
      <div className="horizontal-line"></div>
      {cart.length > 0 ? (
        <>
          {" "}
          <section className="cart-product-container">
            {cart &&
              cart.map((product, index) => (
                <div className="cart-product" key={index}>
                  <img
                    src={imageUrls[product?.productId?.mainImage]}
                    alt={product?.productId?.mainImage}
                  />
                  <div
                    style={{
                      maxWidth: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <p className="fw-normal">{product?.productId?.name}</p>
                    <p className="fw-normal">
                      {product?.size} - {product?.color}
                    </p>
                    <p className="fw-normal product-price-count">
                      {product.quantity} x{" "}
                      <span>₺{product?.productId?.price?.toFixed(2)}</span>
                    </p>
                  </div>
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFromCart(
                        product?.productId?._id,
                        product?.size,
                        product?.color
                      );
                    }}
                    className="cancel-img"
                    alt="delete"
                    src={cancel}
                  />
                </div>
              ))}
          </section>
          <div className="horizontal-line"></div>
          <section className="cart-total">
            <p>Kargo:</p>
            <p>₺{deliveryPrices && deliveryPrices.toFixed(2)}</p>
          </section>{" "}
          <section className="cart-total">
            {" "}
            <p>Toplam:</p>
            <p>
              ₺
              {cart &&
                (
                  cart.reduce(
                    (acc, curr) => acc + curr?.price * curr?.quantity,
                    0
                  ) + deliveryPrices
                ).toFixed(2)}
            </p>
          </section>
          <section className="cart-buttons">
            {" "}
            <button
              onClick={() => navigate("/magaza/checkout/odeme")}
              className="complate-order-button button-primary"
              // onClick={handleAddToCart}
              // disabled={!selectedSize || !selectedColor}
            >
              Siparişi Tamamla
              <img src={Arrow} className="button-arrow" alt="simpleCartIcon" />
            </button>
            <button
              onClick={() => navigate("/magaza/checkout")}
              className="go-to-cart-button button-ghost"
            >
              Sepete Git
            </button>
          </section>
        </>
      ) : (
        <>
          {" "}
          <section className="d-flex align-items-center cart-product-container">
            <h5> Sepetiniz boş</h5>
          </section>
        </>
      )}
    </div>
  );
}

export default CartMenu;
