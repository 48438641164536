import React from "react";
import logo from "../../../assets/images/google-symbol1.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { setSession } from "../../../contexts/AccountContext";
import { loginSuccess } from "../../../store/slices/accountSlice";
import baseService from "../../../library/network/services/BaseService";

const SocialAuth = (props) => {
  const loginService = async (token) =>
    baseService.post("webusers/googleLogin", token);
  const { text } = props;
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      loginService({ googleAccesToken: tokenResponse.access_token }).then(
        (res) => {
          setSession(res.data.token, res.data.userData.id);
          dispatch(loginSuccess(res.data.userData.email));
        }
      );
    },
  });
  const facebookAuth = () => {
    window.open(
      // "https://lionfish-app-mwoqx.ondigitalocean.app/auth/facebook/callback",
      "http://localhost:8080/auth/webusers/facebookLogin",
      "_self"
    );
  };
  return (
    <div className="socialContainer my-5">
      <div className="mobile-auth">
        <div className="divider">
          <span></span>
          <small>veya</small>
          <span></span>
        </div>
      </div>
      <button onClick={login} className="socialButton">
        <img width="20" height="20" src={logo} />
        <p className="text">Google ile {text}</p>
      </button>
      <div className="web">
        <div className="divider">
          <span></span>
          <small>veya</small>
          <span></span>
        </div>
      </div>
      {/* <button onClick={facebookAuth} className="socialButton">
                <img width="20" height="20" src={facebookPng} />
                <p className="text">Facebook ile {text ? "giriş yap" : "üye ol"}</p>
            </button> */}
    </div>
  );
};

export default SocialAuth;
