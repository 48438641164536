import React from "react";
import Logo from "../../../utils/img/online-lostra-logo-white-big.png";
import { Link } from "react-router-dom";

function LeftPanel() {
  return (
    <>
      <div className="register-left web bg-element-3">
        <div>
          <Link to="/">
            <img src={Logo} alt="Online Lostra Logo" />
          </Link>
          <h2>Hoşgeldin!</h2>
          <p>
            Daha önceden üye olduğun mail adresin ve şifrenle güvenli giriş
            yapabilirsin.
          </p>
        </div>
      </div>
    </>
  );
}

export default LeftPanel;
