import React from "react";

function FilterByBrand({ selectedTag, handleTagClick }) {
  const tags = [
    {
      name: "Ayakkabı",
      value: "ayakkabi",
    },
    {
      name: "Çanta",
      value: "canta",
    },
    {
      name: "Bot",
      value: "bot",
    },
    {
      name: "Spor",
      value: "spor",
    },
    {
      name: "Kadın",
      value: "kadin",
    },
    {
      name: "Erkek",
      value: "erkek",
    },
    {
      name: "Çocuk",
      value: "cocuk",
    },
    {
      name: "Bağcık",
      value: "bagcik",
    },
    {
      name: "Taban",
      value: "taban",
    },
    {
      name: "Kalıb",
      value: "kalib",
    },
    {
      name: "Boya",
      value: "boya",
    },
  ];

  return (
    <>
      {" "}
      <div className="side-menu-group-container">
        <p className="side-menu-title">POPÜLER ETİKETLER</p>
        <div className="side-menu-group-menu-items">
          <div className="tags-container">
            {tags.map((tag) => (
              <div
                onClick={() => handleTagClick(tag.value)}
                key={tag.value}
                className={`tag-item ${
                  selectedTag.includes(tag.value) ? "active" : ""
                }`}
              >
                {tag.name}
              </div>
            ))}
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default FilterByBrand;
