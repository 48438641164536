import React from "react";
import Process from "../../app-layouts/Process";
import SssPanel from "../../app-layouts/SssPanel";
import Privileges from "../../app-layouts/Privileges";
import MobileApp from "../../app-layouts/MobileApp";
import photo from "../../../utils/img/Rectangle 982.png";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
function AboutPage() {
  return (
    <>
      <Helmet>
        <title>{`Hakkımızda - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>

      <section className="page-section">
        {" "}
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Hakkımızda", url: "/hakkimizda" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Hakkımızda</h4>
            <h1>Online Lostra </h1>
          </div>
          <div className="container">
            {" "}
            <div className="row">
              <div className="col-md-6">
                <div className="service-content">
                  <h5>Biz kimiz?</h5>
                  <p>
                    Online Lostra adından da anlaşılacağı üzere dijital ortamda
                    rezervasyonlarınızı alarak ayakkabılarınızın tamiratını ve
                    bakımını gerçekleştirir.
                  </p>
                  <h5>Neden biz?</h5>
                  <p>
                    Artık Lostra hizmeti için güvenilir bir yer aramanıza,
                    ayakkabılarınızı taşımanıza ve beklemenize gerek yok.
                  </p>
                  <p>
                    WhatsApp destek hattımızı kullanarak, valemizin
                    ayakkabılarınızı gelip almasını istediğin zaman belirle.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="service-image">
                  <img
                    style={{ objectFit: "cover", maxWidth: "100%" }}
                    src={photo}
                    alt="hakkimizda foto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Process />
      <MobileApp />
      <Privileges />
      <SssPanel />
    </>
  );
}

export default AboutPage;
