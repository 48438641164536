import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";
import {
  getUserById,
  updateWebuserDetails,
} from "../../../library/network/requests/webusers";
import SuccessInfo from "../../app-layouts/SuccessInfo";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import { Helmet } from "react-helmet-async";

function Profile() {
  const PHONE_REGEX = /^05(\d){2} ?\d{3} ?\d{4}$/;

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const userId = storageHelper.getStoreWithDecryption("userId");
  const [user, setUser] = useState([]);
  useEffect(() => {
    // setIsLoading(true);
    getUserById(userId).then((res) => {
      setUser(res.data);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Hesabım - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>

      <div className="register-content">
        <h3>Profilim</h3>

        <Formik
          enableReinitialize={true}
          initialValues={{
            firstname: user.firstname || "",
            lastname: user.lastname || "",
            phone: user.phone || "",
            email: user.email || "",
            password: user.password || "",
            newpassword: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            values.email = values.email.trim().toLowerCase();
            const model = {
              lastname: values?.lastname ? values.lastname : user.lastname,
              firstname: values?.firstname ? values.firstname : user.firstname,
              phone: values?.phone ? values.phone : user.phone,
              password: values?.newpassword
                ? values.newpassword
                : user.password,
              email: values?.email ? values.email : user.email,
            };
            const res = await updateWebuserDetails(user._id, model);
            if (res.status === 200) {
              setMessage("İşleminiz başarılı!");
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 1500);
            }
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="register-form">
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {" "}
                <div style={{ flex: "1" }}>
                  <label htmlFor="firstname">Adın</label>
                  <Field type="text" name="firstname" placeholder="Adın" />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div style={{ flex: "1", marginLeft: "10px" }}>
                  <label htmlFor="name">Soyadın</label>
                  <Field type="text" name="lastname" placeholder="Soyadın" />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              <div className="form-area">
                <label htmlFor="email">E-posta</label>
                <Field
                  className="input"
                  type="email"
                  readOnly
                  // value={values.email.trim().toLowerCase()}
                  name="email"
                  placeholder="E-posta adresinizi girin."
                />
              </div>
              <div className="form-area">
                <label htmlFor="phone">Telefon Numarası</label>

                <InputMask
                  type="tel"
                  name="phone"
                  placeholder="Telefon numaranızı girin."
                  mask="0599 999 9999"
                  value={values.phone}
                  maskChar="_"
                  regex={PHONE_REGEX}
                  onChange={(e) => {
                    const formattedValue = e.target.value
                      .replace(/\s+/g, "")
                      .replace("+", "");
                    setFieldValue("phone", formattedValue);
                  }}
                />
              </div>
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {/* <div style={{ flex: "1" }} className="form-area">
                  <label htmlFor="password">Eski Şifre</label>
                  <Field
                    className="input"
                    type="password"
                    name="password"
                    placeholder="Şifrenizi girin."
                  />
                </div> */}
                <div
                  style={{ flex: "1", }}
                  className="form-area"
                >
                  <label htmlFor="passwordCheck">Yeni Şifre </label>
                  <Field
                    type="password"
                    className="input"
                    name="newpassword"
                    placeholder="Yeni Şifrenizi  girin."
                  />
                </div>
              </div>

              <div className="form-area d-flex justify-content-between align-items-center">
                <button
                  type="submit"
                  className="button button-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Kaydetiliyor..." : "Kaydet"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {success && <SuccessInfo message={message} />}
      </div>
    </>
  );
}

export default Profile;
