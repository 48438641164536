import React, { useEffect } from "react";
import Sally from "../../utils/img/icons/Saly-1.svg";
import basket from "../../utils/img/icons/shopping basket.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";
import { storageHelper } from "../../utils/helpers/StorageHelper";

function OrderConfirmation() {
  const { orderId } = useParams();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    const purchaseData = storageHelper.getStoreWithDecryption("createData");
    if (purchaseData) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(purchaseData);
    }
  }, []);

  let type = query.get("type");
  return (
    <>
      <section>
        <div
          style={{
            padding: "0",
          }}
          className="container"
        >
          {type === "true" ? (
            <div className="confirmation-page">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img
                  alt="basket"
                  style={{
                    width: "40px",

                    height: "40px",
                  }}
                  src={basket}
                />
                <h2 style={{ margin: "0" }}>Siparişin Oluşturuldu</h2>
                <p className="order-information-title">
                  Ürünleriniz anlaşmalı olduğumuz hepsiJET Kargo ile belirtilen
                  zaman diliminde alınacaktır. Alım öncesi size iletilen “iade
                  kargonuz alınacaktır” şeklindeki mesaj ile
                  bilgilendirileceksiniz.
                </p>
                <p className="order-information-title">
                  Ürünlerinizi, ağzı bağlı olacak şekilde içerisine sipariş
                  numaranızı yazarak poşetleyebilirsiniz. İşlem sonunda özel
                  olarak paketlenip sizlere teslim edilecektir.
                </p>
                <p className="order-information-title">
                  <p />
                  Siparişinin detaylarını{" "}
                  <span className="link">
                    <Link
                      onClick={() => {
                        handleButtonClicks(
                          "hesap/siparis",
                          "Siparişlerim",
                          "Teslimat Bilgisi"
                        );
                      }}
                      style={{ fontWeight: "550", textDecoration: "underline" }}
                      to={"/hesap/siparis"}
                    >
                      {" "}
                      “Siparişlerim”
                    </Link>
                  </span>{" "}
                  sayfasından görüntüleyebilirsin.
                </p>
              </div>
              {/* <div style={{ flex: "1" }}> */}
              <img
                style={{
                  objectFit: "contain",
                  maxWidth: "600px",
                  // minWidth: "200px",
                  width: "100%",
                }}
                src={Sally}
                alt="sally"
              />
              {/* </div> */}
            </div>
          ) : (
            <h1>
              {" "}
              <div className="confirmation-page ">
                <div
                  className="order-information-text"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    // flex: "1",
                  }}
                >
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      marginBottom: "30px",
                    }}
                    alt="basket"
                    src={basket}
                  />
                  <h2 style={{ margin: "0" }}>Siparişin Oluşturuldu</h2>
                  <p className="order-information-title">
                    Ürünlerinizi anlaşmalı olduğumuz Sürat Kargo’nun, size en
                    yakın şubesine anlaşma numaramız olan 1381312520 ile 3 gün
                    içerisinde <br /> teslim etmenizi rica ederiz. Kargo
                    sisteminde şirket ünvanımız “DU TECH INTERNET HİZMETLERİ VE
                    LOJİSTİK A.Ş.” olarak görünecektir.
                  </p>
                  <p className="order-information-title">
                    Ayakkabılarınızı ağzı bağlı bir torba içerisinde ücretsiz
                    olarak teslim edebilirsiniz, İşlem sonunda özel olarak
                    paketlenip size teslim edilecektir.
                  </p>
                  <p className="order-information-title">
                    Paketinizin içine ‘’{" "}
                    <span className="font-weight-bold">{orderId ?? ""} </span>{" "}
                    ‘’ *Sipariş Numaranızı yazmanızı rica ederiz. <br />{" "}
                  </p>
                  <p className="order-information-title">
                    Siparişinin detaylarını{" "}
                    <span className="link">
                      <Link
                        onClick={() => {
                          handleButtonClicks(
                            "hesap/siparis",
                            "Siparişlerim",
                            "Teslimat Bilgisi"
                          );
                        }}
                        style={{
                          fontWeight: "550",
                          textDecoration: "underline",
                        }}
                        to={"/hesap/siparis"}
                      >
                        {" "}
                        “Siparişlerim”
                      </Link>
                    </span>{" "}
                    sayfasından görüntüleyebilirsin.
                  </p>
                </div>
                {/* <div style={{ flex: "1" }}> */}
                <img
                  style={{
                    objectFit: "contain",
                    maxWidth: "600px",
                    minWidth: "200px",
                    width: "100%",
                  }}
                  src={Sally}
                  alt="sally"
                />
                {/* </div> */}
              </div>
            </h1>
          )}
        </div>
      </section>
    </>
  );
}

export default OrderConfirmation;
