import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import cancel from "../../../utils/img/icons/cancel.svg";
import ok from "../../../utils/img/icons/ok.svg";

function EcommerceOrdersCard({ ecommerceOrders, imageUrls, formatDate }) {
  return (
    <div className="order-list">
      {ecommerceOrders &&
        ecommerceOrders.map((order) => (
          <div key={order._id} className="order-boxes">
            <div className="my-2"></div>
            <div
              className="btn-holder status-container"
              style={{ height: "110px", flexWrap: "wrap" }}
            >
              <div>
                <div>
                  <p>
                    <span className="light-text">Sipariş NO: </span>#
                    {order?.eCommerceOrderNumber}
                  </p>
                  <div
                    style={{ justifyContent: "space-between" }}
                    className="product-info d-flex my-2"
                  >
                    {order.products.slice(0, 2).map((product) => (
                      <img
                        key={product.productId._id}
                        src={imageUrls[product.productId.mainImage]}
                        alt={product.productId.name}
                        className="ecommerce-product-image"
                      />
                    ))}
                  </div>
                </div>
                <div className="d-flex">
                  {order.categories?.map((category) => (
                    <img
                      key={category._id}
                      src={imageUrls[category.icon]}
                      alt={category.icon}
                    />
                  ))}
                </div>
              </div>
              <div style={{ height: "50px" }}>
                <p>
                  <span className="light-text">Sipariş tarihi: </span>
                  <span style={{ marginRight: "14px" }}>
                    {new Date(order.OrderDate)
                      .toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .replace(/\//g, ".")}
                  </span>
                </p>
                {order.status.some((s) => s.statusName === "delivered") && (
                  <div className="d-flex align-items-center">
                    <div className="icon-container order-done">
                      <img src={ok} alt="Order Completed" />
                    </div>
                    <p
                      style={{ margin: "0", padding: "0", marginLeft: "10px" }}
                    >
                      Order Completed
                    </p>
                  </div>
                )}
              </div>
              {order.status.some((s) => s.statusName === "cancelled") && (
                <div className="d-flex align-items-center">
                  <div className="icon-container order-cancel">
                    <img src={cancel} alt="Order Cancelled" />
                  </div>
                  <p style={{ margin: "0", padding: "0", marginLeft: "10px" }}>
                    Order Cancelled
                  </p>
                </div>
              )}
              {order.status.some((s) => s.statusName === "active") && (
                <div>
                  <p className="d-flex align-items-center">
                    Ücret: {order.totalPrice}₺
                  </p>
                </div>
              )}
              <Link to={`/siparis/magaza/detaylari/${order._id}`}>
                <span className="awaiting-confirm details-link cursor">
                  Sipariş detayı için tıklayınız.
                </span>
              </Link>
            </div>
            <div className="order-products"></div>
          </div>
        ))}
    </div>
  );
}

export default EcommerceOrdersCard;
