import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Img from "../../../utils/img/hizmetlerimiz/ayakkabi-temizleme.png";
import Comments from "../../app-layouts/Comments";
import SssPanel from "../../app-layouts/SssPanel";
import Prices from "../Prices/Prices";
import {
  getAllServiceCategories,
  getAllServiceCurrentCategories,
  getByIdServiceCategories,
} from "../../../library/network/requests/serviceCategory";
import Loader from "../../app-layouts/Loader";
import Ayakkabi from "../../../utils/img/hizmetlerimiz/ayakkabi-temizleme.png";
import Camasir from "../../../utils/img/hizmetlerimiz/camasir-yikama.png";
import Canta from "../../../utils/img/hizmetlerimiz/canta-temizleme.png";
import DryClean from "../../../utils/img/hizmetlerimiz/kuru-temizleme.png";
import Utu from "../../../utils/img/hizmetlerimiz/utuleme-hizmeti.png";
import Process from "../../app-layouts/Process";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import slugify from "slugify";

function CategoryPrices() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);
  const { slug } = params;

  const getServices = async () => {
    setLoading(true);
    const res = await getAllServiceCurrentCategories();
    const selectedService = res?.data?.find(
      (item) =>
        slugify(item?.name, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }) === slug
    );
    getServiceCategory(selectedService?.id);
    setLoading(false);
  };
  const getServiceCategory = async (id) => {
    setLoading(true);

    const res = await getByIdServiceCategories(id);
    setService(res.data);
    setLoading(false);
  };
  useEffect(() => {
    getServices();
  }, [params]);

  const icons = {
    ayakkabi: Ayakkabi,
    kurutemizleme: DryClean,
    camasiryikama: Camasir,
    utulemehizmeti: Utu,
    cantatemizleme: Canta,
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{`${service?.name ?? ""} Fiyatları - Online Lostra`}</title>
        <meta name="description" content={`${service?.name ?? ""} Fiyatları`} />
      </Helmet>
      <div className="container">
        <Breadcrumbs
          paths={[
            { text: "Anasayfa", url: "/" },
            { text: "Fiyatlarımız", url: "/fiyatlarimiz" },
            {
              text: `${service?.name ?? ""} Fiyatları`,
              url: "/" + slug,
            },
          ]}
        />
      </div>
      <Prices />
      <section className="page-section">
        <div className="container">
          <div className="title">
            <h4>{`Hizmetlerimiz`.toLocaleUpperCase("tr-TR")}</h4>
            <h1>{service?.name}</h1>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="service-content">
                <p dangerouslySetInnerHTML={{ __html: service?.description }} />
                <p>
                  Hemen sipariş oluştur, valelerimiz dilediğin saatte
                  ayakkabılarını alsın, yenilendikten sonra 4-5 gün içinde
                  tekrar sana teslim etsin.
                </p>
                <Link to="/siparis-olustur">
                  <span className="button button-primary">Sipariş Oluştur</span>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-image">
                <img src={icons[service?.icon]} alt={service?.name} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Process />

      <Comments />
      <SssPanel pageType="price" category={slug.split("-")[0]} />
    </>
  );
}

export default CategoryPrices;
