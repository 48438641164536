import React from "react";
import { Link } from "react-router-dom";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";

function Pagination({ postsPerPage, totalPosts, paginate }) {
  const pageNumbers = [];

  const totalPages = Math.ceil(totalPosts / postsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul
      style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
      className="pagination"
    >
      {pageNumbers.map((number) => (
        <li key={number} className="page-item">
          <Link
            to={`/blog?page=${number}`}
            className="page-link"
            onClick={() => {
              handleButtonClicks(`/blog?page=${number}`, number, "blog");
              paginate(number);
            }}
          >
            {number}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default Pagination;
