import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import baseService from "../../library/network/services/BaseService";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import { v4 as uuidv4 } from "uuid";

const Bireysel = ({
  userData,
  setUserData,
  getUser,
  setNewInvoice,
  selectedPersonalInvoice,
}) => {
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const userId = storageHelper.getStoreWithDecryption("userId");
  const faturaService = async (url, data) => baseService.post(url, data);
  const cityService = async () => baseService.get("cities");
  const getCities = async () => {
    const res = await cityService();
    setCityData(res.data);
  };
  const initialValues = {
    idNumber: "",
    firstname: "",
    lastname: "",
    address: "",
    city: "",
    district: "",
  };
  const updateValues = {
    idNumber: selectedPersonalInvoice?.idNumber,
    firstname: selectedPersonalInvoice?.firstname,
    lastname: selectedPersonalInvoice?.lastname,
    address: selectedPersonalInvoice?.address,
  };

  const getDistricts = async (il) => {
    const filteredData = cityData.filter((item) => item.city === il);
    setDistrictData(filteredData);
  };
  const handleSubmit = async (values) => {
    try {
      if (selectedPersonalInvoice.firstname) {
        const model = {
          id: selectedPersonalInvoice.id,
          idNumber: values.idNumber,
          firstname: values.firstname,
          lastname: values.lastname,
          address: values.address,
          city: values.city || selectedPersonalInvoice.city,
          district: values.district || selectedPersonalInvoice.district,
        };

        const res = await faturaService(
          "webusers/updatePersonal/" +
            userId +
            "/" +
            selectedPersonalInvoice.id,
          {
            personalInvoice: model,
          }
        );
      } else {
        const model = {
          id: uuidv4(),
          idNumber: values.idNumber,
          firstname: values.firstname,
          lastname: values.lastname,
          address: values.address,

          city: values.city,
          district: values.district,
        };

        const res = await faturaService(
          "webusers/addInvoicePersonal/" + userId,
          {
            personalInvoice: model,
          }
        );

        getUser();
        setNewInvoice(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <Formik
      validate={(values) => {
        const errors = {};

        if (!values.idNumber) {
          errors.idNumber = "TC Kimlik No alanı gereklidir.";
        } else if (values.idNumber.length !== 11) {
          errors.idNumber = "TC Kimlik No 11 karakter olmalıdır.";
        }

        if (!values.firstname) {
          errors.firstname = "İsim alanı gereklidir.";
        }

        if (!values.lastname) {
          errors.lastname = "Soyisim alanı gereklidir.";
        }

        if (!values.address) {
          errors.address = "Adres alanı gereklidir.";
        }

        if (!values.city) {
          errors.city = "İl Seçiniz alanı gereklidir.";
        }

        if (!values.district) {
          errors.district = "İlçe Seçiniz alanı gereklidir.";
        }

        return errors;
      }}
      initialValues={updateValues.address ? updateValues : initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="invoice-form">
          <div>
            <Field
              type="text"
              maxLength="11"
              name="idNumber"
              placeholder="TC Kimlik No"
            />
            <ErrorMessage
              name="idNumber"
              component="div"
              className="error-auth"
            />
          </div>

          <div>
            <Field type="text" name="firstname" placeholder="İsim" />
            <ErrorMessage
              name="firstname"
              component="div"
              className="error-auth"
            />
          </div>

          <div>
            <Field type="text" name="lastname" placeholder="Soyisim" />
            <ErrorMessage
              name="lastname"
              component="div"
              className="error-auth"
            />
          </div>

          <div>
            <Field type="text" name="address" placeholder="Adres" />
            <ErrorMessage
              name="address"
              component="div"
              className="error-auth"
            />
          </div>
          <div className="d-flex">
            <Field
              as="select"
              name="city"
              onChange={(e) => {
                const city = e.target.value;
                const filteredData = cityData.filter(
                  (item) => item.city === city
                );
                setFieldValue("city", filteredData[0].city || "");
                getDistricts(e.target.value);
              }}
            >
              <option style={{ display: "none" }} value="">
                İl Seçiniz
              </option>
              {cityData.map((item) => (
                <option key={item._id} value={item.city}>
                  {item.city}
                </option>
              ))}
            </Field>
            <Field
              style={{
                marginLeft: "10px",
              }}
              disabled={districtData.length === 0}
              as="select"
              onChange={(e) => {
                const district = e.target.value;
                const filteredData = districtData[0].district.filter(
                  (item) => item.name === district
                );

                setFieldValue("district", filteredData[0]?.name || "");
              }}
              name="district"
            >
              <option style={{ display: "none" }} value="">
                İlçe Seçiniz
              </option>
              {districtData &&
                districtData[0]?.district.map((item) => (
                  <option key={item.id} value={item.district}>
                    {item.name}
                  </option>
                ))}
            </Field>
          </div>
          <ErrorMessage name="city" component="div" className="error-auth" />
          <ErrorMessage
            name="district"
            component="div"
            className="error-auth"
          />

          <button className="button button-primary" type="submit">
            Kaydet
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Bireysel;
