import axios from "axios";

export const dijizinService = async (token, url, data) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(
    `https://api.dijiizin.com/api/V3/${url}`,
    data,
    {
      headers,
    }
  );
  return response;
};
export const dijizinLoginService = async () => {
  const response = await axios.post(`https://api.dijiizin.com/api/V3/login`, {
    username: process.env.REACT_APP_DIJIIZIN_USERNAME,
    password: process.env.REACT_APP_DIJIIZIN_PASSWORD,
  });
  return response;
};
export const dijizinGetService = async (token, url) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(`https://api.dijiizin.com/api/V3/${url}`, {
    headers,
  });
  return response;
};
