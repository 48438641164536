import React from "react";
import arrow from "../../utils/img/icons/arrowicon.svg";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

function BlogGrids({ blogPosts, imageUrls }) {
  const navigate = useNavigate();

  const MAX_LENGTH = 150;

  const handleBlogClick = (blog) => {
    const urlTitle = convertToUrlFormat(blog.title);
    navigate(`/blog/${urlTitle}`);
  };

  const convertToUrlFormat = (title) => {
    return slugify(title, {
      lower: true,
      remove: /[*+~.()'"!:@?]/g,
    });
  };

  const truncatedText = (text) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(text, "text/html");
    const plainText = parsedDocument.body.textContent;
    if (plainText.length <= MAX_LENGTH) {
      return plainText;
    } else {
      return plainText.slice(0, MAX_LENGTH) + "...";
    }
  };

  return (
    <>
      <div className="container">
        <div className="row  blog-container">
          {blogPosts &&
            blogPosts.map((blog) => (
              <div
                key={blog._id}
                style={{ maxWidth: "450px" }}
                className="col-xl-4"
              >
                <div className="blog-card">
                  <div>
                    <div
                      style={{
                        maxWidth: "356px",
                        maxHeight: "256px",
                        position: "relative",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "26px",
                          objectFit: "cover",
                          width: "100%",
                          maxWidth: "356px",
                          height: "256px",
                        }}
                        alt="blog resmi"
                        src={imageUrls[blog.mainImages[0]]}
                      />
                      <div
                        onClick={() => handleBlogClick(blog)}
                        style={{
                          display: "flex",
                          borderRadius: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#ffa900",
                          position: "absolute",
                          right: "35px",
                          bottom: "20px",
                          width: "32px",
                          height: "32px",
                          cursor: "pointer",
                        }}
                      >
                        <img src={arrow} alt="arrow icon" />
                      </div>
                    </div>
                  </div>
                  <section
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBlogClick(blog)}
                  >
                    <h5 style={{ margin: "20px 0" }}>{blog?.title}</h5>
                    <p>{truncatedText(blog?.firstContent)}</p>
                  </section>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default BlogGrids;
