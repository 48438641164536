import React, { useEffect, useState } from "react";
import baseService from "../../../library/network/services/BaseService";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import searchIcon from "../../../utils/img/icons/searchicon.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SideMenu from "./SideMenu";
import ProductCards from "./ProductCards";
import FilterIcon from "../../../utils/img/icons/filtering.svg";
import SortingIcon from "../../../utils/img/icons/sorting-icon.svg";
import ReactPaginate from "react-paginate";
import next from "../../../utils/img/icons/pagi-next.svg";
import prev from "../../../utils/img/icons/pagi-prev.svg";
function EcommerceList() {
  const productsService = async () => baseService.get("ecommerceproducts");
  const categoryService = async () => baseService.get("ecommercecategories");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageUrls, setImageUrls] = useState({});
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;
  const [favorites, setFavorites] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await categoryService();
      setCategories(response.data);

      const productResponse = await productsService();
      setProducts(productResponse.data);
      setFilteredProducts(productResponse.data);

      productResponse.data.forEach((product) => {
        getImageUrl(product.mainImage);
        product?.images?.forEach((image) => {
          getImageUrl(image);
        });
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    filterProducts();
  }, [searchQuery, sortOption, selectedCategory, selectedTags]);

  useEffect(() => {
    setCurrentPage(0); // Reset to the first page on filters change
  }, [filteredProducts]);

  const filterProducts = () => {
    let filtered = [...products];

    if (searchQuery) {
      filtered = filtered.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      if (selectedCategory === "all") {
        setFilteredProducts(products);
        return;
      }
      filtered = filtered.filter(
        (product) => product.categoryId._id === selectedCategory
      );
    }

    if (selectedTags.length > 0) {
      filtered = filtered.filter((product) =>
        selectedTags.every((tag) => product.tags.includes(tag))
      );
    }

    if (sortOption) {
      filtered.sort((a, b) => {
        if (sortOption === "1") {
          return a.price - b.price; // Ascending
        } else if (sortOption === "2") {
          return b.price - a.price; // Descending
        }
        return 0;
      });
    }

    setFilteredProducts(filtered);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentProducts = filteredProducts.slice(offset, offset + itemsPerPage);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };

  const toggleFavorite = (event, productId) => {
    event.preventDefault();
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [productId]: !prevFavorites[productId],
    }));
  };

  const handleImageClick = () => {
    setShowModal(!showModal);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleTagChange = (tags) => {
    setSelectedTags(tags);
  };

  return (
    <section className="page-section">
      <div className="container">
        <div className="title">
          <h4>ALIŞVERİŞ</h4>
          <h1>ALIŞVERİŞ</h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SideMenu
            isMenuOpen={isMenuOpen}
            menuToggle={toggleMenu}
            setIsMenuOpen={setIsMenuOpen}
            categories={categories}
            onCategoryChange={handleCategoryChange}
            onTagChange={handleTagChange}
          />
          <div>
            <div
              className="my-4"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <div style={{ flex: 4 }} className="search-container">
                <input
                  className="search-product"
                  type="text"
                  placeholder="Ara"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <img className="search-icon" src={searchIcon} alt="Search" />
              </div>
              <div
                style={{
                  position: "relative",
                }}
                className="menu-toggle"
                htmlFor="sort-select"
              >
                <img onClick={handleImageClick} src={SortingIcon} alt="Sort" />
                {showModal && (
                  <div
                    onMouseLeave={() => setShowModal(false)}
                    className="sort-modal"
                  >
                    <p onClick={() => setSortOption("1")}>Artan Fiyat</p>
                    <p onClick={() => setSortOption("2")}>Azalan Fiyat</p>
                  </div>
                )}
              </div>
              <div onClick={toggleMenu} className="menu-toggle">
                <img src={FilterIcon} alt="Filter" />
              </div>
              <div
                style={{ flex: 0.5, maxWidth: "267px", minWidth: "200px" }}
                className="sort-products-container"
              >
                <label htmlFor="sort-select" className="sort-label">
                  Sıralama:
                </label>

                <select
                  id="sort-select"
                  defaultValue={sortOption}
                  className="sort-select"
                  value={sortOption}
                  onChange={handleSortChange}
                >
                  <option value="1">Artan Fiyat</option>
                  <option value="2">Azalan Fiyat</option>
                </select>
              </div>
            </div>
            {filteredProducts && filteredProducts.length > 0 ? (
              <>
                {" "}
                <div className="ecommerce-products">
                  <ProductCards
                    products={currentProducts}
                    imageUrls={imageUrls}
                  />
                </div>
                <ReactPaginate
                  previousLabel={
                    <img src={prev} alt="Previous" style={{ width: "20px" }} />
                  }
                  nextLabel={
                    <img src={next} alt="Next" style={{ width: "20px" }} />
                  }
                  pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </>
            ) : (
              <h6
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "2rem",
                }}
              >
                Bu kategoride ürün bulunamadı
              </h6>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default EcommerceList;
