const handleButtonClicks = (buttonUrl, buttonName, buttonType) => {
  const url = "https://onlinelostra.com/";
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "button_click",
    click_url: url + buttonUrl,
    click_text: buttonName,
    page_type: buttonType,
  });
};

export default handleButtonClicks;
