import baseService from "../services/BaseService";

export const getAllServiceCategories = async () =>
  baseService.get("servicecategories");
export const getAllServiceCurrentCategories = async () =>
  baseService.get("servicecategories/current");

export const getByIdServiceCategories = async (id) =>
  baseService.get(`servicecategories/${id}`);

export const getServiceCategoriesData = async (adminId, headers = null) =>
  baseService.get(`servicecategories/${adminId}`, headers);
