import React from "react";
import Privileges from "../../app-layouts/Privileges";
import SssPanel from "../../app-layouts/SssPanel";
import { useState } from "react";
import baseService from "../../../library/network/services/BaseService";
import { useEffect } from "react";
import Loader from "../../app-layouts/Loader";
import { Link, useParams } from "react-router-dom";
import {
  getAllServiceCurrentCategories,
  getByIdServiceCategories,
  getServiceCategoriesData,
} from "../../../library/network/requests/serviceCategory";
import { getAllService } from "../../../library/network/requests/service";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import ServicesMainPanel from "../../app-layouts/ServicesMainPanel";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";
import slugify from "slugify";

function AllLocations() {
  const { slug } = useParams();
  // const characterConvert = (text) => {
  //   return text
  //     .toLowerCase()
  //     .replace(/Ğ/g, "g")
  //     .replace(/Ü/g, "u")
  //     .replace(/Ş/g, "s")
  //     .replace(/ş/g, "s")

  //     .replace(/I/g, "i")
  //     .replace(/İ/g, "i")
  //     .replace(/Ö/g, "o")
  //     .replace(/Ç/g, "c")
  //     .replace(/ğ/g, "g")
  //     .replace(/ü/g, "u")
  //     .replace(/i/g, "i")
  //     .replace(/ı/g, "i")

  //     .replace(/ş/g, "s")
  //     .replace(/'/g, "")
  //     .replace(/[^a-z0-9]+$/g, "")
  //     .replace(/ı/g, "i")
  //     .replace(/ö/g, "o")
  //     .replace(/ç/g, "c")
  //     .replace(/--+/g, "-") // Remove multiple consecutive hyphens
  //     .replace(/^-+|-+$/g, "") // Remove leading or trailing hyphens
  //     .trim();
  // };
  const translateService = () => {
    switch (slug) {
      case "kuru-temizleme":
        return "Kuru Temizleme";
      case "utuleme":
        return "Ütüleme Hizmeti";
      case "ayakkabi-temizleme":
        return "Ayakkabı Temizleme";
      case "canta-temizleme":
        return "Çanta Temizleme";
      case "camasir-yikama":
        return "Çamaşır Yıkama";
      default:
        return " Temizleme";
    }
  };
  const istanbulService = async () =>
    baseService.get("cities/64672a1631d68191785adcde");
  const [cityData, setCityData] = useState([]);
  const [istanbulData, setIstanbulData] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [services, setServices] = useState([]);
  const cityService = async () => baseService.get("cities");
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [service, setService] = useState("");

  const getCityData = async () => {
    const res = await cityService();
    setCityData(res.data);
  };
  const getIstanbulData = async () => {
    const res = await istanbulService();
    setIstanbulData(res.data);
  };
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getServiceCategory = async () => {
    setLoading(true);
    const res = await getAllServiceCurrentCategories();
    const data = res.data.filter(
      (item) => item.name === translateService(slug)
    );

    if (data[0].mainImage) {
      getImageUrl(data[0].mainImage[0]);
    }
    setServiceData(data[0]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getCityData(), getIstanbulData(), getServiceCategory()]).then(
      () => {
        setLoading(false);
      }
    );
  }, [slug]);
  if (loading) return <Loader />;
  return (
    <>
      {" "}
      <Helmet>
        <title> {translateService(slug)?.replace("-", " ")} </title>
        <meta
          name="description"
          content={`${translateService(slug)?.replace(
            "-",
            " "
          )} Hizmet Verdiğimiz İller ve İlçeler`}
        />
      </Helmet>
      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: `${translateService(slug)}`, url: "/" },
            ]}
          />
        </div>
        <div className="container">
          {" "}
          {serviceData.name && (
            <ServicesMainPanel
              service={serviceData}
              title={translateService(slug)}
              index={2}
              imageUrls={imageUrls}
            />
          )}
          <div className="title">
            <h4>{translateService(slug)}</h4>
            <h1>Hizmeti Verdiğimiz İller</h1>
          </div>
          <div className="d-flex justify-content-center flex-wrap">
            {cityData &&
              cityData?.map((item, index) => (
                <div
                  key={item._id}
                  className=" location-chips d-inlineel-flex align-items-center   rounded-pill px-4 py-1 m-2"
                >
                  <Link
                    style={{
                      color: "#676767",
                    }}
                    onClick={() => {
                      handleButtonClicks(
                        `/${slugify(item?.city, {
                          lower: true,
                          remove: /[*+~.()'"!:@?]/g,
                        })}-${slugify(slug, {
                          lower: true,
                          remove: /[*+~.()'"!:@?]/g,
                        })}-hizmeti`,
                        item.city,
                        "Lokasyonlar"
                      );
                    }}
                    to={`/${slugify(item?.city, {
                      lower: true,
                      remove: /[*+~.()'"!:@?]/g,
                    })}-${slugify(slug, {
                      lower: true,
                      remove: /[*+~.()'"!:@?]/g,
                    })}-hizmeti`}
                  >
                    <h2
                      style={{
                        margin: "0",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      {item.city}
                    </h2>
                  </Link>
                  {/* <p className="m-0  ">{item.city}</p> */}
                </div>
              ))}
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundColor: " #f9f9f9",
        }}
        className="page-section"
      >
        <div className="container">
          {" "}
          <div className="title">
            <h4>{services?.name?.toLocaleLowerCase("tr-TR")}</h4>
            <h2>
              İstanbul İçi <br /> Jet Hizmet Verdiğimiz İlçeler
            </h2>
          </div>
          <div className="d-flex justify-content-center flex-wrap">
            {istanbulData.district &&
              istanbulData?.district.map((item, index) => (
                <div
                  key={item._id}
                  className=" location-chips d-inline-flex align-items-center   rounded-pill px-4 py-1 m-2"
                >
                  {/* <p className="m-0  ">{item.name}</p> */}
                  <Link
                    style={{
                      color: "#676767",
                    }}
                    onClick={() => {
                      handleButtonClicks(
                        `/${slugify(item?.name, {
                          lower: true,
                          remove: /[*+~.()'"!:@?]/g,
                        })}-${slug}-hizmeti`,
                        item.city,
                        "Lokasyonlar"
                      );
                    }}
                    to={`/${slugify(item?.name, {
                      lower: true,
                      remove: /[*+~.()'"!:@?]/g,
                    })}-${slug}-hizmeti`}
                  >
                    {" "}
                    <h2
                      style={{
                        margin: "0",
                        fontSize: "1.1rem",
                        fontWeight: "400",
                      }}
                    >
                      {item.name}{" "}
                      {translateService(slug)
                        ?.toLocaleUpperCase("tr-TR")
                        ?.replace("-", " ")}
                    </h2>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Privileges />
      <SssPanel pageType="location" category={slug.split("-")[0]} />
    </>
  );
}

export default AllLocations;
