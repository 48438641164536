import React from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function Satis() {
  return (
    <>
      <Helmet>
        <title>{`Satış Sözleşmesi - Online Lostra`}</title>
        <meta name="description" content="noindex" />
      </Helmet>

      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Satış Sözleşmesi", url: "/satis-sozlesmesi" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h1> MESAFELİ SATIŞ SÖZLEŞMESİ ÖN BİLGİ FORMU</h1>
          </div>
          <div className="policy" style={{ textAlign: "left", color: "black" }}>
            {" "}
            <p>
              <strong> MADDE 1 – KONU</strong>
            </p>
            <p>
              İşbu sözleşmenin konusu, MÜŞTERİ’nin{" "}
              <strong>DU TECH İNTERNET HİZMETLERİ A.Ş.</strong>’ye{" "}
              <strong>‘ONLİNE LOSTRA</strong>’ isimli uygulamadan elektronik
              ortamda siparişini verdiği ürün ve hizmetlerin,{" "}
              <strong>DU TECH İNTERNET HİZMETLERİ A.Ş.</strong> tarafından
              MÜŞTERİ’ye hizmet satışı ve teslimi hakkında 6502 sayılı
              Tüketicilerin Korunması Hakkında Kanun, Mesafeli Sözleşmelere Dair
              Yönetmelik ve ilgili diğer yasal hükümler uyarınca tarafların hak
              ve yükümlülüklerinin belirlenmesine ilişkindir. İşbu Ön
              bilgilendirme formunu kabul etmekle Müşteri, sözleşme konusu
              siparişi onaylamasına bağlı olarak, sipariş konusu, bedeli, hizmet
              konusu ürünlerin ulaştırılmasına yönelik kargo ücretini ve
              belirtilmesi halinde doğabilecek ek ücretleri ödeme yükümlülüğü
              altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul
              eder.
              <p>
                <strong> MADDE 2 – TARAFLAR </strong>{" "}
              </p>
              İşbu Mesafeli Satış Sözleşmesi bundan sonra{" "}
              <strong>“SÖZLEŞME”</strong> olarak anılacaktır. Tüketicinin
              Korunması Hakkında Kanun ve Mesafeli Sözleşmelere Dair
              Yönetmelik’e uygun olarak düzenlenmiştir. Sözleşme’nin tarafları
              işbu Sözleşme ile Tüketicinin Korunması Hakkında Kanun ve Mesafeli
              Sözleşmelere Dair Yönetmelik’ten kaynaklanan tüm yükümlülük ve
              sorumluluklarını bildiklerini, anladıklarını ve kabul ettiklerini
              beyan ederler. (Bundan sonra ayrı ayrı olmak üzere;{" "}
              <strong>DU TECH İNTERNET HİZMETLERİ A.Ş. “ONLİNE LOSTRA”</strong>{" "}
              ve müşteri<strong> “MÜŞTERİ” </strong> olarak, her ikisi birlikte
              ise
              <strong> “TARAFLAR” </strong> olarak anılacaktır.)
            </p>
            <p>
              İşbu Sözleşme ile <strong>MÜŞTERİ</strong>, Çırçır Mahallesi Dönen
              Sokak No:8-10B Eyüpsultan, İstanbul, Türkiye adresinde mukim{" "}
              <strong>ONLİNE LOSTRA </strong> aşağıdaki hususları beyan ve
              taahhüt eder. Aynı şekilde <strong>ONLİNE LOSTRA</strong>{" "}
              aşağıdaki hususları MÜŞTERİ’ye beyan ve taahhüt etmektedir. Bu
              nedenle <strong>ONLİNE LOSTRA </strong> adı ile iOS ve Android
              cihazlara indirilerek kullanılabilecek olan uygulama dâhilinde
              doldurulan ad soyad / unvan, e-posta adresi, ve telefon
              bilgilerinin gerçek ve doğru olduğu tekrar kontrol edilmelidir.
            </p>
            <p>
              <strong>MADDE 3 - FİRMA BİLGİLERİ</strong>{" "}
            </p>
            <p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong> Unvanı:</strong>{" "}
                    </td>
                    <td>
                      {" "}
                      <strong>DU TECH İNTERNET HİZMETLERİ A.Ş.</strong> (Bundan
                      sonra <strong>ONLİNE LOSTRA </strong> olarak anılacaktır.){" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <strong>Adresi: </strong>
                    </td>
                    <td>
                      {" "}
                      Çırçır Mahallesi Dönen Sokak No:8-10B Eyüpsultan,
                      İstanbul,
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <strong>Telefon:</strong>
                    </td>
                    <td>0 (850) 533 10 70 </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email:</strong>{" "}
                    </td>
                    <td>info@onlinelostra.com</td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Mersis No:</strong>{" "}
                    </td>
                    <td> 0313-1289-0720-0001</td>
                  </tr>
                </tbody>
              </table>
            </p>
            <p>
              <strong>MADDE 4 – TANIMLAR</strong>
            </p>
            <p>
              <strong>4.1.</strong> Uygulama: Her türlü hakkı ve mülkiyeti DU
              TECH İNTERNET HİZMETLERİ A.Ş ’ye ait{" "}
              <strong>ONLİNE LOSTRA</strong> isimli uygulamayı ifade eder.
            </p>
            <p>
              <strong>4.2.</strong> Kullanıcı: <strong>ONLİNE LOSTRA</strong>{" "}
              ‘yı kullanan kişiyi ifade eder.
            </p>
            <p>
              <strong>4.3.</strong> Hizmet/Ürün: Katalog’da yer alan seçenekler
              arasından Kullanıcı tarafından seçilen ve tarafından,
              Kullanıcı’nın tayin ettiği tarihlerde uygunluk durumuna bağlı
              olarak Kullanıcı’ya sunulan hizmeti/ürünü ifade eder. İşbu
              Sözleşme konusu hizmet; müşterilerin{" "}
              <strong>ONLİNE LOSTRA</strong> mobil uygulaması ve web sitesi
              aracılığıyla kuru temizleme, ütüleme ve lostra hizmeti
              alabilecekleri, bu hizmetlerin zamanlarını seçebilecekleri ve
              hizmete konu ürünlerin Müşterinin adresinden alınıp işlem sonrası
              tekrar Müşteri tarafından belirlenen adrese{" "}
              <strong>ONLİNE LOSTRA</strong> ve/veya anlaşmalı olduğu üçüncü
              kişi kargo şirketleri aracılığı ile teslim edildiği, talep üzerine
              çalışan bir hizmet uygulamasıdır.
            </p>
            <p>
              <strong>4.4.</strong> Müşteri(ler): <strong>ONLİNE LOSTRA</strong>{" "}
              satın alma işlemini gerçekleştiren kişiyi/kişileri ifade eder,
              bundan hareketle <strong>ONLİNE LOSTRA</strong>’yı bizzat
              kullanması veya başkasına hediye etmesi durumuna göre, MÜŞTERİ’nin
              aynı zamanda Hizmet’ten/Ürün’den Kullanıcı kişi olabileceği veya
              MÜŞTERİ’nin Kullanabileceği kişi olmayabileceği anlaşılır.
            </p>
            <p>
              <strong>4.5.</strong> Taraf(lar): MÜŞTERİ’yi ve DU TECH İNTERNET
              HİZMETLERİ A.Ş’yi ifade eder.
            </p>
            <p>
              <strong>4.6.</strong> Hediye Çeki: Uygulamada yer alan çeşitli
              seçenekler arasından seçilecek olan bir veya birden fazla
              Hizmet’in/Ürün’ün maktu fiyatını ödemeye ya da indirmeye yarayan,
              ancak nakdi ve itibari değeri olmayan bir hediye çeki/hediye kodu.
            </p>
            <p>
              <p>
                <strong>
                  {" "}
                  MADDE 5- SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ{" "}
                </strong>
              </p>{" "}
              <p>
                <strong> 5.1.</strong> Hizmetin temel özellikleri (hizmet
                kalemleri, alım, teslim tarihleri, hizmet bedeli ve ek bedeller){" "}
                <strong>ONLİNE LOSTRA</strong> uygulaması ve web sitesinde
                ‘’www.onlinelostra.com’’ yer almaktadır.
              </p>
              <p>
                <strong>5.2. </strong> Listelenen ve sitede ilan edilen fiyatlar
                satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme
                yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan
                edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir.
              </p>
              <p>
                <strong>5.3. </strong> Sözleşme konusu mal ya da hizmetin, kargo
                ücreti ve tüm vergiler dâhil satış fiyatlarına
                https://onlinelostra.com/ linkinden ulaşabilirsiniz.
              </p>
            </p>
            <p>
              <p>
                <strong>
                  {" "}
                  MADDE 6 - <strong>ONLİNE LOSTRA</strong> UYGULAMASI{" "}
                </strong>
              </p>
              <p>
                <strong>6.1.</strong> İşbu sözleşme, MÜŞTERİ’nin Uygulama
                üzerinden sipariş verdiği, yukarıda türünü/adını, adedini, satış
                bedelini, kargo bedelini, ödeme şeklini ve varsa taksit adedini
                belirttiği ürün veya hizmetler içindir.
              </p>
              <p>
                <strong> 6.2.</strong> Ödeme, kredi kartı ile aşağıdaki şartlar
                dâhilinde gerçekleştirilebilir: • Kredi kartı ile (Visa,
                Eurocard/Mastercard, Amex): MÜŞTERİ’nin ödemeyi gerçekleştirmek
                için, ayrılmış alana kart numarasını ve son geçerlilik tarihi
                belirtmesi gerekmektedir. Varsa teslimat masrafı ve bahşiş
                bedeli dâhil olmak üzere toplam bedel MÜŞTERİ’nin bilgilerini
                bildirdiği karttan sipariş verildiğinde tahsil edilecektir.{" "}
                <strong>ONLİNE LOSTRA</strong> bankanın ödemeye izin vermemesi
                halinde, MÜŞTERİ’nin siparişini askıya alma ve iptal etme
                hakkına hakkına sahiptir. Bununla beraber müşteri tarafından
                verilen siparişin karşılığı olan hizmetin ifası{" "}
                <strong>ONLİNE LOSTRA</strong> tarafından tamamlanmış ancak ifa
                edilen hizmetin ücreti alınamamış ise, müşteri durum hakkında
                bilgilendirilecek olup hizmet konusu ürünler ödeme yapılana
                kadar <strong>ONLİNE LOSTRA</strong> tarafından muhafaza
                edilecektir. Ödemenin yapılmasının takiben söz konusu ürünler iş
                bu sözleşme şartları uyarınca müşteriye teslim edilecektir.
                Uygulama, sipariş esnasında MÜŞTERİ’nin kart bilgilerini gizli
                ve güvenli bir biçimde girmesini sağlayacak şekilde
                düzenlenmiştir. MÜŞTERİ, kredi kartı ile ödeme yaptığı takdirde,
                ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri
                bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat
                hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin
                Banka ve alıcı arasındaki kredi kartı sözleşmesi kapsamında
                uygulanacağını kabul, beyan ve taahhüt eder. Kredi kartı ile
                alınmış ürünün iadesi durumunda <strong>ONLİNE LOSTRA</strong>{" "}
                MÜŞTERİ’ye nakit para ile ödeme yapamamaktadır. Kredi kartına
                iade, bankanın prosedürlerine göre yapılmaktadır.
                <p> </p>
                <p>
                  {" "}
                  Bunun dışında hizmete dair ödemeler QNB Finansbank
                  TR………………………… no'lu banka hesabına Havale/EFT yolu ile de
                  yapılabilecektir.
                </p>
                <p>
                  MÜŞTERİ’nin Ürün/Hizmet değişiminden doğan bir alacağı var
                  ise, kredisi ile: MÜŞTERİ’nin ödemeyi gerçekleştirebilmesi
                  için verilen kodu, Uygulama’da işlem için ayrılan bölümden
                  çevrimiçi veya haftanın her günü tüm saatlerde
                  info@onlinelostra.com adresine mail atmalıdırlar. MÜŞTERİ,
                  sahip olduğu Ürün’den/hediye çekinden daha pahalı bir
                  Ürün/Hizmet alma istediği durumda iki Ürün/Hizmet arasındaki
                  fark için yapılması gereken ödemeyi kredi kartı ile
                  gerçekleştirebilir. Buna karşılık, MÜŞTERİ’nin satın aldığı
                  mevcut Ürünü/Hizmeti işbu sözleşmeye uygun olarak daha ucuz
                  bir Ürün/Hizmet ile değiştirmek istediğinde aradaki fark
                  MÜŞTERİ’nin uygulamadaki hesabında başka bir siparişte
                  kullanabileceği bir şekilde saklanır.
                </p>
              </p>
            </p>
            <p>
              <p>
                <strong> MADDE 7 –KULLANIM ŞARTLARI VE GENEL HÜKÜMLER </strong>
              </p>
              <p>
                <strong> 7.1. </strong>
                Ürün’ün Yararlanıcısı olmadığınız durumlarda, aşağıdaki şartlar
                dâhilinde Ürün Yararlanıcısı’nı bilgilendirmelisiniz. Bu
                bilgilendirmeyi yapmamış olmanız halinde,{" "}
                <strong>ONLİNE LOSTRA</strong> Yararlanıcı’ya karşı herhangi bir
                sorumluluk kabul etmez.
              </p>
              <p>
                <strong>7.2. </strong> Hediye Çeki’nin geçerli olması için,
                doğrulanması (aktifleştirilmesi) gerekmektedir. Bu işlemi;
                Uygulama’dan gerçekleştirebilirsiniz.
              </p>
              <p>
                <strong>7.3.</strong> Uygulama’da belirtildiği gibi, Hediye Çeki
                Kullanıcı’lara farklı aktivitelerden/ürünlerden/Hizmetlerden
                faydalanma hakkı verir. Hediye Çeki, belirli süre içinde sunulan
                aktivitelerden/ürünlerden/hizmetlerden (Kullanıcı tarafından
                tercih edilmek üzere) yalnızca biri için geçerlidir.
              </p>
              <p>
                <strong>7.4. </strong>
                <strong>ONLİNE LOSTRA</strong> ’nın kullandığı tüm görsel öğeler
                temsili olabilir. Ayrıca tüm içerik ve görsel öğelerin tüm telif
                hakları saklı olup, kısmen veya tamamen kullanılması,
                çoğaltılması, yayınlanması veya yeniden işlenmesi yasaktır.
              </p>
              <p>
                <strong> 7.5.</strong> Satın alınmış ürünler 7 gün süre
                içerisinde evsafı bozulmamış, ve kullanılmamış olması kaydıyla
                değiştirilebilir.
              </p>
              <p>
                <strong> 7.6. </strong>Hediye Çeki’nin süresi içinde
                kullanılmaması, kayıp/çalıntı veya kullanıcı tarafından
                kullanıcı sözleşmesine aykırı olacak şekilde kullanılması
                durumunda Kullanıcı hiçbir geri ödeme veya telafi talep edemez.
              </p>
              <p>
                <strong> 7.7.</strong>
                MÜŞTERİ, Uygulama’da sözleşme konusu ürünün/hizmetin temel
                özellik ve nitelikleri, satış fiyatı, ödeme şekli, teslimata
                ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik
                ortamda gerekli teyidi verdiğini beyan eder.
              </p>
            </p>
            <p>
              <p>
                <strong> MADDE 8 - MALIN TESLİMİ VE TESLİM ŞEKLİ </strong>
              </p>
              MÜŞTERİ tarafından onaylanmakla yürürlüğe girmiş olup MÜŞTERİ’nin{" "}
              <strong>ONLİNE LOSTRA</strong> ’ dan satın almış olduğu
              Mallar/Hizmetler , MÜŞTERİ’nin sipariş formunda ve işbu belirtmiş
              olduğu adreste bulunan kişi/kişilere teslim edilecektir.
            </p>
            <p>
              MADDE 8 - TESLİMAT MASRAFLARI VE İFASI Mal/Hizmet’in teslimat
              masrafları <strong>ONLİNE LOSTRA</strong> ’ya aittir.{" "}
              <strong>ONLİNE LOSTRA</strong> uygulamasında teslimat ücretinin
              kendisince karşılanacağını beyan etmişse, teslimat masrafları{" "}
              <strong>ONLİNE LOSTRA</strong> ’ ye ait olacaktır. Malın
              teslimatı; <strong>ONLİNE LOSTRA</strong> ’nin stokunun müsait
              olması ve ödemenin gerçekleşmesinden sonra zaman taahhüttü
              olmaksızın yapılır. ONLİNE LOSTRA’nin Mal/Hizmet’i, MÜŞTERİ
              tarafından Mal/Hizmet’in sipariş edilmesinden itibaren{" "}
              <strong>ONLİNE LOSTRA</strong> ’nin teslimatı gerçekleştirebildiği
              en kısa süre içinde teslim eder ve bu süre içinde yazılı
              bildirimle iki günlük süre uzatım hakkını saklı tutar. Herhangi
              bir nedenle MÜŞTERİ tarafından Mal/Hizmet bedeli ödenmez veya
              yapılan ödeme banka kayıtlarında iptal edilirse, ONLİNE LOSTRA’nin
              Mal/Hizmet’in teslimi yükümlülüğünden kurtulmuş kabul edileceği
              gibi yukarıda<strong> Madde 6.2'de </strong>belirtildiği üzere
              satın alınan hizmetin ifası tamamlanmamışsa müşteriye ait ürünler
              ödeme alınana kadar <strong>ONLİNE LOSTRA</strong> tarafından
              muhafaza edilecektir.
            </p>
            <p>
              <p>
                <strong> MADDE 9 - MÜŞTERİ’NİN BEYAN VE TAAHHÜTLERİ </strong>
              </p>
              MÜŞTERİ, <strong>ONLİNE LOSTRA</strong>
              uygulamasında yer alan sözleşme konusu ürünün temel nitelikleri,
              satış fiyatı ve ödeme şekli ile teslimata ilişkin olarak ONLİNE
              LOSTRA tarafından yüklenen ön bilgileri okuyup bilgi sahibi
              olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan
              eder. MÜŞTERİLER Tüketici sıfatıyla talep ve şikâyetlerini
              yukarıda yer alan Satıcı iletişim bilgilerinin sağladığı
              kanallarla ulaştırabilirler. <br /> MÜŞTERİ, işbu Sözleşme’yi ve
              Ön Bilgilendirme Formunu elektronik ortamda teyit etmekle,
              mesafeli sözleşmelerin akdinden önce{" "}
              <strong>ONLİNE LOSTRA</strong> tarafından MÜŞTERİ’ye verilmesi
              gereken adres, siparişi verilen ürünlere ait temel özellikler,
              ürünlerin vergiler dahil fiyatı, ödeme ve teslimat bilgilerini de
              doğru ve eksiksiz olarak edindiğini teyit etmiş olur.
            </p>
            <p>
              MÜŞTERİ’nin, Sözleşme konusu Malları/Hizmetleri teslim almadan
              önce kontrol etmelidir. Tahrip olmuş, kırık, ambalajı yırtılmış
              vb. hasarlı ve ayıplı Mal/Hizmeti kargo şirketinden teslim alması
              halinde sorumluluk tamamen kendisine aittir.
            </p>
            <p>
              Teslimden sonra Mal/Hizmet’in sorumluluğu ve hasarlar MÜŞTERİ’ye
              aittir. Mal/Hizmet’in tesliminden sonra MÜŞTERİ’ye ait kredi
              kartının MÜŞTERİ’nin kusurundan kaynaklanmayan bir şekilde
              yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması
              nedeni ile ilgili banka veya finans kuruluşunun mal bedelini{" "}
              <strong>ONLİNE LOSTRA</strong> ’ya ödememesi halinde, MÜŞTERİ
              kendisine teslim edilmiş olması kaydıyla ürünleri 3 (üç) gün
              içinde <strong>ONLİNE LOSTRA</strong> ’ya iade etmekle yükümlüdür.
              Bu halde teslimat giderleri MÜŞTERİ’ye aittir. Eğer MÜŞTERİ bir
              hizmet aldıysa 3 (üç) iş günü içerisinde MÜŞTERİ farklı bir kredi
              kartı ve / veya nakdi ödeme yapmalı, aksi halde alacakların yasal
              olarak temin edilmesine yönelik hukuki işlemleri başlatma hakkı
              <strong> ONLİNE LOSTRA</strong> ’da saklıdır.
            </p>
            <p></p>
            <p>
              {" "}
              MÜŞTERİ'nin uygulama ve/veya websitesi üzerinden siparişini
              oluşturmuş, siparişe konu ürün/ürünlerin ise
              <strong>ONLİNE LOSTRA</strong> tarafından teslim alınmış ancak
              inceleme safhasında MÜŞTERİ'nin herhangi bir nedenle hizmeti
              almaktan vazgeçmiş olduğu ve/veya ilgili ürünün işlem göremez
              halde olduğunun <strong>ONLİNE LOSTRA</strong>
              tarafından tespit edilip MÜŞTERİ'ye bildirildiği hallerde MÜŞTERİ,
              <span className="bedelli-tutar"> 224.90 TL</span> bedelli "Minimum
              Sepet Tutarı’nı ödemekle yükümlü olacağını peşinen kabul beyan ve
              taahhüt eder. Bu bedel herhangi bir hizmet bedeli olmayıp ilgili
              ürün/ürünlerin <strong>ONLİNE LOSTRA</strong> tarafından
              Müşteri'nin adresinden alınıp geri bırakılması aşamalarında doğan
              masraflar dolayısı ile <strong>ONLİNE LOSTRA</strong> 'nın
              uğradığı/uğrayacağı zararlara karşılık MÜŞTERİ'den talep
              edilmektedir.
            </p>
            <p>
              <p>
                <strong>
                  {" "}
                  MADDE 10 – <strong>ONLİNE LOSTRA</strong> ’NIN BEYAN VE
                  TAAHHÜTLERİ
                </strong>
              </p>
              Sözleşme konusu Mal/Hizmet’in Tüketici Mevzuatına uygun olarak,
              sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa
              garanti belgeleri ve kullanım kılavuzları ile MÜŞTERİ’ye teslim
              edilmesinden sorumludur.
              <strong>ONLİNE LOSTRA</strong> mücbir sebepler veya nakliyeyi
              engelleyen olağanüstü durumlar nedeni ile sözleşme konusu
              ürünü/hizmeti süresi içinde teslim edemez ise, durumu MÜŞTERİ’ye
              mümkün olan en kısa sürede bildirmekle yükümlüdür Sözleşme konusu
              Mal/Hizmet, MÜŞTERİ’den başka bir kişiye teslim edilecek ise,
              teslim edilecek kişinin teslimatı kabul etmemesinden{" "}
              <strong>ONLİNE LOSTRA</strong> sorumlu tutulamaz.
            </p>
            <p>
              <p>
                <strong> MADDE 11 – CAYMA HAKKI </strong>
              </p>
              <p>
                <strong>11.1. </strong> 6502 sayılı Tüketicinin Korunması
                Hakkındaki Kanun gereğince, MÜŞTERİ sözleşme konusu Ürün’ün
                kendisine veya gösterdiği adresteki kişi/kuruluşa teslimat
                tarihinden itibaren 14 (on dört) gün içerisinde cayma haklarını
                kullanarak herhangi bir sebep göstermeden ve cezai şart ödemeden
                Ürün’ü iade etme hakkı haizdir.
              </p>
              <p>
                <strong>11.2 </strong> MÜŞTERİ’nin cayma hakkını kullanabilmesi
                için teslimat tarihinden itibaren 14 (on dört) gün içerisinde
                Ürün’ü iade etmek istediğini <strong>ONLİNE LOSTRA</strong> ’ya
                bildirmesi gerekmektedir. <strong>ONLİNE LOSTRA</strong>{" "}
                MÜŞTERİ’nin talebini aldıktan sonra en geç 10 (on) gün
                içerisinde, ürünün iadesini kabul edip ürün bedelini alıcıya
                ödemekle yükümlüdür. Gümrük ve Ticaret Bakanlığını’nın 29188
                sayılı Mesafeli Sözleşmeler Yönetmeliği md.15/h bendi uyarınca;
                ‘ Cayma Hakkı süresi sona ermeden önce, tüketicinin onayı ile
                ifasına başlanan hizmetlere ilişkin sözleşmeler’ yukarıda
                belirtilen Cayma Hakkı’nın istisnasını oluşturmaktadır. Buna
                göre Müşteri, ONLİNE LOSTRA tarafından verilen; kuru temizleme,
                yıkama, ütü, ayakkabı bakımı, tamiratı ve her türlü lostra
                hizmetlerinin ‘Müşteri’nin kendi onayı ile ifasına başlanan
                hizmetlerden’ olması sebebi ile cayma hakkı olmadığını kabul
                beyan ve taahhüt eder.
              </p>
              <p>
                <strong>11.3. </strong> MÜŞTERİ, cayma hakkı konusunda gerektiği
                şekilde bilgilendirilmezse, cayma hakkını kullanmak için 14 (on
                dört) günlük süreyle bağlı değildir. Her hâlükârda bu süre cayma
                süresinin bittiği tarihten itibaren 1 (bir) yıl sonra sona erer.
                MÜŞTERİ, cayma hakkı süresi içinde malın mutat kullanımı
                sebebiyle meydana gelen değişiklik ve bozulmalardan sorumlu
                değildir
              </p>
              <p>
                <strong> 11.4.</strong> MÜŞTERİ’nin cayma hakkını kullanarak
                Ürün’ü iade etmek istemesi halinde, bu talebini yukarıda
                belirtilen 14 (on dört) günlük süre içinde{" "}
                <strong>ONLİNE LOSTRA</strong> ’ya bildirerek, Ürün’ü herhangi
                bir kargo şirketi ile masraflar müşteriye ait olmak üzere iade
                edebilecektir.
              </p>
              <p>
                <strong>11.5. </strong> 385 sayılı Vergi Usul Kanunu Genel
                Tebliği uyarınca iade işlemlerinin yapılabilmesi için MÜŞTERİ’ye
                Ürün ile birlikte gönderilmiş olan e-faturanın saklanması
                gerekir. Teslim alınan Ürün iade edilmek istenildiği takdirde;
                MÜŞTERİ’ ONLİNE LOSTRA’ya Ürün ile birlikte e-fatura kopyasını
                geri göndermesi gerekmektedir. Ürün’ün MÜŞTERİ tarafından imzalı
                iade faturası gönderilmeksizin <strong>ONLİNE LOSTRA</strong>{" "}
                ’ya iade edilmesi ve/veya faturanın kaybedilmiş olması halinde
                doğabilecek masraf ve cezalar MÜŞTERİ’ye ait olacaktır.
              </p>
              <p>
                <strong>11.6. </strong> Cayma hakkının kullanılması, Ürün’ün
                ambalajının açılmamış, bozulmamış ve kullanılmamış olması
                şartına bağlıdır.
              </p>
              <p>
                <strong>11.7. </strong>
                Cayma hakkının kullanılamayacağı ürünler ise; MÜŞTERİ’nin isteği
                ile kişiye özel olarak üretilen, üzerinde değişiklik veya ilave
                yapılarak kişiye özel hale getirilen ürünler, niteliği
                itibarıyla geri gönderilmeye elverişli olmayan ürünler (İç
                çamaşırı, mayo, kozmetik gibi hijyenik durumu hassas olan
                ürünleri iade edebilmenin koşulu ürünün açılmamış ve denenmemiş
                olmasıdır, MÜŞTERİ, tarafından açılmış olan ses veya görüntü
                kayıtları (DVD, CD ve Kaset vb.), basılı ürünler (Kitap, dergi
                vb.), yazılım programları ve bilgisayar sarf malzemeleri, çabuk
                bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali
                olan ürünler, fiyatı borsa veya teşkilatlanmış diğer piyasalarda
                belirlenen ürünler (Külçe, Ziynet Altın ve Gümüş kategorisindeki
                tüm ürünler vb.) tatil kategorisinden satın alınan otel, yurt
                içi/yurt dışı turlar, gezi ve uçak bileti gibi ürün ve
                hizmetler. Gıda maddeleri, içecek ve diğer günlük tüketim
                maddeleri için sağlanan ürünler, tek seferde kullanılan ürünler,
                hızla bozulma veya son kullanma tarihi geçme ihtimali olan
                ürünlerde, MÜŞTERİ cayma hakkını kullanamaz. Bu ürün ve
                hizmetlerin iptal/iadesi, MÜŞTERİ’nin doğrudan cayma hakkını
                kullanması ile değil; <strong>ONLİNE LOSTRA</strong> uygulaması
                doğrultusunda yapılır. Ayrıca, Gümrük ve Ticaret Bakanlığının,
                Mesafeli Sözleşmeler Yönetmeliğinin 15. Maddesinin g ve h
                bendlerinde de belirtildiği üzere,{" "}
                <strong>ONLİNE LOSTRA</strong> tarafından sağlanan müşterinin
                isteğine göre olan (kuru temizleme, yıkama, ütüleme, lostra
                v.b.) hizmetlerden cayma hakkı söz konusu değildir. Alınan
                lostra hizmetlerinde işlem garantisi 30 gündür.
              </p>
              <p>
                <strong> 11.8.</strong> MÜŞTERİ,işbu sözleşmeyi kabul etmekle,
                cayma hakkı konusunda bilgilendirildiğini peşinen kabul eder.
              </p>
            </p>
            <p>
              <p>
                <strong> MADDE 12 - AYIP VE EKSİK BİLDİRİMİ </strong>
              </p>
              <p>
                <strong> 12.1. </strong>Teslim edilen her Ürün ve Hizmet,
                MÜŞTERİ tarafından dikkatle kontrol edilmelidir. Ürün’ün ayıplı
                veya eksik olması durumunda Ürün’ün MÜŞTERİ’ye ulaşmasından
                itibaren 30 (otuz) gün içinde bu durumun{" "}
                <strong>ONLİNE LOSTRA</strong> ’ya bildirmesi gerekmektedir.
              </p>
              <p>
                <strong> 12.2. </strong> Bu durumun bu süre içinde ONLİNE LOSTRA
                ’ya bildirilmemesi ve/veya Ürün/Hizmet’deki ayıbın/eksikliğin
                <strong>ONLİNE LOSTRA</strong> ‘ dan kaynaklanmaması halinde{" "}
                <strong>ONLİNE LOSTRA</strong> tarafından herhangi bir
                sorumluluk kabul edilmez. MÜŞTERİ’nin, sözleşmenin kurulduğu
                tarihte ayıptan haberdar olduğu veya haberdar olmasının
                kendisinden beklendiği hâllerde, sözleşmeye aykırılık söz konusu
                olmaz. Bunların dışındaki ayıplara karşı tüketicinin seçimlik
                hakları saklıdır.
              </p>
            </p>
            <p>
              {" "}
              <p>
                <strong>
                  {" "}
                  MADDE 13- <strong>ONLİNE LOSTRA</strong> PLUS ÜYELİĞİ{" "}
                </strong>
              </p>
              <p>
                {" "}
                <strong>ONLİNE LOSTRA</strong> PLUS Müşterilere
                <strong>ONLİNE LOSTRA</strong> uygulaması ve içeriğindeki
                hizmetlerden yararlanırken çeşitli avantajlar sağlayan yıllık
                abonelik paketidir. Buna göre
                <strong>ONLİNE LOSTRA</strong> PLUS üyeliği olan müşteriler,
                yıllık bir defaya mahsus olmak üzere{" "}
                <span className="bedelli-tutar"> 319 TL </span> üyelik ücreti
                ödeyerek yıl boyunca her siparişlerinde sepet tutarı üzerinden
                <span className="bedelli-tutar">
                  % 10 indirim hakkı kazanacak, buna ek olarak 34,90 TL
                </span>{" "}
                tutarındaki kargo ücretinden muaf tutulacaklardır.{" "}
              </p>
              <p>
                <strong>ONLİNE LOSTRA</strong> PLUS üyeliği, satın alındığı
                tarih itibari ile bir sene geçerli olacak, üyelik süresinin
                hitamında Müşteri üyeliğini sonlandırmadığı takdirde otomatik
                olarak bir sene için yenilenecektir.{" "}
                <strong>ONLİNE LOSTRA</strong> yıllık üyelik ücreti de dahil
                olmak üzere üyelik şartlarının belirlenmesi konusunda tamamen
                serbest olup, üyelik koşullarında bir değişiklik olması
                durumunda, kayıtlı bütün kullanıcılara uygulama üzerinden
                bildirim yapmakla yükümlüdür.
              </p>
              <p>
                {" "}
                <strong>ONLİNE LOSTRA</strong> PLUS üyeliği satın alan
                Müşteri(ler) işbu sözleşmeyi okuyup onaylayarak{" "}
                <strong>ONLİNE LOSTRA</strong> ‘ nın, üyeliğin satın alınmasını
                takiben üyelik ücretini tahsil etmesine açık rıza göstermiş
                olup, üyelik ücretinin tahsil edilememesi durumunda ONLİNE
                LOSTRA PLUS üyeliğine hak kazanamayacaklarını kabul beyan ve
                taahhüt eder(ler). Müşteri(ler) diledikleri zaman{" "}
                <strong>ONLİNE LOSTRA</strong> PLUS üyeliklerini herhangi bir
                cayma bedeli ve/veya cezai şart olmaksızın iptal etme hakkına
                sahiptirler, Müşteri(ler) <strong>ONLİNE LOSTRA</strong> PLUS
                üyeliği iptal edildiği andan itibaren üyelik avantajlarından
                yararlanamayacaklarını kabul beyan ve taahhüt eder(ler).
              </p>{" "}
              <p>
                Müşteri(ler) diledikleri zaman{" "}
                <strong>ONLİNE LOSTRA PLUS</strong>
                üyeliklerini herhangi bir cayma bedeli ve/veya cezai şart
                olmaksızın iptal etme hakkına sahiptirler, Müşteri(ler){" "}
                <strong>ONLİNE LOSTRA PLUS üyeliği iptal</strong>
                edildiği andan itibaren üyelik avantajlarından
                yararlanamayacaklarını kabul beyan ve taahhüt eder(ler). Bununla
                beraber senelik üyelik bedelinin ödenmesini müteakiben fakat
                üyelik süresi dolmadan Müşteri tarafından iptal edilen
                üyeliklerde, herhangi bir bedel iadesi yapılmayacaktır.
              </p>
            </p>
            <p>
              {" "}
              <p>
                <strong>MADDE 14 – TESLİMAT </strong>
              </p>
              <p>
                <strong>14.1.</strong> Ürünler sınırlı sayıda üretilir.
                Stokların tükenmesi halinde, MÜŞTERİ bilgilendirilecek ve
                sipariş ulaştırılamayacaktır. Siparişiniz stokların tükenmesi
                yüzünden size ulaştırılamaz ise, MÜŞTERİ’ye aşağıdaki iki
                seçenekten birini tercih edebilir: • Cayma (iade) hakkınızı
                kullanabilirsiniz; • veya Ürün değişimi yapabilirsiniz.
              </p>
              <p>
                <strong>14.2. </strong> <strong>ONLİNE LOSTRA</strong> yalnızca
                İstanbul il sınırları içinde kendi belirlediği ve uygulamasında
                açıkça belirttiği bölgelerde teslimat yapmaktadır. Ürünler
                siparişinizi verirken bildirdiğiniz adrese teslim edilecektir.
                Teslimat adresinde bulunmamanız veya hatalı adres bildirmeniz
                durumunda <strong>ONLİNE LOSTRA</strong>
                hiçbir sorumluluk kabul etmez. Bu nedenle, MÜŞTERİ’nin
                Ürünü/Hizmeti geç teslim almasından kaynaklanan her türlü zarar
                ile Ürün’ün kargo firmasında beklemiş olması ve/veya kargonun
                teslim edilememesi nedeniyle <strong>ONLİNE LOSTRA</strong> ’ya
                iade edilmesinden dolayı oluşan giderler de MÜŞTERİ’ye aittir.
              </p>
              <p>
                <strong>14.3. </strong> Sözleşme konusu Ürün’ün/Hizmetin
                teslimatı için satış bedelinin ödenmiş olması şarttır. Herhangi
                bir nedenle satış bedeli ödenmez veya banka kayıtlarında iptal
                edilir ise, <strong>ONLİNE LOSTRA</strong> Ürün’ün/Hizmetin
                teslimi yükümlülüğünden kurtulmuş kabul edilir.
              </p>
              <p>
                <strong> 14.4.</strong> Sipariş edilen her bir Ürün/Hizmet,
                sipariş tarihinden itibaren yasal 30 (otuz) günlük süreyi
                aşmamak koşulu ile MÜŞTERİ’nin yerleşim yerinin uzaklığına bağlı
                olarak MÜŞTERİ veya gösterdiği adresteki kişi/kuruluşa en kısa
                zamanda teslim edilir.
              </p>
              <p>
                <strong> 14.5. </strong> Sipariş edilen Ürün, MÜŞTERİ’den başka
                bir kişi/kuruluşa teslim edilecek ise, teslim edilecek
                kişi/kuruluşun teslimatı kabul etmemesinden{" "}
                <strong>ONLİNE LOSTRA</strong> sorumlu tutulamaz.
              </p>
            </p>
            <p>
              <p>
                <strong>MADDE 15 - SORUMLULUK</strong>
              </p>{" "}
              <strong>ONLİNE LOSTRA</strong> sözleşmede yer alan hizmetlerin
              kendisinden kaynaklanmayan nedenlerle MÜŞTERİ'ye uygulanmaması
              veya eksik uygulanması veya mücbir sebep durumlarında hiçbir
              sorumluluk kabul etmez.
            </p>
            <p>
              <p>
                <strong>MADDE 16- TEMERRÜT VE FESİH HÜKÜMLERİ </strong>
              </p>
              Tarafların işbu sözleşmeden kaynaklarından edimlerini yerine
              getirmemesi durumunda 6098 Sayılı Türk Borçlar Kanunu hükümleri
              uygulanacaktır. Temerrüt durumlarında, herhangi bir tarafın
              edimlerini süresi içinde haklı bir sebebi olmaksızın yerine
              getirmemesi durumunda diğer taraf söz konusu edimin yerine
              getirilmesi için edimini yerine getirmeyen tarafa 7 (yedi) günlük
              süre verecektir. Bu süre zarfında da yerine getirilmesi durumunda
              edimini yerine getirmeyen taraf mütemerrit olarak addolunacak ve
              alacaklı edimin ifasını talep etmek suretiyle Ürünün/Hizmetin
              teslimini veya sözleşmenin feshini ve bedelin iadesini talep etme
              hakkına sahip olacaktır.
            </p>
            <p>
              <p>
                <strong> MADDE 17 - MÜCBİR SEBEP</strong>
              </p>
              <p>
                <strong>17.1.</strong> Taraflar’ın işbu sözleşmedeki
                yükümlülüklerini yerine getirmesini engelleyebilecek nitelikte
                bir mücbir sebebin veya nakliyeyi engelleyen hava
                muhalefetlerinin, ulaşım kesintisinin, yangın, deprem, sel
                baskını gibi olağanüstü olayların varlığı halinde sözleşme
                konusu Ürün süresi içerisinde teslim edilmez ise MÜŞTERİ işbu
                sözleşmenin 11. maddesinde belirtilen cayma hakkı veya teslimat
                süresinin engelleyici durumunun ortadan kalkmasına kadar
                ertelenmesi hakkından birini kullanabilir.
              </p>
              <p>
                <strong>17.2.</strong> Hukuken 'mücbir sebep' sayılan tüm
                hallerde <strong>ONLİNE LOSTRA</strong> işbu sözleşme ile
                belirlenen yükümlülüklerinden herhangi birini geç veya eksik ifa
                etme veya ifa etmeme nedeniyle sorumlu olmayacaktır. Bu ve bunun
                gibi durumlar, <strong>ONLİNE LOSTRA</strong> için, gecikme,
                eksik ifa etme, ifa etmeme veya temerrüt addedilmeyecek ve bu
                durumlar için ONLİNE LOSTRA’dan herhangi bir nam altında
                tazminat talep edilemeyecektir. "Mücbir sebep" terimi, yangın,
                deprem, sel baskını gibi doğal afetler, isyan, savaş, grev,
                iletişim ve ulaşım sorunları, altyapı ve internet arızaları,
                elektrik kesintisi ve kötü hava koşulları da dâhil ve fakat
                bunlarla sınırlı olmamak kaydıyla,{" "}
                <strong>ONLİNE LOSTRA</strong> ’nın makul kontrolü haricinde ve
                gerekli özeni göstermesine rağmen önleyemediği, kaçınılamayacak
                olaylar olarak yorumlanacaktır. Bu tip durumlarda MÜŞTERİ cayma
                veya değişim haklarından herhangi birini kullanabilir.
              </p>
            </p>
            <p>
              {" "}
              <p>
                <strong>
                  {" "}
                  MADDE 18 - MÜŞTERİ HİZMETLERİ, ŞİK YET MERCİ VE YETKİLİ
                  MAHKEME
                </strong>
              </p>{" "}
              <p>
                <strong> 18.1.</strong> Satın alma, iade, değişim, Ürün
                kullanımı da dâhil olmak üzere tüm bilgi ve açıklama
                talepleriniz ile itiraz ve şikâyetleriniz için
                <strong> ONLİNE LOSTRA</strong> ile iletişime geçebilirsiniz:
                <ul>
                  <li>
                    {" "}
                    0 (850) 533 10 70 numaralı telefondan yılın her günü ve
                    saati,
                  </li>
                  <li>
                    {" "}
                    Uygulamamız seçenekler bölümünde aracılığıyla, "İletişim"
                    bağlantısından,
                  </li>
                  <li>
                    <strong>ONLİNE LOSTRA</strong> yukarıda belirtilen adresine
                    posta yoluyla.
                  </li>
                </ul>
              </p>
            </p>
            <p>
              <p>
                <strong> MADDE 19 – BİLDİRİMLER ve DELİL SÖZLEŞMESİ </strong>
              </p>
              İşbu Sözleşme tahtında Taraflar arasında yapılacak her türlü
              yazışma, Kanunda sayılan zorunlu haller dışında, e-mail
              aracılığıyla yapılacaktır. MÜŞTERİ, işbu Sözleşme’den doğabilecek
              ihtilaflarda <strong>ONLİNE LOSTRA</strong> ’nın resmi defter ve
              ticari kayıtlarıyla, kendi veritabanında, sunucularında tuttuğu
              elektronik bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin
              ve münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri
              Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde
              olduğunu kabul, beyan ve taahhüt eder.
            </p>
            <p>
              <p>
                <strong> MADDE 20 - RESMİ MERCİ TALEPLERİ </strong>
              </p>
              MÜŞTERİ’nin işbu sözleşmeden kaynaklanan yükümlülüklerine uymaması
              sebebiyle MÜŞTERİ’ye ilişkin birtakım bilgiler idari, adli, vb.
              resmi merciler tarafından ONLİNE LOSTRA’dan talep edildiği
              takdirde, <strong>ONLİNE LOSTRA</strong> ’nın bu bilgileri talep
              eden mercilere verebileceğini kabul eder.
            </p>
            <p>
              <p>
                <strong> MADDE 21 - GİZLİLİK</strong>
              </p>
              Madde 21’da belirtilen durumlar haricinde, işbu Sözleşme’nin
              yürürlükte olduğu sürede ve hangi nedenle olursa olsun
              Sözleşme'nin sona ermesinden sonra dahi süresiz olarak, TARAFLAR,
              dolaylı ve dolaysız yoldan edinilen ve gizli tutulması gereken her
              türlü bilgiyi, mülkiyeti veya fikri hakları ve bu hususlara
              ilişkin bilgi ve belgeleri herhangi bir TARAF’ın yazılı onayı
              olmadan üçüncü kişilere ve kamuya açıklayamaz, özel faaliyetleri
              için veya ücret karşılığı olsun ya da olmasın başka kişi, kurum ve
              kuruluşların yararına kullanamaz, kullandıramaz.
            </p>
            <p>
              <p>
                <strong> MADDE 22 - YÜRÜRLÜK </strong>
              </p>
              İşbu Sözleşme 22 (yirmiiki) maddeden ibaret olup, Taraflarca
              okunarak, MÜŞTERİ tarafından elektronik ortamda onaylanmak
              suretiyle akdedilmiş ve derhal yürürlüğe girmiştir.
            </p>
            <p> EK 1</p>
            <p></p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              <strong>ONLİNE LOSTRA</strong> <br /> MÜŞTERİ ÖRNEK CAYMA FORMU
            </p>
            <p></p>
            <p></p>
            <p>
              {" "}
              *(Bu form, sadece sözleşmeden cayma hakkı kullanılmak
              istenildiğinde doldurup gönderilecektir.)
            </p>
            <ul
              style={{
                listStyleType: "none",
              }}
            >
              <li>
                - Kime: (Satıcı veya sağlayıcı tarafından doldurulacak olan bu
                kısımda satıcı veya sağlayıcının ismi, unvanı, adresi varsa faks
                numarası ve e-posta adresi yer alacaktır.)
              </li>
              <li>
                - Bu formla aşağıdaki malların satışına veya hizmetlerin
                sunulmasına ilişkin sözleşmeden cayma hakkımı kullandığımı beyan
                ederim.
              </li>
              <li>- Sipariş tarihi veya teslim tarihi:</li>
              <li>- Cayma hakkına konu mal veya hizmet:</li>
              <li>- Cayma hakkına konu mal veya hizmetin bedeli:</li>
              <li>- Tüketicinin adı ve soyadı:</li>
              <li>- Tüketicinin adresi:</li>
              <li>
                - Tüketicinin imzası: (Sadece kağıt üzerinde gönderilmesi
                halinde)
              </li>
              <li>- Tarih:</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Satis;
