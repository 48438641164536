import React, { useEffect, useState } from "react";
import baseService from "../../library/network/services/BaseService";
import { useNavigate } from "react-router-dom";
import cancel from "../../utils/img/icons/cancel-gray.svg";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";

function Banner({ setTurnOff }) {
  const bannerService = async () => baseService.get("promoad/active");
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const parseContent = (content) => {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(content, "text/html");

    const paragraphs = parsedHTML.body.querySelectorAll("p");

    paragraphs.forEach((paragraph) => {
      const parent = paragraph.parentNode;
      while (paragraph.firstChild) {
        parent.insertBefore(paragraph.firstChild, paragraph);
      }
      parent.removeChild(paragraph);
    });

    return { __html: parsedHTML.body.innerHTML };
  };

  const getData = async () => {
    try {
      const res = await bannerService();
      setData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data.title && (
        <div className="ad-banner">
          <div
            style={{
              width: "15px",
              height: "15px",
              position: "absolute",
              top: "4px",
              right: "20px",

              zIndex: "1",
            }}
            className="x-container web"
          >
            {" "}
            <img
              style={{
                marginLeft: "10px",
                width: "10px",
                height: "10px",
                cursor: "pointer",
              }}
              onClick={() => setTurnOff(false)}
              src={cancel}
              alt="cancel"
            />
          </div>
          <span
            className="text-banner"
            dangerouslySetInnerHTML={parseContent(data.title)}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => {
                navigate("/siparis-olustur");

                handleButtonClicks(
                  "siparis-olustur",
                  "Hemen Sipariş Oluştur",
                  "Anasayfa"
                );
              }}
              className="banner-button"
            >
              Hemen Sipariş Oluştur
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;
