import React, { useContext, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { AccountContext } from "../../contexts/AccountContext";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import SocialAuth from "./components/SocialAuth";
import { Helmet } from "react-helmet-async";
import mail from "../../utils/img/mail-svg.svg";
import password from "../../utils/img/password-svg.svg";
import { GoogleOAuthProvider } from "@react-oauth/google";
import baseService from "../../library/network/services/BaseService";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Geçerli bir e-posta giriniz")
    .required("E-posta giriniz"),
  password: Yup.string().required("Şifre giriniz"),
});

const Login = () => {
  const recaptchaRef = useRef(null);
  const [error, setError] = useState("");
  const [clicked, setClicked] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const navigate = useNavigate();
  const tokenService = () =>
    baseService.post("verify-token", { token: captchaToken });
  const { login } = useContext(AccountContext);

  return (
    <>
      <Helmet>
        <title>{`Giriş Yap - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>

      <div
        // style={{ width: "100%", padding: "0 120px" }}
        className="register-content mt-5"
      >
        {" "}
        <h1>Giriş Yap</h1>
        <p className="web">
          Hesabın yok mu?{" "}
          <Link
            onClick={() => {
              window.dataLayer.push({ ecommerce: null });
              window.dataLayer.push({
                event: "sign_up_form",
                formType: "Üye Ol Form",
                formStep: "1",
              });
            }}
            to="/auth/kayit-ol"
          >
            {" "}
            Üye ol
          </Link>
        </p>
        <div className="web">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
            <SocialAuth text={"giriş yap"} />
          </GoogleOAuthProvider>
        </div>
        <p className="mobile welcome-text">Tekrar Hoş geldin!</p>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const res = await tokenService();
              if (res.data === "OK") {
                values.email = values.email.trim().toLowerCase();
                const res = await login(values);
                if (res.data.status === 200) {
                  navigate("/", { replace: true });
                }
                setError(res.data.message);
              } else {
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                  event: "login_error",
                  label: "error text",
                  userId: "none",
                });
                setError("Bir daha deneyiniz");
              }
            } catch (error) {
              console.log(error);
              setError("Bir daha deneyiniz");
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="register-form">
              <div
                style={{
                  position: "relative",
                }}
                className="form-area"
              >
                <label className="web" htmlFor="email">
                  E-posta <span>*</span>
                </label>
                <img src={mail} alt="mail" className="input-img mobile" />
                <Field
                  className="input-mobile"
                  type="email"
                  name="email"
                  value={values.email.trim().toLowerCase()}
                  placeholder="E-posta adresini gir"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div
                style={{
                  position: "relative",
                }}
                className="form-area"
              >
                <label className="web" htmlFor="password">
                  Şifre <span>*</span>
                </label>
                <img
                  src={password}
                  alt="password"
                  className="input-img password mobile"
                />
                <Field
                  className="input-mobile"
                  type="password"
                  name="password"
                  placeholder="Şifreni gir"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area d-flex justify-content-between align-items-center">
                <Link className="button-link" to="/auth/sifremi-unuttum">
                  {" "}
                  Şifremi Unuttum
                </Link>
              </div>
              <div className="form-area d-flex justify-content-between align-items-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={(token) => {
                    // This function will be called when the user completes the captcha
                    setCaptchaToken(token);

                    setError(""); // Clear any previous error messages
                  }}
                />
              </div>
              <div
                style={{ gap: "30px" }}
                className="form-area d-flex justify-content-between align-items-center flex-wrap "
              >
                <Link
                  onClick={() => {
                    handleButtonClicks("", "Anasayfaya Geri Dön", "Giriş Yap");
                  }}
                  to="/"
                >
                  <span
                    // style={{ fontSize: "14px" }}
                    className="button web button-ghost button-back"
                  >
                    Anasayfaya Geri Dön
                  </span>
                </Link>
                <button
                  style={{ fontSize: "14px" }}
                  type="submit"
                  className="button web button-primary"
                  disabled={isSubmitting}
                >
                  Giriş Yap
                </button>
              </div>
              <div
                className="mobile"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  type="submit"
                  className=" button  mobile-button-auth button-primary"
                  disabled={isSubmitting}
                >
                  Giriş Yap
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {error && <div className="my-3 error-auth">{error}</div>}
        <div className="mobile-auth">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
            <SocialAuth text={"giriş yap"} />
          </GoogleOAuthProvider>
        </div>
        <Link
          onClick={() => {
            handleButtonClicks("", "Anasayfaya Geri Dön", "Giriş Yap");
          }}
          to="/"
        >
          <span
            style={{ color: "#676767" }}
            className=" mobile d-flex justify-content-center align-items-center "
          >
            Anasayfaya Geri Dön
          </span>
        </Link>
        <p className="mobile welcome-text mobile d-flex justify-content-center align-items-center mt-5 ">
          Hesabın yok mu?{" "}
          <Link
            onClick={() => {
              window.dataLayer.push({ ecommerce: null });
              window.dataLayer.push({
                event: "sign_up_form",
                formType: "Üye Ol Form",
                formStep: "1",
              });
            }}
            style={{
              marginLeft: "5px",
            }}
            to="/auth/kayit-ol"
          >
            {" "}
            Üye ol
          </Link>
        </p>
      </div>
    </>
  );
};

export default Login;
