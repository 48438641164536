import React, { useContext } from "react";
import Arrow from "../../../utils/img/icons/arrow-to-right.svg";
import { useNavigate } from "react-router-dom";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";

function SideNavMenu({ route, getTotalPrice, payment, onSubmit }) {
  const { cart, deliveryPrices } = useContext(CartFavoritesContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        // position: "sticky",
        flex: 3,
      }}
      className="checkout-container favorites"
    >
      <p
        style={{
          fontWeight: 500,
        }}
      >
        Sipariş Özeti
      </p>
      <div className="checkout-data-container">
        <span>Ürün toplamı</span>
        <span>₺{(getTotalPrice() - deliveryPrices).toFixed(2)}</span>
      </div>
      <div className="checkout-data-container">
        <span>Kargo</span>
        <span>₺{deliveryPrices && deliveryPrices?.toFixed(2)}</span>
      </div>
      <div className="horizontal-line"></div>
      <div className="checkout-data-container-total">
        <span>Toplam</span>
        <span>₺{getTotalPrice()}</span>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <button
          onClick={() => {
            // navigate(`${route}`)
            onSubmit();
          }}
          className="complate-order-button button-primary"
        >
          {payment ? "Ödemeye Geç" : "SEPETİ Onayla"}
          <img src={Arrow} className="button-arrow" alt="simpleCartIcon" />
        </button>
      </div>
    </div>
  );
}

export default SideNavMenu;
