import React, { useContext, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import SuccessInfo from "../app-layouts/SuccessInfo";
import baseService from "../../library/network/services/BaseService";
const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Şifre giriniz"),
  passwordAgain: Yup.string()
    .required("Şifreyi yeniden giriniz")
    .oneOf([Yup.ref("password")], "Şifreler uyuşmuyor"),
});

function ResetPassword() {
  const { state } = useLocation();
  const resetService = async (values) =>
    baseService.post("webUsers/resetPassword", values);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  return (
    <div
      // style={{ width: "100%", padding: "0 120px" }}
      className="register-content mt-5"
    >
      {" "}
      <h1>Şifreni değiştir</h1>
      {/* <p> Şimdi şifreni değiştire bilirsin </p> */}
      <Formik
        initialValues={{ passwordAgain: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const res = await resetService({
              email: state?.email,
              password: values.password,
            });

            if (res.data.status === 200) {
              setMessage("Şifreniz başarı ile değiştirilmiştir");
              setTimeout(() => {
                navigate("/auth/giris-yap");
                setMessage("");
              }, 1000);
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="register-form">
            <div className="form-area">
              <label htmlFor="password">
                Yeni Şifre <span>*</span>
              </label>
              <Field
                type="password"
                name="password"
                placeholder="Şifreni gir"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error-auth"
              />
            </div>
            <div className="form-area">
              <label htmlFor="password">
                Şifre Tekrarı <span>*</span>
              </label>
              <Field
                type="password"
                name="passwordAgain"
                placeholder="Şifreni gir"
              />
              <ErrorMessage
                name="passwordAgain"
                component="div"
                className="error-auth"
              />
            </div>

            <div
              //   className="mobile"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  fontSize: "14px",
                  width: "100%",
                  borderRadius: "20px",
                }}
                type="submit"
                className=" button  button-primary"
                disabled={isSubmitting}
              >
                Şifreni Değiştir
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {message && <SuccessInfo message={message} />}
    </div>
  );
}

export default ResetPassword;
