import React from "react";

const RadioButton = ({ checked, onChange, name, value, id }) => {
  return (
    <input
      type="radio"
      className="radio-button-commerce"
      checked={checked}
      onChange={onChange}
      id={id}
      name={name}
      value={value}
    />
  );
};

export default RadioButton;
