import React from "react";
import Call from "../../utils/img/icons/calling.svg";
import Email from "../../utils/img/icons/Vecto.svg";

function ContactInfo() {
  return (
    <>
      {" "}
      <div className="container">
        <div className="row contact-container ">
          <div className="">
            <p className="contact-title">İletİşİm</p>
            <h2>
              Sormak İstediğiniz Tüm Sorular <br /> İçin Bize Ulaşabilirsiniz
            </h2>
          </div>
          <div className="col-md-9 ">
            <div className="info-container">
              <div>
                <div className=" info-box-contact">
                  <img className="contact-icons" src={Call} alt="phone logo" />
                  <div>
                    {" "}
                    <h6>TELEFON</h6>
                    <p className="contact-info-span">+90 850 533 1070</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="info-box-contact">
                  <img className="contact-icons" src={Email} alt="email logo" />
                  <div>
                    <h6>E-POSTA</h6>
                    <p className="contact-info-span">info@onlinelostra.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;
