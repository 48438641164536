import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllSss } from "../../../library/network/requests/sss";
import Comments from "../../app-layouts/Comments";
import MobileApp from "../../app-layouts/MobileApp";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";

function Sss() {
  const [isClicked, setIsClicked] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [sss, setSss] = useState([]);

  const getSss = async () => {
    const res = await getAllSss();
    getDataForHtml(res.data)
    setSss(res.data);

  };
  const getDataForHtml = (sss) => {
    const ldJsonData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": sss.map((item) => ({
        "@type": "Question",
        "name": item.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.description,
        },
      })),
    };

    const scriptTag = document.createElement("script");
    scriptTag.type = "application/ld+json";
    scriptTag.innerHTML = JSON.stringify(ldJsonData);
    document.head.appendChild(scriptTag);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleClick = (item) => {
    if (isClicked === item) {
      setIsClicked("");
    } else {
      setIsClicked(item);
    }
  };

  useEffect(() => {
    getSss();
  }, []);
  useEffect(() => {
    const results = sss?.filter((item) =>
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchResults(results);
  }, [searchTerm, sss]);
  return (
    <>
      <Helmet>
        <title>{`Sıkca Sorulan Sorular - Online Lostra`}</title>
        <meta
          name="description"
          content="Online Lostra üzerinden ayakkabılarının ilk günkü temizliğinde olmasını sağlayabilirsin. Detaylı bilgi almak için Online Lostra internet sitesini ziyaret et."
        />
      
      </Helmet>

      <section className="page-section">
        <div className="bread-container container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Sıkca Sorulan Sorular", url: "/sss" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Sıkça Sorulan Sorular</h4>
            <h1>Online Lostra Hakkında Bilmek İstedikleriniz</h1>
          </div>
          <div className="search-bar">
            <form action="#" method="post">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                name="service"
                placeholder="Aradığınız kelimeyi giriniz..."
              />
              {/* <button type="submit" className="button button-primary">
                Arama Yap
              </button> */}
            </form>
          </div>
          <div className="faq">
            {(searchTerm ? searchResults : sss).map((item) => {
              return (
                <div
                  id={item.id}
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                  className={
                    isClicked === item.id
                      ? `faq-box active ${item.id}`
                      : "faq-box"
                  }
                >
                  <h3>{item?.title}</h3>
                  <p
                    className="blogContent"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                  <span className="activate"></span>
                </div>
              );
            })}
          </div>
          <div className="service-action">
            <Link
              onClick={() => {
                handleButtonClicks(
                  "fiyatlarimiz",
                  "Fiyatlar",
                  "Sıkça Sorulan Sorular Sayfası"
                );
              }}
              to="/siparis-olustur"
            >
              <p className="button button-primary text-uppercase">
                SİPARİŞ Oluştur
              </p>
            </Link>
          </div>
        </div>
      </section>
      <Comments />
      <MobileApp />
    </>
  );
}

export default Sss;
