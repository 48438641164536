import React from "react";
import SssPanel from "../../app-layouts/SssPanel";
import CareerForm from "../../app-layouts/CareerForm";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function Career() {
  return (
    <>
      <Helmet>
        <title>{`Kariyer - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>{" "}
      <section className="page-section">
        {" "}
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Kariyer", url: "/kariyer" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>KARİYER</h4>
            <h3>
              Personel ihtiyacı olması halinde değerlendirilmek üzere
              başvurunuzu iletebilirsiniz.
            </h3>{" "}
          </div>
        </div>
      </section>
      <CareerForm />
      <SssPanel />
    </>
  );
}

export default Career;
