import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessInfo from "../app-layouts/SuccessInfo";
import ReCAPTCHA from "react-google-recaptcha";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import baseService from "../../library/network/services/BaseService";

const ConfirmationSchema = Yup.object().shape({
  confirmationCode: Yup.string().required("Onay kodu zorunlu"),
});

function Confirmation() {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const { state } = useLocation();
  const [error, setError] = useState("");
  const recaptchaRef = useRef(null);
  const [clicked, setClicked] = useState(false);
  const tokenService = () =>
    baseService.post("verify-token", { token: captchaToken });

  const navigate = useNavigate();
  const webuserService = async (values) =>
    baseService.post("webusers/confirm", values);
  return (
    <div className="form-container  container-auth">
      <h2>Hesabını onayla</h2>
      <Formik
        initialValues={{ confirmationCode: "", email: state?.email }}
        validationSchema={ConfirmationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const token = await recaptchaRef.current.executeAsync();
          recaptchaRef.current.reset();
          try {
            const res = await tokenService();
            if (res.data === "OK") {
              const response = await webuserService(values);
              if (response.status === 200) {
                setMessage("Hesabınız başarı ile onaylanmıştır");
                setSuccess(true);
                storageHelper.setStoreWithEncryption(
                  "token",
                  response.data.token
                );
                storageHelper.setStoreWithEncryption(
                  "userId",
                  response.data.userData.id
                );

                setTimeout(() => {
                  setSuccess(false);
                  navigate("/");
                }, 1000);
                resetForm();
              } else {
                setError(response.data.message);
                setSubmitting(false);
              }
            } else {
              setError("Testi bir daha yapınız");
            }
          } catch (error) {
            setError("Testi bir daha yapınız");
            console.log(error);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="register-form">
            <div className=" form-area form-group-auth">
              <label htmlFor="confirmationCode">Onay Kodunuzu Giriniz</label>
              <Field
                type="text"
                name="confirmationCode"
                // className="form-control"
              />
              <ErrorMessage
                name="confirmationCode"
                component="div"
                className="error-auth"
              />
            </div>
            {error && <h4 className="error-auth">{error}</h4>}
            <div className="form-area d-flex justify-content-between align-items-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={(token) => {
                  // This function will be called when the user completes the captcha
                  setCaptchaToken(token);

                  setError(""); // Clear any previous error messages
                }}
              />
            </div>
            <button
              type="submit"
              className="btn button btn-primary button-auth"
              //   disabled={isSubmitting}
            >
              Onayla
            </button>
          </Form>
        )}
      </Formik>
      {success && <SuccessInfo message={message} />}
    </div>
  );
}

export default Confirmation;
