import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { AccountContext } from "../../../contexts/AccountContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import AccountViews from "../../../views/AccountViews";
import Orders from "../Orders/Orders";

import { Helmet } from "react-helmet-async";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";

function Account() {
  const { logout } = useContext(AccountContext);
  const [pathname, setPathName] = useState("");
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const pathArray = window.location.pathname.split("/");
  useEffect(() => {
    const lastElement = pathArray[pathArray.length - 1];
    const pathName =
      lastElement === "" ? pathArray[pathArray.length - 2] : lastElement;
    setPathName(pathName);
  }, [pathArray]);
  return (
    <>
      {" "}
      <Helmet>
        <title>Hesap-Online Lostra </title>
        <meta name="description" content="noindex" />
      </Helmet>{" "}
      <div className="container my-5 ">
        <div className="row ">
          <div className=" mb-4 col-xl-4">
            <div id="main-actions">
              <div className="dropdown-box  ">
                <div className="inner  orders-menu ">
                  <ul className=" order-user-menu">
                    <li>
                      <Link
                        onClick={() => {
                          setPathName("hesap");

                          handleButtonClicks("hesap", "Profilim", "Profilim");
                        }}
                        className={
                          pathname === "hesap"
                            ? "  iconUserActive "
                            : "icon-user"
                        }
                        to="/hesap"
                      >
                        <span
                          onClick={() => setPathName("hesap")}
                          className={pathname === "hesap" ? "activeMenu" : ""}
                        >
                          {" "}
                          Profilim
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          handleButtonClicks(
                            "hesap/siparis",
                            "Siparişlerim",
                            "Profilim"
                          );
                          setPathName("siparis");
                        }}
                        className={
                          pathname === "siparis"
                            ? "  iconReceiptActive "
                            : "icon-receipt"
                        }
                        to="/hesap/siparis"
                      >
                        <span
                          onClick={() => setPathName("siparis")}
                          className={pathname === "siparis" ? "activeMenu" : ""}
                        >
                          {" "}
                          Siparişlerim
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          handleButtonClicks(
                            "hesap/adres",
                            "Adreslerim",
                            "Profilim"
                          );
                          setPathName("adres");
                        }}
                        className={
                          pathname === "adres"
                            ? "  iconLocationActive "
                            : "icon-location"
                        }
                        to="/hesap/adres"
                      >
                        <span
                          onClick={() => setPathName("adres")}
                          className={pathname === "adres" ? "activeMenu" : ""}
                        >
                          {" "}
                          Adreslerim
                        </span>
                      </Link>
                    </li>

                    <li
                      onClick={() => {
                        logout();
                        navigate("/");

                        handleButtonClicks("", "Çıkış yap", "Profilim");
                      }}
                    >
                      <Link className="icon-power-circle">
                        <span>Çıkış Yap</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="  col-xl-8">
            <AccountViews />
            {/* <Orders /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Account;
