import React, { useEffect, useState } from "react";
import Privileges from "../../app-layouts/Privileges";
import SssPanel from "../../app-layouts/SssPanel";
import Prices from "../Prices/Prices";
import baseService from "../../../library/network/services/BaseService";
import Loader from "../../app-layouts/Loader";
import { Link, useParams } from "react-router-dom";
import { getAllBrands } from "../../../library/network/requests/brands";
import { characterConvert } from "../../../utils/helpers/CharacterConvert";
import { Helmet } from "react-helmet-async";
import { ref, getDownloadURL } from "firebase/storage"; // Import Firebase storage functions
import { storage } from "../../../firebase";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";
import slugify from "slugify";

function BrandInformation() {
  const params = useParams();
  const websiteUrl = window.location.href;
  const [imageUrls, setImageUrls] = useState(null);
  const { id } = params;
  const [brand, setBrand] = useState({});
  const [titleName, setTitleName] = useState("");
  const [loading, setLoading] = useState(false);
  const brandService = async (brandId) => baseService.get(`brands/${brandId}`);
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);

      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getBrand = async (brandId) => {
    setLoading(true);

    const res = await getAllBrands();
    const selectedBrand = res?.data?.find(
      (item) =>
        slugify(item?.title, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }) === id
    );
    setTitleName(selectedBrand?.title);

    try {
      const response = await brandService(selectedBrand?._id);
      if (response.data.mainImage) {
        getImageUrl(response.data.mainImage);
      }

      if (response.status === 200) {
        if (response.data.primaryParagraph) {
          const modifiedPrimaryParagraph = modifyParagraph(
            response.data.primaryParagraph?.content,
            selectedBrand?.title
          );
          const modifiedSecondaryParagraph = modifyParagraph(
            response.data.secondaryParagraph?.content,
            selectedBrand?.title
          );

          const modifiedBrand = {
            ...response.data,
            primaryParagraph: {
              content: modifiedPrimaryParagraph,
            },
            secondaryParagraph: {
              content: modifiedSecondaryParagraph,
            },
          };
          if (modifiedBrand?.title) {
            setBrand(modifiedBrand);
          }
        } else {
          setBrand(response.data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const modifyParagraph = (content, title) => {
    try {
      if (content && title) {
        return content.replace(/CHANGE/g, title);
      } else return "";
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  useEffect(() => {
    getBrand();
  }, [id]);
  if (loading || titleName.length === undefined) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{`${brand?.title ?? ""} ${
          brand?.serviceCategoryId?.name
        } Hizmeti - Online Lostra`}</title>
        {brand?.serviceCategoryId?.name === "utuleme" ? (
          <meta
            name="description"
            content={`${
              brand?.title ?? ""
            } ütüleme hizmeti için Online Lostra'dan hızlı ve kolay bir şekilde hizmet alabilirsiniz. Detaylı bilgi alın ve şıklığınızı tamamlayacak şekilde gömlek ütülettirin.`}
          />
        ) : (
          <meta
            name="description"
            content={`${
              brand?.title ?? ""
            } temizleme ve bakım hizmeti Online Lostra ile bir tık uzağında! ${
              brand?.title ?? ""
            }  temizleme ve bakım hizmeti hakkında detaylı bilgi almak için tıkla.`}
          />
        )}
      </Helmet>

      {brand.mainImage ? (
        <section className="page-section bg-element-2">
          {" "}
          {brand.serviceCategoryId?.name && (
            <div className="container">
              <Breadcrumbs
                paths={[
                  { text: "Anasayfa", url: "/" },
                  { text: "Fiyatlarımız", url: "/fiyatlarimiz" },
                  {
                    text: `${brand?.serviceCategoryId?.name} fiyatları`,
                    url: `/${slugify(brand?.serviceCategoryId?.name, {
                      lower: true,
                      remove: /[*+~.()'"!:@?]/g,
                    })}-fiyatlari`,
                  },
                  { text: brand?.title, url: `${websiteUrl.split("/").pop()}` },
                ]}
              />
            </div>
          )}
          <div className="container">
            <div className="title">
              <h4>
                {brand?.serviceCategoryId?.name.toLocaleUpperCase("tr-TR")}
              </h4>
              <h1>
                {brand?.title}
                <span></span> Temizleme Fiyatı
              </h1>
            </div>
            <div className="brand-info-container">
              <div style={{ flex: "1" }} className=" py-3 brand-info ">
                {brand.primaryParagraph && brand.secondaryParagraph && (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: brand.primaryParagraph?.content,
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: brand.secondaryParagraph?.content,
                      }}
                    />
                  </>
                )}
                {/* <p>
                Vale hizmeti, ödeme kolaylığı ve hemen teslim avantajı ile sen
                de {brand?.title} temizliği ihtiyacını kısa bir süre içerisinde
                gidermek istiyorsan hemen Temiz'i ziyaret et.
              </p> */}
              </div>

              <div
                style={{ flex: "1", textAlign: "right" }}
                className="d-flex flex-column "
              >
                <div className="brand-img-container">
                  {" "}
                  {/* <img
                  style={{ marginLeft: "0" }}
                  className="brand-img"
                  src={imageUrl ? imageUrl : ""}
                  alt={brand?.title}
                /> */}
                  {imageUrls && imageUrls[brand?.mainImage] && (
                    <img
                      style={{ marginLeft: "0" }}
                      className="brand-img"
                      src={imageUrls[brand?.mainImage] || ""}
                    />
                  )}
                </div>

                {/* {imageUrls[brand?.mainImage]} */}
                <h3 className="my-4  text-right">{brand?.price} TL</h3>
                <Link
                  onClick={() => {
                    handleButtonClicks(
                      "siparis-olustur",
                      "Sipariş Oluştur",
                      "Marka fiyatları"
                    );
                  }}
                  to="/siparis-olustur"
                >
                  <span className="button button-primary">Sipariş Oluştur</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Loader />
      )}
      <Prices title={brand?.serviceCategoryId?.name} />
      <Privileges />
      <SssPanel
        pageType="price"
        category={
          brand?.serviceCategoryId?.name.split(" ")[0] === "Ayakkabı"
            ? "ayakkabi"
            : brand?.serviceCategoryId?.name.split(" ")[0] === "Çanta"
            ? "canta"
            : brand?.serviceCategoryId?.name.split(" ")[0]
        }
      />
    </>
  );
}

export default BrandInformation;
