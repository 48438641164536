import React, { useContext, useEffect, useState } from "react";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";
import Recommendations from "../EcommerceList/Recommendations";
import cancel from "../../../utils/img/icons/delete-product.svg";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import { useNavigate } from "react-router-dom";

function Favorites() {
  const { favorites, toggleFavorite } = useContext(CartFavoritesContext);
  const [imageUrls, setImageUrls] = useState({});
  const navigate = useNavigate();
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  useEffect(() => {
    Promise.all(favorites.map((item) => getImageUrl(item?.mainImage)));
  }, [favorites]);

  return (
    <>
      <section className="page-section">
        <div className="container">
          <div className="favorites">
            <div className="favorites-header">
              <p>Favorilerim</p>
            </div>
            {favorites.length > 0 ? (
              <table className="favorites-table">
                <thead>
                  <tr>
                    <th>Ürün</th>
                    <th>Fiyat</th>
                    <th className="web">Stok Durumu</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {favorites.map((item) => (
                    <tr key={item._id}>
                      <td>
                        {" "}
                        <div className="cart-product" key={item?._id}>
                          <img
                            src={imageUrls[item?.mainImage]}
                            alt={item?.productId?.mainImage}
                          />

                          <p className="fw-normal web">{item?.name}</p>
                          <p className="fw-normal mobile">
                            {item?.name?.length > 15
                              ? item?.name.substring(0, 15) + "..."
                              : item?.name}
                          </p>
                        </div>
                      </td>
                      <td className="table-price">{item.price.toFixed(2)}₺</td>
                      <td className="web">
                        {item.inStock ? (
                          <span className="stock-info yes">STOKTA</span>
                        ) : (
                          <span className="stock-info yes">STOKTA YOK</span>
                        )}
                      </td>
                      <td className="actions">
                        <button
                          className="check-product-button button"
                          onClick={() => navigate(`/magaza/${item._id}`)}
                        >
                          Ürünü Gör
                        </button>
                        <img
                          src={cancel}
                          onClick={() => toggleFavorite(item)}
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  width: "100%",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Favorileriniz boş
              </div>
            )}
          </div>
          <Recommendations />
        </div>
      </section>
    </>
  );
}

export default Favorites;
