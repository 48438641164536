import React, { useEffect } from "react";
import baseService from "../../../library/network/services/BaseService";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import ProductCards from "./ProductCards";
function Recommendations() {
  const [products, setProducts] = React.useState([]);
  const [imageUrls, setImageUrls] = React.useState({});
  const productsService = async () => baseService.get("ecommerceproducts");
  const getImageUrl = async (image, type) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getProducts = async () => {
    const response = await productsService();
    const limitedProducts = response?.data?.slice(0, 4);
    setProducts(limitedProducts);

    limitedProducts.forEach((product) => {
      getImageUrl(product.mainImage, "general");
      product?.images?.forEach((image) => {
        getImageUrl(image, "general");
      });
    });
  };

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div>
      <h2
        style={{
          margin: "70px 0",
        }}
        className="text-start"
      >
        Bu Ürünü Alanlar Bunları da Aldı
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <ProductCards products={products} imageUrls={imageUrls} />
      </div>
    </div>
  );
}

export default Recommendations;
