import React from "react";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function Cookies() {
  return (
    <section
      style={{
        textAlign: "start",
      }}
      className="page-section"
    >
      <div className="container">
        <Breadcrumbs
          paths={[
            { text: "Anasayfa", url: "/" },
            { text: "Çerezler", url: "/cerez-politikasi" },
          ]}
        />
      </div>
      <div className="container">
        <div>
          <h2>Çerez Politikası</h2>
          <p>
            DU TECH İNTERNET HİZMETLERİ VE LOJİSTİK ANONİM ŞİRKETİ (“ONLİNE
            LOSTRA”) şirketi, veri sorumlusu sıfatıyla, 6698 sayılı Kişisel
            Verilerin Korunması Kanunu’nun (Kanun) 10’uncu maddesi ile
            Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve
            Esaslar Hakkında Tebliğ kapsamında bu metni hazırlamıştır.
          </p>
          <p>
            Bu Çerez Aydınlatma metninin amacı, internet sitemizde kullanılan
            çerezlerin cihazınıza yerleştirilmesi aracılığıyla otomatik yolla
            elde edilen kişisel verilerin işlenmesine ilişkin olarak, hangi
            amaçlarla hangi tür çerezleri kullandığımızı ve bu çerezleri nasıl
            yönetebileceğiniz hakkında sizlere bilgi vermektir.
          </p>
          <p>
            İnternet sitemizde kullandığımız, zorunlu çerezler haricindeki
            çerezler için, kullanıcıların açık rızaları alınmakta ve istedikleri
            zaman rızalarını değiştirebilme olanağı sağlanmaktadır. Kullanıcılar
            çerez yönetim paneli üzerinden, internet sitemizde kullanılan çerez
            çeşitlerini görebilmekte ve Zorunlu Çerezler dışında kalan tüm
            çerezler için “açık” veya “kapalı” seçenekleri ile tercihlerini
            belirleyebilmektedirler. Yine bu panel üzerinden kullanıcılar
            tercihlerini her zaman değiştirebilmektedirler.
          </p>
          <h3>Çerez Türleri</h3>
          <h5>Sürelerine Göre Çerez Türleri</h5>
          <ol>
            <li>
              Oturum çerezleri: Oturumun sürekliliğinin sağlanması amacıyla
              kullanılmakta olup kullanıcı tarayıcısını kapattığında bu çerezler
              de silinmektedir.
            </li>
            <li>
              Kalıcı çerezler: Bu Çerezler sizler silene kadar veya yok olma
              gününe bağlı olarak tarayıcınız tarafından otomatik olarak
              silinene kadar sabit sürücünüzde kalan çerezlerdir.
            </li>
          </ol>
          <h5>Kaynaklarına Göre Çerez Türleri</h5>
          <ol>
            <li>
              Birinci Taraf Çerezler: Birinci taraf çerezler, doğrudan
              kullanıcının ziyaret ettiği internet sitesi yani tarayıcının adres
              çubuğunda gösterilen URL tarafından yerleştirilmektedir.
            </li>
            <li>
              Üçüncü Taraf Çerezler: Üçüncü taraf çerezler kullanıcının ziyaret
              ettiği etki alanından farklı bir etki alanı tarafından
              yerleştirilmektedir.
            </li>
          </ol>
          <h5>Kullanım Amaçlarına Göre Çerez Türleri</h5>
          <ol>
            <li>
              Zorunlu Çerezler: Bu tarz çerezler web sitesinin işleyişi için
              zorunludur ve kapatılamaz. Bunlar genellikle sizin gizlilik
              tercihlerinizi tutmak veya sisteme girerken ya da bir form
              doldururken girdiğiniz verileri kullanmak amacıyla güncellenirler.
              Tarayıcınızı bu çerezleri engellemek veya sizi uyarması için
              güncelleyebilirsiniz, fakat sitenin bazı parçaları çalışmayabilir.
              Bu çerezler kişisel bilgi içermezler.
            </li>
            <li>
              Performans Çerezleri: Bu çerezleri sitenin ziyaretçi sayılarını ve
              trafiğini anlamak için kullanırız, böylece sitemizin performansını
              ölçebilir ve iyileştirebiliriz. Bunlar bizim hangi sayfaların en
              çok ya da en az kullanıldığını anlamamıza ve ziyaretçilerin sitede
              nasıl hareket ettiklerini görmemize yardımcı olur. Bu çerezlerin
              topladığı bilgiler kişisel olarak değil topluca anonim olarak
              işlenir. Eğer bu çerezlere izin vermezseniz, sizin sitemizi ne
              zaman ziyaret ettiğinizi bilemeyiz.
            </li>
            <li>
              Fonksiyonel Çerezler: Bu çerezler sitenin daha fonksiyonel ve
              kişiselleştirilmiş olmasına olanak sağlar. Bizim tarafımızdan veya
              sayfalara eklediğimiz 3.parti servis sağlayıcıları tarafından
              güncellenebilirler. Eğer bu çerezlere izin vermezseniz bu
              servislerin bazıları veya tümü düzgün çalışmayabilir.
            </li>
            <li>
              Pazarlama Çerezleri: Bu çerezler reklamcılık alanındaki iş
              ortaklarımız tarafından kullanılabilirler Bu reklam şirketleri
              sizin ilgi alanlarınıza göre profilleme yapabilir ve diğer
              gezindiğiniz web sitelerinde sizin ilginizi çekebilecek reklamları
              size gösterebilirler. Doğrudan kişisel bilgi saklamazlar, fakat
              sizin tarayıcıdaki ve internet cihazınızdaki sizi tanımlayan tekil
              kimliği kullanırlar. Eğer bu çerezlere izin vermezseniz, daha az
              hedefli pazarlamaya mazur kalırsınız.
            </li>
          </ol>
          <p>
            Kişisel Verilerin Aktarıldığı Taraflar Web sitesi üzerinde
            kullanılmakta olan ve aşağıdaki tabloda detayları verilen bazı
            çerezler kişisel veriler içermektedir. Bu çerezlerin kullanımına
            onay vermeniz durumunda açık rızanız dahilinde bazı kişisel
            verileriniz yurtdışındaki iş ortaklarımıza aktarılır.
          </p>
          <p>Kişisel Verilerin İşlenmesinde Hukuki Sebepler</p>
          <ol>
            <li>
              {" "}
              Zorunlu çerezler: Bu çerezler aracılığıyla toplanan kişisel
              verileriniz, Kanunun 5’inci maddesinin (2) numaralı fıkrasının (c)
              bendi “Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
              ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
              verilerin işlenmesinin gerekli olması” veya (f) bendi “İlgili
              kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
              sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
              olması” kapsamında işlenmektedir.
            </li>
            <li>
              {" "}
              Performans Çerezleri: Bu çerezler aracılığıyla toplanan kişisel
              verileriniz, Kanun’un 5’inci maddesinin (1) numaralı fıkrası
              kapsamında açık rızanızın alınması suretiyle işlenmektedir.
            </li>
            <li>
              Fonksiyonel çerezler: Bu çerezler aracılığıyla toplanan kişisel
              verileriniz, Kanun’un 5’inci maddesinin (1) numaralı fıkrası
              kapsamında açık rızanızın alınması suretiyle işlenmektedir.
            </li>
            <li>
              Pazarlama çerezleri: Bu çerezler aracılığıyla toplanan kişisel
              verileriniz, Kanun’un 5’inci maddesinin (1) numaralı fıkrası
              kapsamında açık rızanızın alınması suretiyle işlenmektedir.
            </li>
          </ol>
          <p>Veri Sahibi Olarak Haklarınız ve İlgili Kişilerin Talepleri</p>
          <p>KVK Kanunu’nun 11. maddesi uyarınca veri sahipleri,</p>
          <ul>
            <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>
              Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
              kişileri bilme,
            </li>
            <li>
              Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
            </li>
            <li>
              {" "}
              KVK Kanunu ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
              isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
              edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
              çıkmasına itiraz etme,
            </li>
            <li>
              Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
              uğraması hâlinde zararın giderilmesini talep etme haklarına
              sahiptir.
            </li>
          </ul>
          <p>
            İnternet sitemizde kullanılan çerezler yoluyla kişisel verileri
            işlenen ilgili kişiler, Kişisel Verilerin Korunması Kanunu’nun
            11’inci maddesi kapsamındaki taleplerini, “Veri Sorumlusuna Başvuru
            Usul ve Esasları Hakkında Tebliği”ne göre aşağıdaki adrese veya
            e-posta adresine yazılı olarak iletebilirler.
          </p>
          <p>
            Adres: Çırçır Mahallesi Dönen Sokak No:8-10B Eyüpsultan, İstanbul,
          </p>
          <p>e-posta Adresi: info@onlinelostra.com</p>
          <p>Çerezlerin Kullanımını Nasıl Kontrol Edebilirim?</p>
          <p>
            İnternet sitemizde istediğiniz anda çerezlere ilişkin tercihlerinizi
            değiştirebilirsiniz. Çerez yönetim panelindeki butonları tercihinize
            göre açık veya kapalı konuma getirerek “Değişiklikleri kaydet”
            butonuna tıklayınız.
          </p>
          <p>
            Bunun yanı sıra, tarayıcı ayarları aracılığıyla da kısmen kontrol
            sağlanabilmektedir. Bu konudaki tercihler kullanılan tarayıcıya göre
            değişiklik göstermekle birlikte genel açıklamaya
            https://www.aboutcookies.org adresinden ulaşmak mümkündür. Sık
            kullanılan tarayıcılarda çerezlerin yönetimine ilişkin bilgilere
            aşağıdaki bağlantılar aracılığıyla erişebilirsiniz:
          </p>
          <ul>
            <li>
              Google Chrome: https://support.google.com/chrome/answer/95647
            </li>
            <li>
              Mozilla Firefox :
              https://support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma
            </li>
            <li>
              Microsoft Edge:
              https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
            </li>
            <li>
              Safari: https://support.apple.com/tr-tr/guide/safari/sfri11471/mac
            </li>
            <li>
              Opera:
              https://www.operaturkiye.net/cerezleri-nasil-silebilirim/index.html
            </li>
          </ul>
          <p>Bunun dışında;</p>
          <ul>
            <li>
              {" "}
              Google Analytics tarafından yönetilen Çerezler’i kapatmak için şu
              linke tıklayınız: https://tools.google.com/dlpage/gaoptout
            </li>
            <li>
              Google tarafından sağlanan kişiselleştirilmiş reklam deneyimini
              yönetmek için şu linke tıklayınız:
              https://www.google.com/settings/ads/
            </li>
            <li>
              Birçok firmanın reklam faaliyetleri için kullandığı çerezler
              bakımından tercihler şu link üzerinden yönetilebilir:
              http://www.youronlinechoices.com/tr/reklam-tercihleriniz
            </li>
            <li>
              Mobil cihazlar üzerinden çerezleri yönetmek için mobil cihaza ait
              ayarlar menüsü kullanılabilir.
            </li>
          </ul>
          <p>Son güncelleme tarihi: 20.03.2024</p>
        </div>
        <p>
          İnternet sitemizde yer alan çerezlere ilişkin bilgiler aşağıdaki
          tabloda yer almaktadır.
        </p>
        <table className="kvkk-table">
          <thead>
            <tr>
              <th colspan="8">
                onlinelostra.com - Sınıflandırılmış Çerez Listesi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Çerez Adı</td>
              <td>Çerez Sağlayıcı (Domain)</td>
              <td>Çerez Türü</td>
              <td>Çerez Süresi</td>
              <td>Çerez Tanımı</td>
            </tr>
            <tr>
              <td>__cf_bm</td>
              <td>.onlinelostra.com</td>
              <td>Zorunlu</td>
              <td>20 dakika</td>
              <td>
                Cloudflare'nin bot ürünleri, sitenizi kötü botlardan korumak
                için otomatik trafiği tanımlar ve azaltır. Cloudflare, __cf_bm
                tanımlama bilgisini, Bot Yönetimi veya Bot Dövüş Modu tarafından
                korunan Müşteri sitelerine erişen Son Kullanıcı cihazlarına
                yerleştirir. Bu bot çözümlerinin düzgün çalışması için __cf_bm
                tanımlama bilgisi gereklidir.
              </td>
            </tr>
            <tr>
              <td>__cf_bm</td>
              <td>.ondigitalocean.app</td>
              <td>Zorunlu</td>
              <td>1 saat</td>
              <td>
                Cloudflare'nin bot ürünleri, sitenizi kötü botlardan korumak
                için otomatik trafiği tanımlar ve azaltır. Cloudflare, __cf_bm
                tanımlama bilgisini, Bot Yönetimi veya Bot Dövüş Modu tarafından
                korunan Müşteri sitelerine erişen Son Kullanıcı cihazlarına
                yerleştirir. Bu bot çözümlerinin düzgün çalışması için __cf_bm
                tanımlama bilgisi gereklidir.
              </td>
            </tr>
            <tr>
              <td>session</td>
              <td>.ondigitalocean.app</td>
              <td>Zorunlu</td>
              <td>1 gün</td>
              <td>
                Dahili olarak bir kullanıcı için bir oturum örneğini tanımlamak
                için kullanır.
              </td>
            </tr>
            <tr>
              <td>session.sig</td>
              <td>.ondigitalocean.app</td>
              <td>Zorunlu</td>
              <td>1 gün</td>
              <td>
                Dahili olarak bir kullanıcı için bir oturum örneğini tanımlamak
                için kullanır.
              </td>
            </tr>
            <tr>
              <td>cookiespool_consent</td>
              <td>onlinelostra.com</td>
              <td>Zorunlu</td>
              <td>6 ay</td>
              <td>
                Web sitesinde kullanılan çerezler için Cookiespool aracılığıyla
                ziyaretçiden alınan açık rıza seçimlerini saklamak için
                kullanılır.
              </td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>onlinelostra.com</td>
              <td>Performans</td>
              <td> 400 gün</td>
              <td>
                Bu çerez Google Analytics tarafından kullanıcıları ayırt etmek
                için kullanılır.
              </td>
            </tr>
            <tr>
              <td>_clck</td>
              <td>.onlinelostra.com</td>
              <td>Performans</td>
              <td>1 gün</td>
              <td>
                Bu çerez, ziyaretçilerin bir web sitesini nasıl kullandığına
                ilişkin bilgileri depolamak ve web sitesinin nasıl çalıştığına
                dair bir analiz raporu oluşturmaya yardımcı olmak için Microsoft
                Clarity tarafından yüklenir. Ziyaretçi sayısı, geldikleri kaynak
                ve ziyaret edilen sayfalar dahil olmak üzere toplanan veriler
                anonim bir biçimde toplanır.
              </td>
            </tr>
            <tr>
              <td>MR</td>
              <td>.c.bing.com</td>
              <td>Performans</td>
              <td>7 gün</td>
              <td>Analitik amaçlarla bilgi toplamak için kullanılır.</td>
            </tr>
            <tr>
              <td>SM</td>
              <td>.c.clarity.ms</td>
              <td>Performans</td>
              <td>Oturum Süresince</td>
              <td>
                Web sitesinin kullanımını ölçmek için kullandığımız bir
                Microsoft MSN çerezidir.
              </td>
            </tr>
            <tr>
              <td>MR</td>
              <td>.c.clarity.ms</td>
              <td>Performans</td>
              <td>7 gün</td>
              <td>Analitik amaçlarla bilgi toplamak için kullanılır.</td>
            </tr>
            <tr>
              <td>_ga_*</td>
              <td>.onlinelostra.com</td>
              <td>Performans</td>
              <td>400 gün</td>
              <td>
                Bu çerez Google Analytics tarafından kullanıcıları ayırt etmek
                için kullanılır.
              </td>
            </tr>
            <tr>
              <td>CLID</td>
              <td>www.clarity.ms</td>
              <td>Fonksiyonel</td>
              <td>365 gün</td>
              <td>
                Bu çerez genellikle medya içeriğinin sosyal medyada
                paylaşılmasını sağlamak için ayarlanır. 
              </td>
            </tr>
            <tr>
              <td>SRM_B</td>
              <td>.c.bing.com</td>
              <td>Fonksiyonel</td>
              <td>390 gün</td>
              <td>
                Toplanan kullanıcı verileri, kullanıcıya veya cihaza özel olarak
                uyarlanır. Kullanıcı, yüklenen web sitesinin dışında da
                izlenebilir, böylece ziyaretçinin davranışının bir resmi
                oluşturulur.
              </td>
            </tr>
            <tr>
              <td>_fbp</td>
              <td>.onlinelostra.com</td>
              <td>Pazarlama</td>
              <td>90 gün</td>
              <td>
                Facebook tarafından, üçüncü taraf reklamcılardan gerçek zamanlı
                teklif verme gibi bir dizi reklam ürünü sunmak için kullanılır
              </td>
            </tr>
            <tr>
              <td>_gcl_au</td>
              <td>.onlinelostra.com</td>
              <td>Pazarlama</td>
              <td>90 gün</td>
              <td>Dönüşümleri depolamak ve izlemek için kullanılır.</td>
            </tr>
            <tr>
              <td>test_cookie</td>
              <td>.doubleclick.net</td>
              <td>Pazarlama</td>
              <td>5 dakika</td>
              <td>
                Bu çerez, web sitesi ziyaretçisinin tarayıcısının çerezleri
                destekleyip desteklemediğini belirlemek için DoubleClick
                (Google'a aittir) tarafından ayarlanır.
              </td>
            </tr>
            <tr>
              <td>MUID</td>
              <td>.bing.com</td>
              <td>Pazarlama</td>
              <td>390 gün</td>
              <td>
                Microsoft sitelerini ziyaret eden benzersiz web tarayıcılarını
                tanımlar. Bu çerezler reklam, site analizi ve diğer operasyonel
                amaçlar için kullanılır.
              </td>
            </tr>
            <tr>
              <td>MUID</td>
              <td>.clarity.ms</td>
              <td>Pazarlama</td>
              <td>390 gün</td>
              <td>
                Microsoft sitelerini ziyaret eden benzersiz web tarayıcılarını
                tanımlar. Bu çerezler reklam, site analizi ve diğer operasyonel
                amaçlar için kullanılır.
              </td>
            </tr>
            <tr>
              <td>MUID</td>
              <td>.clarity.ms</td>
              <td>Pazarlama</td>
              <td>390 gün</td>
              <td>
                Microsoft sitelerini ziyaret eden benzersiz web tarayıcılarını
                tanımlar. Bu çerezler reklam, site analizi ve diğer operasyonel
                amaçlar için kullanılır.
              </td>
            </tr>
            <tr>
              <td>ANONCHK</td>
              <td>.clarity.ms</td>
              <td>Pazarlama</td>
              <td>22 saniye</td>
              <td>
                Bing arama motorundaki reklamlardan gelen tıklamaların raporlama
                amacıyla ve kişiselleştirme için doğrulandığından emin olmak
                için bir kullanıcı oturumu için oturum kimliğini depolamak için
                kullanılır
              </td>
            </tr>
            <tr>
              <td>IDE</td>
              <td>.doubleclick.net</td>
              <td>Pazarlama</td>
              <td> 400 gün</td>
              <td>
                Bu çerez, DoubleClick/Google Marketing Suite'teki reklam
                kampanyalarının hedeflenmesi, analiz edilmesi ve optimizasyonu
                için kullanılır
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className="button button-primary  my-3"
          onClick={window.createCookiespoolObject}
        >
          Çerez Ayarları
        </button>
      </div>
    </section>
  );
}

export default Cookies;
