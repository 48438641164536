import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import baseService from "../../../library/network/services/BaseService";
import BlogGrids from "../../app-layouts/BlogGrids";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import HTMLReactParser from "html-react-parser";

import slugify from "slugify";

function BlogPages() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [firstHalfContent, setFirstHalfContent] = useState("");
  const [mainPicture, setMainPicture] = useState("");
  const [secondHalfContent, setSecondHalfContent] = useState("");
  const { slug } = useParams();
  const [imageUrls, setImageUrls] = useState({});
  const [data, setData] = useState([]);

  const parseHtml = (text) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(text, "text/html");
    const plainText = parsedDocument.body.textContent;
    return plainText;
  };
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const blogService = async () => baseService.get("blogs");
  const [blogPosts, setBlogPosts] = useState([]);
  const getBlogs = async () => {
    const res = await blogService();

    const newdata = res.data.filter(
      (item) =>
        slugify(item.title, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }) === slug
    );
    if (!newdata || newdata.length === 0) {
      navigate("/404-not-found");
    }

    setData(newdata[0]);
    setMainPicture(newdata[0].mainImages[0]);
    const firstHalfContent = newdata[0].firstContent;
    const secondHalfContent = newdata[0]?.secondContent;
    setFirstHalfContent(firstHalfContent);
    setSecondHalfContent(secondHalfContent);
    res.data.map((item) => {
      if (item.mainImages) {
        item.mainImages.map((item) => {
          getImageUrl(item);
        });
      }
    });
    setBlogPosts(res.data);
  };
  useEffect(() => {
    setLoading(true);

    getBlogs();

    setLoading(false);
  }, [slug]);

  return (
    <>
      {data && data.mainImages !== undefined && (
        <div>
          {/* <Helmet>
            <meta name="description" content={data?.description} />
            <meta property="og:title" content={data?.title} />
            <meta property="og:type" content="article" />
     
            <meta property="og:image" content={data?.mainImages} />
          </Helmet> */}
          <section
            style={{
              textAlign: "left",
            }}
            className="page-section"
          >
            <div className="container">
              <img
                className="blog-cover"
                src={imageUrls[mainPicture]}
                alt="Blog Cover"
              />
              <div className="title">
                <h1 style={{ maxWidth: "100%" }} className="my-4">
                  {data?.title}
                </h1>
              </div>
              {/* <div className="first-half">
                <p
                  className="blogContent"
                  dangerouslySetInnerHTML={{ __html: firstHalfContent }}
                />
              </div> */}
              <div className="first-half">
                {HTMLReactParser(firstHalfContent)}
              </div>
              <div className="second-half">
                <div
                  className="blog-images-container"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  // }}
                >
                  {data?.mainImages.slice(1).map((image, index) => (
                    <div className="blog-images">
                      <img
                        // style={{ objectFit: "contain" }}
                        src={imageUrls[image]}
                        alt={`Blog Image ${index}`}
                        key={index}
                      />
                    </div>
                  ))}
                </div>

                {secondHalfContent && (
                  <div className="second-half">
                    {HTMLReactParser(secondHalfContent)}
                  </div>
                )}
              </div>
            </div>
            <div className="title">
              <h2 className="my-4">Son Eklenen Yazılar</h2>
            </div>
            <BlogGrids imageUrls={imageUrls} blogPosts={blogPosts?.slice(-3)} />
          </section>
        </div>
      )}
    </>
  );
}

export default BlogPages;
