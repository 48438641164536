import React, { useEffect, useState } from "react";
import Logo from "../../utils/img/online-lostra-logo-white.png";
import CallLogo from "../../utils/img/icons/calling.png";
import FooterBrands from "../../utils/img/Group 34743.svg";

import { Link, useNavigate } from "react-router-dom";
import baseService from "../../library/network/services/BaseService";
import slugify from "slugify";

function Footer({ id }) {
  const blogService = async () => baseService.get("blogs");
  const [blogPosts, setBlogPosts] = useState([]);
  const navigate = useNavigate();
  const getBlogs = async () => {
    const res = await blogService();

    res.data = res.data.slice(0, 5);

    setBlogPosts(res.data);
  };
  useEffect(() => {
    getBlogs();
  }, []);
  const handleBlogClick = (blog) => {
    const urlTitle = convertToUrlFormat(blog.title);
    navigate(`/blog/${urlTitle}`);
  };

  const convertToUrlFormat = (title) => {
    return slugify(title, {
      lower: true,
      remove: /[*+~.()'"!:@?]/g,
    });
  };
  const handleMainMenuClick = (menuName, menuUrl) => {
    const website = "https://onlinelostra.com/";
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "footermenuClicks",
      menuName: menuName,
      menuUrl: website + menuUrl,
    });
  };
  return (
    <>
      <footer>
        <div
          style={{
            padding: "0 18px",
          }}
          className="container"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #676767",
              paddingBottom: "20px",
              marginBottom: "20px",
            }}
            className="footer-main"
          >
            <div
              style={{
                width: "194.77px",
              }}
              onClick={() => handleMainMenuClick("Anasayfa", "")}
              className="footer-logo"
            >
              <a href="https://onlinelostra.com/">
                <img src={Logo} alt="Online Lostra Logo Footer" />
              </a>
            </div>
            <p className="footer-main-information">
              Ayakkabılarınız ilk günkü görünümüne tekrardan kavuşması için
              İstanbul içinden kuryemizle, İstanbul dışından ise Yurtiçi Kargo
              ile gönderim yapabilirsiniz.
            </p>
            <div className="footer-whatsapp">
              <div className="icon my-2">
                <img src={CallLogo} alt="Whatsapp Hattı" />
              </div>
              <div className="info">
                <p>Whatsapp Destek Hattı</p>
                <a href="tel:+908505331070">+90 850 533 1070</a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <h6>Online Lostra</h6>
              <ul
                style={{
                  minHeight: "270px",
                }}
                className="footer-menu"
              >
                <li
                  onClick={() =>
                    handleMainMenuClick("Hakkımızda", "hakkimizda")
                  }
                >
                  <Link to="hakkimizda">Hakkımızda</Link>
                </li>
                <li onClick={() => handleMainMenuClick("İletişim", "iletisim")}>
                  <Link to="iletisim">İletişim</Link>
                </li>
                <li onClick={() => handleMainMenuClick("Kariyer", "kariyer")}>
                  <Link to="kariyer">Kariyer</Link>
                </li>
                <h6>Gizlilik ve Kullanım</h6>
                <ul className="footer-menu">
                  <li
                    onClick={() =>
                      handleMainMenuClick(
                        "Satış Sözleşmesi",
                        "satis-sozlesmesi"
                      )
                    }
                  >
                    <Link to="satis-sozlesmesi"> Satış Sözleşmesi</Link>
                  </li>
                  <li
                    onClick={() =>
                      handleMainMenuClick(
                        "KVKK Aydınlatma Metni",
                        "kvkk-aydinlatma"
                      )
                    }
                  >
                    <Link to="kvkk-aydinlatma">KVKK Aydınlatma Metni</Link>
                  </li>

                  <li
                    onClick={() =>
                      handleMainMenuClick("Çerez Koşulları", "cerez-politikasi")
                    }
                  >
                    <Link to="cerez-politikasi">Çerez Politikasi</Link>
                  </li>
                </ul>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6">
              <h6 className="pd-20"> Ayakkabı Temizleme Lokasyonları</h6>
              <ul
                style={{
                  minHeight: "270px",
                }}
                className="footer-menu borderLeft"
              >
                <li
                  onClick={() =>
                    handleMainMenuClick(
                      "İstanbul",
                      "istanbul-ayakkabi-temizleme"
                    )
                  }
                >
                  <Link to="istanbul-ayakkabi-temizleme-hizmeti">
                    İstanbul Ayakkabı Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick(
                      "Ankara",
                      "ankara-ayakkabi-temizleme-hizmeti"
                    )
                  }
                >
                  <Link to="ankara-ayakkabi-temizleme-hizmeti">
                    Ankara Ayakkabı Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("İzmir", "izmir-ayakkabi-temizleme")
                  }
                >
                  <Link to="izmir-ayakkabi-temizleme-hizmeti">
                    İzmir Ayakkabı Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("Antalya", "antalya-ayakkabi-temizleme")
                  }
                >
                  <Link to="antalya-ayakkabi-temizleme-hizmeti">
                    Antalya Ayakkabı Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("Bursa", "bursa-ayakkabi-temizleme")
                  }
                >
                  <Link to="bursa-ayakkabi-temizleme-hizmeti">
                    Bursa Ayakkabı Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6">
              <h6 className="pd-20"> Çanta Temizleme Lokasyonları</h6>
              <ul
                style={{
                  minHeight: "270px",
                }}
                className="footer-menu borderLeft"
              >
                <li
                  onClick={() =>
                    handleMainMenuClick("İstanbul", "istanbul-canta-temizleme")
                  }
                >
                  <Link to="istanbul-canta-temizleme-hizmeti">
                    İstanbul Çanta Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick(
                      "Ankara",
                      "ankara-canta-temizleme-hizmeti"
                    )
                  }
                >
                  <Link to="ankara-canta-temizleme-hizmeti">
                    Ankara Çanta Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("İzmir", "izmir-canta-temizleme")
                  }
                >
                  <Link to="izmir-canta-temizleme-hizmeti">
                    İzmir Çanta Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("Antalya", "antalya-canta-temizleme")
                  }
                >
                  <Link to="antalya-canta-temizleme-hizmeti">
                    Antalya Çanta Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("Bursa", "bursa-canta-temizleme")
                  }
                >
                  <Link to="bursa-canta-temizleme-hizmeti">
                    Bursa Çanta Temizleme ve Bakım Hizmeti
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6">
              <h6 className="pd-20">Blog yazıları</h6>
              <ul
                style={{
                  minHeight: "270px",
                }}
                className="footer-menu borderLeft"
              >
                {blogPosts.map((post, i) => (
                  <li
                    style={{
                      fontSize: "12px",
                    }}
                    key={i}
                  >
                    <Link to={`/blog/${convertToUrlFormat(post.title)}`}>
                      {post.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="load">
            <div className="twt"></div>
            <div className="fb"></div>
            <div className="ig"></div>
          </div>{" "}
          <div className="footer-bottom">
            <img
              className="footerBrands"
              src={FooterBrands}
              alt="Footer Brands"
            />
            <p>Copyright &copy; Tüm Hakları Saklıdır.</p>

            <div className="footer-icons-container">
              {" "}
              <a href="https://www.facebook.com/onlinelostra" target="_blank">
                <div className="facebook"></div>
              </a>
              <div className="twitter"></div>
              <a href="https://www.instagram.com/onlinelostra/" target="_blank">
                <div className="instagram"></div>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
{
  /* <li
                  onClick={() =>
                    handleMainMenuClick(
                      "Hizmet Bölgeleri",
                      "ayakkabi-temizleme-lokasyonlari"
                    )
                  }
                >
                  <Link to={`/ayakkabi-temizleme-lokasyonlari`}>
                    Hizmet Bölgeleri
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick("Fiyat Listesi", "fiyatlarimiz")
                  }
                >
                  <Link to="fiyatlarimiz">Fiyat Listesi</Link>
                </li>
                <li
                  onClick={() =>
                    handleMainMenuClick(
                      "Sıkça Sorulan Sorular",
                      "sikca-sorulan-sorular"
                    )
                  }
                >
                  <Link to="sikca-sorulan-sorular">SSS</Link>
                </li>
                <li onClick={() => handleMainMenuClick("Blog", "blog")}>
                  <Link to="blog">Blog</Link>
                </li> */
}
{
  /* <div className="col-lg-2 col-md-4">
              <h6>Hakkımızda</h6>
              <ul className="footer-menu">
                <li
                  onClick={() =>
                    handleMainMenuClick("Hakkımızda", "hakkimizda")
                  }
                >
                  <Link to="hakkimizda">Hakkımızda</Link>
                </li>
                <li onClick={() => handleMainMenuClick("İletişim", "iletisim")}>
                  <Link to="iletisim">İletişim</Link>
                </li>
                <li onClick={() => handleMainMenuClick("Kariyer", "kariyer")}>
                  <Link to="kariyer">Kariyer</Link>
                </li>
              </ul>
            </div> */
}
{
  /* <li
                  onClick={() =>
                    handleMainMenuClick(
                      "Teslimat Koşulları",
                      "teslimat-kosullari"
                    )
                  }
                >
                  <Link to="teslimat-kosullari">Teslimat Koşulları</Link>
                </li> */
}
{
  /* <li
                  onClick={() =>
                    handleMainMenuClick(
                      "İptal ve İade Koşulları",
                      "iptal-iade-kosullari"
                    )
                  }
                >
                  <Link to="iptal-iade-kosullari">İptal ve İade Koşulları</Link>
                </li> */
}
{
  /* <li
                  onClick={() =>
                    handleMainMenuClick(
                      "Kullanım Koşulları",
                      "kullanim-kosullari"
                    )
                  }
                >
                  <Link to="kullanim-kosullari">Kullanım Koşulları</Link>
                </li> */
}
{
  /* <li
                  onClick={() => {
                    handleMainMenuClick("Hizmetlerimiz", "ayakkabi-temizleme");
                  }}
                >
                  <Link to="/ayakkabi-temizleme">Hizmetlerimiz</Link>
                </li> */
}
