import { createSlice } from "@reduxjs/toolkit";

const accountSlice = createSlice({
  name: "account",
  initialState: {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
  },
  reducers: {
    registerSuccess: (state, action) => {
      const userData = action.payload;
      state.user = userData;
      state.isLoggedIn = true;
      state.isInitialized = true;
    },
    loginSuccess: (state, action) => {
      const userData = action.payload;
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = userData;
    },
    logoutSuccess: (state) => {
      state.isInitialized = true;
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const { registerSuccess, loginSuccess, logoutSuccess } =
  accountSlice.actions;

export default accountSlice.reducer;
