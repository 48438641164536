import { createContext, useEffect } from "react";
import Cookies from "js-cookie";
import { loginSuccess, logoutSuccess } from "../store/slices/accountSlice";
import axios from "axios";
import {
  getUserById,
  loginUser,
  loginWithGui,
  registerUser,
} from "../library/network/requests/webusers";
import { useDispatch } from "react-redux";
import { storageHelper } from "../utils/helpers/StorageHelper";

export const setSession = (userToken, id) => {
  if ((userToken, id)) {
    storageHelper.setStoreWithEncryption("token", userToken);
    storageHelper.setStoreWithEncryption("userId", id);
    axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
  }
};

export const AccountContext = createContext(null);

export const AccountProvider = ({ children }) => {
  let userId;
  const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
      try {
        let userToken = Cookies.get("jwt");
        let decodedValue = decodeURIComponent(Cookies.get("userId"));
        if (decodedValue) {
          userId = decodedValue?.substring(3, decodedValue.length - 1);
        }
        if (!userToken) {
          userToken = storageHelper.getStoreWithDecryption("token");
          userId = storageHelper.getStoreWithDecryption("userId");
        }
        if (userToken && userId) {
          const response = await getUserById(userId);
          if (response.status === 200) {
            setSession(userToken, userId);
            dispatch(loginSuccess(response.data.email));
          } else {
            dispatch(logoutSuccess());
            setSession(null, null);
          }
        } else {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const guiId = urlParams.get("guiId");
          if (guiId) {
            const value = {
              gui: guiId,
            };
            const response = await loginGui(value);
            if (response.data.status === 200) {
              const { token, userData } = response.data;
              setSession(token, userData.id);
              dispatch(loginSuccess(response.data.userData.email));
            } else {
              dispatch(logoutSuccess());
              setSession(null, null);
            }
          } else {
            dispatch(logoutSuccess());
            localStorage.clear();
            setSession(null, null);
          }
        }
      } catch (err) {
        console.error(err);
        dispatch(logoutSuccess());
        setSession(null, null);
      }
    };

    init();
  }, [dispatch]);

  const login = async (values) => {
    const response = await loginUser(values);
    if (response.data.status === 200) {
      const { token, userData } = response.data;
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "login",
        label: "email",
        userId: userData.id,
        action: "Basarili",
      });
      setSession(token, userData.id);
      dispatch(loginSuccess(response.data.userData.email));
    }
    return response;
  };
  const loginGui = async (values) => {
    const response = await loginWithGui(values);
    if (response.data.status === 200) {
      const { token, userData } = response.data;
      setSession(token, userData.id);
      dispatch(loginSuccess(response.data.userData.email));
    }
    return response;
  };
  const register = async (values) => {
    const response = await registerUser(values);
    return response;
  };

  const logout = () => {
    setSession(null);
    Cookies.remove("jwt");
    Cookies.remove("userId");
    dispatch(logoutSuccess());
  };
  const resetPassword = (email) => console.log(email);

  const updateProfile = () => {};

  return (
    <AccountContext.Provider
      value={{
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        loginGui,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
