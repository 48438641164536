import React from "react";
import SssPanel from "../../app-layouts/SssPanel";
import ContactInfo from "../../app-layouts/ContactInfo";
import ContactForm from "../../app-layouts/ContactForm";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
function Contact() {
  return (
    <>
      <Helmet>
        <title>{`İletişim - Online Lostra`}</title>
        <meta name="description" content="Online Lostra - İletişim" />
      </Helmet>
      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "İletişim", url: "/iletisim" },
            ]}
          />
        </div>
      </section>
      <ContactInfo />
      <ContactForm />
      <SssPanel />
    </>
  );
}

export default Contact;
