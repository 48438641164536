import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import baseService from "../../library/network/services/BaseService";
import { v4 as uuidv4 } from "uuid";
import { storageHelper } from "../../utils/helpers/StorageHelper";

const MyForm = ({ getUser, setNewInvoice, selectedCorporateInvoice }) => {
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const faturaService = async (url, data) => baseService.post(url, data);
  const userId = storageHelper.getStoreWithDecryption("userId");

  const initialValues = {
    taxNumber: "",
    invoiceTitle: "",
    address: "",
    taxOffice: "",
    city: "",
    district: "",
  };
  const updateValues = {
    taxNumber: selectedCorporateInvoice?.taxNumber,
    invoiceTitle: selectedCorporateInvoice?.invoiceTitle,
    address: selectedCorporateInvoice?.address,
    taxOffice: selectedCorporateInvoice?.taxOffice,
    city: selectedCorporateInvoice?.city,
    district: selectedCorporateInvoice?.district,
  };

  const cityService = async () => baseService.get("cities");
  const getCities = async () => {
    const res = await cityService();
    setCityData(res.data);
  };
  const getDistricts = async (il) => {
    const filteredData = cityData.filter((item) => item.city === il);
    setDistrictData(filteredData);
  };
  const handleSubmit = async (values) => {
    try {
      if (updateValues.taxNumber) {
        const model = {
          id: selectedCorporateInvoice.id,
          taxNumber: values.taxNumber,
          invoiceTitle: values.invoiceTitle,
          address: values.address,
          taxOffice: values.taxOffice,
          city: values.city || selectedCorporateInvoice.city,
          district: values.district || selectedCorporateInvoice.district,
        };
        const res = await faturaService(
          "webusers/updateCorporate/" + userId + "/" + selectedCorporateInvoice.id,
          {
            corporateInvoice: model,
          }
        );
        getUser();
        setNewInvoice(false);
      } else {
        const model = {
          id: uuidv4(),
          taxNumber: values.taxNumber,
          invoiceTitle: values.invoiceTitle,
          address: values.address,
          taxOffice: values.taxOffice,
          city: values.city,
          district: values.district,
        };
    
        const res = await faturaService("webusers/addInvoiceCorporate/" + userId, {
          corporateInvoice: model,
        });

        getUser();
        setNewInvoice(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCities();
  }, []);

  return (
    <Formik
      validate={(values) => {
        const errors = {};

        if (!values.taxNumber) {
          errors.taxNumber = "Vergi No alanı gereklidir.";
        }

        if (!values.invoiceTitle) {
          errors.invoiceTitle = "Fatura Ünvanı alanı gereklidir.";
        }

        if (!values.address) {
          errors.address = "Adres alanı gereklidir.";
        }

        if (!values.taxOffice) {
          errors.taxOffice = "Vergi Dairesi alanı gereklidir.";
        }

        if (!values.city) {
          errors.city = "İl Seçiniz alanı gereklidir.";
        }

        if (!values.district) {
          errors.district = "İlçe Seçiniz alanı gereklidir.";
        }

        return errors;
      }}
      initialValues={updateValues.taxNumber ? updateValues : initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="invoice-form">
          <div>
            <Field
              maxLength={11}
              type="text"
              value={values.taxNumber}
              name="taxNumber"
              placeholder="Vergi No"
            />
            <ErrorMessage
              style={{ textAlign: "left" }}
              name="taxNumber"
              component="div"
              className="error-auth"
            />
          </div>

          <div>
            <Field
              type="text"
              value={values.invoiceTitle}
              name="invoiceTitle"
              placeholder="Fatura Ünvanı"
            />
            <ErrorMessage
              name="invoiceTitle"
              component="div"
              className="error-auth"
            />
          </div>

          <div>
            <Field
              type="text"
              value={values.address}
              name="address"
              placeholder="Adres"
            />
            <ErrorMessage
              name="address"
              component="div"
              className="error-auth"
            />
          </div>

          <div>
            <Field
              type="text"
              name="taxOffice"
              value={values.taxOffice}
              placeholder="Vergi Dairesi"
            />
            <ErrorMessage
              name="taxOffice"
              component="div"
              className="error-auth"
            />
          </div>
          <div className="d-flex">
            <Field
              as="select"
              name="city"
              onChange={(e) => {
                const city = e.target.value;
                const filteredData = cityData.filter(
                  (item) => item.city === city
                );
                setFieldValue("city", filteredData[0].city || "");
                getDistricts(e.target.value);
              }}
            >
              <option style={{ display: "none" }} value="">
                İl Seçiniz
              </option>
              {cityData.map((item) => (
                <option key={item._id} value={item.city}>
                  {item.city}
                </option>
              ))}
            </Field>
            <Field
              style={{
                marginLeft: "10px",
              }}
              disabled={districtData.length === 0}
              as="select"
              onChange={(e) => {
                const district = e.target.value;
                const filteredData = districtData[0].district.filter(
                  (item) => item.name === district
                );

                setFieldValue("district", filteredData[0]?.name || "");
              }}
              name="district"
            >
              <option style={{ display: "none" }} value="">
                İlçe Seçiniz
              </option>
              {districtData &&
                districtData[0]?.district.map((item) => (
                  <option key={item._id} value={item.district}>
                    {item.name}
                  </option>
                ))}
            </Field>
          </div>
          <ErrorMessage name="city" component="div" className="error-auth" />
          <ErrorMessage
            name="district"
            component="div"
            className="error-auth"
          />
          <button className="button button-primary" type="submit">
            Kaydet
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default MyForm;
