import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllSss } from "../../library/network/requests/sss";
import Logo from "../../utils/img/icons/chat02.svg";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";
function SssPanel({ pageType = "general", category = "general" }) {
  const [isClicked, setIsClicked] = useState("");
  const [sss, setSss] = useState([]);
  const getSss = async () => {
    try {
      const res = await getAllSss();
      const filteredSss = res.data?.filter((sss) => sss.title.length < 55);
      const filterPages = filteredSss?.filter(
        (sss) => sss.pageType === pageType && sss.category === category
      );

      setSss(filterPages?.slice(0, 3));
    } catch (error) {
      console.error("Error fetching and filtering Sss:", error);
      setSss([]);
    }
  };

  const handleClick = (item) => {
    if (isClicked === item) {
      setIsClicked("");
    } else {
      setIsClicked(item);
    }
  };

  useEffect(() => {
    getSss();
  }, []);
  return (
    <>
      <div className="container faq-container container-fluid bg-primary">
        <div className="row main-container">
          <div className=" col-md-6 sss-slider ">
            <div>
              <img src={Logo} className="chat-logo" alt="Your logo" />
              <h2
                style={{
                  fontSize: "36px",
                }}
                className="text-white"
              >
                Sıkça Sorulan <br /> Sorular
              </h2>
              <p className="text-white">
                Ayakkabı temizleme ve kuru temizleme hakkında cevap <br />{" "}
                bulamadığın sorular burada
              </p>
            </div>
            <div className="button-cont">
              {" "}
              <Link
                onClick={() => {
                  handleButtonClicks(
                    "/sikca-sorulan-sorular",
                    "Sıkça Sorulan Sorular",
                    "Anasayfa"
                  );
                }}
                to="/sikca-sorulan-sorular"
              >
                <button
                  style={{ lineHeight: "1.5" }}
                  className="btn button-sss button button-secondary mr-2"
                >
                  Tüm Sorular
                </button>
              </Link>
              <Link
                onClick={() => {
                  handleButtonClicks("/iletisim", "İletişim", "Anasayfa");
                }}
                to="/iletisim"
              >
                <button
                  style={{ lineHeight: "1.5" }}
                  className="btn button-sss button button-ghost text-white"
                >
                  İletişime geç
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sss-slider">
              {sss &&
                sss.map((item) => {
                  return (
                    <div
                      id={item.id}
                      key={item.id}
                      onClick={() => handleClick(item.id)}
                      className={
                        isClicked === item.id
                          ? `faq-box active ${item.id}`
                          : "faq-box"
                      }
                    >
                      <h3>{item?.title}</h3>
                      <p
                        className="blogContent"
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      />
                      <span className="activate"></span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SssPanel;
