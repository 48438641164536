import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import baseService from "../../../library/network/services/BaseService";
import { storageHelper } from "../../../utils/helpers/StorageHelper";
import SuccessInfo from "../../app-layouts/SuccessInfo";

function RegisteredUsers({
  cityData,
  setMessage,
  setIsClicked,
  districtData,
  handleNewAddressChange,
  getAddress,
  mahalle,
  getDistricts,
  getNeighborhood,
  setSelectedNeighborhood,
  setCaptchaToken,
  recaptchaRef,
  setError,
}) {
  const userId = storageHelper.getStoreWithDecryption("userId");
  const addEcommerceService = async (userId, values) =>
    baseService.post("webusers/ecommerceaddress" + "/" + userId, values);
  return (
    <Formik
      initialValues={{
        title: "",
        address: "",
        city: "",
        district: "",
        neighborhood: "",
      }}
      validate={(values) => {
        const errors = {};

        if (!values.city) errors.city = "İl seçiniz.";
        if (!values.district) errors.district = "İlçe seçiniz";
        if (!values.neighborhood) errors.neighborhood = "Mahalle seçiniz";
        if (!values.address) errors.address = "Adresinizi giriniz.";
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await addEcommerceService(userId, values);
          setMessage(true);
          if (response.status === 200) {
            setTimeout(() => {
              setMessage(false);
            }, 1500);
            setIsClicked((prevstate) => !prevstate);
            getAddress();
            handleNewAddressChange(response.data);
          }
        } catch (error) {
          setError("Bir daha deneyiniz.");
          console.log(error);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="register-form">
          <div className="form-area">
            <label htmlFor="title">Adres Başlığı</label>
            <Field type="text" name="title" placeholder="Adres Başlığı" />
            <ErrorMessage name="title" component="div" className="error-auth" />
          </div>
          <div className="form-area">
            <label htmlFor="address">Adres </label>
            <Field type="text" name="address" placeholder="Adres" />
            <ErrorMessage
              name="address"
              component="div"
              className="error-auth"
            />
          </div>
          <div className="form-area">
            <label htmlFor="city" style={{ display: "block" }}>
              İl
            </label>
            <Field
              as="select"
              onChange={(e) => {
                setFieldValue("city", e.target.value);
                getDistricts(e.target.value);
              }}
              name="city"
              value={values.city}
              style={{ display: "block" }}
            >
              <option style={{ display: "none" }} value="">
                İl seçiniz
              </option>
              {cityData &&
                cityData.map((city) => (
                  <option label={city.city} key={city._id} value={city.city}>
                    {city.name}
                  </option>
                ))}
            </Field>
            <ErrorMessage name="city" component="div" className="error-auth" />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="form-area"
          >
            <div style={{ flex: "1" }}>
              <label htmlFor="district" style={{ display: "block" }}>
                İlçe
              </label>
              <Field
                as="select"
                onChange={async (e) => {
                  const selectedDistrict = districtData?.districts?.filter(
                    (item) => item.name === e.target.value
                  );
                  setFieldValue("district", selectedDistrict[0]?.name || "");
                  await getNeighborhood(selectedDistrict[0]?.id || "");
                }}
                name="district"
                value={values.district}
                style={{ display: "block" }}
              >
                <option style={{ display: "none" }} value="">
                  İlçe seçiniz
                </option>
                {districtData &&
                  districtData?.districts?.map((item) => (
                    <option label={item.name} key={item.id} value={item.name}>
                      {item.name} hi
                    </option>
                  ))}
              </Field>
              <ErrorMessage
                name="district"
                component="div"
                className="error-auth"
              />
            </div>

            <div style={{ flex: "1", marginLeft: "10px" }}>
              <label htmlFor="neighborhood" style={{ display: "block" }}>
                Mahalle
              </label>
              <Field
                as="select"
                onChange={(e) => {
                  const selectedObject = mahalle?.neighborhoods.find(
                    (item) => item.name === e.target.value
                  );
                  setSelectedNeighborhood(selectedObject || {});
                  setFieldValue("neighborhood", selectedObject?.name || "");
                }}
                name="neighborhood"
                value={values.neighborhood}
                style={{ display: "block" }}
              >
                <option style={{ display: "none" }} value="">
                  Mahalle seçiniz
                </option>
                {mahalle &&
                  mahalle?.neighborhoods?.map((item) => (
                    <option label={item.name} key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
              </Field>
              <ErrorMessage
                name="neighborhood"
                component="div"
                className="error-auth"
              />
            </div>
          </div>
          <div className="form-area"></div>
          <div className="form-area contact-button-container">
            <button
              type="submit"
              disabled={
                isSubmitting ||
                !values.title ||
                !values.address ||
                !values.city ||
                !values.district
              }
              className="button button-primary w-100"
            >
              Kaydet
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RegisteredUsers;
