import React from "react";
import { Link, Outlet } from "react-router-dom";
import AuthViews from "../../views/AuthViews";
import LeftPanel from "../../components/auth-layouts/components/LeftPanel";
import icon from "../../utils/img/Logo Icon White.svg";
import logo from "../../utils/img/Logo Text Vertical.svg";

function AuthLayout() {
  return (
    <section className="register-section">
      <div className="container-fluid h-100 g-0">
        <div className="row h-100 g-0">
          <div className=" web col-xl-6 g-0">
            <LeftPanel />
          </div>
          <div className="col-xl-6">
            <div className="mobile  mt-5">
              <Link
              className="auth-logo-img"
                to="/"
              >
                <img
                  src={icon}
                  alt="Online Lostra Logo"
                />
                <img src={logo} alt="Online Lostra Logo" />
              </Link>
            </div>
            <div className="register-right">
              <Outlet />
              <AuthViews />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AuthLayout;
