import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetailsById } from "../../../library/network/requests/order-offer-details";
import Loader from "../../app-layouts/Loader";
import moment from "moment";
import "moment/locale/tr";
import AddressForm from "../../app-layouts/AddressForm";
import ArrowLeft from "../../../utils/img/icons/share.svg";
import cancel from "../../../utils/img/icons/cancel-gray.svg";
import { Helmet } from "react-helmet-async";
import OrderPickupRange from "../../app-layouts/OrderPickupRange";
import { getUserAddress } from "../../../library/network/requests/webusers";
import baseService from "../../../library/network/services/BaseService";
import {
  userAddressUpdate,
  userDateUpdate,
} from "../../../library/network/requests/address";
import { getOrdersById } from "../../../library/network/requests/orders";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import { formatDate } from "../../../utils/helpers/FormatDate";

function NonRegisteredOrder() {
  const params = useParams();

  const [ecommerceOrders, setEcommerceOrders] = useState([]);
  const [change, setChange] = useState("order"); // [change, setChange
  const { id } = params;
  const [imageUrls, setImageUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [address, setAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressObj, setSelectedAddressObj] = useState(null);
  const [pickupDate, setPickupDate] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [pickupTimeRange, setPickupTimeRange] = useState(null);
  const [hours, setHours] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const deliveryService = async () => baseService.get("deliveryhours");
  const navigate = useNavigate();
  const ecommerceOrderService = async (id) =>
    baseService.get("ecommerceorders/nonregistered/" + id);

  function getStatusTranslation(statusName) {
    switch (statusName) {
      case "active":
        return "Sipariş oluşturuldu";

      case "delivering":
        return "Yolda";
      case "done":
        return "Teslim edildi";
      case "cancelled":
        return "İptal edildi";
      default:
        return statusName;
    }
  }

  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  }

  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getEcommerceOrders = async () => {
    try {
      setLoading(true);
      const res = await ecommerceOrderService(id);
      setEcommerceOrders(res?.data);
      if (res?.data) {
        res?.data?.products.forEach((product) => {
          getImageUrl(product.productId.mainImage);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const colorTranslation = (color) => {
    switch (color) {
      case "black":
        return "Siyah";
      case "white":
        return "Beyaz";
      case "red":
        return "Kırmızı";
      case "blue":
        return "Mavi";
      case "green":
        return "Yeşil";
      case "yellow":
        return "Sarı";
      case "pink":
        return "Pembe";
      case "purple":
        return "Mor";
      case "brown":
        return "Kahverengi";
      case "gray":
        return "Gri";
      case "orange":
        return "Turuncu";
      case "beige":
        return "Bej";
      case "cream":
        return "Krem";
      case "gold":
        return "Altın";
      case "silver":
        return "Gümüş";
      case "bronze":
        return "Bronz";
      case "copper":
        return "Bakır";
      case "transparent":
        return "Şeffaf";
      default:
        return color;
    }
  };
  function isPickupDateWithin24Hours(pickupDate) {
    const currentDate = new Date();

    const timeDifference = pickupDate.getTime() - currentDate.getTime();

    const hoursDifference = timeDifference / (1000 * 60 * 60);
    const isWithin24Hours = hoursDifference < 24;

    return isWithin24Hours;
  }

  useEffect(() => {
    getEcommerceOrders();
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Sipariş Detayı</title>
        <meta name="description" content="Sipariş Detayı" />
      </Helmet>
      <section className="page-section">
        {" "}
        <div className="container">
          <div className="row">
            {ecommerceOrders?._id && (
              <>
                {" "}
                <div className="col-xl-7">
                  <div className="section-title flex-container ">
                    <h2
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      Sipariş Detay
                    </h2>
                  </div>

                  <div
                    style={{
                      margin: "20px 0",
                      textAlign: "start",
                    }}
                  >
                    <p>
                      Sipariş No :{" "}
                      <span className="font-weight-bold">
                        {ecommerceOrders &&
                          ecommerceOrders?.eCommerceOrderNumber}
                      </span>
                    </p>
                    <h3 className="my-4">Sipariş Durumu</h3>
                    <p
                      style={{
                        fontWeight: "600",
                        width: "fit-content",
                      }}
                      className="order-information"
                    >
                      {getStatusTranslation(
                        ecommerceOrders &&
                          ecommerceOrders?.status[
                            ecommerceOrders?.status?.length - 1
                          ]?.statusName
                      )}
                    </p>
                    <div
                      style={{
                        width: "100%",
                      }}
                      className="favorites"
                    >
                      <div className="favorites-header">
                        <p>Ürünlerim</p>
                      </div>

                      <table className="favorites-table">
                        <thead>
                          <tr>
                            <th>Ürün</th>
                            <th>Adet</th>
                            <th>Beden</th>
                            <th>Renk</th>
                            <th>Fiyat</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ecommerceOrders.products.map((item) => (
                            <tr key={item._id}>
                              <td>
                                {" "}
                                <div className="cart-product" key={item?._id}>
                                  <img
                                    src={imageUrls[item?.productId?.mainImage]}
                                    alt={item?.productId?.mainImage}
                                  />

                                  <p className="fw-normal web">
                                    {item?.productId?.name}
                                  </p>
                                  <p className="fw-normal mobile">
                                    {item?.productId?.name?.length > 15
                                      ? item?.productId?.name.substring(0, 15) +
                                        "..."
                                      : item?.productId?.name}
                                  </p>
                                </div>
                              </td>
                              <td>{item?.quantity}</td>
                              <td>{item?.size}</td>
                              <td>
                                {colorTranslation(item?.color?.toLowerCase())}
                              </td>
                              <td className="table-price">
                                {item.price.toFixed(2) * item?.quantity}₺
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                  <form>
                    <div
                      style={{
                        textAlign: "start",
                      }}
                      className="order-box"
                    >
                      <h3 className="mb-4">Sipariş Özeti</h3>{" "}
                      {/* {!isEditable && (
                    <span
                      onClick={() => {
                        setChange("update");
                      }}
                      disabled={isEditable}
                      style={{
                        color: "rgb(62, 87, 254)",
                        marginBottom: "0.5rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Siparişi düzenle
                    </span>
                  )} */}
                      <table>
                        {/* <tbody>
                      {order &&
                        order[0]?.orderId?.status[
                          order[0]?.orderId?.status.length - 1
                        ]?.statusName !== "cancelled" && (
                          <tr>
                            <td>Teslimat tarihi: </td>
                            {order &&
                            order[0]?.orderId?.status[
                              order[0]?.orderId?.status.length - 1
                            ]?.statusName === "done" ? (
                              <td>
                                {formatDate(
                                  order[0]?.orderId?.status[
                                    order[0]?.orderId?.status.length - 1
                                  ].date
                                )}
                              </td>
                            ) : (
                              <td>5-15 iş günü.</td>
                            )}
                          </tr>
                        )}
                    </tbody> */}
                      </table>
                      <div className="order-field">
                        <h5>Sipariş Tarihi</h5>
                        <p>{formatDate(ecommerceOrders.OrderDate)}</p>
                        <h5>Teslim Adresi:</h5>
                        <p>
                          {ecommerceOrders && (
                            <>{ecommerceOrders?.address[0]?.address}</>
                          )}
                        </p>
                        <h5>Toplam tutar:</h5>
                        <p>
                          {ecommerceOrders && (
                            <>{ecommerceOrders?.totalPrice?.toFixed(2)}₺</>
                          )}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default NonRegisteredOrder;
