export const turkeyCitiesAndIstanbulDistricts = [
  { urlFriendly: "adana", original: "Adana" },
  { urlFriendly: "adapazari", original: "Adapazarı" },
  { urlFriendly: "adiyaman", original: "Adıyaman" },
  { urlFriendly: "afyonkarahisar", original: "Afyonkarahisar" },
  { urlFriendly: "agri", original: "Ağrı" },
  { urlFriendly: "amasya", original: "Amasya" },
  { urlFriendly: "ankara", original: "Ankara" },
  { urlFriendly: "antalya", original: "Antalya" },
  { urlFriendly: "ardahan", original: "Ardahan" },
  { urlFriendly: "artvin", original: "Artvin" },
  { urlFriendly: "aydin", original: "Aydın" },
  { urlFriendly: "balikesir", original: "Balıkesir" },
  { urlFriendly: "bartin", original: "Bartın" },
  { urlFriendly: "batman", original: "Batman" },
  { urlFriendly: "bayburt", original: "Bayburt" },
  { urlFriendly: "bilecik", original: "Bilecik" },
  { urlFriendly: "bingol", original: "Bingöl" },
  { urlFriendly: "bitlis", original: "Bitlis" },
  { urlFriendly: "bolu", original: "Bolu" },
  { urlFriendly: "burdur", original: "Burdur" },
  { urlFriendly: "bursa", original: "Bursa" },
  { urlFriendly: "canakkale", original: "Çanakkale" },
  { urlFriendly: "cankiri", original: "Çankırı" },
  { urlFriendly: "corum", original: "Çorum" },
  { urlFriendly: "denizli", original: "Denizli" },
  { urlFriendly: "diyarbakir", original: "Diyarbakır" },
  { urlFriendly: "duzce", original: "Düzce" },
  { urlFriendly: "edirne", original: "Edirne" },
  { urlFriendly: "elazig", original: "Elazığ" },
  { urlFriendly: "erzincan", original: "Erzincan" },
  { urlFriendly: "erzurum", original: "Erzurum" },
  { urlFriendly: "eskisehir", original: "Eskişehir" },
  { urlFriendly: "gaziantep", original: "Gaziantep" },
  { urlFriendly: "giresun", original: "Giresun" },
  { urlFriendly: "gumushane", original: "Gümüşhane" },
  { urlFriendly: "hakkari", original: "Hakkari" },
  { urlFriendly: "hatay", original: "Hatay" },
  { urlFriendly: "igdir", original: "Iğdır" },
  { urlFriendly: "isparta", original: "Isparta" },
  { urlFriendly: "istanbul", original: "İstanbul" },
  { urlFriendly: "i̇stanbul", original: "İstanbul" },
  { urlFriendly: "izmir", original: "İzmir" },
  { urlFriendly: "kahramanmaras", original: "Kahramanmaraş" },
  { urlFriendly: "karabuk", original: "Karabük" },
  { urlFriendly: "karaman", original: "Karaman" },
  { urlFriendly: "kars", original: "Kars" },
  { urlFriendly: "kastamonu", original: "Kastamonu" },
  { urlFriendly: "kayseri", original: "Kayseri" },
  { urlFriendly: "kilis", original: "Kilis" },
  { urlFriendly: "kirikkale", original: "Kırıkkale" },
  { urlFriendly: "kirklareli", original: "Kırklareli" },
  { urlFriendly: "kirsehir", original: "Kırşehir" },
  { urlFriendly: "kocaeli", original: "Kocaeli" },
  { urlFriendly: "konya", original: "Konya" },
  { urlFriendly: "kutahya", original: "Kütahya" },
  { urlFriendly: "malatya", original: "Malatya" },
  { urlFriendly: "manisa", original: "Manisa" },
  { urlFriendly: "mardin", original: "Mardin" },
  { urlFriendly: "mersin", original: "Mersin" },
  { urlFriendly: "mugla", original: "Muğla" },
  { urlFriendly: "mus", original: "Muş" },
  { urlFriendly: "nevsehir", original: "Nevşehir" },
  { urlFriendly: "nigde", original: "Niğde" },
  { urlFriendly: "ordu", original: "Ordu" },
  { urlFriendly: "osmaniye", original: "Osmaniye" },
  { urlFriendly: "rize", original: "Rize" },
  { urlFriendly: "sakarya", original: "Sakarya" },
  { urlFriendly: "samsun", original: "Samsun" },
  { urlFriendly: "siirt", original: "Siirt" },
  { urlFriendly: "sinop", original: "Sinop" },
  { urlFriendly: "sivas", original: "Sivas" },
  { urlFriendly: "sanliurfa", original: "Şanlıurfa" },
  { urlFriendly: "sirnak", original: "Şırnak" },
  { urlFriendly: "tekirdag", original: "Tekirdağ" },
  { urlFriendly: "tokat", original: "Tokat" },
  { urlFriendly: "trabzon", original: "Trabzon" },
  { urlFriendly: "tunceli", original: "Tunceli" },
  { urlFriendly: "usak", original: "Uşak" },
  { urlFriendly: "van", original: "Van" },
  { urlFriendly: "yalova", original: "Yalova" },
  { urlFriendly: "yozgat", original: "Yozgat" },
  { urlFriendly: "zonguldak", original: "Zonguldak" },
  { urlFriendly: "adalar", original: "Adalar" },
  { urlFriendly: "arnavutkoy", original: "Arnavutköy" },
  { urlFriendly: "atasehir", original: "Ataşehir" },
  { urlFriendly: "avcilar", original: "Avcılar" },
  { urlFriendly: "bagcilar", original: "Bağcılar" },
  { urlFriendly: "bahcelievler", original: "Bahçelievler" },
  { urlFriendly: "aksaray", original: "Aksaray" },
  { urlFriendly: "bakirkoy", original: "Bakırköy" },
  { urlFriendly: "basaksehir", original: "Başakşehir" },
  { urlFriendly: "bayrampasa", original: "Bayrampaşa" },
  { urlFriendly: "besiktas", original: "Beşiktaş" },
  { urlFriendly: "beykoz", original: "Beykoz" },
  { urlFriendly: "beylikduzu", original: "Beylikdüzü" },
  { urlFriendly: "beyoglu", original: "Beyoğlu" },
  { urlFriendly: "buyukcekmece", original: "Büyükçekmece" },
  { urlFriendly: "catalca", original: "Çatalca" },
  { urlFriendly: "cekmekoy", original: "Çekmeköy" },
  { urlFriendly: "esenler", original: "Esenler" },
  { urlFriendly: "esenyurt", original: "Esenyurt" },
  { urlFriendly: "eyupsultan", original: "Eyüpsultan" },
  { urlFriendly: "fatih", original: "Fatih" },
  { urlFriendly: "gaziosmanpasa", original: "Gaziosmanpaşa" },
  { urlFriendly: "gungoren", original: "Güngören" },
  { urlFriendly: "kadikoy", original: "Kadıköy" },
  { urlFriendly: "kagithane", original: "Kağıthane" },
  { urlFriendly: "kartal", original: "Kartal" },
  { urlFriendly: "kucukcekmece", original: "Küçükçekmece" },
  { urlFriendly: "maltepe", original: "Maltepe" },
  { urlFriendly: "pendik", original: "Pendik" },
  { urlFriendly: "sancaktepe", original: "Sancaktepe" },
  { urlFriendly: "sariyer", original: "Sarıyer" },
  { urlFriendly: "silivri", original: "Silivri" },
  { urlFriendly: "sile", original: "Şile" },
  { urlFriendly: "sisli", original: "Şişli" },
  { urlFriendly: "sultanbeyli", original: "Sultanbeyli" },
  { urlFriendly: "sultangazi", original: "Sultangazi" },
  { urlFriendly: "tuzla", original: "Tuzla" },
  { urlFriendly: "umraniye", original: "Ümraniye" },
  { urlFriendly: "uskudar", original: "Üsküdar" },
  { urlFriendly: "zeytinburnu", original: "Zeytinburnu" },
];
