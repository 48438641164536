import React from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";

function OrderPickupRange({
  hours,
  pickupDate,
  index,
  setPickupDate,
  pickupTimeRange,
  setPickupTimeRange,
  valid,
}) {
  return (
    <>
      <h3 className="mb-4">Alım Tarihi ve Saati</h3>
      <div className="mb-4">
        {" "}
        <p className="order-information">
          Kuryemiz ürünlerinizi teslim almadan önce arayarak sizleri
          bilgilendirecektir.
        </p>
      </div>
      <div className="row order-date-picker ">
        <div className={` my-4 ${index === 0 ? "col-md-12" : "col-md-6"}`}>
          <h5 className="mb-4">Alım Tarihi</h5>
          <div>
            <DateTime
              closeOnSelect={true}
              locale="tr"
              className="date-picker"
              isValidDate={valid}
              inputProps={{ placeholder: "--Tarih Seçiniz--", readOnly: true }}
              value={pickupDate}
              // style={{
              //   flex: "1",
              //   backgroundColor: "#f1f1f1",

              // }}
              onChange={(date) => {
                if (date && typeof date.format === "function") {
                  const formattedDate = date.format("DD/MM/YYYY");
                  const formatDate = date.format("DD MMMM YYYY");
                  setPickupDate(formattedDate);
                }
              }}
              dateFormat="DD MMMM YYYY"
              timeFormat={false}
            />
          </div>
        </div>
        <div className={` my-4 ${index === 0 ? "col-md-12" : "col-md-6"}`}>
          <h5 className="mb-4">Alım Saati</h5>

          <div className="input-select">
            <select
              // style={{ flex: "1" }}
              value={pickupTimeRange}
              placeholder="Saat seçiniz."
              style={{
                flex: "1",
                padding: "12px 28px",
                height: "56px",
              }}
              onChange={(event) => setPickupTimeRange(event.target.value)}
            >
              <option value="">--Saat seçiniz--</option>
              {hours &&
                hours.map((time, index) => {
                  return (
                    <option
                      key={index}
                      value={`${time.startTime}-${time.endTime}`}
                    >{`${time.startTime}-${time.endTime}`}</option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderPickupRange;
