import React from "react";
import { Routes, Route } from "react-router-dom";
import Addresses from "../components/app-layouts/Addresses";
import CreditCards from "../components/app-layouts/CreditCards";
import Orders from "../components/pages/Orders/Orders";
import Profile from "../components/pages/Profile/Profile";
import MandatoryProfile from "../components/pages/MandatoryProfile";

export const AccountViews = () => {
  return (
    <Routes>
      <Route path={"/*"} element={<Profile />} />
      <Route path={"/profil"} element={<MandatoryProfile />} />
      <Route path={"/siparis"} element={<Orders />} />
      <Route path={"/adres"} element={<Addresses />} />
      <Route path={"/kredi-kart"} element={<CreditCards />} />
      {/* <Route path={"/"} element={<Account />} /> */}
    </Routes>
  );
};

export default AccountViews;
