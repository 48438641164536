import React from "react";

function ServiceForm({ services, selectedServices, handleServiceChange }) {
  return (
    <>
      <div className="load">
        <span className="utu"></span>
        <span className="makine"></span>
        <span className="aski"></span>
        <span className="cantatemizleme"></span>
        <span className="ayakkabi"></span>
      </div>

      {/* <div className="web"> */}
      {services.map((service) => {
        return (
          <label key={service.id}>
            <input
              name="services"
              value={service.id}
              checked={selectedServices.includes(service.id)}
              onChange={handleServiceChange}
              type="checkbox"
              id={service.icon}
            />
            <div className="card">
              <span className="card-title">
                <h2>
                  {service?.name === "Çanta Temizleme" ? (
                    <>
                      Çanta <br /> Tamir&Bakım
                    </>
                  ) : service?.name === "Ayakkabı Temizleme" ? (
                    <>
                      Ayakkabı <br /> Tamir&Bakım
                    </>
                  ) : (
                    <>{service?.name}</>
                  )}
                </h2>
              </span>
              <span className={service?.icon}></span>
            </div>
          </label>
        );
      })}
      {/* </div> */}
    </>
  );
}

export default ServiceForm;
