import React from "react";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function ETK() {
  return (
    <>
      {" "}
      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "ETK metni", url: "/etk" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Online Lostra</h4>
            <h1>ETK METNİ </h1>
          </div>
          <div className="policy" style={{ textAlign: "left", color: "black" }}>
            <p>
              Tarafınıza ticari elektronik ileti gönderilmesi amacıyla 6563
              Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve 15
              Temmuz 2015 tarihli Ticari İletişim ve Ticari Elektronik İletiler
              Hakkında Yönetmelik ile 04 Ocak 2020 Tarihli Ticari̇ İletişim ve
              Ticari Elektronik İletiler Hakkında Yönetmelikte Değişiklik
              Yapılmasına Dair Yönetmelik kapsamında hizmet sağlayıcı sıfatıyla
              ticari elektronik iletilerin <strong>ONLİNE LOSTRA</strong>{" "}
              tarafından tarafınıza gönderilmesi için vereceğiniz onayınıza
              istinaden iletişim verileriniz (cep telefonu numarası, e-posta
              adresi), tarafınızca ticari elektronik ileti gönderimi reddetme
              hakkınızı kullanıncaya kadar devam edecektir. Ticari elektronik
              ileti gönderilmesi için vereceğiniz onay 6698 sayılı Kişisel
              Verilerin Korunması Kanunu (“KVKK”) 5’inci maddesinin 1’inci
              fıkrası kapsamında istediğiniz zaman ve herhangi bir sınırlamaya
              gerek olmaksızın geri alabileceğiniz açık rızanız olarak kabul
              edilmektedir. Bunun yanında iletişim bilgileriniz, yetkili kişi,
              kurum ve kuruluşlara bilgi verilmesi amacıyla Kanunun 5’inci
              maddesinin 2’nci fıkrasının (a) bendinde yer alan “kanunlarda
              açıkça öngörülmesi” hukuki sebebine dayanarak İleti Yönetim
              Sistemine aktarılacaktır.
            </p>
            <p>
              <strong>ONLİNE LOSTRA</strong> tarafından KVKK kapsamında
              hazırlanmış olan AYDINLATMA METNİ’nin tamamını ve yukarıdaki
              bilgilendirmeyi okudum, anladım ve hangi kişisel verilerimi ne
              şekilde işlediği ve açık rızamı dilediğim zaman herhangi bir
              sınırlamaya tabi olmaksızın geri alabileceğim hakkında bilgi
              sahibi oldum.{" "}
            </p>
            <p>
              {" "}
              <strong>ONLİNE LOSTRA</strong> ’ ya vermiş olduğum GSM numaram
              üzerinden tarafıma kısa mesaj gönderimi ile telefon araması
              yapılmasına ve mail adresime e-posta gönderilmesine ticari
              elektronik ileti onayı şeklinde onay veriyorum.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default ETK;
