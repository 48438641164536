import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Comments from "../../app-layouts/Comments";
import SssPanel from "../../app-layouts/SssPanel";
import Prices from "../Prices/Prices";
import Loader from "../../app-layouts/Loader";
import Process from "../../app-layouts/Process";
import ServicesMainPanel from "../../app-layouts/ServicesMainPanel";
import {
  getAllServiceCurrentCategories,
  getByIdServiceCategories,
} from "../../../library/network/requests/serviceCategory";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import slugify from "slugify";

function Sneakers() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const { slug } = params;
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getServices = async () => {
    setLoading(true);
    const res = await getAllServiceCurrentCategories();
    const selectedService = res?.data?.find(
      (item) =>
        slugify(item?.name, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }) === slug
    );
    getServiceCategory(selectedService?.id);
    setLoading(false);
  };

  const getServiceCategory = async (id) => {
    setLoading(true);
    const res = await getByIdServiceCategories(id);
    setService(res.data);
    if (res.data.mainImage) {
      getImageUrl(res.data.mainImage);
    }

    setLoading(false);
  };
  useEffect(() => {
    getServices();
  }, [params]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{`${service?.name ?? ""} Hizmeti - Online Lostra`}</title>
        <title>
          {`${service?.name ?? ""} Ve Bakım Hizmeti - Online Lostra`}
        </title>
        <meta
          name="description"
          content={
            service?.name === "Ayakkabı Temizleme"
              ? "Ayakkabı temizleme ve bakımı için Online Lostra'dan randevu alın. Tüm ayakkabı türleri için profesyonel hizmet. Güvenilir, hızlı ve mükemmel sonuçlar!"
              : service?.name === "Çanta Temizleme"
              ? "Çanta temizleme ve bakımı için Online Lostra'dan randevu alın. Detaylı temizlik ve bakım sonrası çantanız size özel olarak geri gönderilecek."
              : "Default Description"
          }
        />
      </Helmet>
      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: `${service?.name ?? ""} Hizmeti`, url: `/${slug}` },
            ]}
          />
        </div>
      </section>
      {service.name && (
        <ServicesMainPanel
          service={service}
          imageUrls={imageUrls}
          index={1}
          title={"Hİzmetlerİmİz"}
        />
      )}
      <Process />
      <Prices />
      <Comments />
      <SssPanel pageType="service" category={slug.split("-")[0]} />
    </>
  );
}

export default Sneakers;
