import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import baseService from "../../library/network/services/BaseService";
import handleButtonClicks from "../../utils/helpers/handleButtonClicks";

function VerifyPassword() {
  const recaptchaRef = useRef(null);
  const { state } = useLocation();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState("");
  const forgotPasswordService = async (values) =>
    baseService.post("webUsers/forgotPassword", values);
  const tokenService = async () =>
    baseService.post("verify-token", { token: captchaToken });
  const webUserService = async (data) =>
    baseService.post("webUsers/checkCode", data);
  const [otp, setOtp] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (otp.length < 5) {
        setError("Doğrulama kodu 5 haneli olmalıdır");
      } else {
        const res = await tokenService();
        if (res.data === "OK") {
          const response = await webUserService({
            confirmationCode: otp,
            email: state?.email,
          });
          if (response.status === 200) {
            setTimeout(() => {
              setSuccess(false);
              navigate("/auth/sifre-yenile", {
                state: { email: state.email },
              });
            }, 1000);
          } else {
            setError("Doğrulama kodu yanlış");
          }
        } else {
          setError("Testi bir daha yapınız");
        }
      }
    } catch (error) {
      setError("Testi bir daha yapınız");
      console.log(error);
    }
  };
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds
  const [isCounting, setIsCounting] = useState(true);

  useEffect(() => {
    let timeout;

    if (isCounting && countdown > 0) {
      timeout = setTimeout(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isCounting, countdown]);

  const handleResendCode = async () => {
    const res = await forgotPasswordService({ email: state.email });

    setIsCounting(true);
    setCountdown(120);
  };

  return (
    <>
      <Helmet>
        <title>{`Şifre Doğrulama - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      <div
        style={{ width: "100%" }}
        className="register-content forgot-password"
      >
        <h1>Doğrulama Kodu</h1>
        <small
          style={{
            color: " #9CA4AB",
          }}
        >
          Lütfen telefonunuza gelen doğrulama kodunu aşağıya girin.
        </small>
        <form className="my-5">
          {" "}
          <input
            className="input"
            type="text"
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          {/* <OTPInput
            numInputs={5}
            value={otp}
            inputStyle="otp-input"
            containerStyle={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0",
            }}
            renderInput={(props) => <input className="otp-input" {...props} />}
            onChange={(otpp) => {
              setOtp(otpp);
            }}
            seperator={<span>-</span>}
          /> */}
          <div
            style={{
              gap: "30px",
              marginTop: "30px",
              flexWrap: "wrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}

            //   className="form-area d-flex justify-content-between align-items-center flex-wrap "
          >
            <Link
              onClick={() => {
                handleButtonClicks("auth/login", "Geri Dön", "Şifre onayla");
              }}
              to="/auth/login"
            >
              <span
                style={{
                  lineHeight: "1.5",
                }}
                className="button-new button-ghost"
              >
                Geri Dön
              </span>
            </Link>{" "}
            <button
              style={{ fontSize: "14px" }}
              type="submit"
              onClick={handleSubmit}
              className="button-new button-primary"
              // disabled={isSubmitting}
            >
              Kod Onayla
            </button>
          </div>
          <div className="my-5">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={(token) => {
                // This function will be called when the user completes the captcha
                setCaptchaToken(token);

                setError(""); // Clear any previous error messages
              }}
            />
          </div>
          {error && (
            <div
              style={{
                marginTop: "20px",
                color: "red",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {error}
            </div>
          )}
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "20px" }}
          >
            {countdown > 0 && (
              <span
                style={{ color: "#9CA4AB", fontSize: "12px" }}
                className="  d-flex justify-content-center align-items-center "
              >
                Tekrar Gönder ({Math.floor(countdown / 60)}.
                {countdown % 60 < 10 ? "0" : ""}
                {countdown % 60})
              </span>
            )}
            {countdown === 0 && (
              <button
                type="button"
                style={{ fontSize: "14px", backgroundColor: "#fff" }}
                onClick={handleResendCode}
                className=" button-ghost button-new d-flex justify-content-center align-items-center "
                // disabled={isCounting}
              >
                Yeni Kod Gönder
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default VerifyPassword;
