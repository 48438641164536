import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/auth-layouts/Login";
import Register from "../components/auth-layouts/Register";
import ForgotPassword from "../components/auth-layouts/ForgotPassword";
import Verify from "../components/auth-layouts/Verify";
import VerifyPassword from "../components/auth-layouts/VerifyPassword";
import ResetPassword from "../components/auth-layouts/ResetPassword";

export const AuthViews = () => {
  return (
    <Routes>
      <Route path={"/*"} element={<Login />} />
      <Route path={"/giris-yap"} element={<Login />} />
      <Route path={"/kayit-ol"} element={<Register />} />
      <Route path={"/sifremi-unuttum"} element={<ForgotPassword />} />
      <Route path={"/dogrulama-ekrani"} element={<Verify />} />
      <Route path={"/sifre-dogrulama"} element={<VerifyPassword />} />
      <Route path={"/sifre-yenile"} element={<ResetPassword />} />

      {/* <Route path={"/onayla"} element={<Confirmation />} /> */}
    </Routes>
  );
};

export default AuthViews;
