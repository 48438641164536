import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Contact from "../components/pages/Contact/Contact";
import Home from "../components/pages/Home/Home";
import Sneakers from "../components/pages/Sneakers/Sneakers";
import Sss from "../components/pages/Sss/Sss";
import CreateOrder from "../components/pages/CreateOrder/CreateOrder";
import Account from "../components/pages/Account/Account";
import Confirmation from "../components/auth-layouts/Confirmation";
import BlogPages from "../components/pages/BlogPages/BlogPages";
import Blogs from "../components/pages/Blogs/Blogs";
import AllPrices from "../components/pages/AllPrices/AllPrices";
import CategoryPrices from "../components/pages/CategoryPrices/CategoryPrices";
import Policy from "../components/pages/Policy/Policy";
import { useSelector } from "react-redux";
import OrderConfirm from "../components/pages/orderConfirm";
import BrandInformation from "../components/pages/BrandInformation/BrandInformation";
import AboutPage from "../components/pages/AboutPage/AboutPage";
import Locations from "../components/pages/Locations";
import AllLocations from "../components/pages/AllLocations";
import Campaigns from "../components/pages/Campaigns";
import CampaignPages from "../components/pages/CampaignPages";
import Career from "../components/pages/Career";
import Kvkk from "../components/pages/Kvkk";
import OrderDetails from "../components/pages/OrderDetails";
import OrderConfirmation from "../components/app-layouts/OrderConfirmation";
import PaymentPage from "../components/pages/Payment/Payment";
import RefundPolicy from "../components/pages/RefundPolicy/RefundPolicy";
import DeliveryPolicy from "../components/pages/DeliveryPolicy/DeliveryPolicy";
import FailedPayment from "../components/pages/FailedPayment/Failedapayment";
import ThankYou from "../components/pages/ThankYou/ThankYou";
import AcikRizaPage from "../components/pages/AcikRizaPage";
import ETK from "../components/pages/ETK";
import Cookies from "../components/pages/Cookies";
import EcommerceList from "../components/pages/EcommerceList";
import EcommerceProduct from "../components/pages/EcommerceProduct";
import Satis from "../components/pages/Satis";
import Favorites from "../components/pages/Favorites";
import Checkout from "../components/pages/Checkout";
import Payment from "../components/pages/EcommercePayment";
import EcommerceOrderDetails from "../components/pages/EcommerceOrderDetail";
import NonRegisteredOrder from "../components/pages/EcommerceOrderDetail/NonRegisteredOrder";
import ThankYouEcommerce from "../components/pages/ThankYouEcommerce";

export const AppViews = () => {
  const userId = localStorage.getItem("userId");
  const isLoggedIn = useSelector((state) => state.account?.isLoggedIn);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/" element={<Home />} />
      <Route path="/onayla" element={<Confirmation />} />
      <Route path="/iptal-iade-kosullari" element={<RefundPolicy />} />
      <Route path="/acik-riza" element={<AcikRizaPage />} />
      <Route path="/cerez-politikasi" element={<Cookies />} />
      <Route path="/etk" element={<ETK />} />
      <Route path="/teslimat-kosullari" element={<DeliveryPolicy />} />
      <Route path="/kullanim-kosullari" element={<Policy />} />
      <Route path="/kvkk-aydinlatma" element={<Kvkk />} />
      <Route path="/satis-sozlesmesi" element={<Satis />} />
      <Route path="/hakkimizda" element={<AboutPage />} />
      <Route path="/kariyer" element={<Career />} />
      <Route path="/iletisim" element={<Contact />} />
      <Route path="/sikca-sorulan-sorular" element={<Sss />} />
      <Route path={"/blog/"} element={<Blogs />} />
      <Route path={"/magaza"} element={<EcommerceList />} />
      <Route path={"/magaza/:slug"} element={<EcommerceProduct />} />
      <Route path={"/magaza/favorilerim"} element={<Favorites />} />
      <Route path={"/magaza/checkout"} element={<Checkout />} />
      <Route path={"/magaza/checkout/odeme"} element={<Payment />} />
      <Route
        path={"/siparis-olusturuldu/magaza/:id/thank-you"}
        element={<ThankYouEcommerce />}
      />
      <Route path="/fiyatlarimiz" element={<AllPrices />} />
      <Route path="/:lokasyon-hizmeti" element={<Locations />} />
      <Route path="/:slug-fiyatlari" element={<CategoryPrices />} />
      <Route path="/:id-temizleme-fiyati" element={<BrandInformation />} />
      <Route path="/:slug" element={<Sneakers />} />
      <Route path={"/:slug-lokasyonlari"} element={<AllLocations />} />

      <Route path={"/:ilce/:service-hizmeti"} element={<Locations />} />
      {/* <Route path="/fiyatlarimiz/:slug/:id" element={<BrandInformation />} /> */}

      <Route path={"/kampanyalar/"} element={<Campaigns />} />
      <Route path={"/kampanyalar/:slug"} element={<CampaignPages />} />

      <Route path={"/blog/:slug"} element={<BlogPages />} />

      {/* private paths */}
      <Route path={"/siparis/onay/:id"} element={<OrderConfirm />} />
      <Route
        path={"/payment-failed"}
        element={userId || isLoggedIn ? <FailedPayment /> : <Home />}
      />
      <Route
        path={"/siparis/detaylari/:id"}
        element={isLoggedIn || userId ? <OrderDetails /> : <Home />}
      />
      <Route
        path={"/siparis/magaza/detaylari/:id"}
        element={isLoggedIn || userId ? <EcommerceOrderDetails /> : <Home />}
      />
      <Route
        path={"/siparis/magaza/urun/detaylari/:id"}
        element={<NonRegisteredOrder />}
      />
      <Route
        path={"/siparis-olustur"}
        element={
          isLoggedIn || userId ? (
            <CreateOrder />
          ) : (
            <Navigate to="/auth/giris-yap" replace />
          )
        }
        // element={<CreateOrder />}
      />
      <Route
        path={"/thank-you"}
        element={
          isLoggedIn || userId ? <ThankYou /> : <Navigate to="/" replace />
        }
        // element={<CreateOrder />}
      />
      <Route
        path={"/siparis-olusturuldu/:orderId/thank-you"}
        element={
          isLoggedIn || userId ? (
            <OrderConfirmation />
          ) : (
            <Navigate to="/auth/giris-yap" replace />
          )
        }
        // element={<CreateOrder />}
      />
      <Route
        path={"/payment"}
        element={<PaymentPage />}
        // element={<CreateOrder />}
      />
      <Route
        path={"/hesap/*"}
        element={userId || isLoggedIn ? <Account /> : <Home />}
      />
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
      <Route
        path="*"
        element={<Navigate to="/" replace />}
        // element={<CreateOrder />}
      />
    </Routes>
  );
};

export default AppViews;
