import React, { useContext, useState } from "react";
import { CartFavoritesContext } from "../../contexts/CartFavoritesContext";
import cartIcon from "../../utils/img/icons/blue-cart.svg";
import CartMenu from "./CartMenu";
import likeRed from "../../utils/img/icons/unlove.svg";
import { Link } from "react-router-dom";

function CartIconButton({ mobile }) {
  const [cartShow, setCartShow] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { cart } = useContext(CartFavoritesContext);
  return (
    <>
      <Link
        to="/magaza/favorilerim"
        onClick={() => {}}
        className={`cart-icon-container ${mobile ? "mob" : ""}`}
      >
        <img className="header-icons" src={likeRed} alt="favorites" />
      </Link>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onClick={() => {
          if (cartShow === null) {
            setCartShow(true);
          } else {
            console.log("cartShow", cartShow);
            setCartShow((currValue) => !currValue);
          }
        }}
        className={`cart-icon-container has-drop ${mobile ? "mob" : ""}`}
      >
        <img className="header-icons" src={cartIcon} alt="cart" />
        <div className="counter-holder">{cart.length}</div>
        {cartShow ||
          (isHovered && (
            <>
              {" "}
              <CartMenu setIsHovered={setIsHovered} mobile={mobile} />
            </>
          ))}
      </div>
    </>
  );
}

export default CartIconButton;
