import React from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function Policy() {
  return (
    <>
      <Helmet>
        <title>{`Kullanım Koşulları - Online Lostra`}</title>
        <meta name="description" content="noindex" />
      </Helmet>

      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Kullanım Koşulları", url: "/kullanim-kosullari" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Gİzlİlİk ve kullanım</h4>
            <h1>Gizlilik politikası </h1>
          </div>
          <div className="policy" style={{ textAlign: "left", color: "black" }}>
            <p>1.TARAFLAR VE ŞARTLAR</p>
            <p>
              İşbu “Sözleşme” DU TECH İNTERNET HİZMETLERİ A.Ş nin bir hizmeti
              olan (“ONLİNE LOSTRA” olarak anılacaktır) web sitesi{" "}
              <a href="http://www.onlinelostra.com">www.onlinelostra.com</a> ve
              Apple iTunes App Store üzerinden ulaşılabilmek üzere “ONLİNE
              LOSTRA” uygulaması gibi, şu anda sunulan ve gelecekte sunulacak
              olan içerik, ürün ve hizmetleri (HİZMETLER olarak devam
              edilecektir) kapsamaktadır. Sözleşmede geçen, kullanıcı, siz ve
              müşteri gibi terimler (“Kullanıcı”) olarak anılacak ve temsil
              edecektir.
            </p>
            <p>
              Tarafımızdan sunulan hizmetlere ulaşarak ve kullanarak, üye
              olsanız da olmasanız da Kullanım Koşullarını kabul ettiğinizi
              taahhüt etmiş olursunuz. Eğer bu sözleşmeyi kabul etmiyorsanız
              lütfen hizmetlerimizi kullanmayınız.
            </p>
            <p>
              İş bu sözleşmeye ilave edilecek şartlar belirli bir etkinlik ya da
              promosyon gibi hizmetlere uygulanabilir. Bu gibi ilave şartların
              olması durumunda müşteri bilgilendirilir. İlave edilen koşullar
              uygulanabilir hizmetlerin amaçlarına yönelik olan anlaşmaya ilave
              edilir ve söz konusu anlaşmaya tâbi tutulur. İlave koşullar
              uygulanabilir hizmetleri kapsayan bir anlaşmazlık durumunda bu
              anlaşmanın gereğine göre geçerlilik gösterir.
            </p>
            <p>2.HİZMETLER</p>
            <p>
              ONLİNE LOSTRA, müşterilerin mobil uygulama ve site aracılığıyla
              çamaşır yıkama ve kuru temizleme, ütüleme ve lostra hizmeti
              alabilecekleri, bu hizmetlerin zamanlarını programlayabilecekleri,
              talep üzerine çalışan bir hizmet uygulamasıdır. Uygulamayı
              kullanılarak rezervasyon yapıldıktan sonra üçüncü şahıs ( ONLİNE
              LOSTRA ile çalışan bağımsız girişimci ) çamaşırları teslim alır ve
              teslim eder. Alınan lostra hizmetlerinde işlem garantisi 30
              gündür.
            </p>

            <p>MALZEME KULLANIMINDA MÜLKİYET VE SINIRLANDIRMALAR</p>
            <p>
              Metni, içeriği, fotoğrafları, videoları, ses kayıtlarını,
              grafikleri ve herhangi bir yazılımı (yazılıma dahil olan veya
              yazılım dahilinde üretilen herhangi bir dosya veya fotoğraf, ya da
              yazılımla ilgili olan herhangi bir veri) içeren hizmetler, ONLİNE
              LOSTRA şirketine aittir ve ONLİNE LOSTRA şirketi tarafından
              yönetilir. Hizmetler ve malzemeler telif hakkı, marka, takdim
              şekli, alan adı, patent, ticari sır ve T.C. kanunlarıyla
              korunmaktadır.
            </p>
            <p>
              Hizmetlerimizden yararlanarak ve giriş yaparak bu anlaşmaya dahil
              olan herhangi bir tebliğ ve sınırlamanın yanı sıra tüm
              uygulanabilir mülkiyet kanunlarını kabul etmiş ve anlaşmaya uymuş
              sayılırsınız.
            </p>
            <p>
              Kişisel bilgisayarlara ticari amaç gütmeden kullanılması amacıyla
              indirilmesi veya kopyalanması dışında, hizmetlerden ve
              uygulamalardan hiçbir madde veya malzeme kopyalanamaz, tekrar
              yayınlanamaz, yüklenemez, herhangi bir şekilde nakledilemez veya
              dağıtılamaz. Herhangi bir amaç için materyallerin değiştirilmesi
              veya materyallerin yetki dışı kullanımı ONLİNE LOSTRA şirketinin
              marka haklarının ihlaline girer.
            </p>

            <p>LİSANS</p>
            <p>
              Anlaşmaya uymanıza bağlı olarak, ONLİNE LOSTRA size kişisel
              cihazınızdan uygulamalara ve hizmetlere ulaşabilmeniz ve
              hizmetlerimiz doğrultusundaki herhangi bir içeriğe veya bilgiye
              ticari olmayan kişisel amaçlarınız için ulaşabilmeniz açısından
              yalnızca hizmetlerimizin kullanımıyla bağlantılı, sınırlı, özel
              olmayan, iptal edilebilir ve devrolunamayan bir lisans sunar.
              Burada açıkça ifade edilmeyen herhangi bir hak ONLİNE LOSTRA ve
              ruhsatçıları tarafından korunur.
            </p>

            <p>HİZMETLERİN KULLANIMI</p>
            <p>MÜŞTERİ TALEPLERİ VE KULLANICI HESAPLARI</p>
            <p>
              Hizmetlerimizin çoğuna erişebilmek ve kullanabilmek için, hizmet
              hesabınızı/üyeliğinizi oluşturmalı ve aktif hesabınızı devam
              ettirmelisiniz. Hesap edinebilmek için en az 13 yaşında
              olmalısınız. Hesap kaydı yaparken ONLİNE LOSTRA sizden adınız,
              soyadınız, adresiniz ve telefon numaranız ve kredi kartı
              bilgileriniz gibi belirli kişisel bilgilerinizi vermenizi ister.
              Hesabınızda doğru, eksiksiz ve güncel olarak bilgilerinizi
              sürdürmeyi kabul etmiş olursunuz. Yapmamanız durumunda
              hizmetlerimizden yararlanamaz ve sisteme giriş yapamazsınız.
              Hesabınız dahilinde olan her şeyden siz sorumlusunuzdur ve
              kullanıcı adı ile şifrenizin gizliliğini üstlenmiş olursunuz.
              ONLİNE LOSTRA aksini onaylamadıkça yalnızca bir hesaba sahip
              olabilirsiniz.
            </p>

            <p>
              Hizmetlerimiz 13 yaş altı bireyler için uygun değildir. Üçüncü
              şahıslara hesabınız üzerinde bir yetki vermemeli ve 13 yaş altına
              sizin eşliğinizde olmadıkları sürece hesabınızı kullanmaları için
              izin vermemelisiniz. Başka bir şahısa hesabınızı devredemez veya
              havale edemezsiniz. Hizmetlerimizi kullanırken tüm uygulanabilir
              yasalara tabii olmayı kabul etmiş olursunuz ve hizmetlerimizi
              sadece yasal yollarla kullanabilirsiniz. Belirli durumlarda ONLİNE
              LOSTRA veya üçüncü bir şahıs (anlaşmacı) sizden hizmetimizi
              kullanırken kimlik doğrulama isteğinde bulunabilir ve kimliğinizin
              veya şahsınızın doğrulanamadığı durumlarda erişiminizin
              kısıtlanmasını kabul etmiş olursunuz
            </p>

            <p>
              Üyelik hesabınızı açarak, size şirketimiz tarafından bilgi
              içerikli mesaj atımını onaylamış olursunuz . Herhangi bir zamanda
              şirketimizin e-posta adresine (bilgi@ONLİNE LOSTRA.co) ulaşarak
              bilgi içerikli mesaj almak istemediğinizi belirtebilirsiniz ve
              böylece bu isteğinizin hizmet kullanım deneyiminizde etkisi
              olacağını kabul etmiş olursunuz.
            </p>
            <p>KULLANIM KOŞULLARI</p>

            <p>KISA MESAJLAR VE MAİL BİLGİLENDİRMELER</p>

            <p>
              Üyelik hesabınızı açarak, size şirketimiz tarafından bilgi
              içerikli mesaj ve mail atımını onaylamış olursunuz . Herhangi bir
              zamanda şirketimizin e-posta adresine (info@onlinelostra.com)
              ulaşarak bilgi içerikli mesaj almak istemediğinizi
              belirtebilirsiniz ve böylece bu isteğinizin hizmet kullanım
              deneyiminizde etkisi olacağını kabul etmiş olursunuz.
            </p>

            <p>ÖDEME İŞLEMLERİ</p>

            <p>
              ONLİNE LOSTRA ödeme altyapı sağlayıcısı olarak BDDK (Bankacılık
              Düzenleme ve Denetleme Kurulu) tarafından lisanslı ve bir güvenlik
              standardı olan PCI DSS Level 1 sertifikasına sahip ödeme aracılığı
              firmalarıyla çalışılmaktadır. Detay bilgiyi çağrı merkezimizden
              alabilirsiniz
            </p>

            <p>PROMOSYON KODLARI VE ÖZEL TEKLİFLER</p>

            <p>
              ONLİNE LOSTRA, yalnızca kendi takdiriyle hesap kredisi / promosyon
              kodu / hediye çeki veya diğer fırsatlara dönüştürülebilecek
              promosyon kodları oluşturabilir. Promosyon kodlarının; istenilen
              hedef kitle ve amaç için yasal bir yolla; çoğaltılmadan,
              devredilmeden, satılmadan veya herkese açık olmadan; ONLİNE LOSTRA
              tarafından yazılı bir şekilde peşinen onaylanmadıkça, ONLİNE
              LOSTRA’nın sorumluluğunda olmadan herhangi bir anda herhangi bir
              sebeple etkisiz kılınabileceğini, nakit para olarak geçerli
              olmadığını, kullanma tarihi geçtikten sonra etkisiz olduğunu
              bildiğinizi ve onayladığınızı kabul etmiş olursunuz. ONLİNE
              LOSTRA, promosyon kodlarının kullanımında yasal olmayan veya
              uygunsuz bir yol olduğuna veya bu şartların ihlali durumunun
              olduğuna karar verdiği takdirde promosyon kodlarının fırsatlarını
              ya da kredilerini eksiltme veya vermeme hakkına sahiptir.
            </p>

            <p>EN DÜŞÜK SİPARİŞ ÜCRETİ</p>

            <p>
              En düşük sipariş ücreti ONLİNE LOSTRA uygulamasında belirtilir ve
              zaman zaman değişebilir.
            </p>

            <p>ŞEBEKE ERİŞİMİ VE AYGITLAR</p>
            <div>
              {" "}
              <p>
                Hizmetlerin kullanımı için gerekli olan ağ verisini elde
                etmekten siz sorumlusunuzdur. Mobil ağınızın veri ve mesajlaşma
                oranları ve ücretleri kablosuz ağa bağlanmamış bir aygıttan
                hizmet kullanımınız durumunda geçerli olabilir. Hizmetlerin ve
                uygulamaların kullanımı için gerekli olan uyumlu donanım, aygıt
                veya güncellemelerin elde edilmesi sizin sorumluluğunuzdadır.
                ONLİNE LOSTRA, hizmetlerin veya uygulamaların herhangi ya da
                belirli bir cihazda veya donanımda işlev göreceğini garanti
                etmez.
              </p>
              <p>ZARAR GÖREN VEYA KAYBOLAN EŞYALAR</p>
              <p>
                Güveninize müteşekkir olduğumuzdan giysilerinize en iyi özeni
                göstermek bizim önceliğimizdir.
              </p>
              <p>ZARAR GÖREN EŞYALAR</p>
              <p>
                Giysilerinize gelebilecek herhangi bir zarardan kaçınmak için
                elimizden gelenin en iyisini yapmaya çalışsak da
                giysilerinizdeki renk kaybına veya çekmeye/küçülmeye karşılık
                bir garanti veremeyiz. İlaveten, küçük delinmelerden veya
                yırtıklardan dolayı bozulan kıyafetlerin ve ayakkabıların
                sorumluluğunu alamıyoruz. Bağımsız araştırmalar sonucu zarar
                görmüş kabul edilen faturalı kıyafetlerinizi/ayakkabılarınızı,
                faturasını bize ibraz etmenizin ardından 15 iş günü içerisinde
                kıyafetin/ayakkabının temizlik bedelinin 10 katına kadar ONLİNE
                LOSTRA şirketi olarak karşılıyoruz.(10 katına kadar olan tutar
                fatura tutarınızdan büyükse, fatura tutarınız kadar size
                önereceğimiz markalardan hediye çeki tarafınıza sağlanacaktır.
                Fatura tutarınız karşılama tutarımızı aşıyorsa, temizlik
                bedelinin 10 katı kadar hediye çekiyle zararınız
                karşılanacaktır)
              </p>
              <p>
                Zarar gören eşyalar ONLİNE LOSTRA şirketinin e-posta adresi
                (info@onlinelostra.com) yoluyla bildirilmeli ve ONLİNE LOSTRA
                tarafından siparişin size teslim edilmesinden 15 gün içinde
                denetlenmelidir.
              </p>
              <p>KAYBOLAN EŞYALAR</p>
              <p>
                Kaybolan eşyalar teslimattan itibaren 7 gün içinde e-posta
                adresine (info@onlinelostra.com) rapor edilmelidir. Tüm kayıplar
                duruma özel olarak incelenir. Eşyalar sipariş verilen tarihten
                20 iş günü içerisinde bulunamadığında kayıp sayılır. ONLİNE
                LOSTRA tarafından kaybedilen her kıyafet/ayakkabı, faturasını
                bize ibraz etmenizin ardından 15 iş günü içerisinde
                kıyafetin/ayakkabının temizlik bedelinin 10 katına kadar
                karşılanır.(10 katına kadar olan tutar fatura tutarınızdan
                büyükse, fatura tutarınız kadar size önereceğimiz markalardan
                hediye çeki tarafınıza sağlanacaktır. Fatura tutarınız karşılama
                tutarımızı aşıyorsa, temizlik bedelinin 10 katı kadar hediye
                çekiyle zararınız karşılanacaktır)
              </p>
              <p>
                Müşteri, çağrı merkezimize ya da vale personeline, siparişinizin
                başka birine teslimini ya da kapısına bırakılmasını
                belirttiğinde (ses kaydınız alınmaktadır) sorumluluk tamamen
                müşterinindir.
              </p>
              <p>ENVANTER </p>
              <p>
                ONLİNE LOSTRA, kıyafetlerinizin ve elbiselerinizin görüntü veya
                fotoğraflar içerebilecek kayıtlarını tutma ve sergileme hakkına
                sahiptir ve bilgileriniz ile bu kayıtları envanterle ilgili
                amaçlarla, kalite kontrolü için, kayıp vakalarını önlemek için
                veya veri toplama amacıyla ilişkilendirebilir.
              </p>
              <p>KULLANICI İÇERİĞİ VE ETKİLEŞİMLİ ÇÖZÜMLER</p>
              <p>
                ONLİNE LOSTRA, kullanıcı deneyimini arttırmak için, kullanıcı
                puanlamaları, geri bildirimler, yorumlar ve kayıtlı notlar gibi
                etkileşimli çözümler sunabilmektedir. Kısaca Kullanıcı İçeriği
                olarak belirtilen terim, sizin ürettiğiniz ve paylaştığınız
                herhangi bir bilgi, yorum, oylama, geri bildirim ya da diğer
                materyaller, gönderilen e-posta’ları kapsamaktadır. Ayrıca,
                Kullanıcı içeriklerini paylaşarak transfer edilebilir telif
                hakları, standart ve özel telif hakları, Dünya Geneli telif
                haklarını ve bu haklar dolayısıyla oluşan, her türlü yayın ve
                medya alanında, hizmetin doğru verilmesi için iş birliği yapılan
                kişi ve kurumlarla, kullanım, kopyalama, baskı, gösterim, yayın,
                değişiklik, silme ve kamuya sunma, çeviri ve benzeri işler
                üretme haklarını ONLİNE LOSTRA’ya vermiş sayılırsınız. Bu
                kullanıcı içeriğini ONLİNE LOSTRA’ya göndererek herhangi bir
                maddi ya da manevi karşılık, uyarı, bildiri beklemediğinizi
                taahhüt etmiş sayılırsınız.
              </p>
              <p>YASADIŞI VE İZİN VERİLMEYEN KULLANIMLAR </p>
              <p>
                {" "}
                ONLİNE LOSTRA hizmetlerini kullanarak, bu hizmetleri herhangi
                bir yasadışı bir amaç veya bu sözleşmede izin verilmeyen
                herhangi bir durum için kullanmadığınızı kabul ve taahhüt etmiş
                sayılırsınız. Ek olarak, hizmetleri kullanım şeklinizin ve
                amacınızın hiçbir şekilde ONLİNE LOSTRA hizmetlerini ve ONLİNE
                LOSTRA’ ya bağlı herhangi bir hizmeti kötü etkileyecek, zarar
                verecek şekilde kullanmayacağınızı kabul ve taahhüt etmiş
                sayılırsınız. ONLİNE LOSTRA tüm hizmetlerini sürekli kontrol
                etme ve denetleme hakkını saklı tutar.
              </p>
            </div>
            <p>SÖZLEŞME İHLALLERİ</p>

            <p>
              ONLİNE LOSTRA dilediği zaman belirlediği kişilere, kurumlara ve
              internet adreslerine erişimi engelleme hakkını saklı tutmaktadır.
            </p>

            <p>Gizlilik Politikası</p>

            <p>
              Bu döküman ONLİNE LOSTRA'in, uygulamayı kullanan müşterilerin
              kişisel bilgileri ile ilgili politikasını açıklar.
            </p>

            <p>1. ONLİNE LOSTRA aşağıdaki bilgileri işlemektedir.</p>

            <ul>
              <li>
                Tanımlama bilgileri (ad, soyad, cep telefonu numarası, e-posta
                adresi).
              </li>
              <li>
                Kullandığınız mobil aygıtın işletim sistemi versiyonu bilgisi.
              </li>
              <li>Kullandığınız mobil aygıttan edinilen konum bilgileri.</li>
              <li>
                Sipariş sonunda kuryeye verilen oylama bilgisi ve yorumlar.
              </li>
              <li>
                Uygulama içindeki "Öneri ve Şikayet" bölümünde iletilen
                yorumlar.
              </li>
            </ul>

            <p>1.1 ONLİNE LOSTRA bu bilgileri hangi amaçla kullanır?</p>

            <p>ONLİNE LOSTRA bu bilgileri aşağıdaki amaçlar için kullanır:</p>
            <ul>
              <li>
                Kimliğinizi doğrulamak (vermiş olduğunuz cep telefonu numarasına
                doğrulama kodu gönderme vasıtasıyla).
              </li>
              <li>Müşteri desteği sağlamak ve sorun gidermek.</li>
              <li>
                Servis güncellemeleri ve hatalar hakkında sizi bilgilendirmek.
              </li>
              <li>
                Uygulama ile ilgili kampanyalar hakkında sizi bilgilendirmek.
              </li>
              <li>
                Valenin sipariş isteğini ve adresini teyit etmek istemesi
                durumunda sizi arayabilmesi için. (Bu konuyu istismar eden
                kuryeler sistemimizden çıkarılmaktadır.)
              </li>
            </ul>
            <div>
              <p>1.2 Kişisel bilgilerin paylaşımı</p>
              <ul>
                <li>
                  ONLİNE LOSTRA, ilgili yasalar gereği yükümlü olmadıkça veya
                  yetkili makamların emriyle istenmedikçe kişisel verileri
                  ve/veya trafik verilerini ya da iletişim içeriğini sizin açık
                  izniniz olmadan satmaz, kiralamaz, pazarlamaz veya başka
                  şekilde aktarmaz.
                </li>
                <li>
                  ONLİNE LOSTRA, yasal gereklilikleri karşılamak, yasal
                  haklarımız doğrultusunda hareket etmek veya yasal hak
                  taleplerine karşı kendini savunmak, ONLİNE LOSTRA’nın
                  menfaatlerini korumak, dolandırıcılığa karşı mücadele etmek ve
                  ilkelerimizi uygulamak ya da herhangi bir kişinin haklarını,
                  malını veya güvenliğini korumak için kişisel bilgileri ilgili
                  yasal makamlara gelen yasal istek doğrultusunda açıklayabilir.
                </li>
              </ul>
              <p>2. Verilerinizi nasıl saklıyoruz?</p>
              <p>
                Verileriniz güvenli sunucularımızda saklanmaktadır. Uygulamaya
                sizin belirlemiş olduğunuz şifre ile erişebilirsiniz ve şifreler
                sistemimizde geri çevrilemeyecek şekilde kodlanmış olarak
                tutulur. Şifrenizi güvende tutmak ve paylaşmamanız sizin
                sorumluluğunuzdadır. Ancak bilgilerin aktarılmasının halka açık
                internet üzerinden yapılmasının %100 güvenli olduğunun garantisi
                verilemez. Verilerinizi korumak için şifrelenmiş bağlantı
                kullanmaktayız, fakat veri aktarım sırasında internet
                bağlantınıza yapılan saldırıların riski sizin üzerinizdedir.
                Bilgileriniz sunucularımıza ulaştıktan sonra korunması ile
                ilgili her türlü güvenlik önlemi tarafımızca alınır.
              </p>
              <p>3. Bilgilerinize ulaşmanız ve güncellemeler</p>
              <p>
                Sistemimizde tutulan bilgilerinizin kopyasını başvurmanız
                durumunda gerekli güvenlik kontrolu yapıldıktan sonra sizinle
                paylaşabiliriz. Bazı bilgilerinizi uygulama üzerinde
                güncelleyebilir, müşteri hizmetlerimizi arayarak da güncelleme
                talebinde bulunabilirsiniz.
              </p>
              <p>4. Cookie(çerez)’ler ve cihazlarda tutulan bilgiler</p>
              <p>
                Servisimizi daha hızlı ve etkin bir şekilde kullanabilmeniz için
                oturum cookie (çerez)’lerini cihazınızda saklamaktayız.
                Dilerseniz bu bilgilerin tutulmasını cihaz ayarlarınızda
                yapacağınız değişiklikle engelleyebilirsiniz. Ancak bunu
                yapmanız durumunda ONLİNE LOSTRA servisinin tüm
                fonksiyonlarından yararlanamayabilirsiniz. Gizlilik Politikası
                üzerinde zaman zaman değişiklik yapmak hakkımızı saklı tutarız.
                Herhangi bir değişiklik yapmamız durumunda bununla ilgili bilgi
                tarafınıza verilecektir.
              </p>
              <p>
                {" "}
                Bu Gizlilik Politikası dokümanı en son 26 Mayıs 2023 tarihinde
                güncellenmiştir.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Policy;
