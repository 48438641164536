import React from "react";
import Cancel from "../../../src/utils/img/icons/packaging-cancel.svg";
function DeleteModal({ showModal, setShowModal, info, id, handleDelete }) {
  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div
            style={{
              height: "300px",
              width: "400px",
            }}
            className="modal-container"
          >
            <img src={Cancel} />
            <div>
              <h5>
                Silmek istediğinize <br /> emin misiniz?
              </h5>
            </div>
            <div
              style={{
                padding: "0 40px",
              }}
            ></div>
            <div className="confirm-modal-delete">
              <button
                onClick={() => setShowModal(false)}
                className="btn cancel-button"
              >
                Vazgeç
              </button>
              <button
                onClick={handleDelete}
                className="btn package-delete-button"
              >
                Sil
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteModal;
