import React from "react";

import { useNavigate } from "react-router-dom";
import slugify from "slugify";

function CampaignGrids({ campaigns, imageUrls }) {
  const navigate = useNavigate();
  const handleBlogClick = (item) => {
    const urlTitle = slugify(item.title, {
      lower: true,
      remove: /[*+~.()'"!:@?]/g,
    });

    navigate(`/kampanyalar/${urlTitle}?id=${item._id}`, {});
  };

  function formatTimeToYearMonthDay(timeString) {
    const createdDate = new Date(timeString);
    const formattedDate = createdDate
      .toLocaleDateString("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, ".");

    return formattedDate;
  }

  return (
    <>
      <div className="container">
        <div className="row blog-container">
          {campaigns &&
            campaigns.map((campaign) => (
              <div
                key={campaign._id}
                style={{ maxWidth: "420px" }}
                className="col-xl-4 my-4"
              >
                <div
                  className="card"
                  style={{
                    color: "#fff",
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "relative",
                    maxWidth: "420px",
                    height: "374px",
                    backgroundImage: `url(${
                      imageUrls[campaign.mainImages[0]]
                    })`,

                    backgroundSize: "cover",
                    borderRadius: "25px",
                    backgroundPosition: "center",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="card-overlay"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.3)",
                    }}
                  ></div>
                  <div
                    className="card-content"
                    style={{
                      position: "relative",
                      textAlign: "left",
                      padding: "30px",
                    }}
                  >
                    <p>{formatTimeToYearMonthDay(campaign.createdDate)}</p>
                    <h3 style={{ fontSize: "24px" }} className="card-title">
                      {campaign.title}
                    </h3>
                    <button
                      onClick={() => handleBlogClick(campaign)}
                      style={{
                        backgroundColor: "#ffa900",
                        color: "#fff",
                        fontWeight: "600",
                        borderRadius: "15px",
                        padding: "8px 45px",
                      }}
                      className="btn"
                    >
                      İncele
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default CampaignGrids;
