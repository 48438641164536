import React, { Fragment } from "react";
import info from "../../../utils/img/icons/information.svg";
import OptionalImages from "./OptionalImages";
import cancelImg from "../../../utils/img/icons/cancel.svg";

import Kalib from "../../../utils/img/ayakkabi-kalibi.jpg";

function OptionalRadios({
  offer,
  offerIndex,
  handleOptionalChange,
  imageUrls,
  setSelectedImageIndex,
  setIsModalOpen,
  handleCoverChange,
}) {
  const [modal, setModal] = React.useState(false);
  return (
    <>
      {" "}
      {offer && offer.optionals.length > 0 && (
        <>
          {" "}
          <h5>Tamir İşlemleri (Opsiyonlu)</h5>
          <OptionalImages
            imageUrls={imageUrls}
            offer={offer}
            offerIndex={offerIndex}
          />
          {offer.optionals.map((optional, optionalIndex) => (
            <Fragment key={optional?._id}>
              {" "}
              <div
                className="my-3 main-radio-button"
                style={{
                  display: "Flex",
                  gap: "10px",
                }}
              >
                <input
                  id={`${offer?._id}-${optional?._id}`}
                  defaultChecked={optionalIndex === 0}
                  type="radio"
                  name={`${offer?._id}`}
                  value={`${offer?._id}-${optional?._id}`}
                  onChange={(event) => {
                    handleOptionalChange(event, offer._id, offer.optionals);
                  }}
                />
                <div>
                  <label
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "500",
                    }}
                    htmlFor={`${offer?._id}-${optional?._id}`}
                    // htmlFor={`radio-${offer._id}`}
                  >
                    {optional?.name}{" "}
                    <span>{optional?.price?.toFixed(2)} ₺</span>
                  </label>
                  <small style={{ maxWidth: "600px" }} className="smallInfo">
                    ({optional?.description})
                    <span className="information-img">
                      {" "}
                      <img
                        onClick={() => {
                          setSelectedImageIndex(optional.images[0]);
                          setIsModalOpen(true);
                        }}
                        src={info}
                      />
                    </span>
                  </small>
                </div>
              </div>
            </Fragment>
          ))}
        </>
      )}{" "}
      {offer &&
        offer?.orderDetailId?.serviceCategoryId?.icon === "ayakkabi" && (
          <>
            <h5>Ayakkabı Kalıbı (Opsiyonlu)</h5>
            <div
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
              className=" my-3"
            >
              <input
                id={`${offer?._id}`}
                type="checkbox"
                name="addCover"
                value={`${offer?._id}`}
                onChange={(event) => {
                  handleCoverChange(event, offer);
                }}
              />
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <div>
                  {" "}
                  <label
                    htmlFor={`${offer?._id}`}
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      fontWeight: "500",
                    }}
                  >
                    Ayakkabı Kalıbı <span>89.90₺</span>
                  </label>
                  <small style={{ maxWidth: "600px" }} className="smallInfo">
                    (Ayakkabılarını kullanmadığın zamanlarda <br /> kalıpta
                    tutarak ömrünü uzatabilirsin.)
                    <span className="information-img"> </span>
                  </small>
                </div>
                <div
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  {" "}
                  <img
                    style={{
                      width: "60px",
                      borderRadius: "8px",
                    }}
                    alt="Ayakkabı Kalıbı"
                    src={Kalib}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      {modal && (
        <div
          style={{
            opacity: modal ? 1 : 0,
            backgroundColor: modal ? "rgba(0,0,0,0.5)" : "transparent",
            zIndex: modal ? 1000 : -1,
          }}
          className="mainImagesOrderOverlay"
          onClick={() => setModal(false)}
        >
          <div className="serviceimgcontainer">
            <img
              className="servicecloseimg"
              style={{
                backgroundColor: "grey",
                padding: "5px",
                borderRadius: "30%",
                position: "absolute",
                top: "5px",
                zIndex: "1001",
                cursor: "pointer",
                right: "5px",
                width: "20px",
              }}
              onClick={() => {
                setModal(false);
              }}
              src={cancelImg}
              alt="cancel"
            />
            <img
              className="serviceImage"
              style={{
                objectFit: "contain",
                maxWidth: "400px",
                maxHeight: "600px",
                transform: modal
                  ? "scale(1) translate(0,0)"
                  : "scale(0.8) translate(0,100px)",
                transition: "transform 0.5s ease-in-out",
              }}
              src={Kalib}
              alt="Ayakaabı Kalıbı"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default OptionalRadios;
