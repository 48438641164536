import React from "react";
import Sally from "../../../utils/img/404-image.svg";
import { Link } from "react-router-dom";
import Header from "../../app-layouts/Header";
import Footer from "../../app-layouts/Footer";
function NotFound() {
  return (
    <>
      <Header />
      <section>
        <div
          style={{
            padding: "0",
          }}
          className="container"
        >
          <div className="confirmation-page">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "20px",
                // flex: "1",
              }}
            >
              <h3>Aradığın Sayfayı Bulamadık 😢</h3>
              <p>
                Böyle bir sayfa yok gibi görünüyor. Lütfen URL'yi kontrol edin
                ve tekrar deneyin.
              </p>
              <Link onClick={(e) => e.window.refresh()} to={"/"}>
                <span className="button button-back button-primary">
                  Ana Sayfaya Dön
                </span>
              </Link>
            </div>
            <img
              style={{
                objectFit: "contain",
                maxWidth: "600px",
                minWidth: "200px",
                width: "100%",
              }}
              src={Sally}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NotFound;
