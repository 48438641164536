import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import Icon from "../../utils/img/Saly-3.png";
import ReCAPTCHA from "react-google-recaptcha";
import uploadIcon from "../../utils/img/icons/upload.svg";
import SuccessInfo from "./SuccessInfo";

import { storage } from "../../firebase";
import { ref, uploadBytesResumable } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import baseService from "../../library/network/services/BaseService";

const ContactSchema = Yup.object().shape({
  email: Yup.string().email("Geçerli bir e-posta giriniz"),
  name: Yup.string().required("Adınızı giriniz"),
  lastname: Yup.string().required("Soyadınızı giriniz"),
  phone: Yup.number(),
});

const CareerForm = () => {
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const recaptchaRef = React.useRef();
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const tokenService = () =>
    baseService.post("verify-token", { token: captchaToken });
  const handleCap = () => {
    setClicked(true);
  };
  const addCareer = async (values) => baseService.post("career", values);
  const sanitizeFileName = (file) => {
    if (file) {
      const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9._-]/g, "_");
      const sanitizedFile = new File([file], sanitizedFileName, {
        type: file.type,
      });
      return sanitizedFile;
    }
    return null;
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const sanitizedFiles = files.map((file) => sanitizeFileName(file));
    setSelectedFile(sanitizedFiles);
  };
  const PHONE_REGEX = /^05(\d){2} ?\d{3} ?\d{4}$/;
  return (
    <div className=" container contact-form-container">
      <div className="mb-5 bg-image d-none d-md-block logo-container">
        <img
          style={{
            objectFit: "contain",
          }}
          src={Icon}
          alt="logo"
        />
      </div>
      <div className="form-container container">
        <h1 className="contact-form-title"> Kariyer formu</h1>
        <div
          style={{
            margin: "0 auto",
          }}
          className="register-content"
        >
          <Formik
            initialValues={{
              email: "",
              phone: "",

              file: "",
              name: "",
              lastname: "",
            }}
            validationSchema={ContactSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const res = await tokenService();
                if (res.data === "OK") {
                  values.email = values.email.trim().toLowerCase();
                  let files = [];
                  if (selectedFile.length > 0) {
                    selectedFile.forEach((file) => {
                      files.push(file.name);
                    });
                    selectedFile.forEach((file) => {
                      if (file) {
                        const storageRef = ref(storage, "images/" + file.name);
                        const uploadTask = uploadBytesResumable(
                          storageRef,
                          file
                        );
                        uploadTask.on("state_changed", null, null, () => {
                          console.log("File uploaded successfully!");
                        });
                      }
                    });
                  }
                  const model = {
                    email: values.email,
                    phone: values.phone,
                    name: values.name,
                    lastname: values.lastname,
                    files: files,
                  };
                  const res = await addCareer(model);
                  setMessage(res.data.message);
                  resetForm();
                  setSelectedFile(null);
                  navigate("/kariyer", { replace: true });
                  setSuccess(true);
                  setTimeout(() => {
                    setSuccess(false);
                  }, 1000);
                } else {
                  setError("Lütfen robot olmadığınızı doğrulayınız.");
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="register-form">
                <div
                  className="form-area"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ flex: "1" }}>
                    <Field type="text" name="name" placeholder="Adınız" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-auth"
                    />
                  </div>
                  <div style={{ flex: "1", marginLeft: "10px" }}>
                    <Field
                      type="text"
                      name="lastname"
                      placeholder="Soyadınız"
                    />
                    <ErrorMessage
                      name="lastname"
                      component="div"
                      className="error-auth"
                    />
                  </div>
                </div>

                <div className="form-area">
                  <InputMask
                    type="tel"
                    name="phone"
                    placeholder="Telefon numaranız"
                    mask="0599 999 9999"
                    maskChar="_"
                    regex={PHONE_REGEX}
                    onChange={(e) => {
                      const formattedValue = e.target.value.replace(/\s+/g, "");
                      setFieldValue("phone", formattedValue);
                    }}
                  />

                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error-auth"
                  />
                </div>

                <div className="form-area">
                  <Field
                    type="email"
                    name="email"
                    value={values.email.trim().toLowerCase()}
                    placeholder="E-posta adresiniz"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div className="form-group">
                  <div className="custom-file">
                    <Field
                      type="file"
                      name="file"
                      id="file"
                      accept="application/pdf"
                      style={{ display: "none" }}
                      onChange={(event) => {
                        handleImageChange(event);
                      }}
                      className="custom-file-input"
                    />
                    <img
                      style={{ width: "30px", cursor: "pointer" }}
                      src={uploadIcon}
                      alt="upload"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="file"
                      style={{
                        cursor: "pointer",
                        margin: "0 10px",
                        fontWeight: "400",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedFile ? selectedFile[0].name : "CV Yükle"}
                    </label>
                  </div>
                  <small>*PDF formatında ekleyiniz</small>
                  {/* <h1 style={{ color: "red" }}>{values.file.name}</h1> */}
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-area contact-button-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(token) => {
                      setCaptchaToken(token);

                      setError("");
                    }}
                  />
                  <button
                    type="submit"
                    className="button button-contact button-primary"
                  >
                    Gönder
                  </button>
                </div>
                {error && (
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                    className="error-auth"
                  >
                    {error}
                  </p>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {success && <SuccessInfo message={message} />}
    </div>
  );
};

export default CareerForm;
