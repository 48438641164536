import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { getAllComments } from "../../library/network/requests/comments";

function Comments() {
  const [comments, setComments] = useState([]);
  const getComments = async () => {
    try {
      const res = await getAllComments();
      setComments(res.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setComments([]);
    }
  };

  const carouselRef = useRef(null);
  const handlePreviousSlide = () => {
    carouselRef.current.slickPrev();
  };
  const [expandedComments, setExpandedComments] = useState([]);

  const handleExpandComment = (commentId) => {
    setExpandedComments((prevExpandedComments) => {
      if (prevExpandedComments.includes(commentId)) {
        return prevExpandedComments.filter((id) => id !== commentId);
      } else {
        return [...prevExpandedComments, commentId];
      }
    });
  };
  const getRand = () => {
    const randomNum = Math.floor(Math.random() * 2) + 4;
    return randomNum;
  };
  const handleNextSlide = () => {
    carouselRef.current.slickNext();
  };

  useEffect(() => {
    getComments();
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoPlay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1390,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 896,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section
        style={{
          overflowX: "hidden",
        }}
        className="middle-title light-bg"
      >
        <div className="container">
          <div className="title">
            <h2>Kullanıcı Yorumları</h2>
            <p>Kullanıcılarımızın Online Lostra hakkındaki yorumları...</p>
          </div>
        </div>
        <div className="comments-container">
          <Slider ref={carouselRef} {...settings}>
            {comments &&
              comments.map((comment) => {
                getRand();
                return (
                  <div key={comment._id} className="comment-box ">
                    <div className="stars">
                      <span className="star star-full"></span>
                      <span className="star star-full"></span>
                      <span className="star star-full"></span>
                      <span className="star star-full"></span>
                      <span className="star star-full"></span>
                    </div>
                    {/* {getRand() % 2 === 0 ? (
                      <div className="stars">
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                        <span className="star star-half"></span>
                      </div>
                    ) : (
                      <div className="stars">
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                        <span className="star star-full"></span>
                      </div>
                    )} */}

                    <div className="comment">
                      <p>
                        {expandedComments.includes(comment._id)
                          ? comment.description
                          : comment.description.length > 250
                          ? comment.description.slice(0, 250)
                          : comment.description}
                        {comment.description.length > 250 && (
                          <span
                            className="oku-link"
                            onClick={() => handleExpandComment(comment._id)}
                          >
                            {expandedComments.includes(comment._id)
                              ? "...kapat"
                              : "...devamı"}
                          </span>
                        )}
                      </p>
                    </div>
                    <h5>{comment.name}</h5>
                    <small>{comment.title}</small>
                  </div>
                );
              })}
          </Slider>
        </div>

        <div className="my-2 comment-navigation">
          <div className="arrow prev" onClick={handlePreviousSlide}></div>
          <div className="arrow next" onClick={handleNextSlide}></div>
        </div>
      </section>
    </>
  );
}
export default Comments;
