import React from "react";

const ProgressBar = ({ status }) => {
  return (
    <div className={`order-progress-bar in-progress ${status}`}>
      <div className="progress-meter"></div>
    </div>
  );
};

export default ProgressBar;
