import React from "react";

function AddressFrom({
  address,
  selectedAddress,
  handleAddressChange,
  index,
  setIsClicked,
}) {
  return (
    <>
      <h3 className="mb-4">Adres Seçimi</h3>
      <div className=" my-4 input-create-order">
        <div
          style={{
            flex: 1,
          }}
          className="input-select"
        >
          <select
            id="address"
            name="address"
            value={selectedAddress}
            onChange={handleAddressChange}
          >
            <option disabled value="">
              -- Adres Seçiniz --
            </option>
            {address.length > 0 &&
              address?.map((item, i) => (
                <option key={i} value={item?._id}>
                  {item?.title}
                </option>
              ))}
          </select>
        </div>
        <div style={{ display: index === 0 ? "none" : "block" }}>
          <button
            type="button"
            onClick={() => setIsClicked((prevstate) => !prevstate)}
            className="button button-ghost-input "
          >
            Yeni Adres Ekle
          </button>
        </div>
      </div>
    </>
  );
}

export default AddressFrom;
