import React from "react";
import Cancel from "../../../src/utils/img/icons/packaging-cancel.svg";
function Modal({ showModal, setShowModal, info, id, handleDelete }) {
  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-container">
            <img src={Cancel} />
            <div>
              <h3>
                Siparişi İptal Etmek <br /> İstiyor musun?
              </h3>
            </div>
            <div
              style={{
                padding: "0 40px",
              }}
            >
              <p>
                {info || "Siparişinizi iptal etmek istediğinize emin misiniz?"}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <button
                onClick={() => setShowModal(false)}
                className="btn cancel-button"
              >
                Vazgeç
              </button>
              <button
                onClick={handleDelete}
                className="btn package-delete-button"
              >
                Siparişi İptal Et
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
