import React from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function RefundPolicy() {
  return (
    <>
      <Helmet>
        <title>{`İptal ve İade Koşulları - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>

      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "İptal ve İade Koşulları", url: "/iptal-iade-kosullari" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Online Lostra</h4>
            <h1>İptal ve İade Koşulları </h1>
          </div>
          <div className="policy" style={{ textAlign: "left", color: "black" }}>
            <p>
              <strong>1.</strong> 6502 sayılı Tüketicinin Korunması Hakkındaki
              Kanun gereğince, MÜŞTERİ sözleşme konusu Ürün’ün kendisine veya
              gösterdiği adresteki kişi/kuruluşa teslimat tarihinden itibaren 14
              (on dört) gün içerisinde cayma haklarını kullanarak herhangi bir
              sebep göstermeden ve cezai şart ödemeden Ürün’ü iade etme hakkı
              haizdir.
            </p>

            <p>
              <strong>1.2.</strong> MÜŞTERİ’nin cayma hakkını kullanabilmesi
              için teslimat tarihinden itibaren 14 (on dört) gün içerisinde
              Ürün’ü iade etmek istediğini ONLİNE LOSTRA’ya bildirmesi
              gerekmektedir. ONLİNE LOSTRA MÜŞTERİ’nin talebini aldıktan sonra
              en geç 10 (on) gün içerisinde, ürünün iadesini kabul edip ürün
              bedelini alıcıya ödemekle yükümlüdür.
            </p>

            <p>
              <strong>1.3.</strong> MÜŞTERİ, cayma hakkı konusunda gerektiği
              şekilde bilgilendirilmezse, cayma hakkını kullanmak için 14 (on
              dört) günlük süreyle bağlı değildir. Her hâlükârda bu süre cayma
              süresinin bittiği tarihten itibaren 1 (bir) yıl sonra sona erer.
              MÜŞTERİ, cayma hakkı süresi içinde malın mutat kullanımı sebebiyle
              meydana gelen değişiklik ve bozulmalardan sorumlu değildir.
            </p>

            <p>
              <strong>1.4.</strong> MÜŞTERİ’nin cayma hakkını kullanarak Ürün’ü
              iade etmek istemesi halinde, bu talebini yukarıda belirtilen 14
              (on dört) günlük süre içinde ONLİNE LOSTRA’ya bildirerek, Ürün’ü
              herhangi bir kargo şirketi ile masraflar müşteriye ait olmak üzere
              iade edebilecektir.
            </p>

            <p>
              <strong>1.5.</strong> 385 sayılı Vergi Usul Kanunu Genel Tebliği
              uyarınca iade işlemlerinin yapılabilmesi için MÜŞTERİ’ye Ürün ile
              birlikte gönderilmiş olan e-faturanın saklanması gerekir. Teslim
              alınan Ürün iade edilmek istenildiği takdirde; MÜŞTERİ’ ONLİNE
              LOSTRA’ya Ürün ile birlikte e-fatura kopyasını geri göndermesi
              gerekmektedir. Ürün’ün MÜŞTERİ tarafından imzalı iade faturası
              gönderilmeksizin ONLİNE LOSTRA’ya iade edilmesi ve/veya faturanın
              kaybedilmiş olması halinde doğabilecek masraf ve cezalar
              MÜŞTERİ’ye ait olacaktır.
            </p>

            <p>
              <strong>1.6.</strong> Cayma hakkının kullanılması, Ürün’ün
              ambalajının açılmamış, bozulmamış ve kullanılmamış olması şartına
              bağlıdır.
            </p>

            <p>
              <strong>1.7.</strong> Cayma hakkının kullanılamayacağı ürünler
              ise; MÜŞTERİ’nin isteği ile kişiye özel olarak üretilen, üzerinde
              değişiklik veya ilave yapılarak kişiye özel hale getirilen
              ürünler, niteliği itibarıyla geri gönderilmeye elverişli olmayan
              ürünler (İç çamaşırı, mayo, kozmetik gibi hijyenik durumu hassas
              olan ürünleri iade edebilmenin koşulu ürünün açılmamış ve
              denenmemiş olmasıdır, MÜŞTERİ, tarafından açılmış olan ses veya
              görüntü kayıtları (DVD, CD ve Kaset vb.), basılı ürünler (Kitap,
              dergi vb.), yazılım programları ve bilgisayar sarf malzemeleri,
              çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme
              ihtimali olan ürünler, fiyatı borsa veya teşkilatlanmış diğer
              piyasalarda belirlenen ürünler (Külçe, Ziynet Altın ve Gümüş
              kategorisindeki tüm ürünler vb.) tatil kategorisinden satın alınan
              otel, yurt içi/yurt dışı turlar, gezi ve uçak bileti gibi ürün ve
              hizmetler. Gıda maddeleri, içecek ve diğer günlük tüketim
              maddeleri için sağlanan ürünler, tek seferde kullanılan ürünler,
              hızla bozulma veya son kullanma tarihi geçme ihtimali olan
              ürünlerde, MÜŞTERİ cayma hakkını kullanamaz. Bu ürün ve
              hizmetlerin iptal/iadesi, MÜŞTERİ’nin doğrudan cayma hakkını
              kullanması ile değil; ONLİNE LOSTRA uygulaması doğrultusunda
              yapılır. Ayrıca, Gümrük ve Ticaret Bakanlığının, Mesafeli
              Sözleşmeler Yönetmeliğinin 15. Maddesinin g ve h bendlerinde de
              belirtildiği üzere, ONLİNE LOSTRA tarafından sağlanan müşterinin
              isteğine göre olan (kuru temizleme, yıkama, ütüleme, lostra v.b.)
              hizmetlerden cayma hakkı söz konusu değildir. Alınan lostra
              hizmetlerinde işlem garantisi 30 gündür.
            </p>

            <p>
              <strong>1.8.</strong> MÜŞTERİ, işbu sözleşmeyi kabul etmekle,
              cayma hakkı konusunda bilgilendirildiğini peşinen kabul eder.
            </p>

            <p>
              Eğer iade/değişim yapmak istersen, üye girişi sonrası “Siparişler”
              ekranını ya da “Sipariş Takibi” sayfamızı kullanıp talebini
              oluşturabilirsin. Paketin içine sipariş numarasını ekleyerek
              göndermeniz gerekmektedir.
            </p>

            <p>
              <strong>İade/Değişim Adresi:</strong> Çırçır Mahallesi Dönen Sokak
              No: 8-10B Eyüpsultan, İstanbul, Türkiye
            </p>

            <p>
              Aklınıza takılan herhangi bir şey olursa bilgi için{" "}
              <strong>info@onlinelostra.com</strong> adresinden bize
              ulaşabilirsin.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default RefundPolicy;
