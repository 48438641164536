import React, { useEffect, useState } from "react";
import {
  deleteWebuserAddress,
  getUserAddress,
} from "../../library/network/requests/webusers";
import NewAddress from "./NewAddress";
import logo from "../../utils/img/icons/delete.svg";
import SuccessInfo from "./SuccessInfo";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import baseService from "../../library/network/services/BaseService";
import DeleteModal from "./DeleteModal";
import { Helmet } from "react-helmet-async";

function Addresses() {
  const [showModal, setShowModal] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [newEntry, setNewEntry] = useState("");
  const userId = storageHelper.getStoreWithDecryption("userId");
  const [address, setAddress] = useState([]);
  const [mahalle, setMahalle] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState("");
  const cityService = async () => baseService.get("cities");
  const getCities = async () => {
    const res = await cityService();
    setCityData(res.data);
  };
  const getDistricts = async (il) => {
    const filteredData = cityData.filter((item) => item.city === il);
    setDistrictData(filteredData);
  };
  const getNeighborhood = (il) => {
    const filteredData = districtData[0].district.filter(
      (item) => item.name === il
    );
    setMahalle(filteredData);
  };
  const getAddress = async () => {
    try {
      await getUserAddress(userId).then((res) => {
        setAddress(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getDistrictsAndNeighborhood = async (city, district) => {
    const filteredCityData = cityData.filter((item) => item.city === city);
    setDistrictData(filteredCityData);

    const filteredDistrictData = filteredCityData[0].district.filter(
      (item) => item.name === district
    );
    setMahalle(filteredDistrictData);
  };

  const handleClick = async (data) => {
    setNewEntry(data);
    getDistrictsAndNeighborhood(data.city, data.district);

    setSelectedNeighborhood(data.neighborhood);
  };
  useEffect(() => {
    getAddress();
    getCities();
  }, []);

  const handleDeleteIconClick = (id) => {
    setAddressId(id);
    setShowModal(true);
  };
  const handleDelete = async () => {
    const res = await deleteWebuserAddress(addressId, userId);
    setShowModal(false);
    getAddress();
  };
  return (
    <>
      <Helmet>
        <title>{`Adreslerim - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>
      <div className="form-container ">
        <h3 className="mb-4">Adreslerim</h3>
        <div className="register-content">
          {address &&
            address.map((el) => {
              return (
                <form key={el._id} className=" register-form">
                  {" "}
                  <div
                    className="form-area container-address form-content
                  "
                  >
                    <h5 className="my-3">{el.title}</h5>
                    <input readOnly value={el.address} type="text" />{" "}
                    <button
                      onClick={() => handleClick(el)}
                      type="button"
                      className="edit-button"
                    >
                      {" "}
                      Düzenle
                    </button>
                    <button
                      onClick={(e) => handleDeleteIconClick(el._id)}
                      type="button"
                      className="delete-button"
                    >
                      {" "}
                      <img src={logo} />
                    </button>
                  </div>
                </form>
              );
            })}
        </div>
        <div>
          <NewAddress
            index={0}
            setMessage={setMessage}
            setSuccess={setSuccess}
            setNewEntry={setNewEntry}
            setCityData={setCityData}
            cityData={cityData}
            getCities={getCities}
            getDistricts={getDistricts}
            getNeighborhood={getNeighborhood}
            mahalle={mahalle}
            setMahalle={setMahalle}
            districtData={districtData}
            setDistrictData={setDistrictData}
            getAddress={getAddress}
            updateValue={newEntry}
            selectedNeighborhood={selectedNeighborhood}
            setSelectedNeighborhood={setSelectedNeighborhood}
          />
        </div>
        {success && <SuccessInfo message={message} />}
        <DeleteModal
          showModal={showModal}
          id={addressId}
          handleDelete={handleDelete}
          setShowModal={setShowModal}
        />
      </div>
    </>
  );
}

export default Addresses;
