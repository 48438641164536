import React, { useEffect, useState } from "react";
import MobileApp from "../../app-layouts/MobileApp";
import baseService from "../../../library/network/services/BaseService";
import arrow from "../../../utils/img/icons/arrowicon.svg";
import { useNavigate } from "react-router-dom";
import BlogGrids from "../../app-layouts/BlogGrids";
import Loader from "../../app-layouts/Loader";
import Pagination from "../../app-layouts/Pagination";
import { Helmet } from "react-helmet-async";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebase";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";

function Blogs() {
  const blogService = async () => baseService.get("blogs");
  const [loading, setLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageUrls, setImageUrls] = useState({});
  const postsPerPage = 12;
  const getBlogs = async () => {
    setLoading(true);
    let res = await blogService();
    res.data.map((item) => {
      if (item.mainImages) {
        getImageUrl(item.mainImages[0]);
      }
    });
    res.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

    setBlogPosts(res.data);

    setLoading(false);
  };
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);
  // Calculate the index of the last post for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  // Calculate the index of the first post for the current page
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Get the current posts for the current page
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{`Blog - Online Lostra`}</title>
        <meta name="description" content="Online Lostra Blog" />
      </Helmet>

      <section className="page-section">
        {" "}
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Blog", url: "/blog" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Blog</h4>
            <h1>Haberler ve Yazılar</h1>
          </div>
          <BlogGrids imageUrls={imageUrls} blogPosts={currentPosts} />
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={blogPosts.length}
            paginate={paginate}
          />
        </div>
        <MobileApp />
      </section>
    </>
  );
}

export default Blogs;
