import React from "react";
import cancel from "../../../utils/img/icons/cancel.svg";

function OptionalImages({ offer, offerIndex, imageUrls }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState("");
  const [index, setIndex] = React.useState(0);
  function goToPreviousImage(off, e) {
    e.stopPropagation();
    const previousIndex =
      index - 1 < 0 ? offer.optionsExtraImages.length - 1 : index - 1;
    setSelectedImageIndex(
      offer.optionsExtraImages[previousIndex].replace(/ /g, "%20")
    );
    setIndex(previousIndex);
  }

  function goToNextImage(e) {
    e.stopPropagation();
    const nextIndex =
      index + 1 > offer.optionsExtraImages.length - 1 ? 0 : index + 1;
    setSelectedImageIndex(
      offer.optionsExtraImages[nextIndex].replace(/ /g, "%20")
    );

    setIndex(nextIndex);
  }

  return (
    <div style={{ margin: "20px 0" }}>
      {offer.optionsExtraImages.map((imageName, imageIndex) => (
        <img
          className="optionalImages"
          onClick={() => {
            setIndex(imageIndex);
            setSelectedImageIndex(imageName);
            setIsModalOpen(true);
          }}
          key={imageIndex}
          src={imageUrls[imageName]}
          alt={`Offer ${offerIndex + 1} Image ${imageIndex + 1}`}
        />
      ))}
      {selectedImageIndex && (
        <div
          style={{
            opacity: isModalOpen ? 1 : 0,
            backgroundColor: isModalOpen ? "rgba(0,0,0,0.3)" : "transparent",
            zIndex: isModalOpen ? 1000 : -1,
          }}
          className="mainImagesOrderOverlay"
          onClick={() => setIsModalOpen(false)}
        >
          <div className="slideshowContainer">
            <img
              style={{
                width: "25px",
                backgroundColor: "grey",
                padding: "5px",
                borderRadius: "30%",
                position: "absolute",
                top: "5px",
                zIndex: "1001",
                cursor: "pointer",
                right: "5px",
              }}
              onClick={() => setIsModalOpen(false)}
              src={cancel}
              alt="cancel"
            />
            <img
              style={{
                objectFit: "contain",
                maxWidth: "400px",
                maxHeight: "700px",
                transform: isModalOpen
                  ? "scale(1) translate(0,0)"
                  : "scale(0.8) translate(0,100px)",
                transition: "transform 0.5s ease-in-out",
              }}
              src={imageUrls[selectedImageIndex]}
              alt={`Offer ${offerIndex + 1} Image ${selectedImageIndex + 1}`}
            />
            <div className="slideshowNavigation">
              <div
                className="slideshowArrow"
                onClick={(e) => {
                  goToPreviousImage(offer, e);
                }}
              >
                &lt;
              </div>
              <div className="slideshowArrow" onClick={(e) => goToNextImage(e)}>
                &gt;
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OptionalImages;
