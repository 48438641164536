import React, { lazy } from "react";
import { Helmet } from "react-helmet-async";
import Carousel from "../../app-layouts/Carousel";

const Slider = lazy(() => import("../../app-layouts/Slider"));
const Process = lazy(() => import("../../app-layouts/Process"));
const BigInfo = lazy(() => import("../../app-layouts/BigInfo"));
const MobileApp = lazy(() => import("../../app-layouts/MobileApp"));
const Privileges = lazy(() => import("../../app-layouts/Privileges"));
const HizmetBolgeleri = lazy(() => import("../../app-layouts/HizmetBolgeleri"));
const Comments = lazy(() => import("../../app-layouts/Comments"));
const SssPanel = lazy(() => import("../../app-layouts/SssPanel"));

function Home() {
  return (
    <>
      <Helmet>
        <title>{`Online Ayakkabı ve Çanta Temizleme Öncüsü - Online Lostra`}</title>
        <meta
          name="description"
          content="Online Lostra üzerinden ayakkabılarının ilk günkü temizliğinde olmasını sağlayabilirsin. Detaylı bilgi almak için Online Lostra internet sitesini ziyaret et."
        />
      </Helmet>
      <Slider />
      <Carousel />
      <Process />
      <BigInfo />
      <MobileApp />
      <Privileges />
      <HizmetBolgeleri />
      <Comments />
      <SssPanel />
    </>
  );
}

export default Home;
