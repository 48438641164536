import React, { useState, useContext, useEffect } from "react";
import simpleCartIcon from "../../../utils/img/icons/simpleCartIcon.svg";
import baseService from "../../../library/network/services/BaseService";
import { useParams } from "react-router-dom";
import SliderProduct from "./SliderProduct";
import simpleLike from "../../../utils/img/icons/simpleheart.svg";
import heartFilled from "../../../utils/img/icons/heartFilled.svg";
import copyIcon from "../../../utils/img/icons/copyicon.svg";
import payment from "../../../utils/img/payment.png";
import ProductCards from "../EcommerceList/ProductCards";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";
import Loader from "../../app-layouts/Loader";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";
import Recommendations from "../EcommerceList/Recommendations";

const EcommerceProduct = () => {
  const { cart, favorites, addToCart, removeFromCart, toggleFavorite } =
    useContext(CartFavoritesContext);
  const [selectedSize, setSelectedSize] = useState("");
  const [error, setError] = useState({
    quantity: "",
    size: "",
    color: "",
  });
  const [selectedColor, setSelectedColor] = useState("");
  const [thumbnail, setThumbnail] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1); // Default to 1 instead of 0
  const [productData, setProductData] = useState({});
  const [addedToCart, setAddedToCart] = useState(false);
  const { slug } = useParams();

  const productsService = async () => baseService.get("ecommerceproducts");

  const getImageUrl = async (image, type) => {
    const storageRef = ref(storage, "products/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        if (type === "thumbnail") {
          setThumbnail((prevThumbnail) => ({
            ...prevThumbnail,
            [image]: url,
          }));
        } else {
          setImageUrls((prev) => ({ ...prev, [image]: url }));
        }
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };

  const handleSizeSelection = (e) => {
    setError({ ...error, size: "" });
    setSelectedSize(e.target.innerText);
  };

  const handleColorSelection = (color) => {
    setError({ ...error, color: "" });
    setSelectedColor(color);
  };

  const getProducts = async () => {
    const response = await productsService();
    const limitedProducts = response?.data?.slice(0, 4);
    setProducts(limitedProducts);

    limitedProducts.forEach((product) => {
      getImageUrl(product.mainImage, "general");
      product?.images?.forEach((image) => {
        getImageUrl(image, "general");
      });
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const copyToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying URL to clipboard:", error);
      });
  };

  const handleMultiplier = (type) => {
    if (type === "decrement") {
      if (counter > 1) {
        setCounter(counter - 1);
      }
    } else {
      setCounter(counter + 1);
    }
  };

  const productService = async (id) =>
    baseService.get("ecommerceproducts/name/" + id);

  const getProduct = async () => {
    setLoading(true);
    try {
      const res = await productService(slug);
      setProductData(res.data);

      res?.data?.images?.forEach((image) => {
        getImageUrl(image, "thumbnail");
      });

      getImageUrl(res?.data?.mainImage[0], "thumbnail");
      setLoading(false);
    } catch (error) {
      console.error("Error getting product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setThumbnail({});
    getProduct();
  }, [slug]);

  if (loading) {
    return <Loader />;
  }
  const isInCart = cart.some(
    (cartItem) =>
      cartItem.productId === productData._id &&
      cartItem.size === selectedSize &&
      cartItem.color === selectedColor
  );

  const handleAddToCart = () => {
    if (selectedColor === "" || selectedSize === "") {
      setError({
        size: selectedSize === "" ? "Lütfen bir beden seçiniz" : "",
        color: selectedColor === "" ? "Lütfen bir renk seçiniz" : "",
      });
      return;
    }
    if (selectedSize && selectedColor) {
      addToCart({
        productId: productData,
        name: productData.name,
        price: productData.price,
        size: selectedSize,
        color: selectedColor,
        quantity: counter,
      });
      setAddedToCart(true);
      setTimeout(() => {
        setAddedToCart(false);
      }, 3000);
    }
  };

  return (
    <section className="page-section">
      <div className="container">
        <div className="product-details-container">
          <div
            className="mobile"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "flex-start",
              }}
            >
              <img
                style={{
                  cursor: "pointer",
                }}
                src={
                  favorites?.some((fav) => fav?._id === productData?._id)
                    ? heartFilled
                    : simpleLike
                }
                alt="like"
                onClick={() => toggleFavorite(productData)}
              />
              <p className="product-extra-details">
                {favorites?.some((fav) => fav?._id === productData?._id)
                  ? "Favorilerden Kaldır"
                  : "Favorilere Ekle"}
              </p>{" "}
            </div>
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "flex-start",
              }}
            >
              <p className="product-extra-details">Ürünü Paylaş</p>
              <img
                onClick={copyToClipboard}
                style={{
                  cursor: "pointer",
                }}
                src={copyIcon}
                alt="copy"
              />
            </div>
          </div>
          {productData && (
            <SliderProduct imageUrls={thumbnail} product={productData} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "flex-start",
              gap: "24px",
            }}
          >
            <p className="product-details-header">{productData?.name}</p>
            <div className="product-extra-details-container">
              <p className="product-extra-details">
                Ürün Kodu: <span>{productData?.code}</span>
              </p>
              <p className="product-extra-details">
                Stok durumu:{" "}
                {productData?.stock ? (
                  <span id="stock-decleration">Stokta Var</span>
                ) : (
                  <span id="no-stock">Stokta Yok</span>
                )}
              </p>
            </div>
            <p className="product-price-details">{productData?.price} TL</p>
            <div className="horizontal-line"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <p className="product-extra-details">Beden:</p>
              {productData?.size && (
                <div className="tags-container">
                  {productData.size?.map((size, index) => (
                    <div
                      key={index}
                      onClick={handleSizeSelection}
                      className={`tag-item ${
                        selectedSize === size ? "active" : ""
                      }`}
                    >
                      {size}
                    </div>
                  ))}
                </div>
              )}
              <p className="error-text"> {error.size && error.size}</p>
              <p className="product-extra-details">Renk:</p>
              <div className="color-options">
                {productData && Array.isArray(productData?.color) && (
                  <>
                    {productData?.color.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleColorSelection(item)}
                        style={{
                          backgroundColor: item === "Coffee" ? "#a47148" : item,
                          border:
                            selectedColor === item ? "2px solid black" : "",
                        }}
                        className="color-option details"
                        data-bs-toggle="tooltip"
                        title={item}
                      ></div>
                    ))}
                  </>
                )}
              </div>
              <p className="error-text">{error.color && error.color}</p>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <div className="product-counter-container">
                  <span
                    onClick={() => handleMultiplier("decrement")}
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    -
                  </span>
                  <span> {counter}</span>
                  <span
                    onClick={() => handleMultiplier("increment")}
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    +
                  </span>
                </div>

                <button
                  className={`add-cart-button button-primary ${
                    addedToCart ? "added" : ""
                  }`}
                  onClick={handleAddToCart}
                  disabled={!productData?.stock}
                >
                  {addedToCart ? "Sepete Eklendi" : "Sepete Ekle"}
                  <img src={simpleCartIcon} alt="simpleCartIcon" />
                </button>

                {/* <button
                  className="add-cart-button button-primary"
                  onClick={handleAddToCart}
                  disabled={!selectedSize || !selectedColor}
                >
                  Sepete Ekle
                  <img src={simpleCartIcon} alt="simpleCartIcon" />
                </button> */}
                {/* <button className="buy-now-button button-ghost">
                  Hemen Al
                </button> */}
              </div>
            </div>
            <div
              className="web"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "flex-start",
                }}
              >
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  src={
                    favorites?.some((fav) => fav?._id === productData?._id)
                      ? heartFilled
                      : simpleLike
                  }
                  alt="like"
                  onClick={() => toggleFavorite(productData)}
                />
                <p className="product-extra-details">
                  {favorites?.some((fav) => fav?._id === productData?._id)
                    ? "Favorilerden Kaldır"
                    : "Favorilere Ekle"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "flex-start",
                }}
              >
                <p className="product-extra-details">Ürünü Paylaş</p>
                <img
                  onClick={copyToClipboard}
                  style={{
                    cursor: "pointer",
                  }}
                  src={copyIcon}
                  alt="copy"
                />
              </div>
            </div>
            <div className="payment-container">
              <p className="product-extra-details">100% Güvenli Ödeme</p>
              <img src={payment} alt="payment" />
            </div>
          </div>
        </div>
        <Recommendations />
      </div>
    </section>
  );
};

export default EcommerceProduct;
