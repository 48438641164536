import React from "react";

function RadioButtons({
  offerIndex,
  handleDeliveryOptionChange,
  info,
  selectedOptions,
  offer,
  firstOrder,
}) {
  return (
    <>
      <div
        className="main-radio-button"
        style={{ display: "Flex", gap: "10px" }}
      >
        <input
          id={`radio-${offer?._id}-${offerIndex}`}
          type="radio"
          name={`${offer?._id}-${offerIndex}`}
          value={`free-${offer?._id}`}
          checked={
            !selectedOptions.hasOwnProperty(offer?._id + "-" + offerIndex) ||
            selectedOptions[offer?._id + "-" + offerIndex] === 0
          }
          onChange={handleDeliveryOptionChange}
        />
        <div>
          <label
            style={{
              verticalAlign: "middle",
              fontWeight: "500",
            }}
            htmlFor={`radio-${offer?._id}-${offerIndex}`}
          >
            Standart Teslimat
            <span> Ücretsiz</span>
          </label>
          <small className="smallInfo">
            (Standart gönderim) <img alt="info" src={info} />
          </small>
        </div>
      </div>
      {firstOrder ? (
        <div
          className="main-radio-button"
          style={{ display: "Flex", gap: "10px" }}
        >
          <input
            id={`radio3-${offer?._id}-${offerIndex}`}
            type="radio"
            name={`${offer?._id}-${offerIndex}`}
            value={`firstfree-${offer?._id}`}
            checked={selectedOptions[offer?._id + "-" + offerIndex] === 1}
            onChange={handleDeliveryOptionChange}
          />
          <div>
            <label
              style={{
                verticalAlign: "middle",
                fontWeight: "500",
              }}
              htmlFor={`radio3-${offer?._id}-${offerIndex}`}
            >
              Premium Teslimat
              <span
                style={{
                  textDecoration: "line-through",
                }}
              >
                {" "}
                34₺{" "}
              </span>
              <span id="firstFree">(İlk siparişine özel ücretsiz.)</span>
            </label>
            <small className="smallInfo">
              (Online Lostra kutusu) <img alt="info" src={info} />
            </small>
          </div>
        </div>
      ) : (
        <div
          className="main-radio-button"
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <input
            id={`radio2-${offer?._id}-${offerIndex}`}
            type="radio"
            name={`${offer?._id}-${offerIndex}`}
            value={`premium-${offer?._id}`}
            checked={selectedOptions[offer?._id + "-" + offerIndex] === 34}
            onChange={handleDeliveryOptionChange}
          />
          <div>
            <label
              style={{
                verticalAlign: "middle",
                fontWeight: "500",
              }}
              // htmlFor={`radio2-${offer._id}`}
              htmlFor={`radio2-${offer?._id}-${offerIndex}`}
            >
              Premium Teslimat
              <span> 34.00₺</span>
            </label>
            <small className="smallInfo">
              (Online Lostra kutusu) <img alt="info" src={info} />
            </small>
          </div>
        </div>
      )}
    </>
  );
}

export default RadioButtons;
