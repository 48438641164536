import { useState, useEffect } from "react";
import EcommerceCategory from "./EcommerceCategory";
import FilterByBrand from "./FilterByBrand";
import closeButton from "../../../utils/img/icons/close-filter-icon.svg";

function SideMenu({
  categories,
  onCategoryChange,
  onTagChange,
  isMenuOpen,
  setIsMenuOpen,
  menuToggle,
}) {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedTag, setSelectedTag] = useState([]);
  const handleRadioChange = (event) => {
    const category = event.target.value;
    setSelectedValue(category);
    onCategoryChange(category);
  };

  const handleTagClick = (tag) => {
    let updatedTags = [];
    if (selectedTag?.includes(tag)) {
      updatedTags = selectedTag.filter((item) => item !== tag);
    } else {
      updatedTags = [...selectedTag, tag];
    }
    setSelectedTag(updatedTags);
    onTagChange(updatedTags);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 820 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]);

  return (
    <>
      {" "}
      {isMenuOpen && (
        <>
          {" "}
          <div className="overlay" onClick={menuToggle}></div>{" "}
        </>
      )}
      <div className={`side-menu ${isMenuOpen ? "open visible" : "hidden"}`}>
        <div className="close-button-container" onClick={menuToggle}>
          <img
            style={{
              padding: "5px",
              borderRadius: "10px",
            }}
            className="close-button"
            src={closeButton}
            alt="Close"
            onClick={menuToggle}
          />
        </div>
        <div className={`menu-content ${isMenuOpen ? "visible" : "hidden"}`}>
          <p className="filter-title" style={{}} onClick={menuToggle}>
            Filtrele
          </p>
          <EcommerceCategory
            categories={categories}
            selectedValue={selectedValue}
            handleRadioChange={handleRadioChange}
          />
          <div className="horizontal-line"></div>
          <FilterByBrand
            selectedTag={selectedTag}
            handleTagClick={handleTagClick}
          />
        </div>
      </div>
    </>
  );
}

export default SideMenu;
