import React from "react";
import Ayakkabi from "../../utils/img/hizmetlerimiz/ayakkabi-temizleme.png";
import Camasir from "../../utils/img/hizmetlerimiz/camasir-yikama.png";
import Canta from "../../utils/img/hizmetlerimiz/canta-temizleme.png";
import DryClean from "../../utils/img/hizmetlerimiz/kuru-temizleme.png";
import Utu from "../../utils/img/hizmetlerimiz/utuleme-hizmeti.png";
import { Link } from "react-router-dom";

function ServicesMainPanel({ service, index, title, imageUrls }) {
  const icons = {
    ayakkabi: Ayakkabi,
    kurutemizleme: DryClean,
    camasiryikama: Camasir,
    utulemehizmeti: Utu,
    cantatemizleme: Canta,
  };

  return (
    <section className="page-section">
      <div className="container">
        <div className="title">
          <h4>{title?.toLocaleUpperCase("tr-TR")}</h4>
          <h1>{service?.name}</h1>
        </div>
        <div className="row">
          <div className="col-md-6  order-2 order-md-1 my-2 ">
            <div className="service-content">
              {index === 1 && (
                <>
                  {" "}
                  <p
                    dangerouslySetInnerHTML={{ __html: service?.description }}
                  />
                </>
              )}
              {index === 0 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: service?.paragraphId?.content
                      .replace(/CHANGE/g, title)
                      .replace(/SERVISISMI/g, service?.name),
                  }}
                />
              )}
              {index === 2 && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: service?.locationParagraphId?.content.trim(),
                  }}
                />
              )}
              {index === 0 && (
                <Link to="/siparis-olustur">
                  <span className="button button-primary">Sipariş Oluştur</span>
                </Link>
              )}
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2 ">
            <div className="service-image">
              <img
                // src={icons[service?.icon]}
                src={imageUrls[service?.mainImage[0]] || ""}
                alt={service?.name}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="service-content">
              <div>
                {index === 1 && (
                  <>
                    {" "}
                    {/* <div> */}{" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: service?.secondaryDescription.trim(),
                      }}
                    />
                    {/* </div> */}
                    <Link to="/siparis-olustur">
                      <span className="button button-primary">
                        Sipariş Oluştur
                      </span>
                    </Link>
                  </>
                )}
                {index === 2 && (
                  <>
                    {" "}
                    {/* <div> */}{" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          service?.locationSecondaryParagraphId?.content.trim(),
                      }}
                    />
                    {/* </div> */}
                    <Link to="/siparis-olustur">
                      <span className="button button-primary">
                        Sipariş Oluştur
                      </span>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesMainPanel;
