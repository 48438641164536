import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import InputMask from "react-input-mask";
import baseService from "../../../library/network/services/BaseService";
import email from "../../../utils/img/mail-svg.svg";
import person from "../../../utils/img/person-svg.svg";
import call from "../../../utils/img/call.svg";
import ReCAPTCHA from "react-google-recaptcha";

function NonRegistered({
  cityData,
  districtData,
  getDistricts,
  getNeighborhood,
  mahalle,
  setSelectedNeighborhood,
  selectedNeighborhood,
  formikRef,
}) {
  const PHONE_REGEX = /^0(5\d{1}|2[1-9]\d{1})\d{3}\d{4}$/;
  const recaptchaRef = React.useRef();
  const [captchaToken, setCaptchaToken] = React.useState("");
  const [error, setError] = React.useState("");

  const tokenService = () =>
    baseService.post("verify-token", { token: captchaToken });

  return (
    <>
      <div
        style={{ textAlign: "left", width: "100%" }}
        className="register-content mt-5"
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            title: "",
            address: "",
            city: "",
            district: "",
            neighborhood: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.firstname) errors.firstname = "Adınızı giriniz.";
            if (!values.lastname) errors.lastname = "Soyadınızı giriniz.";
            if (!values.email) {
              errors.email = "E-posta adresinizi giriniz.";
            } else if (!/\S+@\S+\.\S+/.test(values.email)) {
              errors.email = "Geçersiz e-posta adresi.";
            }
            if (!values.phone) errors.phone = "Telefon Numaranızı giriniz.";
            if (!values.city) errors.city = "İl seçiniz.";
            if (!values.district) errors.district = "İlçe seçiniz";
            if (!values.neighborhood) errors.neighborhood = "Mahalle seçiniz";
            if (!values.address) errors.address = "Adresinizi giriniz.";
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            values.email = values.email.trim().toLowerCase();
            try {
              const res = await tokenService();
              if (res.data === "OK") {
              } else {
                setError("Testi bir daha yapınız.");
                recaptchaRef.current.reset();
              }
            } catch (error) {
              setError("Bir daha deneyiniz.");
              console.log(error);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="register-form">
              <div
                className="form-area"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: "1" }}>
                  <label htmlFor="firstname">
                    Adın <span>*</span>
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="firstname"
                    placeholder="Adın"
                  />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div
                  style={{
                    flex: "1",
                    marginLeft: "10px",
                    position: "relative",
                  }}
                >
                  <label htmlFor="lastname">
                    Soyadın <span>*</span>
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="lastname"
                    placeholder="Soyadın"
                  />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              <div className="form-area">
                <label htmlFor="email">
                  E-posta <span>*</span>
                </label>

                <Field
                  className="input "
                  type="email"
                  name="email"
                  placeholder="E-posta adresinizi girin."
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <label htmlFor="phone">
                  Telefon <span>*</span>
                </label>

                <InputMask
                  type="tel"
                  className="input"
                  name="phone"
                  placeholder="Telefon numarası gir"
                  mask="0599 999 9999"
                  maskChar="_"
                  onChange={(e) => {
                    const formattedValue = e.target.value.replace(/\s+/g, "");
                    setFieldValue("phone", formattedValue);
                  }}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <label htmlFor="title">
                  Adres Başlığı <span>*</span>
                </label>
                <Field type="text" name="title" placeholder="Adres Başlığı" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <label htmlFor="address">
                  Adres <span>*</span>{" "}
                </label>
                <Field type="text" name="address" placeholder="Adres" />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div className="form-area">
                <label htmlFor="city" style={{ display: "block" }}>
                  İl <span>*</span>
                </label>
                <Field
                  as="select"
                  onChange={(e) => {
                    setFieldValue("city", e.target.value);
                    getDistricts(e.target.value);
                  }}
                  name="city"
                  value={values.city}
                  style={{ display: "block" }}
                >
                  <option style={{ display: "none" }} value="">
                    İl seçiniz
                  </option>
                  {cityData &&
                    cityData.map((city) => (
                      <option
                        label={city.city}
                        key={city._id}
                        value={city.city}
                      >
                        {city.name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error-auth"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="form-area"
              >
                <div style={{ flex: "1" }}>
                  <label htmlFor="district" style={{ display: "block" }}>
                    İlçe <span>*</span>
                  </label>
                  <Field
                    as="select"
                    onChange={async (e) => {
                      const selectedDistrict = districtData?.districts?.filter(
                        (item) => item.name === e.target.value
                      );
                      setFieldValue(
                        "district",
                        selectedDistrict[0]?.name || ""
                      );
                      await getNeighborhood(selectedDistrict[0]?.id || "");
                    }}
                    name="district"
                    value={values.district}
                    style={{ display: "block" }}
                  >
                    <option style={{ display: "none" }} value="">
                      İlçe seçiniz
                    </option>
                    {districtData &&
                      districtData?.districts?.map((item) => (
                        <option
                          label={item.name}
                          key={item.id}
                          value={item.name}
                        >
                          {item.name} hi
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="district"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div style={{ flex: "1", marginLeft: "10px" }}>
                  <label htmlFor="neighborhood" style={{ display: "block" }}>
                    Mahalle <span>*</span>
                  </label>
                  <Field
                    as="select"
                    onChange={(e) => {
                      const selectedObject = mahalle?.neighborhoods.find(
                        (item) => item.name === e.target.value
                      );
                      setSelectedNeighborhood(selectedObject || {});
                      setFieldValue("neighborhood", selectedObject?.name || "");
                    }}
                    name="neighborhood"
                    value={values.neighborhood}
                    style={{ display: "block" }}
                  >
                    <option style={{ display: "none" }} value="">
                      Mahalle seçiniz
                    </option>
                    {mahalle &&
                      mahalle?.neighborhoods?.map((item) => (
                        <option
                          label={item.name}
                          key={item.id}
                          value={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage
                    name="neighborhood"
                    component="div"
                    className="error-auth"
                  />
                </div>
              </div>
              <div className="form-area">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(token) => setCaptchaToken(token)}
                />
                {error && <div className="error-auth">{error}</div>}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default NonRegistered;
