import React, { useEffect } from "react";
import ImageSlider from "./ImageSlider";

function OrderImages({ offer, offerIndex, imageUrls }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState("");

  const [index, setIndex] = React.useState(0);
  const [selectedOffer, setSelectedOffer] = React.useState({});
  const [selectedOfferIndex, setSelectedOfferIndex] = React.useState(0);
  useEffect(() => {
    setSelectedOffer(offer);
    setSelectedOfferIndex(offerIndex);
  }, [offer, offerIndex]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      {offer.mainImages.map((imageName, imageIndex) => (
        <img
          className="mainImagesOrder"
          onClick={() => {
            setIndex(imageIndex);
            setSelectedImageIndex(imageName);
            setIsModalOpen(true);
            setSelectedOffer(offer);
            setSelectedOfferIndex(offerIndex);
          }}
          key={imageIndex}
          src={imageUrls[imageName]}
          alt={`Offer ${offerIndex + 1} Image ${imageIndex + 1}`}
        />
      ))}
      {selectedImageIndex && (
        <ImageSlider
          imageUrls={imageUrls}
          offer={selectedOffer}
          offerIndex={selectedOfferIndex}
          setIsModalOpen={setIsModalOpen}
          setSelectedImageIndex={setSelectedImageIndex}
          isModalOpen={isModalOpen}
          index={index}
          setIndex={setIndex}
          selectedImageIndex={selectedImageIndex}
        />
      )}
    </div>
  );
}

export default OrderImages;
