import React, { useState } from "react";
import cancel from "../../utils/img/icons/cancel-gray.svg";
import Arrow from "../../utils/img/icons/left-arrow.svg";
import KurumsalFaturaForm from "./KurumsalFaturaForm";
import Edit from "../../utils/img/icons/edit.svg";
import Delete from "../../utils/img/icons/delete-gray.svg";
import Bireysel from "./BireyselFaturaForm";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import baseService from "../../library/network/services/BaseService";

function SelectInvoiceModal(props) {
  const {
    setInvoiceModal,
    userData,
    setUserData,
    setInvoice,
    setSelectInvoice,
    getUser,
    selectedInvoiceObj,
    selectedAddressObj,
    setSelectedInvoiceObj,
  } = props;

  const userId = storageHelper.getStoreWithDecryption("userId");
  const [newInvoice, setNewInvoice] = useState(false);
  const [activeMenu, setActiveMenu] = useState("kurumsal");
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [selectedPersonalInvoice, setSelectedPersonalInvoice] = useState({});
  const [selectedCorporateInvoice, setSelectedCorporateInvoice] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const token = storageHelper.getStoreWithDecryption("token");
  const invoiceService = async (userId, id) =>
    baseService.delete("webusers/deleteCorporate/" + userId + "/" + id);
  const handleDeleteCorperate = async (id) => {
    try {
      const res = await invoiceService(userId, id);

      getUser();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeletePersonal = async (id) => {
    try {
      const res = await invoiceService(userId, id);

      getUser();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="modal-overlay">
        <div
          style={{
            height: "auto",
            padding: "35px",
            width: "auto",
            position: "relative",
            maxWidth: "500px",
          }}
          className="modal-container"
        >
          {" "}
          <div
            style={{
              top: "25px",
              right: "25px",
              cursor: "pointer",
              position: "absolute",
            }}
          >
            <img
              src={cancel}
              alt="cancel"
              className="cancel-icon"
              onClick={() => setInvoiceModal(false)}
            />
          </div>
          <div
            className={!newInvoice ? "d-none" : "d-block"}
            style={{
              top: "25px",
              left: "30px",
              cursor: "pointer",
              position: "absolute",
            }}
          >
            <img onClick={() => setNewInvoice(false)} src={Arrow} />
          </div>
          {newInvoice ? (
            <div>
              {" "}
              <div className="d-flex align-items-center justify-content-center">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Yeni Fatura Ekle
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "20px",
                  flexWrap: "wrap",
                }}
              >
                <button
                  onClick={() => setActiveMenu("kurumsal")}
                  className={
                    activeMenu === "kurumsal"
                      ? "btn invoice-type-button active"
                      : "btn invoice-type-button"
                  }
                  // className="btn invoice-type-button"
                >
                  Kurumsal
                </button>
                <button
                  onClick={() => setActiveMenu("bireysel")}
                  className={
                    activeMenu === "bireysel"
                      ? "btn invoice-type-button active"
                      : "btn invoice-type-button"
                  }
                  //  className="btn invoice-type-button active"
                >
                  Bireysel
                </button>
              </div>
              {activeMenu === "kurumsal" ? (
                <KurumsalFaturaForm
                  selectedCorporateInvoice={selectedCorporateInvoice}
                  userData={userData}
                  setNewInvoice={setNewInvoice}
                  getUser={getUser}
                  setUserData={setUserData}
                />
              ) : (
                <Bireysel
                  selectedPersonalInvoice={selectedPersonalInvoice}
                  getUser={getUser}
                  setNewInvoice={setNewInvoice}
                  userData={userData}
                  setUserData={setUserData}
                />
              )}
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Fatura Bilgisi Seçiniz
                </h3>
              </div>

              <div
                style={{
                  // borderBottom: "1px solid #E5E5E5",
                  padding: "20px 10px",
                  // width: "400px",
                  width: "100%",
                }}
              >
                <form>
                  <div>
                    {" "}
                    <div
                      className="main-radio-button"
                      style={{
                        borderBottom: "1px solid #E5E5E5",
                        paddingBottom: "20px ",
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <input
                        style={{
                          marginBottom: "3px",
                        }}
                        type="radio"
                        id="standart"
                        name="invoice"
                        onChange={(e) => {
                          if (selectedAddressObj) {
                            setSelectedInvoice("");
                            setSelectedInvoiceObj({
                              firstname: userData?.firstname,
                              lastname: userData?.lastname,
                              idNumber: "11111111111",
                              address: selectedAddressObj.address,
                            });
                          } else {
                            setErrorMessage(
                              "Lütfen önce sipariş adresi seçiniz."
                            );
                          }
                        }}
                      />
                      <label
                        className="invoice-label-text"
                        style={{
                          color: "#3E57FE",
                        }}
                        htmlFor="standart"
                      >
                        Standart E-Arşiv Faturası İstiyorum
                      </label>
                    </div>
                    {userData &&
                      userData.corporateInvoice.map((el) => {
                        return (
                          <div
                            className="main-radio-button"
                            key={el.id}
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              paddingBottom: "20px ",
                              display: "flex",
                              alignItems: "flex-end",
                              gap: "10px",
                            }}
                          >
                            <input
                              style={{
                                marginBottom: "3px",
                              }}
                              type="radio"
                              id={el.id}
                              name="invoice"
                              onChange={(e) => {
                                setErrorMessage("");
                                setSelectedInvoiceObj(el);
                                setSelectedInvoice(e.target.id);
                              }}
                            />
                            <label
                              className="invoice-label-text"
                              htmlFor={el.id}
                            >
                              {el.invoiceTitle} -{el.taxNumber}
                              <div>
                                <img
                                  onClick={() => {
                                    setSelectedCorporateInvoice(el);
                                    setNewInvoice(true);
                                    setActiveMenu("kurumsal");
                                  }}
                                  className="cursor"
                                  src={Edit}
                                  alt="edit"
                                />
                                <img
                                  className="cursor"
                                  onClick={() => {
                                    handleDeleteCorperate(el.id);
                                  }}
                                  src={Delete}
                                  alt="delete"
                                />
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    {userData &&
                      userData.personalInvoice.map((el) => {
                        return (
                          <div
                            className="main-radio-button"
                            key={el.id}
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              paddingBottom: "20px ",
                              display: "flex",
                              alignItems: "flex-end",
                              gap: "10px",
                            }}
                          >
                            <input
                              style={{
                                marginBottom: "3px",
                              }}
                              type="radio"
                              id={el.id}
                              name="invoice"
                              onChange={(e) => {
                                setErrorMessage("");
                                setSelectedInvoiceObj(el);
                                setSelectedInvoice(e.target.id);
                              }}
                            />
                            <label
                              className="invoice-label-text"
                              htmlFor={el.id}
                            >
                              {el?.firstname} {el?.lastname} - {el.idNumber}
                              <div>
                                <img
                                  className="cursor"
                                  onClick={() => {
                                    setSelectedPersonalInvoice(el);
                                    setNewInvoice(true);

                                    setActiveMenu("bireysel");
                                  }}
                                  src={Edit}
                                  alt="edit"
                                />
                                <img
                                  className="cursor"
                                  onClick={() => handleDeletePersonal(el.id)}
                                  src={Delete}
                                  alt="delete"
                                />
                              </div>
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </form>
                <p className="error-auth">{errorMessage}</p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <button
                  onClick={() => {
                    setNewInvoice(true);
                    setSelectedCorporateInvoice({});
                    setSelectedPersonalInvoice({});
                  }}
                  className="btn cancel-button"
                  style={{ padding: "12px 24px", flex: "1" }}
                >
                  Yeni Fatura Ekle
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    setInvoice(false);
                    setSelectInvoice(true);
                    setNewInvoice(false);
                  }}
                  className="btn campaign-select-button"
                  style={{ padding: "12px 24px", flex: "1" }}
                >
                  Seç
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SelectInvoiceModal;
