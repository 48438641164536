import React, { useEffect, useState } from "react";
import SssPanel from "../../app-layouts/SssPanel";
import Privileges from "../../app-layouts/Privileges";
import ServicesMainPanel from "../../app-layouts/ServicesMainPanel";
import { getAllServiceCurrentCategories } from "../../../library/network/requests/serviceCategory";
import { useLocation, useParams } from "react-router-dom";

import baseService from "../../../library/network/services/BaseService";
import Loader from "../../app-layouts/Loader";
import { Helmet } from "react-helmet-async";

import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import slugify from "slugify";
import { turkeyCitiesAndIstanbulDistricts } from "../../../utils/helpers/CityNames";

function Locations() {
  const [ilce, setIlce] = useState("");
  const [service, setService] = useState("");
  const params = useParams();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const id = query.get("id");
  const [loading, setLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [serviceData, setServiceData] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [districtData, setDistrictData] = useState([]);
  const cityService = async () => baseService.get("districts");
  const districtService = async () => baseService.get("cities");
  const [istanbulDistrictClicked, setIstanbulDistrictClicked] = useState(false);
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getCities = async (ilce) => {
    setLoading(true);
    const res = await cityService();
    const filteredData = res.data.filter(
      (item) =>
        slugify(item?.name, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }).toLocaleLowerCase() === ilce
    );

    setCityData(filteredData);
  };

  const getCityById = async (ilce) => {
    const res = await districtService();
    const filteredData = res.data.filter(
      (item) =>
        slugify(item?.city, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }) == ilce.toLocaleLowerCase()
    );

    setDistrictData(filteredData);
  };
  const getServiceCategory = async (serviceName) => {
    const response = await getAllServiceCurrentCategories();
    const selectedService = response?.data?.find(
      (item) =>
        slugify(item.name, {
          lower: true,
          remove: /[*+~.()'"!:@?]/g,
        }) === serviceName
    );
    if (selectedService.mainImage) {
      getImageUrl(selectedService.mainImage[0]);
    }
    setServiceData(selectedService);
  };
  const istanbulDistricts = [
    { urlFriendly: "adalar", original: "Adalar" },
    { urlFriendly: "arnavutkoy", original: "Arnavutköy" },
    { urlFriendly: "atasehir", original: "Ataşehir" },
    { urlFriendly: "avcilar", original: "Avcılar" },
    { urlFriendly: "bagcilar", original: "Bağcılar" },
    { urlFriendly: "bahcelievler", original: "Bahçelievler" },
    { urlFriendly: "aksaray", original: "Aksaray" },
    { urlFriendly: "bakirkoy", original: "Bakırköy" },
    { urlFriendly: "basaksehir", original: "Başakşehir" },
    { urlFriendly: "bayrampasa", original: "Bayrampaşa" },
    { urlFriendly: "besiktas", original: "Beşiktaş" },
    { urlFriendly: "beykoz", original: "Beykoz" },
    { urlFriendly: "beylikduzu", original: "Beylikdüzü" },
    { urlFriendly: "beyoglu", original: "Beyoğlu" },
    { urlFriendly: "buyukcekmece", original: "Büyükçekmece" },
    { urlFriendly: "catalca", original: "Çatalca" },
    { urlFriendly: "cekmekoy", original: "Çekmeköy" },
    { urlFriendly: "esenler", original: "Esenler" },
    { urlFriendly: "esenyurt", original: "Esenyurt" },
    { urlFriendly: "eyupsultan", original: "Eyüpsultan" },
    { urlFriendly: "fatih", original: "Fatih" },
    { urlFriendly: "gaziosmanpasa", original: "Gaziosmanpaşa" },
    { urlFriendly: "gungoren", original: "Güngören" },
    { urlFriendly: "kadikoy", original: "Kadıköy" },
    { urlFriendly: "kagithane", original: "Kağıthane" },
    { urlFriendly: "kartal", original: "Kartal" },
    { urlFriendly: "kucukcekmece", original: "Küçükçekmece" },
    { urlFriendly: "maltepe", original: "Maltepe" },
    { urlFriendly: "pendik", original: "Pendik" },
    { urlFriendly: "sancaktepe", original: "Sancaktepe" },
    { urlFriendly: "sariyer", original: "Sarıyer" },
    { urlFriendly: "silivri", original: "Silivri" },
    { urlFriendly: "sile", original: "Şile" },
    { urlFriendly: "sisli", original: "Şişli" },
    { urlFriendly: "sultanbeyli", original: "Sultanbeyli" },
    { urlFriendly: "sultangazi", original: "Sultangazi" },
    { urlFriendly: "tuzla", original: "Tuzla" },
    { urlFriendly: "umraniye", original: "Ümraniye" },
    { urlFriendly: "uskudar", original: "Üsküdar" },
    { urlFriendly: "zeytinburnu", original: "Zeytinburnu" },
  ];

  useEffect(() => {
    setLoading(true);

    const districtName = params.lokasyon.split("-")[0];
    const isIstanbulDistrict = istanbulDistricts.some(
      (district) => district.urlFriendly === districtName
    );

    if (isIstanbulDistrict) {
      setIstanbulDistrictClicked(true);
      getCities(districtName);
    } else {
      getCityById(districtName);
      setIstanbulDistrictClicked(false);
    }

    const parts = params.lokasyon.split("-");
    const originalName = turkeyCitiesAndIstanbulDistricts.find(
      (item) => item.urlFriendly === districtName
    )?.original;
    setIlce(originalName);

    let serviceName;
    if (parts.length < 3) {
      serviceName = params.lokasyon.split("-")[1];
    } else {
      serviceName = `${params.lokasyon.split("-")[1]}-${
        params.lokasyon.split("-")[2]
      }`;
      setService(serviceName);
    }

    getServiceCategory(serviceName);

    setLoading(false);
  }, [service, ilce]);
  if (loading) return <Loader />;
  return (
    <>
      <Helmet>
        {id === 1 ? (
          <title>
            {` ${ilce} ${serviceData?.name} Hizmeti, Fiyatları - Online Lostra`}
          </title>
        ) : (
          <title>
            {` ${ilce} ${serviceData?.name} Hizmeti, Fiyatları - Online Lostra`}
          </title>
        )}
        <meta
          name="description"
          content={` ${ilce} ${serviceData?.name} hizmeti Online Lostra'da! ${ilce} ${serviceData?.name} fiyatları hakkında bilgi almak ve sipariş oluşturmak için hemen tıkla.
`}
        />
      </Helmet>
      <section className="page-section">
        <div className="container">
          {serviceData.name && (
            <Breadcrumbs
              paths={[
                { text: "Anasayfa", url: "/" },
                {
                  text: `${serviceData?.name} Lokasyonları`,
                  url: `/${slugify(serviceData?.name, {
                    lower: true,
                    remove: /[*+~.()'"!:@?]/g,
                  })}-lokasyonlari`,
                },
                { text: `${ilce} ${serviceData?.name}`, url: `/${params}` },
              ]}
            />
          )}
        </div>
      </section>
      {serviceData.name && (
        <ServicesMainPanel
          imageUrls={imageUrls}
          service={serviceData}
          index={0}
          title={ilce}
        />
      )}
      {istanbulDistrictClicked ? (
        <section
          style={{ backgroundColor: " #f9f9f9" }}
          className="page-section"
        >
          <div className="container">
            <div className="title">
              <h4>{ilce}</h4>
              <h2>{serviceData?.name} Hizmeti Verdiğimiz Mahalleler</h2>
            </div>

            <div className="d-flex justify-content-center flex-wrap">
              {cityData &&
                cityData[0]?.neighborhood?.map((city, index) => (
                  <div
                    key={city._id}
                    className=" location-chips d-inline-flex align-items-center   rounded-pill px-4 py-1 m-2"
                  >
                    <p className="m-0  ">
                      {city?.neighborhood}{" "}
                      {serviceData?.name?.toLocaleUpperCase()} HİZMETİ
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <section
          style={{ backgroundColor: " #f9f9f9" }}
          className="page-section"
        >
          <div className="container">
            <div className="title">
              <h4>{districtData[0]?.city?.toLocaleUpperCase("tr-TR")}</h4>
              <h2>{serviceData?.name} Hizmeti Verdiğimiz İlçeler</h2>
            </div>
            <div className="d-flex justify-content-center flex-wrap">
              {districtData &&
                districtData[0]?.district?.map((city, index) => (
                  <div
                    key={city._id}
                    className=" location-chips d-inline-flex align-items-center   rounded-pill px-4 py-1 m-2"
                  >
                    <p className="m-0  ">
                      {city?.name} {serviceData?.name?.toLocaleUpperCase()}{" "}
                      HİZMETİ
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </section>
      )}
      <Privileges />
      <SssPanel pageType="general" category="general" />
    </>
  );
}

export default Locations;
