import baseService from "../services/BaseService";

export const getAllWebusers = async () => baseService.get("webusers");
export const loginUser = async (data) =>
  baseService.post("webusers/login", data);
export const loginWithGui = async (data) =>
  baseService.post("webusers/loginGui", data);
export const registerUser = async (data) =>
  baseService.post("webusers/register", data);
export const getUserById = async (id) => baseService.get(`webusers/${id}`);
export const getUserAddress = async (id) =>
  baseService.get(`webusers/address/${id}`);
export const getEcommerceAddress = async (id) =>
  baseService.get(`webusers/ecommerceaddress/${id}`);
export const addWebusers = async (data) => baseService.post("webusers/", data);
export const updateWebusers = async (id, data) =>
  baseService.post(`webusers/update/${id}`, data);
export const deleteWebusers = async (id) =>
  baseService.delete(`webusers/${id}`);
export const addUserAddress = async (id, data) =>
  baseService.post(`webusers/address/${id}`, data);
export const updateUserAddress = async (data_id, userId, data) =>
  baseService.post(`webusers/updateAddress/${data_id}/${userId}`, data);
export const deleteWebuserAddress = async (id, userId) =>
  baseService.post(`webusers/deleteAddress/${id}/${userId}`);
export const updateWebuserDetails = async (id, data) =>
  baseService.post(`webusers/update/${id}`, data);
export const updateWebuserAddress = async (data_id, userId, data) =>
  baseService.post(`webusers/updateAddress/${data_id}/${userId}`, data);
