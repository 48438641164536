import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import like from "../../../utils/img/icons/love.svg";
import likeRed from "../../../utils/img/icons/unlove.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CartFavoritesContext } from "../../../contexts/CartFavoritesContext";
import slugify from "slugify";

function ProductCards({ products, imageUrls }) {
  const { cart, favorites, toggleFavorite, addToCart, removeFromCart } =
    useContext(CartFavoritesContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleFavoriteClick = (e, productId) => {
    e.preventDefault();
    toggleFavorite(productId);
  };

  const handleCartClick = (e, product) => {
    e.preventDefault();
    if (cart.some((cartItem) => cartItem._id === product._id)) {
      removeFromCart(product._id);
    } else {
      addToCart(product);
    }
  };

  return (
    <>
      {products &&
        products.map((product) => {
          const slugifiedName = slugify(product.name, {
            lower: true,
            remove: /[*+~.()'"!:@?]/g,
          });

          return (
            <Link to={`/magaza/${slugifiedName}`} key={product._id}>
              <div
                style={{
                  position: "relative",
                }}
                className="product-item"
              >
                <div
                  style={{
                    margin: "0 30px",
                  }}
                >
                  <Slider {...settings}>
                    <div className="product-image">
                      <img
                        src={imageUrls[product.mainImage]}
                        alt={product.name}
                      />
                    </div>
                    <div className="product-image">
                      {product.images.map((image, index) => (
                        <img
                          key={index}
                          src={imageUrls[image]}
                          alt={product.name}
                        />
                      ))}
                    </div>
                  </Slider>
                </div>
                <img
                  src={
                    favorites.some((fav) => fav._id === product._id)
                      ? likeRed
                      : like
                  }
                  alt="Like"
                  style={{
                    position: "absolute",
                    width: "24px",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleFavoriteClick(e, product)}
                />
                <div className="product-content">
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="color-options">
                      <div className="color-options">
                        {product?.color.slice(0, 5).map((item, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor:
                                item === "Coffee" ? "#a47148" : item,
                            }}
                            className="color-option"
                            data-bs-toggle="tooltip"
                            title={item}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className="product-name">{product?.name}</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <p className="commerce-price">
                      {product.price.toFixed(2)} TL
                    </p>
                    {/* <button
                      className="button button-primary addAcartButton"
                      onClick={(e) => handleCartClick(e, product)}
                    >
                      {cart.some((cartItem) => cartItem._id === product._id)
                        ? "Sepetten Kaldır"
                        : "Sepete Ekle"}
                    </button> */}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
    </>
  );
}

export default ProductCards;
