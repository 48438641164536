import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetailsById } from "../../../library/network/requests/order-offer-details";
import Loader from "../../app-layouts/Loader";
import moment from "moment";
import "moment/locale/tr";
import AddressForm from "../../app-layouts/AddressForm";
import ArrowLeft from "../../../utils/img/icons/share.svg";
import cancel from "../../../utils/img/icons/cancel-gray.svg";
import { Helmet } from "react-helmet-async";
import OrderPickupRange from "../../app-layouts/OrderPickupRange";
import { getUserAddress } from "../../../library/network/requests/webusers";
import baseService from "../../../library/network/services/BaseService";
import {
  userAddressUpdate,
  userDateUpdate,
} from "../../../library/network/requests/address";
import { getOrdersById } from "../../../library/network/requests/orders";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";

function OrderDetails() {
  const params = useParams();
  const [change, setChange] = useState("order"); // [change, setChange
  const [isClicked, setIsClicked] = useState(false);
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [address, setAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressObj, setSelectedAddressObj] = useState(null);
  const [pickupDate, setPickupDate] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [pickupTimeRange, setPickupTimeRange] = useState(null);
  const [hours, setHours] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const deliveryService = async () => baseService.get("deliveryhours");
  const navigate = useNavigate();
  const getAddress = async (id) => {
    try {
      await getUserAddress(id).then((res) => {
        setAddress(res?.data);
      });
    } catch (error) {
      setAddress([]);
    }
  };
  const getHours = async () => {
    try {
      const res = await deliveryService();

      const filtered = res?.data?.sort((a, b) =>
        a.startTime.localeCompare(b.startTime)
      );
      setHours(filtered);
    } catch (error) {
      console.log(error);
    }
  };
  function getStatusTranslation(statusName) {
    switch (statusName) {
      case "active":
        return "Sipariş oluşturuldu";
      case "pickedup":
        return "Teslim alındı";
      case "pending":
        return "İnceleniyor";
      case "ongoing":
        return "Temizleniyor";
      case "delivering":
        return "Yolda";
      case "done":
        return "Teslim edildi";
      case "cancelled":
        return "İptal edildi";
      default:
        return statusName;
    }
  }
  var valid = function (current) {
    var today = moment();
    var tomorrow = today.clone().add(1, "day");

    if (current.day() === 0) {
      return false; // It's Sunday, not valid
    }

    return current.isSameOrAfter(today);
  };
  function formatDate(dateStr) {
    if (dateStr.date) {
      const date = new Date(dateStr.date);
      const optionsTime = {
        hour: "numeric",
        minute: "numeric",
      };
      const optionsDate = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      const formattedDate = date.toLocaleDateString("tr-TR", optionsDate);
      const formattedTime = date.toLocaleTimeString("tr-TR", optionsTime);
      return `${formattedDate} / ${formattedTime}`;
    } else {
      const date = new Date(dateStr);
      const optionsTime = {
        hour: "numeric",
        minute: "numeric",
      };
      const optionsDate = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      const formattedDate = date.toLocaleDateString("tr-TR", optionsDate);
      const formattedTime = date.toLocaleTimeString("tr-TR", optionsTime);
      return `${formattedDate} / ${formattedTime}`;
    }
  }
  const handleAddressChange = (event) => {
    const selectedAddressObjj = address.find(
      (item) => item._id === event.target.value
    );
    setSelectedAddress(event.target.value);

    setPickupDate("");
    setPickupTimeRange("");
    setSelectedAddressObj(selectedAddressObjj);
  };
  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  }
  const getOrderDetail = async () => {
    const res = await getOrderDetailsById(id);

    setOrderDetails(res.data);
  };
  const getOrderById = async () => {
    try {
      setLoading(true);

      // const res = await getOrderDetailsById(id);\
      const res = await getOrdersById(id);

      getAddress(res.data[0]?.webUserId?._id);
      setPickupTimeRange(res.data[0]?.pickupTimeRange);
      setSelectedAddressObj(res.data[0]?.address[0]);
      setSelectedAddress(res.data[0]?.address[0]?._id);
      const dateString = res.data[0]?.pickUpDate;
      let date;
      if (dateString !== null) {
        const [day, month, year] = dateString.split("/");
        date = new Date(`${year}-${month}-${day}`);
        setPickupDate(date);
        setIsEditable(isPickupDateWithin24Hours(date));
      } else {
        date = dateString;
        setIsEditable(false);
      }

      setOrder(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  function isPickupDateWithin24Hours(pickupDate) {
    const currentDate = new Date();

    const timeDifference = pickupDate.getTime() - currentDate.getTime();

    const hoursDifference = timeDifference / (1000 * 60 * 60);
    const isWithin24Hours = hoursDifference < 24;

    return isWithin24Hours;
  }
  const updateDeliverySubmit = async () => {
    if (selectedAddressObj?.neighborhood?.isCourier) {
      const res = await userAddressUpdate(id, { address: selectedAddressObj });
      const response = await userDateUpdate(id, {
        pickUpDate: pickupDate,
        pickupTimeRange: pickupTimeRange,
      });

      setChange("order");
      getOrderById();
    } else {
      await userAddressUpdate(id, { address: selectedAddressObj });
      await userDateUpdate(id, {
        pickUpDate: null,
        pickupTimeRange: null,
      });

      setChange("order");
      getOrderById();
    }
  };
  useEffect(() => {
    getOrderById();
    getHours();
    getOrderDetail();
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Sipariş Detayı</title>
        <meta name="description" content="Sipariş Detayı" />
      </Helmet>
      <section className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="section-title flex-container ">
                <button
                  onClick={() => {
                    navigate("/hesap/siparis");
                    handleButtonClicks(
                      "hesap/siparis",
                      "Geç",
                      "siparis detayları"
                    );
                  }}
                  className=" button-previous"
                >
                  <img src={ArrowLeft} />
                </button>
                <h2
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  Sipariş Detay
                </h2>
              </div>
              <div
                style={{
                  margin: "20px 0",
                  textAlign: "start",
                }}
              >
                <p>
                  Sipariş No :{" "}
                  <span className="font-weight-bold">
                    {order && order[0]?.orderNumber}
                  </span>
                </p>
                <h3 className="my-4">Aldığınız Hizmetler</h3>
                <div
                  style={{
                    flexWrap: "wrap",
                  }}
                  className="services-icon flex-container"
                >
                  {orderDetails &&
                    orderDetails.map((item, index) => {
                      return (
                        <div key={index} className="card">
                          <p>{item?.serviceCategoryId?.name}</p>
                          <span
                            className={item?.serviceCategoryId?.icon}
                          ></span>
                          {/* {item?.serviceCategoryId?.icon} */}
                        </div>
                      );
                    })}
                </div>
                <h3 className="my-4">Sipariş Durumu</h3>
                <p
                  style={{
                    fontWeight: "600",
                    width: "fit-content",
                  }}
                  className="order-information"
                >
                  {getStatusTranslation(
                    order &&
                      order[0]?.status[order[0]?.status.length - 1]?.statusName
                  )}
                </p>
                {order &&
                  order[0]?.status[order[0]?.status.length - 1]?.statusName ===
                    "ongoing" && (
                    <small
                      className="order-information-title"
                      style={{
                        display: "block",
                      }}
                    >
                      Gönderdiğiniz ürünler işlem aşamasındadır.
                    </small>
                  )}
                {order &&
                  order[0]?.status[order[0]?.status?.length - 1]?.statusName ===
                    "delivering" && (
                    <small
                      className="order-information-title"
                      style={{
                        display: "block",
                      }}
                    >
                      Ürünleriniz teslim birimine aktarılmıştır.
                    </small>
                  )}
                {order &&
                  order[0]?.status[order[0]?.status.length - 1]?.statusName ===
                    "done" && (
                    <small
                      className="order-information-title"
                      style={{
                        display: "block",
                      }}
                    >
                      Ürünleriniz teslim edilmiştir.
                    </small>
                  )}
                {order &&
                  order[0]?.status[order[0]?.status.length - 1]?.statusName ===
                    "active" && (
                    <>
                      {!order[0]?.pickupTimeRange ? (
                        <small
                          className="order-information-title"
                          style={{
                            display: "block",
                          }}
                        >
                          Ürünlerinizi anlaşmalı olduğumuz Sürat Kargo’nun, size
                          en yakın şubesine anlaşma numaramız olan 1381312520
                          ile 3 gün içerisinde <br /> teslim etmenizi rica
                          ederiz. Kargo sisteminde şirket ünvanımız “DU TECH
                          INTERNET HİZMETLERİ VE LOJİSTİK A.Ş.” olarak
                          görünecektir.
                          <p>
                            {" "}
                            <br /> Paketinizin içine
                            <strong>
                              “ {order && order[0]?.orderNumber}”
                            </strong>{" "}
                            Sipariş Numaranızı yazmanızı rica ederiz.
                          </p>
                        </small>
                      ) : (
                        <small
                          className="order-information-title"
                          style={{
                            display: "block",
                          }}
                        >
                          Ürünleriniz anlaşmalı olduğumuz hepsiJET Kargo ile
                          belirtilen zaman diliminde alınacaktır. Alım öncesi
                          size iletilen “iade kargonuz alınacaktır” şeklindeki
                          mesaj ile bilgilendirileceksiniz.
                          <br />
                          Ürünlerinizi, ağzı bağlı olacak şekilde içerisine
                          sipariş numaranızı yazarak poşetleyebilirsiniz. İşlem
                          sonunda özel olarak paketlenip sizlere teslim
                          edilecektir.
                          <br />
                        </small>
                      )}
                    </>
                  )}
                {order &&
                  order[0]?.status[order[0]?.status.length - 1]?.statusName ===
                    "pickedup" && (
                    <small
                      className="order-information-title"
                      style={{
                        display: "block",
                      }}
                    >
                      Yırtılma, açılma ve tahribata göre fiyatlarımız
                      değişkenlik gösterebilir. Doğru fiyatlandırma ve hızlı
                      çözüm için, ayakkabılarınız bizlere ulaştıktan sonra
                      inceleme ekibimiz tarafından detaylı kontrol edilir ve
                      gerekli işlemler “İnceleme” adımında onayınıza sunulur.
                      Onayınıza istinaden ise işlemlere başlanmaktadır.
                    </small>
                  )}
                {order &&
                  order[0]?.status[order[0]?.status.length - 1]?.statusName ===
                    "pending" && (
                    <small
                      className="order-information-title"
                      style={{
                        display: "block",
                      }}
                    >
                      Siparişiniz bize ulaştı. İnceleme ekibimiz tarafından
                      detaylı kontrol edilip, gerekli işlemler için onayınıza
                      sunulacaktır.
                    </small>
                  )}
                {order && order[0]?.isConfirmed && (
                  <button
                    onClick={() => setIsClicked(true)}
                    className="button button-primary my-4"
                  >
                    Onay Verdiğiniz İşlemler
                  </button>
                )}{" "}
                {isClicked && (
                  <div className="order-offer-container">
                    <img
                      style={{
                        position: "absolute",
                        right: "40px",
                        cursor: "pointer",
                      }}
                      src={cancel}
                      onClick={() => {
                        setIsClicked(false);
                      }}
                      alt="cancel"
                    />
                    {order &&
                      order?.map((item, index) => {
                        return (
                          <div key={index}>
                            {React.Children.toArray(
                              item?.confirmOrder?.map((product, num) => (
                                <>
                                  <p>
                                    <span
                                      style={{
                                        fontWeight: "600",
                                      }}
                                    >
                                      Ürün:{" "}
                                    </span>
                                    {product?.productId?.name?.name}
                                  </p>
                                  <ul>
                                    {product.services.map((service, index) => (
                                      <li key={service._id}>
                                        <span
                                          style={{
                                            fontWeight: "600",
                                          }}
                                        >
                                          {service?.name}:
                                        </span>{" "}
                                        {service?.price} TL
                                      </li>
                                    ))}
                                  </ul>
                                  {product?.note?.length > 0 && (
                                    <p>Ürün Notu: {product?.note}</p>
                                  )}
                                </>
                              ))
                            )}
                          </div>
                        );
                      })}
                    {orderDetails &&
                      orderDetails?.map((item, index) => {
                        return (
                          <div key={index}>
                            {React.Children.toArray(
                              item?.offers?.map((product, num) => (
                                <>
                                  {product.service.map(
                                    (serviceEntry, index) => (
                                      <>
                                        {serviceEntry?.name ===
                                          "İşlem Yok (İade Edilecek)" && (
                                          <p key={index}>
                                            <span style={{ fontWeight: "600" }}>
                                              İptal edilen ürün:{" "}
                                            </span>
                                            {product?.name?.name}
                                          </p>
                                        )}

                                        {product?.notes?.length > 0 && (
                                          <p key={index}>
                                            Ürün Notu: {product?.notes}
                                          </p>
                                        )}
                                      </>
                                    )
                                  )}
                                </>
                              ))
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-5">
              <form>
                {change === "order" && (
                  <div
                    style={{
                      textAlign: "start",
                      // maxWidth: "400px",
                    }}
                    className="order-box"
                  >
                    <h3 className="mb-4">Sipariş Özeti</h3>{" "}
                    {!isEditable && (
                      <span
                        onClick={() => {
                          setChange("update");
                        }}
                        disabled={isEditable}
                        style={{
                          color: "rgb(62, 87, 254)",
                          marginBottom: "0.5rem",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Siparişi düzenle
                      </span>
                    )}
                    {/* <img src={ArrorRight} /> */}
                    <table>
                      <tbody>
                        <tr>
                          <td>Alım tarihi:</td>
                          {order && (
                            <>
                              {
                                <td>
                                  {order[0]?.orderId?.status.map((status) => {
                                    if (status.statusName === "pickedup") {
                                      return formatDate(status.date);
                                    }
                                  })}
                                </td>
                              }
                            </>
                          )}
                        </tr>
                        {order &&
                          order[0]?.orderId?.status[
                            order[0]?.orderId?.status.length - 1
                          ]?.statusName !== "cancelled" && (
                            <tr>
                              <td>Teslimat tarihi: </td>
                              {order &&
                              order[0]?.orderId?.status[
                                order[0]?.orderId?.status.length - 1
                              ]?.statusName === "done" ? (
                                <td>
                                  {formatDate(
                                    order[0]?.orderId?.status[
                                      order[0]?.orderId?.status.length - 1
                                    ].date
                                  )}
                                </td>
                              ) : (
                                <td>5-15 iş günü.</td>
                              )}
                            </tr>
                          )}
                      </tbody>
                    </table>
                    <div className="order-field">
                      <h5>Teslim Adresi:</h5>
                      <p>
                        {order && <>{order[0]?.orderId?.address[0]?.address}</>}
                      </p>
                    </div>
                    <div className="order-field">
                      <h5>Notunuz:</h5>
                      <textarea
                        name="note"
                        id="note"
                        value={order && order[0]?.orderId?.note}
                        readOnly
                        maxLength="500"
                        cols="30"
                        rows="6"
                      />
                    </div>
                  </div>
                )}
              </form>

              {change === "update" && (
                <div
                  style={{
                    textAlign: "start",
                  }}
                  className="order-box"
                >
                  <div className=" order-container-form order-form">
                    {" "}
                    <h3 className="mb-4">Sipariş Güncelle</h3>{" "}
                    <AddressForm
                      index={0}
                      address={address}
                      selectedAddress={selectedAddress}
                      setIsClicked={setIsClicked}
                      handleAddressChange={handleAddressChange}
                    />
                    <div
                      // className="delivery active"
                      className={
                        selectedAddressObj?.neighborhood?.isCourier
                          ? "delivery active"
                          : "delivery"
                      }
                    >
                      <OrderPickupRange
                        pickupDate={pickupDate}
                        valid={valid}
                        index={0}
                        setPickupDate={setPickupDate}
                        pickupTimeRange={pickupTimeRange}
                        setPickupTimeRange={setPickupTimeRange}
                        hours={hours}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => setChange("order")}
                    className="button button-ghost-input w-100"
                  >
                    Sipariş Ekranına dön
                  </button>
                  <button
                    type="submit"
                    onClick={updateDeliverySubmit}
                    disabled={
                      !selectedAddressObj ||
                      (selectedAddressObj?.neighborhood?.isCourier &&
                        (!pickupDate || !pickupDate))
                    }
                    className="button button-contact button-primary my-3  w-100"
                  >
                    Kaydet
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OrderDetails;
