import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { addCreditCard } from "../../library/network/requests/creditcards";
import { storageHelper } from "../../utils/helpers/StorageHelper";
import { userCardUpdate } from "../../library/network/requests/card";
import { useParams } from "react-router-dom";
const creditSchema = Yup.object().shape({
  cardTitle: Yup.string().required("Kredi kartı başlığı gereklidir."),
  number: Yup.string()
    .required("Kart numarası gereklidir.")
    .matches(
      /^(\d{4}\s){3}\d{4}$|^\d{16}$/,
      "Kart numarası 16 haneli olmalıdır."
    ),
  owner: Yup.string().required("Kart sahibinin adı gereklidir."),
  CVV: Yup.string()
    .required("CVV Kodu gereklidir.")
    .matches(
      /^[0-9]{3}$/,
      "CVV kodu 3 haneli olmalıdır ve sadece rakam içermelidir."
    ),
  endDateMonth: Yup.string().test(
    "valid-endDateMonth",
    "Geçersiz son kullanma ayı",
    function (value) {
      const today = new Date();
      const enteredMonth = parseInt(value, 10);
      const enteredYear = parseInt(this.parent.endDateYear, 10);
      const currentYear = today.getFullYear() % 100;
      const currentMonth = today.getMonth() + 1;

      if (enteredYear < currentYear) {
        return false;
      } else if (enteredYear === currentYear && enteredMonth < currentMonth) {
        return false;
      }
      return true;
    }
  ),
  endDateYear: Yup.string().test(
    "valid-endDateYear",
    "Geçersiz son kullanma yılı",
    function (value) {
      const today = new Date();
      const enteredYear = parseInt(value, 10);
      const currentYear = today.getFullYear() % 100;

      if (enteredYear < currentYear) {
        return false;
      }
      return true;
    }
  ),
});

function AddCreditCard({
  getCards,
  setShowCredit,
  setMessage,
  setSuccess,
  index,
  handleNewCreditChange,
  change,
  setChange,
  cards,
  selectedCard,
  setIsClicked,
  getOffersById,
}) {
  const userId = storageHelper.getStoreWithDecryption("userId");

  const params = useParams();

  const handleChange = async (item) => {
    try {
      const res = await userCardUpdate(params.id, { cardID: item._id });
      if (res.status === 200) {
        setMessage("Kartınız başarıyla değişmiştir.");
        setSuccess(true);
        getOffersById();
        setIsClicked && setIsClicked((prevstate) => !prevstate);
        setTimeout(() => {
          setSuccess(false);
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {" "}
      <div className="form-container ">
        {change && (
          <>
            <h3 className="contact-form-title">Kayıtlı Kartlarım</h3>
            <div className="divider">
              <span></span>
            </div>
            <div className="register-content">
              {React.Children.toArray(
                cards.map((item) => (
                  <>
                    <label
                      style={{
                        padding: "10px 15px",
                        background: "#f1f1f1",
                        cursor: "pointer",
                        border:
                          selectedCard === item._id
                            ? "2px solid var(--primary-blue)"
                            : "none",
                      }}
                      className="d-flex form-area terms align-items-center justify-content-between order-box"
                    >
                      <div>
                        <h5 style={{ marginBottom: "5px" }}>
                          {item.cardTitle}
                        </h5>
                        <small style={{ fontWeight: "400", fontSize: "14px" }}>
                          {item.number.slice(0, 4) +
                            " " +
                            item.number.slice(4, 8) +
                            " **** ****"}
                          <br />
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <small>
                              {item.endDateMonth} / {item.endDateYear}
                            </small>
                            <small>{item.CVV.slice(0, 1) + "**"}</small>
                          </div>
                        </small>
                      </div>
                      <div className="main-radio-button d-flex flex-column align-items-center justify-content-center">
                        <input
                          type="radio"
                          className="m-0"
                          name="address"
                          value={item.title}
                          id={item._id}
                          checked={selectedCard === item._id}
                          onChange={() => handleChange(item)}
                        />
                        <small style={{ fontWeight: "400", fontSize: "14px" }}>
                          Seç
                        </small>
                      </div>
                    </label>
                  </>
                ))
              )}
            </div>
          </>
        )}
        <h3 className="contact-form-title">Yeni Kart Tanımla</h3>
        <div className="register-content">
          <Formik
            initialValues={{
              cardTitle: "",
              number: "",
              owner: "",
              CVV: "",
              endDateMonth: "",
              endDateYear: "",
            }}
            validationSchema={creditSchema}
            onSubmit={async (
              values,
              { setSubmitting, setFieldValue, resetForm }
            ) => {
              values.number = values.number.replaceAll(" ", "");

              const res = await addCreditCard(userId, values);
              if (res.status === 200) {
                if (index === 1) {
                  handleNewCreditChange(res.data);
                }
                setSuccess(true);
                setShowCredit && setShowCredit((prevstate) => !prevstate);
                setMessage("Kartınız başarıyla eklenmiştir.");
                setTimeout(() => {
                  setSuccess(false);
                }, 1500);
              }

              getCards();
              resetForm();
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="register-form">
                <div className="form-area">
                  <label htmlFor="cardTitle">Kredi Kartı Başlığı</label>
                  <Field
                    type="text"
                    name="cardTitle"
                    value={values.cardTitle}
                    placeholder="Kredi Kartı Başlığı"
                  />
                  <ErrorMessage
                    name="cardTitle"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div className="form-area">
                  <label htmlFor="owner">İsim Soyisim </label>
                  <Field
                    type="text"
                    name="owner"
                    value={values.owner}
                    placeholder="Kredi Kartı Üzerindeki İsim Soyismi Giriniz"
                  />
                  <ErrorMessage
                    name="owner"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div className="form-area">
                  <label htmlFor="number">Kart Numarası </label>
                  <Field
                    type="text"
                    name="number"
                    value={values.number}
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="9999 9999 9999 9999"
                        maskChar=" "
                        placeholder="Kart Numarasını Giriniz"
                      />
                    )}
                    onChange={(event) => {
                      const newCardNumber = event.target.value.replaceAll(
                        " ",
                        ""
                      );
                      setFieldValue("number", newCardNumber);
                    }}
                  />
                  <ErrorMessage
                    name="number"
                    component="div"
                    className="error-auth"
                  />
                </div>
                <div
                  className="form-area"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  {" "}
                  <div style={{ flex: "1" }}>
                    <label htmlFor="date">Son Kullanma Tarihi</label>
                    <InputMask
                      mask="99/99"
                      onChange={(e) => {
                        const dates = e.target.value;
                        const [endDateMonth, endDateYear] = dates.split("/");
                        setFieldValue("endDateMonth", endDateMonth);
                        setFieldValue("endDateYear", endDateYear);
                      }}
                      name="date"
                      value={values.date}
                      placeholder="Ay / Yıl"
                    />

                    <ErrorMessage
                      name="date"
                      component="div"
                      className="error-auth"
                    />
                    <ErrorMessage
                      name="endDateMonth"
                      component="div"
                      className="error-auth"
                    />
                    <ErrorMessage
                      name="endDateYear"
                      component="div"
                      className="error-auth"
                    />
                  </div>
                  <div style={{ flex: "1", marginLeft: "10px" }}>
                    <label htmlFor="CVV">CVV</label>
                    <Field
                      type="text"
                      name="CVV"
                      value={values.CVV}
                      placeholder="CVV Kodunu Giriniz"
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="CVV"
                  component="div"
                  className="error-auth"
                />

                {change ? (
                  <>
                    <div className="register-button-area">
                      <button
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !values.owner ||
                          !values.CVV ||
                          !values.number ||
                          !values.cardTitle
                        }
                        className="button button-primary w-100"
                      >
                        Kaydet
                      </button>
                      <button
                        type="button"
                        onClick={() => setChange("order")}
                        className="button button-ghost-input w-100"
                      >
                        Sipariş Ekranına dön
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="form-area contact-button-container">
                    <button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !values.owner ||
                        !values.CVV ||
                        !values.number ||
                        !values.cardTitle
                      }
                      className="button button-contact button-primary"
                    >
                      Kaydet
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default AddCreditCard;
