import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ paths }) => {
  return (
    <div style={{ marginLeft: "10px" }}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: paths.map((path, index) => ({
              "@type": "ListItem",
              position: index + 1,
              name: path.text,
              item: path.url,
            })),
          }),
        }}
      />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {paths.map((path, index) => (
            <li
              className={`breadcrumb-item ${
                index === paths.length - 1 ? "active" : ""
              }`}
              key={index}
            >
              {index === paths.length - 1 ? (
                path.text
              ) : (
                <Link to={path.url}>{path.text}</Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
