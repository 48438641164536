import React from "react";
import RadioButton from "../../app-layouts/RadioButton";

function EcommerceCategory({ selectedValue, handleRadioChange, categories }) {
  const radioOptions = [
    {
      name: "ayakkabi-temizleme",
      value: "ayakkabi-temizleme",
      label: "Ayakkabı temizleme",
    },
    {
      name: "ayakkabi-silme",
      value: "ayakkabi-silme",
      label: "Ayakkabı silme",
    },
    {
      name: "canta-temizleme",
      value: "canta-temizleme",
      label: "Çanta temizleme",
    },
    { name: "boyama", value: "boyama", label: "Boyama" },
  ];
  return (
    <div className="side-menu-group-container">
      <p className="side-menu-title">KATEGORİ</p>
      <div className="side-menu-group-menu-items">
        <div className="radio-button-container-ecommmerce">
          <RadioButton
            name="all"
            value="all"
            defaultChecked
            checked={selectedValue === "all"}
            onChange={handleRadioChange}
            id="all"
          />
          <label
            htmlFor="all"
            className={selectedValue === "all" ? "active" : ""}
          >
            Hepsi
          </label>
        </div>
        {categories?.map((option, index) => (
          <div key={index} className="radio-button-container-ecommmerce">
            <RadioButton
              name={option.name}
              value={option._id}
              checked={selectedValue === option._id}
              onChange={handleRadioChange}
              id={option._id}
            />
            <label
              htmlFor={option._id}
              className={selectedValue === option._id ? "active" : ""}
            >
              {option.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EcommerceCategory;
