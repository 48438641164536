import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useParams } from "react-router-dom";
import baseService from "../../../library/network/services/BaseService";
import BlogGrids from "../../app-layouts/BlogGrids";
import CampaignGrids from "../../app-layouts/campaignGrids";
import { set } from "react-hook-form";
import Loader from "../../app-layouts/Loader";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebase";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import handleButtonClicks from "../../../utils/helpers/handleButtonClicks";

function CampaignPages() {
  const [campaigns, setCampaigns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [imageUrls, setImageUrls] = useState({});
  const { slug } = params;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const parseHtml = (text) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(text, "text/html");
    const plainText = parsedDocument.body.textContent;
    return plainText;
  };
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };

  useEffect(() => {
    // setLoading(true);

    const getData = async () => {
      setLoading(true);
      const res = await baseService.get(`campaigns/${query.get("id")}`);
      setData(res.data);
      res.data.mainImages.map((image) => {
        getImageUrl(image);
      });
      setLoading(false);
    };

    const getCampaigns = async () => {
      const res = await baseService.get("campaigns");
      setCampaigns(res.data);

      res.data.map((blog) => {
        if (blog.mainImages) {
          getImageUrl(blog.mainImages[0]);
        }
      });
    };

    getCampaigns();
    getData();
    // setLoading(false);
  }, [slug]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {data && (
            <div>
              <Helmet>
                {/* <title>{blog.title}</title> */}
                <meta name="description" content={data?.title} />
                <meta property="og:title" content={data?.title} />
                <meta property="og:type" content="article" />

                <meta property="og:image" content={data?.mainImages} />
                {/* <meta property="og:description" content={campaign.} /> */}
              </Helmet>

              <section className="page-section">
                <div className="container">
                  <Breadcrumbs
                    paths={[
                      { text: "Anasayfa", url: "/" },
                      { text: "Kampanyalar", url: "/kampanyalar" },
                      { text: `${data.title}`, url: "/kampanyalar" },
                    ]}
                  />
                </div>
                <div className="container">
                  <img
                    className="blog-cover"
                    src={imageUrls[data?.mainImages]}
                    alt="campaign Cover"
                  />
                  <div className="title">
                    <h1 style={{ maxWidth: "100%" }} className="my-5">
                      {data?.title}
                    </h1>
                  </div>
                  <div className="first-half">
                    <p
                      className="blogContent"
                      dangerouslySetInnerHTML={{
                        __html: parseHtml(data?.content),
                      }}
                    />
                  </div>
                  <div>
                    {/* <div className="my-5">
                      <h3 style={{ fontSize: "18px" }}>Kampanya Kodu</h3>{" "}
                      <button className="button button-primary-outline ">
                        {data?.promoCode}
                      </button>
                    </div> */}
                    <Link
                      onClick={() => {
                        handleButtonClicks(
                          "siparis-olustur",
                          "Sipariş Oluştur",
                          "Kampanyalar"
                        );
                      }}
                      to="/siparis-olustur"
                    >
                      <span className="button button-primary">
                        Sipariş Oluştur
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="title">
                  <h2 className="my-5">Diğer Kampanyalar</h2>
                </div>
                {/* <BlogGrids blogPosts={blogPosts?.slice(-3)} /> */}
                <CampaignGrids
                  imageUrls={imageUrls}
                  campaigns={campaigns?.slice(-3)}
                />
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CampaignPages;
