import React, { useState } from "react";
import MobileApp from "../../app-layouts/MobileApp";
import baseService from "../../../library/network/services/BaseService";
import Loader from "../../app-layouts/Loader";
import Pagination from "../../app-layouts/Pagination";
import CampaignGrids from "../../app-layouts/campaignGrids";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebase";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "../../app-layouts/Breadcrumbs";
import balaban from "../../../utils/img/carousel/balaban 1.svg";
import image2 from "../../../utils/img/carousel/image 2359.svg";
import image3 from "../../../utils/img/carousel/image 2360.svg";
import image4 from "../../../utils/img/carousel/image 2361-2.svg";
import image5 from "../../../utils/img/carousel/image 2362.svg";
import image6 from "../../../utils/img/carousel/image 2363.svg";
import image7 from "../../../utils/img/carousel/image 2364.svg";
import image8 from "../../../utils/img/carousel/image 2365.svg";
import image10 from "../../../utils/img/carousel/image 2361.svg";
import image9 from "../../../utils/img/carousel/Rectangle 22410.svg";
function Campaigns() {
  const brandLogos = [
    image2,
    image9,
    image3,
    image10,
    image5,

    image6,
    image4,
    balaban,
    image7,
    image8,
  ];
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 12;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentCampaigns = campaigns.slice(indexOfFirstPost, indexOfLastPost);
  const [imageUrls, setImageUrls] = useState({});
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getImageUrl = async (image) => {
    const storageRef = ref(storage, "images/" + image);
    try {
      const url = await getDownloadURL(storageRef);
      if (url) {
        setImageUrls((prev) => ({ ...prev, [image]: url }));
      }
    } catch (error) {
      console.error("Error getting image URL from Firebase Storage:", error);
    }
  };
  const getCampaigns = async () => {
    setLoading(true);
    const res = await baseService.get("campaigns");
    setCampaigns(res.data);
    res.data.map((item) => {
      if (item.mainImages) {
        getImageUrl(item.mainImages[0]);
      }
    });
    setLoading(false);
  };
  useState(() => {
    getCampaigns();
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{`Kampanyalar - Online Lostra`}</title>
        <meta name="description" content="Lostra Web" />
      </Helmet>

      <section className="page-section">
        <div className="container">
          <Breadcrumbs
            paths={[
              { text: "Anasayfa", url: "/" },
              { text: "Kampanyalar", url: "/kampanyalar" },
            ]}
          />
        </div>
        <div className="container">
          <div className="title">
            <h4>Kampanyalar</h4>
            <h1 style={{ maxWidth: "100%" }}>
              Online Lostra Üyelerine Özel Kampanyalar
            </h1>
          </div>
          <CampaignGrids imageUrls={imageUrls} campaigns={campaigns} />
          <div style={{ marginTop: "80px" }} className="title">
            <h4>ANLAŞMALI FİRMALAR</h4>
            <h2 style={{ maxWidth: "100%" }}>
              Çalışanlarına Avantaj Sağladığımız Firmalar
            </h2>
          </div>
          <div className="brand-logos">
            {brandLogos.map((logo, index) => (
              <div className="brand-logo" key={index}>
                <img src={logo} alt="" />
              </div>
            ))}
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={campaigns.length}
            paginate={paginate}
          />
        </div>
      </section>
      <MobileApp />
    </>
  );
}

export default Campaigns;
