import React from "react";
import HizliSiparis from "../../utils/img/icons/hizli-siparis.png";
import GuvenliOdeme from "../../utils/img/icons/guvenli-odeme.png";
import PremiumUyelik from "../../utils/img/icons/premium-uyelik.png";
import SiparisTakibi from "../../utils/img/icons/siparis-takibi.png";
import Kargo from "../../utils/img/icons/ozenli-kargo.png";
import Teslimat from "../../utils/img/icons/jet-hizinda-teslim.png";

function Privileges() {
  return (
    <>
      <section className="big-title ">
        <div className="container">
          <div className="title">
            <h2>OnlIne Lostra Ayrıcalıkları</h2>
            <p className="text-wrap">
              Türkiye’nin tüm illerinden ayakkabı temizleme hizmetimizden
              faydalanabilirsiniz.
            </p>
          </div>
          <div className="privileges">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="privilege-box blue">
                  <img
                    src={HizliSiparis}
                    alt="Hızlı Sipariş - Online Lostra Ayrıcalıkları"
                  />
                  <h3>Hızlı Sipariş</h3>
                  <p>
                    Sadece birkaç tıkla dilediğiniz lostra ve kuru temizleme
                    hizmetimizden faydalanın.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="privilege-box yellow">
                  <img
                    src={GuvenliOdeme}
                    alt="Güvenli Ödeme - Online Lostra Ayrıcalıkları"
                  />
                  <h3>Güvenli Ödeme</h3>
                  <p>
                    Müşterilerimizin 3D Secure yöntemi ile güvenli ve kolay
                    ödeme yapmalarını sağlıyoruz.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="privilege-box green">
                  <img
                    src={Teslimat}
                    alt="Jet Hızında Teslim - Online Lostra Ayrıcalıkları"
                  />
                  <h3>Jet Hızında Teslim</h3>
                  <p>
                    Sipariş verdiğiniz anda harekete geçip ürünlerinizin ait
                    olduğu yere, dolaplarınıza jet hızında ulaşmasını
                    sağlıyoruz.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="privilege-box red">
                  <img
                    src={Kargo}
                    alt="Özenli Kargo - Online Lostra Ayrıcalıkları"
                  />
                  <h3>Özenli Kargo</h3>
                  <p>
                    Paketlemelerinizi özenle yapıp, ürünlerinizi titizlikle
                    taşıyoruz. Belirlenen tarihte kargolarınızı özenle teslim
                    ediyoruz.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="privilege-box purple">
                  <img
                    src={SiparisTakibi}
                    alt="Sipariş Takibi - Online Lostra Ayrıcalıkları"
                  />
                  <h3>Sipariş Takibi</h3>
                  <p>
                    Web sitesi veya mobil uygulama üzerinden kolayca giriş
                    yaparak siparişlerim sekmesi ürünlerinizi adım adım takip
                    edebilirsiniz.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="privilege-box yellow">
                  <img
                    src={PremiumUyelik}
                    alt="Premium Üyelik - Online Lostra Ayrıcalıkları"
                  />
                  <h3>Premium Üyelik</h3>
                  <p>
                    "Premium Üyelik" seçeneğimiz ile birbirinden özel imkan ve
                    avantajlarımızdan faydalanabilirsiniz!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privileges;
