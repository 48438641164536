import React, { createContext, useState, useEffect } from "react";
import { storageHelper } from "../utils/helpers/StorageHelper";
import baseService from "../library/network/services/BaseService";

export const CartFavoritesContext = createContext();

export const CartFavoritesProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = storageHelper.getStoreWithDecryption("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [favorites, setFavorites] = useState(() => {
    const savedFavorites = storageHelper.getStoreWithDecryption("favorites");
    return savedFavorites ? JSON.parse(savedFavorites) : [];
  });

  useEffect(() => {
    storageHelper.setStoreWithEncryption("cart", JSON.stringify(cart));
  }, [cart]);

  const products = async () => baseService.get("ecommerceproducts");
  const getProducts = async () => {
    const res = await products();
    return res.data;
  };
  useEffect(() => {
    const removeOutOfStockItems = (cartItems, products) => {
      const productMap = products.reduce((map, product) => {
        map[product._id] = product;
        return map;
      }, {});

      return cartItems.filter(
        (item) => productMap[item.productId._id]?.stock !== false
      );
    };

    const updateCart = async () => {
      const products = await getProducts();
      let data = storageHelper.getStoreWithDecryption("cart");
      let parsedCart = [];

      try {
        if (data) {
          parsedCart = JSON.parse(data);
        }
      } catch (e) {
        console.error("Failed to parse cart data:", e);
        parsedCart = [];
      }

      const updatedCart = removeOutOfStockItems(parsedCart, products);

      if (updatedCart.length !== parsedCart.length) {
        setCart(updatedCart);
        storageHelper.setStoreWithEncryption(
          "cart",
          JSON.stringify(updatedCart)
        );
      } else {
        setCart(parsedCart);
      }
    };

    updateCart();
  }, []);
  useEffect(() => {
    storageHelper.setStoreWithEncryption(
      "favorites",
      JSON.stringify(favorites)
    );
  }, [favorites]);
  const updateCartQuantity = (productId, size, color, newQuantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.productId._id === productId &&
        item.size === size &&
        item.color === color
          ? { ...item, quantity: newQuantity }
          : item
      )
    );
  };
  const [deliveryPrices, setDeliveryPrices] = useState(0);
  const service = async () => baseService.get("deliveryPrice");
  useEffect(() => {
    service().then((res) => {
      setDeliveryPrices(res.data[0]?.ecommercePrice);
    });
  }, []);
  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProductIndex = prevCart.findIndex(
        (item) =>
          item.productId._id === product.productId._id &&
          item.size === product.size &&
          item.color === product.color
      );
      if (existingProductIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quantity += product.quantity;
        return updatedCart;
      } else {
        return [...prevCart, product];
      }
    });
  };

  const removeFromCart = (productId, size, color) => {
    setCart((prevCart) =>
      prevCart.filter(
        (item) =>
          item.productId._id !== productId ||
          item.size !== size ||
          item.color !== color
      )
    );
  };
  const clearCart = () => {
    setCart([]);
  };
  // const toggleFavorite = (productId) => {
  //   setFavorites((prevFavorites) => {
  //     if (prevFavorites.includes(productId._id)) {
  //       return prevFavorites.filter((id) => id !== productId);
  //     } else {
  //       return [...prevFavorites, productId];
  //     }
  //   });
  // };
  const toggleFavorite = (product) => {
    setFavorites((prevFavorites) => {
      if (!prevFavorites || prevFavorites.length === 0) {
        // If prevFavorites is empty, just add the product
        return [product];
      }

      const isFavorite = prevFavorites.some((fav) => fav._id === product._id);

      if (isFavorite) {
        // Remove the product from favorites
        return prevFavorites.filter((fav) => fav._id !== product._id);
      } else {
        // Add the product to favorites
        return [...prevFavorites, product];
      }
    });
  };

  return (
    <CartFavoritesContext.Provider
      value={{
        cart,
        favorites,
        deliveryPrices,

        clearCart,
        addToCart,
        updateCartQuantity,
        removeFromCart,
        toggleFavorite,
      }}
    >
      {children}
    </CartFavoritesContext.Provider>
  );
};
