import React from "react";

function Aydinlatma({ setAydinlatma }) {
  return (
    <div className="modal-overlay">
      <div className="terms-container">
        <p>
          Tarafınıza ticari elektronik ileti gönderilmesi amacıyla 6563 Sayılı
          Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve 15 Temmuz 2015
          tarihli Ticari İletişim ve Ticari Elektronik İletiler Hakkında
          Yönetmelik ile 04 Ocak 2020 Tarihli Ticari İletişim ve Ticari
          Elektronik İletiler Hakkında Yönetmelikte Değişiklik Yapılmasına Dair
          Yönetmelik kapsamında hizmet sağlayıcı sıfatıyla ticari elektronik
          iletilerin <strong>ONLİNE LOSTRA</strong> tarafından tarafınıza
          gönderilmesi için vereceğiniz onayınıza istinaden iletişim verileriniz
          (cep telefonu numarası, e-posta adresi), tarafınızca ticari elektronik
          ileti gönderimi reddetme hakkınızı kullanıncaya kadar devam edecektir.
          Ticari elektronik ileti gönderilmesi için vereceğiniz onay 6698 sayılı
          Kişisel Verilerin Korunması Kanunu (“KVKK”) 5’inci maddesinin 1’inci
          fıkrası kapsamında istediğiniz zaman ve herhangi bir sınırlamaya gerek
          olmaksızın geri alabileceğiniz açık rızanız olarak kabul edilmektedir.
          Bunun yanında iletişim bilgileriniz, yetkili kişi, kurum ve
          kuruluşlara bilgi verilmesi amacıyla Kanunun 5’inci maddesinin 2’nci
          fıkrasının (a) bendinde yer alan “kanunlarda açıkça öngörülmesi”
          hukuki sebebine dayanarak İleti Yönetim Sistemine aktarılacaktır.
        </p>
        <p>
          <strong>ONLİNE LOSTRA</strong> tarafından KVKK kapsamında hazırlanmış
          olan AYDINLATMA METNİ’nin tamamını ve yukarıdaki bilgilendirmeyi
          okudum, anladım ve hangi kişisel verilerimi ne şekilde işlediği ve
          açık rızamı dilediğim zaman herhangi bir sınırlamaya tabi olmaksızın
          geri alabileceğim hakkında bilgi sahibi oldum.{" "}
        </p>
        <p>
          {" "}
          <strong>ONLİNE LOSTRA</strong> ’ ya vermiş olduğum GSM numaram
          üzerinden tarafıma kısa mesaj gönderimi ile telefon araması
          yapılmasına ve mail adresime e-posta gönderilmesine ticari elektronik
          ileti onayı şeklinde onay veriyorum.
        </p>
        <button
          onClick={() => setAydinlatma(false)}
          className="btn cancel-button"
        >
          Kapat
        </button>
      </div>
    </div>
  );
}

export default Aydinlatma;
